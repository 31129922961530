export const promeritumInterviewForm = {
  data () {
    return {
      interview: {
        recommendable: "",
        familyEnviroment: "",
        freeTime: "",
        sports: "",
        character: "",
        challenges: "",
        achievements: "",
        mediumTermGoals: "",
        longTermGoals: "",
        strengths: "",
        skillToImprove: "",
        careerPath: "",
        capabilityOne: "",
        capabilityTwo: "",
        capabilityThree: "",
        comments: "",
      }
    }
  }
}