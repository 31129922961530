<template>
  <b-navbar
    v-if="!empty"
    wrapper-class="container"
    fixed-top
    class="is-blue hidden-print">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="@/assets/logo-white.png"
          alt="ProMeritum">
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-dropdown :label="user.email">
        <hr class="dropdown-divider">
        <b-navbar-item @click.native="logout">
          Logout
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.session.user
    },

    empty () {
      return !!this.$route.query.empty
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden-print {
  @media print {
    display: none;
  }
}

</style>
