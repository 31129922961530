<template>
  <GChart
    v-if="chartData"
    style="min-height: 100%;"
    type="PieChart"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
export default {
  components: {
    GChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
  },
  watch: {
  },
  data(){
    return {
      chartOptions: {
        pieSliceTextStyle: {
          color: 'white',
        },
        legend: {
          position: 'right',
          alignment: 'center',
          textStyle: {
            color: 'black',
            fontSize: 12,
          },
        },
        chartArea: {
          width: '100%', 
          height: '90%'
        },
        is3D: true,
      },
    }
  }
}
</script>

<style>
  .google-visualization-tooltip { 
    pointer-events: none; 
  }
</style>