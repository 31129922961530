<template>
    <section>
        <b-modal
                :active.sync="localModalActive"
                @close="closeModal"
                has-modal-card
                trap-focus
                :destroy-on-hide="false"
                aria-role="dialog"
                aria-modal
            >
			<div
				class="modal-card"
				style="width: 70rem; margin-left: -5rem"
			>
				<div class="modal-card-head">
					<p class="subtitle">
						<span class="has-text-weight-semibold">
							Historial de cambios del candidatx: {{ profileLogsData.name }}
						</span>
					</p>
				</div>
				<div class="modal-card-body">
					<b-table
						v-if="tableLogs.data.length !== 0"
						:data="tableLogs.data"
						:columns="tableLogs.columns"
						:striped="true"
						:narrowed="true"
						sticky-header
						height="400px"
					>
						<!-- Log date -->
						<b-table-column
							label="Fecha y hora"
							v-slot="props"
							class="columns"
						>
							<span
								class="column p-0 mb-1 is-size-6 is-flex is-align-items-center"
								style="text-wrap: nowrap"
							>
								<b-icon
									class="mr-2"
									icon="calendar"
									size="is-small"
								>
								</b-icon>
								{{ props.row.createdAt | formatToDate }}
							</span>
							<span
								class="column p-0 is-size-7 is-flex is-align-items-center"
								style="text-wrap: nowrap"
							>
								<b-icon
									class="mr-2"
									icon="clock"
									size="is-small"
								>
								</b-icon>
								{{ props.row.createdAt | formatToHours }}
							</span>
						</b-table-column>

						<!-- Editor -->
						<b-table-column
							label="Responsable"
							centered
							v-slot="props"
							class="columns"
							width="200"
						>
							{{ getEditorName(props.row.author) }}
						</b-table-column>

						<!-- Previous value -->
						<b-table-column
							label="Valor anterior"
							class="headerClass"
							v-slot="props"
						>
						<div class="is-flex is-flex-direction-column">
							<span
								class="has-text-weight-semibold	is-size-7 tag is-blue title_tag"
							>
								{{toTitleCase(props.row.field)}}
							</span>
							<span
								class="tag customWidth"
							>
								{{formatValue(props.row.previousValue)  }}
							</span>
						</div>
						</b-table-column>

						<!-- New value -->
						<b-table-column
							label="Nuevo Valor"
							v-slot="props"
						>
						<div class="is-flex is-flex-direction-column">
							<span
								class="has-text-weight-semibold	is-size-7 tag is-primary title_tag"
							>
								{{toTitleCase(props.row.field)}}
							</span>
							<span
									class="tag customWidth"
							>
								{{formatValue(props.row.newValue)  }}
							</span>
						</div>
						</b-table-column>
					</b-table>
					<p v-if="tableLogs.data.length == 0"
					class="subtitle">Aún no se realizan cambios sobre este candidato</p>
				</div>
				<div class="modal-card-foot justify-flex-end">
					<b-button
						rounded
						@click="closeModal"
					>
						Cerrar
					</b-button>
				</div>
			</div>
		</b-modal>
		<b-loading :active.sync="isLoading" />
    </section>
</template>

<script>
import {CANDIDATE_PROFILE_DIC } from '@/lib/candidateDictionary.js'
export default {
  props: {
    isModalActive: Boolean, // Recibe del padre el estado de visibilidad del modal
    profileLogsData : {
        required:true
    },
    id : String
},
    data() {
        return{
            isLoading:false,
            
            tableLogs: {
					data: [],
					isPaginated: true,
					isPaginationSimple: false,
					paginationPosition: "bottom",
					defaultSortDirection: "asc",
					sortIcon: "arrow-up",
					sortIconSize: "is-small",
					currentPage: 1,
					perPage: 10,
				},
        }
    },
  computed: {
    localModalActive: {
      get() {
        this.getVacancyLogs()
        return this.isModalActive; // Retorna el valor desde la prop
      },
      set() {
        this.$emit('close-modal'); 
      }
	},
  },
  methods: {
    closeModal() {
        this.$emit('close-modal'); 
    },
    async getVacancyLogs() {
		this.isLoading = true;
				const userID = this.id		
				try {
					const { data } = await this.$api.get(`/application/${userID}/logs`);
					this.tableLogs.data = data;
					
				} catch (error) {
					this.$buefy.toast.open({
						message: !error.response ? error : error.response.data.message,
						type: "is-danger",
					});
				}
				this.isLoading = false;
			},
	formatValue (value) {
		
		const parsedDate = new Date(value); // Parseando a Fecha

		// Comprobacion de tipo fecha
		if (!isNaN(parsedDate.getTime())) {

		const day = parsedDate.getDate().toString().padStart(2, '0');
		const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); 
		const year = parsedDate.getFullYear();

		return `${day}/${month}/${year}`;
		}
		// Cuando no es fecha devolver valor
		return value;
		
	},
	toTitleCase (str) {
		let vacanyFields = CANDIDATE_PROFILE_DIC
		//Separar la cadena cada que haya una mayuscula, convertir cada inicial en mayuscula y Unir con espacio
		return vacanyFields[str] || str ;
	},
	getEditorName (user) {
		if (!user) return '---'
		const {fullName } = user
		return fullName
	}
	},
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
	table thead tr th .th-wrap.is-relative{
		justify-content: center;
	}
	span.tag.customWidth{
		display: inline-block ; 
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all 0.6s ease-in;
	}
	span.tag.title_tag:hover + .customWidth{
		max-width: 100%;
	}


	@media (prefers-reduced-motion) {
		span.tag.customWidth{
			display: inline-block ; 
			max-width: 100%; /* O el ancho específico que prefieras */
		}
	}	

</style>