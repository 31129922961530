<template>
  <SimpleCollapse
    title="Rechazados y gender rate"
    :isLoading="isLoading"
    description="Tipos de reclamación y de servicios comúnmente solicitados."
    icon="fa-regular fa-person-half-dress"
    :data="data"
    @open="getData"
    ref="collapse"
  >
  <template #content="{ data }">
    <SimplePie
      :pieData="data?.rejectionReasons"
      title="Razones de rechazo"
      :headers="['Razón', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <div class="columns is-multiline">
      <SimplePie
        class="column is-6"
        :pieData="data?.genderRate?.applying"
        title="Gender Rate de en aplicación"
        :headers="['Tipo', 'Cantidad']"
      />
      <SimplePie
        class="column is-6"
        :pieData="data?.genderRate?.sent"
        title="Gender Rate de enviados"
        :headers="['Tipo', 'Cantidad']"
      />
    </div>
    
    <hr class="separation-line">
    <div class="columns is-multiline">
      <SimplePie
        class="column is-12"
        :pieData="data?.genderRate?.hired"
        title="Gender Rate de contratados"
        :headers="['Tipo', 'Cantidad']"
      />
    </div>
    </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from './SimpleCollapse.vue';
import SimplePie from './SimplePie.vue';

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCollapse,
    SimplePie
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        rejectionReasons: {},
        genderRate: {
          applying: {},
          sent: {},
          hired: {}
        }
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.separation-line{
  margin: 1.5rem 0;
  height: 2px;
}
</style>
