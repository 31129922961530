<template>
  <div class="container"> 
    <div 
      class="card pt-30 pb-20 pr-30 pl-30" 
      style="border-radius: 15px;">

      <div class="is-flex justify-space-between align-center">
        <p class="is-size-4 has-text-weight-semibold">
          <span class="has-text-weight-normal">
            {{ project.name }}
          </span>
        </p>
        <p class="has-text-primary is-5 no-selectable" style="font-size: 14px;">
          <f-icon icon="fa-regular fa-clock-rotate-left" />&nbsp;
          {{ timeAgo(project.createdAt) }}
        </p>
      </div>

      <div class="card-body mt-1">
        <p class="has-text-weight-medium" style="font-size: 14px;">
          {{ project.createdAt | formatDate }}
        </p>
      </div>

      <div class="vacancies-stats mt-3">
        <div class="vacancies-stats-title">
          Vacantes
        </div>
        <div class="vacancies-stats-data">
          <div>
            <b-tooltip position="is-bottom" label="Vacantes abiertas" type="is-success">
              <span class="tag is-success">
                <f-icon icon="fa-regular fa-upload" />&nbsp;
                {{ project.vacanciesStats.published }}
              </span>
            </b-tooltip>
            <b-tooltip position="is-bottom" label="Vacantes cerradas" type="is-info">
              <span class="tag is-info">
                <f-icon icon="fa-regular fa-arrow-right-from-bracket" />&nbsp;
                {{ project.vacanciesStats.closed }}
              </span>
            </b-tooltip>
            <b-tooltip position="is-bottom" label="Vacantes canceladas" type="is-danger">
              <span class="tag is-danger">
                <f-icon icon="fa-regular fa-ban" />&nbsp;
                {{ project.vacanciesStats.canceled }}
              </span>
            </b-tooltip>
            <b-tooltip position="is-bottom" label="Otras vacantes" type="is-light">
              <span class="tag is-light">
                <f-icon icon="fa-regular fa-rectangle-vertical-history" />&nbsp;
                {{ project.vacanciesStats.other }}
              </span>
            </b-tooltip>
          </div>
          <b-tooltip position="is-bottom" label="Total de vacantes" type="is-primary">
            <span class="tag is-primary">
              <f-icon icon="fa-regular fa-boxes-stacked" />&nbsp;
              {{ project.vacanciesStats.total }}
            </span>
          </b-tooltip>
        </div>
      </div>

      <div 
        class="card-footer mt-10 is-flex justify-flex-end">
        <b-button 
          @click="$emit('selection',project._id)" 
          class="mt-10" 
          icon-right="arrow-right" 
          type="is-primary" 
          outlined>
          Visitar
        </b-button>
      </div>
      
    </div>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    project: {
      type: Object,
      required: false
    }
  },
  data(){
    return{
    }
  },
  mounted(){
  },
  computed: {
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    }
  },
  methods: {
    timeAgo ( dated ) {
      if ( dated ) {
        const date = moment( dated ).locale( 'es' )
        return date.fromNow()
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.no-selectable {
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}
.vacancies-stats{
  border-radius: 5px;

  &-title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  &-data{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;

    div{
      display: flex;
      gap: 5px;
    }
    .tag{
      padding: 10px 10px;
    }
  }
}
</style>