<template>
 <div class="card" v-if="section.name">
    <div class="card-header">
      <div class="column">
        <b-icon icon="arrows-up-down-left-right" />
        Reclutamiento
      </div>
    </div>
    <div class="card-content">
      <div v-for="(rec, index) in section.recruitment.steps" :key="index">
        <div class="columns">
          <div class="column">
            <b-field label="Título">
              <b-input 
                v-model="rec.title" 
                placeholder="Título del paso para reclutamiento" 
                @blur="updateSection"
              />
            </b-field>
            <b-field label="Descripción">
              <b-input 
                v-model="rec.description" 
                placeholder="Ingresa un texto corto" 
                @blur="updateSection"
              />
            </b-field>
          </div>
          <div class="column">
            <div class="buttons" style="justify-content: flex-end">
              <b-button type="is-danger" icon-right="trash" @click="removeRecruitmentStep(index)" />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <b-button @click="addRecruitmentStep">
        Agregar paso de reclutamiento
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
    uploadImage: {
      type: Function,
    },
    deleteImage: {
      type: Function,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = {...this.defaultSection};
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = {...val}
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
    addRecruitmentStep() {
      this.section.recruitment.steps.push({ title: '', description: '' })
      this.updateSection()
    },
    removeRecruitmentStep(index) {
      this.section.recruitment.steps.splice(index, 1)
      this.updateSection()
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
