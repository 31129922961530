<template>
  <div class="card mt-5 mb-5" :class="{
  'disabled has-background-grey-light' : sectionDisabled
  }">
    <div class="card-content columns is-multiline">
      <div v-if="sectionDisabled" class="no-interaction"></div>
      <div class="column">
        <b-field label="Color de títulos">
          <ColorPicker :value="colors.title" @input="updateTitleColor" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Color de texto en beneficios">
          <ColorPicker :value="colors.benefits" @input="updateBenefitsColor" />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  components: {
    ColorPicker: Chrome,
  },
  props: {
    defaultColors: {
      title: String,
      benefits: String
    },
    sectionDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: {
        title: '#42CBB6',
        benefits: '#42CBB6'
      }
    }
  },
  mounted() {
    this.colors = { ...this.defaultColors };
  },
  watch: {
    defaultColors: {
      handler: function (val) {
        this.colors = { ...val }
      }
    },
    
  },
  methods: {
    updateTitleColor(color) {
      this.colors.title = color.hex;
      this.updateColors();
    },
    updateBenefitsColor(color) {
      this.colors.benefits = color.hex;
      this.updateColors();
    },
    updateColors() {
      this.$emit('updateLandingProperty', this.colors, 'colors');
    },
  }
}
</script>

<style>
.sort-element {
  cursor: grab !important;
}

.no-interaction{
  background: rgba(203, 203, 203, 0.626);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  user-select: none;

}
</style>
