<template>
  <div>
    <b-field label="Correo electrónico">
      <b-input
        type="email"
        v-model="form.email"
      />
    </b-field>
    <div class="columns is-multiline">
      <div class="column is-12 has-text-right">
        <b-button
          icon-left="arrow-left"
          type="is-light"
          @click="goBack"
        ></b-button>
        <b-button
          type="is-primary"
          @click="login"
        >
          Enviar correo
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          email: null,
        },
      };
    },
    watch: {
      "form.email": function (val) {
        if (val) this.form.email = this.form.email.toLowerCase();
      },
    },
    methods: {
      async login() {
        try {
          const { status } = await this.$api.post("/auth/signin-by-mail", this.form);
          if (status === 200) {
            this.$buefy.toast.open({
              message: "Correo electrónico enviado.",
              type: "is-blue",
              duration: 5000,
            });
            this.goBack();
          }
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data?.message,
            type: "is-danger",
            duration: 5000,
          });
        }
      },
      goBack() {
        this.$emit("go-back");
      },
    },
  };
</script>
