<template>
  <GChart v-if="chartData.length" style="min-height: 100%;" type="ComboChart" :data="combinedChartData"
    :options="chartOptions" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export default {
  components: {
    GChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    goals: {
      type: Object,
      default: () => ({})
    },
    rangeColors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartOptions: {
        chartArea: {
          bottom: 60,
          top: 10,
          left: 70,
          height: '70%',
          width: '75%'
        },
        seriesType: 'bars',
        series: this.getGoalsSeries(),
        hAxis: {
          slantedText: true,
          slantedTextAngle: 60,
        },
        bar: { groupWidth: '85%' },
        legend: this.getLegend(),
      }
    }
  },
  computed: {
    goalsHeaders(){
      let goalsHeaders = []

      Object.keys(this.goals).forEach((goalKey) => {
        if (!this.goals[goalKey].value) return

        goalsHeaders.push(goalKey)
      })

      return goalsHeaders
    },
    goalsValues(){
      let goalsValues = []

      Object.keys(this.goals).forEach((goalKey) => {
        if (!this.goals[goalKey].value) return

        goalsValues.push(this.goals[goalKey].value)
      })

      return goalsValues
    },
    staticData() {
      const header = ['Month', ...this.goalsHeaders];
      const rows = this.chartData.slice(1).map(row => {
        const month = row[0];
        return [month, ...this.goalsValues];
      });
      return [header, ...rows];
    },
    combinedChartData() {
      // Combina los datos estáticos y dinámicos
      return this.chartData.map((row, index) => {
        // Getting column color for ranges
        const columnValue = row[1];
        const color = this.rangeColors?.find(range => {
          if (range.to && range.from)
            return columnValue >= range.from && columnValue <= range.to

          if (range.to && !range.from)
            return columnValue <= range.to

          if (!range.to && range.from)
            return columnValue >= range.from
        })?.color || "#3366CC";

        const dataRow = [...row, ...(this.staticData[index].slice(1))]

        if(this.goalsValues.length)
          return dataRow


        if (index === 0) 
          dataRow.push({ role: 'style' }, { role: 'annotation' });
        else 
          dataRow.push(`color: ${color}`, String(row[1]));
        
        return dataRow;
      });
    }
  },
  methods: {
    getGoalsSeries(){
      let goalsSeries = {}

      Object.keys(this.goals).forEach((goalKey, index) => {
        if (!this.goals[goalKey].value) return

        goalsSeries[index + 1] = {
          type: this.goals[goalKey].type,
          color: this.goals[goalKey].color
        }
      })

      return goalsSeries
    },
    getLegend(){
      if(Object.keys(this.goals).length)
        return { position: 'right' }
      else
        return { position: 'none' }
    }
  }
}
</script>
