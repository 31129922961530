export default {
  // FAT-4
  generalProfile: 'General Profile',
  birthday: 'Birthday',
  gender: 'Gender',
  pronoun: 'Pronoun',
  anyDisability: 'Any Disability?',
  typeDisability: 'Type of Disability',
  telephone: 'Telephone',
  mail: 'Email',
  nationalIdentifier: 'National Identifier',
  state: 'State',
  city: 'City',
  academicProfile: 'Academic Profile',
  university: 'University',
  campus: 'Campus',
  career: 'Career',
  studyArea: 'Field of Study',
  academicLevel: 'Academic Level',
  currentSemester: 'Current Semester',
  graduateDate: 'Graduation Date',
  degreeDate: 'Degree Date',
  skillsKnowledgeProfile: 'Skills and Knowledge Profile',
  skills: 'Skills',
  interests: 'Interests',
  englishLevel: 'English Level',
  excelLevel: 'Excel Level',
  schoolSchedule: 'School Schedule',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  professionalProfile: 'Professional Profile',
  curriculum: 'Curriculum',
  psychometryApplicationDate: 'Psychometric Test Application Date',
  portfolio: 'Portfolio / Blog',
  vacancyFoundSource: 'Candidate Source',
  noRecordedVacancyFound: 'Candidate source has not been recorded.',

  // FAT-6
  characterProfile: 'Personality Profile',
  recommendable: 'Recommendable',
  familyEnviroment: 'Family Environment',
  freeTime: 'Free Time',
  sports: 'Sports',
  character: 'Character',
  challenges: 'Challenges',
  achievements: 'Achievements',
  mediumTermGoals: 'Medium-term Goals',
  longTermGoals: 'Long-term Goals',
  strengths: 'Strengths',
  skillToImprove: 'Skills to Improve',
  careerPath: 'Career Path',
  capabilityOne: '1st Competency',
  capabilityTwo: '2nd Competency',
  capabilityThree: '3rd Competency',
  comments: 'Comments',
  addComments: 'Add Comments and Conclude Interview',
  companyComments: 'Company Comments',
  noCompanyComments: 'No comments from the company.',

  // Custom Evaluation
  customEvaluation: 'Custom Evaluation',
  urlVideo: 'Presentation Video',
  noUrlVideo: 'No presentation video available yet.',
  uploadFile: 'Candidate Application File',
  noUploadFile: 'No candidate application file available yet.',
  addCustomEvaluation: 'Add Custom Evaluation',
  aditionalComments: 'Additional Comments',
  noAditionalComments: 'No additional comments available yet.',
}
