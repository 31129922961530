<template>
  <div class="container pb-50">
      <div class="pt-5 column is-flex justify-flex-end">
        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="getReturningPageParams"
        >
          {{translateEditVacancy.generalData.returnButton}}
        </b-button>
      </div>

      <div class="columns">
        <div class="column">
          <span class="is-size-3">
            <strong>{{ companyName || "" }}</strong> | {{ translateEditVacancy.generalData.vacancyAction }}
          </span>

        </div>
      </div>

      <div class="columns">
        <div class="column is-8">
          <ValidationObserver ref="observer">
            <div 
              class="card" 
              style="border-radius: 15px">
              <div class="column mt-3 is-12">
                <div class="columns is-multiline">

                  <div class="column">
                    <p class="title is-4 text--primary">{{translateEditVacancy.generalData.title}}</p>
                  </div>

                  <!-- Alignament Date -->
                  <div class="column is-5" v-if="alligment">
                    <b-field 
                      horizontal 
                      expanded
                      >
                      <template #label>
                        <p class="is-size-7">{{ translateEditVacancy.generalData.fields.alignamentDate.label }}</p>
                      </template>
                      
                      <b-datepicker
                          icon="calendar"
                          v-model="phoneCallDate"
                          :placeholder="translateEditVacancy.generalData.fields.alignamentDate.select"
                          :unselectable-days-of-week="[0, 6]"
                        />

                    </b-field>
                  </div>
                </div>

                <!-- Seleccion de idioma-->
                <div class="columns  px-3">
                  <VacancyFormLanguage 
                    ref="vacancyFormLanguage" 
                    :initialValue="form.vacancyFormLanguages" 
                    :editData="form"
                  />
                </div>

                <!-- General Data -->
                <div class="columns is-multiline px-3">
                  <GeneralData ref="generalData"
                    :renameField="renameField"
                    :newPlaceholder="newPlaceholder"
                    :editData="form"
                  />
                </div>

                <!-- Academic data & language data -->
                <hr>
                <div class="column is-12">
                  <AcademicData ref="academicData"
                    :renameField="renameField"
                    :newPlaceholder="newPlaceholder"
                    :editData="form"
                  />
                  <LanguageData
                    ref="languageData"
                    :renameField="renameField"
                    :newPlaceholder="newPlaceholder"
                    :editData="form"
                  />
                </div>

                <!-- Habilities -->
                <hr>
                <VacancyHabilities
                  ref="habilities"
                  :renameField="renameField"
                  :newPlaceholder="newPlaceholder"
                  :editData="form"
                />

                <!-- Description -->
                <hr>
                <VacancyDescription ref="description"
                  :renameField="renameField"
                  :newPlaceholder="newPlaceholder"
                  :editData="form"
                />

                <!-- Recruitment -->
                <hr>
                <VacancyRecruitment
                  ref="recruitment"
                  :renameField="renameField"
                  :newPlaceholder="newPlaceholder"
                  :editData="form"
                />
              
                <!-- Killer Questions -->
                <hr>
                <KillerQuestions ref="killerQuestions"
                  :renameField="renameField"
                  :newPlaceholder="newPlaceholder"
                  :editData="form" 
                  :vacancy="id"
                />

                <!-- Comments -->
                <VacancyComments ref="comments"
                  :renameField="renameField"
                  :newPlaceholder="newPlaceholder"
                  :editData="form"
                />

                <!-- Additional fiels -->
                <VacancyAdditionalFields
                  ref="otherFields"
                  :editData="form"
                />
              </div>
            </div>
          </ValidationObserver>
        </div>
          <VacancyMondayId :vacancy="form" 
            :validateForms="validateForms"
            :editData="form"
            @handle-update="handleUpdateAndSetDate"
        />        
      </div>

      <UserCreate 
        contactUsers 
        :company="companyId" 
        @updated="getAuthors" 
        ref="create"/>
      
    <b-loading :active.sync="isLoading" />
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserCreate from '../components/modals/UserCreate.vue'
import VacancyFormLanguage from '../components/vacancy-form/VacancyFormLanguage.vue'
import alternativeRecruitmentServices from '../lib/alternativeRecruitmentServices'
import cities from '../lib/cities'
import days from '../lib/days'
import municipalities from '../lib/ES/municipalities'
import provinces from '../lib/ES/provinces'
import { vacancyManagementAllowedRoles } from '../lib/permissions'
import schedules from '../lib/schedules'
import states from '../lib/states'
import GeneralData from '../components/vacancy-form/GeneralData.vue'
import AcademicData from '../components/vacancy-form/AcademicData.vue'
import LanguageData from '../components/vacancy-form/LanguageData.vue'
import VacancyHabilities from '../components/vacancy-form/VacancyHabilities.vue'
import VacancyDescription from "../components/vacancy-form/VacancyDescription.vue";
import VacancyRecruitment from "../components/vacancy-form/VacancyRecruitment.vue";
import KillerQuestions from '../components/KillerQuestions.vue'
import VacancyComments from "../components/vacancy-form/VacancyComments.vue";
import VacancyMondayId from "../components/vacancy-form/VacancyMondayId.vue";
import VacancyAdditionalFields from "../components/vacancy-form/VacancyAdditionalFields.vue";
/* import VacancyProject from "../components/vacancy-form/VacancyProject.vue";
*/

export default {
  components: {
    UserCreate,
    KillerQuestions,
    VacancyFormLanguage,
    GeneralData,
    AcademicData,
    LanguageData,
    VacancyHabilities,
    VacancyDescription,
    VacancyRecruitment,
    VacancyComments,
    VacancyMondayId, 
    VacancyAdditionalFields,
/*  VacancyProject,
    */
  },
  data () {
    return {
      isLoading: false,
      vacancyManagementAllowedRoles,
      states,
      cities,
      provinces,
      municipalities,
      companyName: null,
      form: {
        /* TONY-FIX Agregando campos para actualizacion de formualrio con manejo de idioma */
        vacancyFormLanguages : null,
        name: null,
        duration: null,
        places: 1,
        location: {
          state: null,
          city: null,
        },
        schedule: null,
        showScholarship: false,
        scholarship: null,
        modality: null,
        degree: null,
        academicLevel: [],
        minimumSemester: 0,
        maximumSemester: 0,
        excelLevel: null,
        englishLevels: {
          language: 'Inglés',
          reading: null,
          listening: null,
          speaking: null,
          writing: null,
          representativeSkill: [],
        },
        otherLanguages: [],
        graduateDate: null,
        degreeDate: null,
        skills: [],
        knowledges: [],
        goal: null,
        benefits: [],
        benefit: '',
        activities: ['','',''],
        author: null,
        project: null,
        comments: null,
        mondayVacancyId: null,
        projectMondayId: null,
        schemaType: null,
        serviceType: null,
        serviceDetail: null,
        claimType: null,
        projectId: null,
        otherFields: [],
      },
      alternativeRecruitmentServices,
      phoneCallDate: null,
      file: null,
      noRounded: false,
      citySelected: null,
      stateSelected: null,
      provinceSelected: null,
      municipalitySelected: null,
      authors: [],
      selectedAuthor: null,
      roles: {
        hrmanager: 'Manager de RH',
        solicitant: 'Solicitante'
      },
      schedules,
      days,
      fieldsNames:{
        name: 'Nombre de la vacante',
        duration: 'Duración',
        places: 'Número de Posiciones',
        state: 'Estado',
        city: 'Ciudad',
        schedule: 'Horario',
        showScholarship: 'Mostrar Beca',
        scholarship: 'Beca',
        modality: 'Modalidad',
        degree: 'Licenciatura(s)',
        academicLevel: 'Nivel académico',
        minimumSemester: 'Semestre Mínimo',
        maximumSemester: 'Semestre Máximo',
        excelLevel: 'Nivel de Excel',
        englishLevel: 'Nivel de Inglés',
        graduateDate: 'Termino de Materias',
        degreeDate: 'Titulación',
        skills: 'Habilidades',
        knowledges: 'Conocimientos',
        goal: 'Objetivo',
        benefit: 'Beneficios',
        activities: 'Actividades Diarias',
        author: 'Contacto de la empresa',
        authorName: 'Nombre',
        authorEmail: 'Correo electrónico',
        authorPhone: 'Teléfono',
        project: 'Proyecto',
        comments: 'Comentarios',
      },
      renamedFields: [],
      company: null,
      maxHour: new Date(),
      knowledges: [],
      knowledgeIndex: 0,
      selectedKnowledge: '',
      availableProjects: [],
      selectedProject: null,
      project: null
    }
  },
  watch: {
    async file (val) {
      const formData = new FormData()
      if (val) {
        this.isLoading = true
        try {
          formData.append('file', val)
          const response = await this.$api.post('/upload', formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          this.form.project = response.data
          this.$buefy.toast.open({
            message: 'El archivo se subió con éxito',
            type: 'is-success'
          })
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    },
    selectedAuthor (val) {
      if(val){
        this.form.author = val.id
      }
    },
    stateSelected(){
      if(this.stateSelected?.id == '00' && this.isSpain){
        this.citySelected = this.provincesMunicipalities[0]
      }
    },
    selectedProject(val){
      if(val){
        this.form.projectMondayId = val.projectMondayId
        this.form.projectId = val.projectId
      }
    }
  },
  computed: {
    ...mapGetters(['companyId']),
    user () {
      return this.$store.state.session.user
    },
    id () {
      return this.$route.params.id
    },
    alligment(){
      return  this.$route.query.alligment
    },
    getReturningPageParams(){
      const companyId = this.company?.id || this.companyId; // Use Vuex getter as a fallback
      if (this.$route.query.from == 'projects'){
        return { name: 'Projects', params: { id: companyId }, query: { project: this.form.projectId }}
      } else {
        return { name: 'Vacancies', params: { id: companyId } }
      }
    },
    stateCities () {
      if (this.stateSelected) {
        const state = this.states.find(st => st.id === this.stateSelected.id)
        const cities = [...this.cities]
          .filter(city => city.state_id === state.id)
          .sort((a, b) => {
            const nameA = this.replaceAccent(a.name)
            const nameB = this.replaceAccent(b.name)
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
            return 0
          })
        return cities
      }
      return []
    },
    provincesMunicipalities() {
      const provinces = this.stateSelected
        ? this.municipalities.filter(item => item.state_id == this.stateSelected.id)
            .sort((a, b) => {
              const nameA = this.replaceAccent(a.name);
              const nameB = this.replaceAccent(b.name);
              if (nameA > nameB) {
                return 1;
              }
              if (nameA < nameB) {
                return -1;
              }
              return 0;
            })
        : [];

      return provinces;
    },
    canManageAllFields () {
      return (this.$hasPermissionsByRole(vacancyManagementAllowedRoles))
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION  === 'mx'
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION === 'es'
    },
    translateEditVacancy () {
    return this.$t("screens.vacancies.edit")  
    },
  },
  mounted () {
    this.$i18n.locale = process.env.VUE_APP_LOCATION || 'mx'
    this.mountedPromises();
  },
  methods: {
    async mountedPromises() {
      await this.getAuthors()
      await this.getVacancy()
      await this.getRenamedFields()
      await this.getKnowledges()
    },
    async getVacancy () {
      const { data } = await this.$api.get(`/vacancies/${this.id}`)
      data.otherFields.forEach(field => {
        if(field.fieldType === 'Date'){
          field.value = new Date(field.value)
        }
      })      
      /* A este formulario le hace falta estos dos campos e implementar la seleccion de lenguage */
      this.form = {
        /* TONY-FIX Agregando los campos que tenemos de la BD */
        vacancyFormLanguages : data.vacancyFormLanguages,
        name: data.name,
        duration: data.duration,
        places: data.places,
        location: {
          state: data.location ? data.location.state : '',
          city: data.location ? data.location.city : ''
        },
        schedule: data.schedule,
        showScholarship: data.showScholarship,
        scholarship: data.scholarship,
        modality: data.modality,
        degree: data.degree,
        academicLevel: data.academicLevel,
        minimumSemester: data.minimumSemester,
        maximumSemester: data.maximumSemester,
        excelLevel: data.excelLevel,
        englishLevels: data.englishLevels,
        otherLanguages: data.otherLanguages,
        graduateDate: data.graduateDate ? new Date(data.graduateDate) : null,
        degreeDate: data.graduateDate ? new Date(data.degreeDate) : null,
        skills: data.skills,
        goal: data.goal,
        benefits: data.benefits,
        activities: data.activities,
        comments: data.comments,
        otherFields: data.otherFields,
        mondayVacancyId: data.mondayVacancyId,
        projectMondayId: data.projectMondayId,
        schemaType: data.schemaType,
        serviceType: data.serviceType,
        serviceDetail: data.serviceDetail,
        claimType: data.claimType,
        author: data.author,
        knowledges: data.newKnowledges,
        projectId: data.projectId
      }

      if(data.vacancyFormLanguages) this.$i18n.locale = data.vacancyFormLanguages
      if(data.projectId){
        await this.getProject(data.projectId)
      }
      
      if(!this.form.englishLevels){
        this.form.englishLevels = {
          language: 'Inglés',
          listening: data.englishLevel,
          reading: data.englishLevel,
          speaking: data.englishLevel,
          writing: data.englishLevel,
          representativeSkill: []
        }
      }

      if(!data.benefits?.length > 0){
        this.form.benefits.push(data.benefit)
      }

      if(data.newKnowledges.length > 0){
        this.form.knowledges = data.newKnowledges.map( ({ knowledge, level }) => {
        return {
            name: knowledge.name,
            level: level.level
          }
        })
      }else this.form.knowledges = data.knowledges

      this.selectedAuthor = this.authors.find(item => item.id === this.form.author)

      this.company = data.company

      if(this.isMexico){
        this.stateSelected = states.find(state => state.name === data.location.state)
        this.citySelected = cities.find(city => city.name === data.location.city)
      }else{
        this.stateSelected = provinces.find(item => item.name === data.location.state)
        this.citySelected = municipalities.find(item => item.name === data.location.city)
      }

      this.companyName = data.company.name
    },
    async getKnowledges () {
      try{
        let { data } = await this.$api.get(`knowledges`)
        if(data.length > 0){
          this.knowledges = data.map( item => {
            return { name: item.name  }
          })
        }
      }catch(error){
        this.$buefy.toast.open({
          message: 'Error al buscar conocimientos',
          type: 'is-danger'
        })
      }
    },
    async getAuthors () {
      const solicitants = await this.$api.get(`/companies/${this.companyId}/solicitants`)
      const hrmanagers = await this.$api.get(`/companies/${this.companyId}/hr-managers`)
      this.authors = [ ...solicitants.data, ...hrmanagers.data]
    },
    async getProject(id){
      try{
        const { data } = await this.$api.get(`/companies/${this.companyId}/projects/detail/${id}`)
        if(data){
          this.project = data
        }
      }catch(error){
        this.$buefy.toast.open({
            message: 'No se encontro el proyecto previamente asociado',
            type: 'is-warning'
          })
      }
    },
    async getAvailableProjects(){
      try{
        const { data } = await this.$api.get(`/companies/${this.company?.id}/projects/availables`)
        this.availableProjects = data
      }catch(error){
        error
      }
    },
    replaceAccent (term) {
      if (term) {
        const accents = /á|é|í|ó|ú/
        const word = term.toLowerCase()
        return word.replace(accents, (s) => {
          if (s === 'á') return 'a'
          if (s === 'é') return 'e'
          if (s === 'í') return 'i'
          if (s === 'ó') return 'o'
          if (s === 'ú') return 'u'
        })
      }
      return term
    },
    wichType(field){
      if(field.options === 'default' ) return 'column is-4'
      if(field.options === 'textarea' ) return 'column is-6'
      if(field.fieldType === 'number' ) return 'column is-4'
      if(field.fieldType === 'Date' ) return 'column is-4'
      if(field.fieldType === 'boolean' ) return 'column is-2'
      if(field.fieldType === 'Array' ) {
        return field.options.length > 4 ? 'column is-6' : 'column is-4'
      }
      if(field.fieldType === 'Select' ) return 'column is-4'
    },
    async getRenamedFields(){
      const { data } = await this.$api.get(`aggregate-fields/renamedFields/${this.companyId}`)
      this.renamedFields = data
    },
    renameField(actualName){
      /* Adapting ES messages */
      if (this.isSpain){
        for (const fieldKey in this.fieldsNames){ // Looking for key
          if (this.fieldsNames[fieldKey] === actualName){
            if (this.$te(`screens.vacancies.edit.${fieldKey}`)) return this.$t(`screens.vacancies.edit.${fieldKey}`)
          }
        }
      }
      
      let newNamed = null
      this.renamedFields.forEach( field => {
        if( actualName === field.actualName){
          newNamed = field.newName
        }
      })
      return newNamed ? newNamed : actualName
    },
    newPlaceholder(actualName){
      let newPlaceholder = null
      this.renamedFields.forEach( field =>{
        if( actualName === field.actualName){
          newPlaceholder = field.newPlaceholder
        }
      })
      return newPlaceholder ? newPlaceholder : ''
    },
    async validateForms () { 

        const forms = await Promise.all(
          [
            this.$refs.vacancyFormLanguage.validateForm(),
            this.$refs.generalData.validateForm(),
            this.$refs.academicData.validateForm(),
            this.$refs.languageData.validateForm(),
            this.$refs.habilities.validateForm(),
            this.$refs.description.validateForm(),
            // this.$refs.solicitants.validateForm(),
            this.$refs.recruitment.validateForm(),
            // this.$refs.killerQuestions.validateForm(),
            this.$refs.comments.validateForm(),
            this.$refs.otherFields.validateForm()
          ]
      )
        // Making main form
        if (forms.every((form) => form)) {
          let form = {}

          forms.forEach(formItem => {
            if(formItem)
              form = {...form, ...formItem}
          });

          form = { ...this.form, ...form }
          return {form , isAllignment: this.alligment};
        } else{
          return false
        }
    },
    async handleUpdateAndSetDate() {
      // Llama a updateKillerQuestions y espera que se complete
      await this.updateKillerQuestions();

      if (this.alligment) await this.setPhoneCallDate();

      this.$router.push(`/empresa/${this.companyId}/vacantes`);
    },

    async setPhoneCallDate() {

      if (!this.phoneCallDate) this.phoneCallDate = new Date();

      const payload = {
        startDate: this.phoneCallDate,
        endDate: this.phoneCallDate,
      };

      try {
        await this.$api.post(`vacancies/${this.id}/set-phone-call`, payload);

        this.$buefy.toast.open({
          message: 'Se agregó la fecha de llamada de alineación',
          type: 'is-success',
          duration: 4000,
        });

      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger',
        });
      }
    },

    async updateKillerQuestions () {
      await this.$refs.killerQuestions.createKillerQuestion();
    },
  }
}
</script>

<style>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
  .language-table{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem
  }
  .select select option{
    color: black !important;
  }
</style>