export default {
    'created': "Creación",
    'duplicated': "Duplicación",
    'updated': "Edición",
    'recruiter-assigned': "Reclutador asignado",
    'phone-call-stablished': "Llamada de alineación establecida",
    'published': "Publicación",
    'closed': "Cierre",
    'canceled': "Cancelación",
    'reviewing': "Puesta en Revisión",
    'notified': "Notificado",
    'activated': "Activación",
    'deactivated': "Suspensión",
    'candidates-sent': "Candidatos enviados",
    'candidate-hired': "Candidatos contratados"
  }