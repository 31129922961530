export const customEvaluationForm = {
  data () {
    return {
      form: {
        applicationLinkVideo: "",
        applicationFile: null,
        applicationFileMetadata: null,
        aditionalComments: "",
      }
    }
  }
}