<template>
  <div>
    <div class="columns">
      <div class="column">
        <section class="has-background-light layout">
          <div class="container">
            <br>
            <b-tabs
              v-model="activeTab"
              type="is-boxed"
              :animated="false"
              >
              <b-tab-item
                v-for="(tab, index) in tabs"
                :key="`tab-${index}`"
                :icon="tab.icon"
                :value="`${index}`"
                :label="tab.label"
              >
              </b-tab-item>
            </b-tabs>
            <router-view />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeLayout',
  components: {
  },
  data() {
    return {
      activeTab: 0
    }
  },
  mounted(){
    this.activeTab = this.tabs.findIndex(tab => tab.component === this.$route.name)
  },
  watch: {
    activeTab (val) {
      const tab = this.tabs[val]

      if (tab.component !== this.$route.name)
        this.$router.push({ name: tab.component})
    },
  },
  computed: {
    user () {
      return this.$store.state.session.user
    },
    tabs () {
      const tabs = [
        { 
          label: 'Empresas', 
          component: 'Companies', 
          roles: ['superqueen', 'manager', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'account', 'account-manager'],
          icon: 'buildings', 
        },
        { 
          label: 'Dashboard', 
          component: 'Dashboard', 
          roles: ['superqueen', 'manager','recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'],
          icon: 'chart-pie', 
        },
        { 
          label: 'Empresas Archivadas', 
          component: 'CompaniesArchived', 
          roles: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager'],
          icon: 'archive', 
        },
        { 
          label: 'Cartera de Candidatos', 
          component: 'Wallet', 
          roles: ['superqueen',  'recruiter', 'recruiter-manager' ],
          icon: 'briefcase',
        },
        { 
          label: 'Usuarios', 
          component: 'Users', 
          roles: ['superqueen', 'manager', 'recruiter-manager', 'designer-manager'],
          icon: 'users', 
        }
      ]
      return tabs.filter(tab => tab.roles.includes(this.$isRole(this.user)))
    }
  },
  methods: {
  }
}
</script>