<template>
  <SimpleCard
    title="Indicadores"
    :isLoading="isLoading"
    icon="fa-regular fa-chart-bullet"
    description="Indicadores de vacantes con base en el periodo seleccionado."
    :data="data"
  >
    <template #content="{ data }">
      <!-- índice de entrega en tiempo -->
      <div class="indicator">
        <div class="indicator-title">
          <p class="is-size-5">Índice de entrega en tiempo</p>
          <p class="is-size-6">
            {{ data.deliveredOnTime }} vacantes entregadas en tiempo
          </p>
        </div>
        <b-progress
          size="is-medium"
          format="percent"
          class="mb-1"
          :value="data.deliveryOnTime || 0"
          :type="getDeliveryOnTimeColor(data.deliveryOnTime)"
          :show-value="true"
          style="width: 100%"
        ></b-progress>
        <p class="indicator-explanation">
          (Vacantes entregadas en tiempo * 100 ) / Total de Vacantes (no cuentan
          canceladas)
        </p>
      </div>

      <!-- índice de efectividad -->
      <hr class="separation-line" />
      <div class="indicator">
        <div class="indicator-title">
          <p class="is-size-5">Índice de efectividad</p>
          <p class="is-size-6">
            {{ data.closedByPM }} vacantes cerradas por Pro Meritum
          </p>
        </div>
        <b-progress
          size="is-medium"
          format="percent"
          class="mb-1"
          :value="data.effectiveness || 0"
          :type="getEffectivenessColor(data.effectiveness)"
          :show-value="true"
          style="width: 100%"
        ></b-progress>
        <p class="indicator-explanation">
          (Total de vacantes cerradas con motivo de cierre "Cerrada por Pro
          Meritum * 100) / Total de vacantes cerradas con cualquier motivo de
          cierre
        </p>
      </div>

      <!-- Time to Fill -->
      <hr class="separation-line" />
      <div class="indicator">
        <div class="indicator-title">
          <p class="is-size-5">Time to Fill</p>
        </div>

        <div class="indicator-row">
          <SingleStat
            title="Días hábiles a entrega real"
            :value="data.timeToFill?.requisitionToDelivery"
            bgColor="#eeeeee"
            textColor="#3c3c3c"
            size="5"
            icon="fa-regular fa-paper-plane"
          />
          <SingleStat
            title="Días hábiles a cierre de vacante"
            :value="data.timeToFill?.requisitionToClosing"
            bgColor="#dfdfde"
            textColor="#3c3c3c"
            size="5"
            icon="fa-regular fa-arrow-right-from-bracket"
          />
          <SingleStat
            title="Días hábiles a fecha de ingreso"
            :value="data.timeToFill?.requisitionToEntry"
            bgColor="#c2c2c2"
            textColor="#3c3c3c"
            size="5"
            icon="fa-regular fa-handshake"
          />
        </div>
      </div>
    </template>
  </SimpleCard>
</template>

<script>
import SimpleCard from "./SimpleCard.vue";
import SingleStat from "./SingleStat.vue";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },
  components: {
    SimpleCard,
    SingleStat,
  },
  watch: {
    search: {
      handler: async function () {
        await this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {},
      type: "is-primary",
    };
  },
  async mounted() {},
  methods: {
    /* Top level function to get content */
    async getData() {
      this.isLoading = true;
      const data = await this.loadFunction(this.search);
      this.data = data;
      this.isLoading = false;
    },
    getEffectivenessColor(value) {
      if (value < 70) {
        return "is-danger";
      } else if (value < 90) {
        return "is-warning";
      } else if (value == 90) {
        return "is-success";
      } else {
        return "is-primary";
      }
    },
    getDeliveryOnTimeColor(value) {
      if (value < 86) {
        return "is-danger";
      } else if (value < 98) {
        return "is-warning";
      } else if (value == 98) {
        return "is-success";
      } else {
        return "is-primary";
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.indicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.indicator-title {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.indicator-explanation {
  font-size: 0.8rem;
  color: #7a7a7a;
  margin-top: 0.5rem;
  text-align: center;
}

.indicator-row {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.separation-line {
  margin: 1.5rem 0;
  height: 2px;
}
</style>
