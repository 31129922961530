<template>
	<div class="container">
		<div class="mb-20 pt-20 is-flex justify-flex-end">
			<b-button
				rounded
				icon-left="arrow-left"
				type="is-primary"
				tag="router-link"
				:to="getReturningPageParams"
			>
				Regresar
			</b-button>
		</div>

		<div class="columns is-centered">
			<div class="column is-10">
				<div
					class="card"
					style="border-radius: 15px"
				>
					<div class="card-content">
						<div class="title is-3 is-flex is-justify-content-space-between">
							<div>
								{{ vacancy.name }} en {{ vacancy.company.name }}

								<b-tag
									size="is-medium"
									v-if="vacancy.status"
									:type="`is-${vacancyStatuses[vacancy.status].color}`"
								>
									{{ vacancyStatuses[vacancy.status].text }}
								</b-tag>
							</div>

							<b-tooltip
								label="Visualiza el historial de acciones hechas sobre la vacante"
								position="is-bottom"
								type="is-light"
								v-if="
									$hasPermissionsByRole(vacancyManagementAllowedRoles) ||
									$hasPermissionsByCompany(vacancy.company) ||
									$hasPermissionsByVacancy(vacancy)
								"
							>
								<b-button
									rounded
									type="is-primary"
									icon-left="clock-rotate-left"
									@click="openLogsModal"
								>
									Ver historial de cambios
								</b-button>
							</b-tooltip>
						</div>

						<div class="title is-4">
							<f-icon
								icon="fa-regular fa-location-dot"
								class="has-text-danger"
							/>

							{{ vacancy.location.state }}, {{ vacancy.location.city }}
						</div>

						<div class="columns">
							<div class="column is-6">
								<div class="title is-6">
									{{ renameField(fieldsNames.duration) }}: {{ vacancy.duration }}
									<span v-if="!vacancy.duration.includes('meses')">meses</span>
								</div>

								<div class="title is-6">{{ renameField(fieldsNames.modality) }}: {{ vacancy.modality || noInfo }}</div>

								<div class="title is-6">{{ renameField(fieldsNames.schedule) }}: {{ vacancy.schedule }}</div>

								<div class="title is-6">{{ renameField(fieldsNames.places) }}: {{ vacancy.places }}</div>

								<div class="title is-6">
									{{ renameField(fieldsNames.scholarship) }}: {{ $t(`screens.vacancies.id.currencySign`) }}
									{{ vacancy.scholarship }}
								</div>
							</div>

							<div class="column is-6">
								<p
									class="title is-6"
									v-if="mxLocation"
								>
									<span class="has-text-info">Id de vacante de monday:</span>
									{{ vacancy.mondayVacancyId }}
								</p>

								<p
									class="title is-6"
									v-if="mxLocation"
								>
									<span class="has-text-info">Id de requisición de monday:</span>
									{{ vacancy.projectMondayId }}
								</p>

								<p class="title is-6">Esquema de requisición: {{ vacancy.schemaType }}</p>

								<p class="title is-6">Tipo de servicio: {{ vacancy.serviceType }}</p>

								<p class="title is-6">Detalle de servicio: {{ vacancy.serviceDetail }}</p>

								<p class="title is-6">Tipo de reclamación: {{ vacancy.claimType }}</p>
							</div>
						</div>

						<hr />

						<div class="columns is-multiline">
							<div class="column is-12">
								<div class="columns is-multiline">
									<div class="column is-6">
										<div class="title is-6">
											{{ renameField(fieldsNames.goal) }}
										</div>

										<p>
											{{ vacancy.goal }}
										</p>
									</div>

									<div class="column is-6">
										<div class="title is-6">
											{{ renameField(fieldsNames.benefit) }}
										</div>

										<p
											v-for="(benefit, index) in vacancy.benefits"
											:key="`benefit-${index}`"
										>
											{{ benefit || noInfo }}
										</p>
									</div>

									<div class="column is-6">
										<div class="title is-6">
											{{ renameField(fieldsNames.activities) }}
										</div>

										<div class="content">
											<ol type="1">
												<li
													v-for="(activity, index) in vacancy.activities"
													:key="`activity-${index}`"
												>
													{{ activity }}
												</li>
											</ol>
										</div>
									</div>

									<div class="column is-3">
										<div class="title is-6">
											{{ renameField(fieldsNames.skills) }}
										</div>

										<div
											v-if="vacancy.skills && vacancy.skills.length != 0"
											class="content"
										>
											<ul>
												<li
													v-for="(activity, index) in vacancy.skills"
													:key="`activity-${index}`"
												>
													{{ activity.name }}
												</li>
											</ul>
										</div>

										<div v-else>
											{{ noInfo }}
										</div>
									</div>

									<div class="column is-3">
										<div class="title is-6">
											{{ renameField(fieldsNames.knowledges) }}
										</div>

										<div
											v-if="vacancy.knowledges && vacancy.knowledges?.length != 0"
											class="content"
										>
											<ol type="i">
												<li
													v-for="(activity, index) in vacancy.knowledges"
													:key="`activity-${index}`"
												>
													{{ activity.name }} - {{ activity.level }}
												</li>
											</ol>
										</div>

										<div v-else>
											{{ noInfo }}
										</div>
									</div>
								</div>
							</div>

							<div class="column is-12">
								<hr />

								<div class="title is-4">Formación académica</div>

								<div class="columns is-multiline">
									<div class="column is-6">
										<p class="title is-5">
											{{ renameField(fieldsNames.degree) }}
										</p>

										<div class="columns is-multiline">
											<div
												class="column is-6"
												v-for="(degree, index) in vacancy.degree"
												:key="`degree-${index}`"
											>
												<p>
													{{ degree }}
												</p>

												<hr style="margin: 0px" />
											</div>
										</div>
									</div>

									<div class="column is-3">
										<p class="title is-5">
											{{ renameField(fieldsNames.academicLevel) }}
										</p>

										<div class="column is-12">
											<div class="columns is-multiline">
												<b-tag
													size="is-medium"
													v-for="(level, index) in level"
													:key="`level-${index}`"
													class="column is-12 my-1"
													rounded
													type="is-blue"
												>
													{{ level }}
												</b-tag>

												<div
													v-if="vacancy.academicLevel.includes('student')"
													class="mt-3"
												>
													<p class="subtitle is-6">
														<b>{{ renameField(fieldsNames.minimumSemester) }}:</b> {{ vacancy.minimumSemester }}
														<br />
														<b>{{ renameField(fieldsNames.maximumSemester) }}:</b> {{ vacancy.maximumSemester }}
													</p>
												</div>
											</div>
										</div>
									</div>

									<div class="column is-3">
										<p class="title is-5">
											{{ renameField(fieldsNames.graduateDate) }}
											<br />
											<span class="subtitle is-6 mt-1">
												{{ vacancy.graduateDate | formatToDateMonth }}
											</span>
										</p>

										<p class="title is-5">
											{{ renameField(fieldsNames.degreeDate) }}
											<br />
											<span class="subtitle is-6 mt-1">
												{{ vacancy.degreeDate | formatToDateMonth }}
											</span>
										</p>
									</div>

									<div class="column is-3">
										<p class="title is-5">
											{{ renameField(fieldsNames.excelLevel) }}

											<br />

											<span class="subtitle is-6 mt-1">
												{{ vacancy.excelLevel }}
											</span>
										</p>
									</div>

									<div class="column is-9">
										<p class="title is-5">Idiomas</p>

										<div class="columns is-multiline">
											<div class="column is-6"
												style="border-right: 1px solid #ccc;"
											>
												<div class="columns is-multiline">
													<p
														class="column is-6 has-text-weight-semibold"
														v-for="(value, skill, index) in vacancy.englishLevels"
														:key="`skill-${index}`"
													>
														{{ languageSkills[skill] }}:

														<span
															v-if="skill === 'representativeSkill'"
															class="has-text-weight-normal"
														>
															<li
																v-for="(skill, index) in value"
																:key="`representative-${index}`"
															>
																{{ languageSkills[skill] }}
															</li>
														</span>

														<span
															v-else
															class="has-text-weight-normal"
														>
															{{ value }}
														</span>
													</p>
												</div>
											</div>

											<template v-if="vacancy.otherLanguages?.length > 0">
												<div
													class="column is-6"
													v-for="(language, index) in vacancy.otherLanguages"
													:key="`language-${index}`"
												>
													<div class="columns is-multiline">
														<p
															class="column is-6 has-text-weight-semibold"
															v-for="(value, skill, index) in language"
															:key="`skill-${index}`"
														>
															{{ languageSkills[skill] }}:

															<span
																v-if="skill === 'representativeSkill'"
																class="has-text-weight-normal"
															>
																<li
																	v-for="(skill, index) in value"
																	:key="`representative-${index}`"
																>
																	{{ languageSkills[skill] }}
																</li>
															</span>

															<span
																v-else
																class="has-text-weight-normal"
															>
																{{ value }}
															</span>
														</p>
													</div>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>

							<div class="column is-12">
								<div v-if="killerQuestions?.length > 0">
									<hr />

									<div class="title is-5">Killer Questions</div>

									<div
										class="columns"
										v-for="(killerQuestion, index) in killerQuestions"
										:key="`question-${index}`"
									>
										<div class="column is-full has-text-weight-semibold">
											{{ index + 1 }}.- {{ killerQuestion.question }}

											<div
												class="columns is-multiline mt-1 px-5"
												v-if="hasOptions.includes(killerQuestion.questionType)"
											>
												<div
													class="column is-6"
													v-for="(option, index) in killerQuestion.optionsValues"
													:key="`option-${index}`"
												>
													<li class="has-text-weight-normal">
														{{ option }}
													</li>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div class="title is-5">
									{{ renameField(fieldsNames.comments) }}
								</div>

								<p>
									{{ vacancy.comments }}
								</p>
							</div>
						</div>
					</div>

					<hr />

					<div
						class="column is-12 mt-4 mb-4 is-multiline"
						v-if="vacancy.otherFields?.length > 0"
					>
						<div class="title is-4">Campos Adicionales</div>

						<div class="columns is-multiline ml-2">
							<div
								:class="wichType(field)"
								v-for="(field, index) in vacancy.otherFields"
								:key="`field-${index}`"
							>
								<!-- Strings -->
								<div v-if="field.fieldType === 'string'">
									<b-field :label="field.name">
										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<b-input
												v-model="field.value"
												v-if="options === 'textarea'"
												type="textarea"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
											/>

											<b-input
												v-model="field.value"
												v-else
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
											/>
										</b-tooltip>

										<div v-else>
											<b-input
												v-model="field.value"
												v-if="options === 'textarea'"
												type="textarea"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
											/>

											<b-input
												v-model="field.value"
												v-else
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
											/>
										</div>
									</b-field>
								</div>

								<!-- Number -->
								<div v-if="field.fieldType === 'number'">
									<b-field :label="field.name">
										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<b-input
												v-model="field.value"
												type="number"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
											/>
										</b-tooltip>

										<div v-else>
											<b-input
												v-model="field.value"
												type="number"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
											/>
										</div>
									</b-field>
								</div>

								<!-- Dates -->
								<div v-if="field.fieldType === 'Date'">
									<b-field :label="field.name">
										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<b-datepicker
												v-model="field.rangeDates"
												:type="field.options === 'month' ? 'month' : null"
												range
												v-if="field.options === 'range'"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												:unselectable-days-of-week="[0, 6]"
											></b-datepicker>

											<b-datepicker
												v-model="field.value"
												:type="field.options === 'month' ? 'month' : null"
												v-else
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												:unselectable-days-of-week="[0, 6]"
											></b-datepicker>
										</b-tooltip>

										<div v-else>
											<b-datepicker
												v-model="field.rangeDates"
												:type="field.options === 'month' ? 'month' : null"
												range
												v-if="field.options === 'range'"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												:unselectable-days-of-week="[0, 6]"
											></b-datepicker>

											<b-datepicker
												v-model="field.value"
												:type="field.options === 'month' ? 'month' : null"
												v-else
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												:unselectable-days-of-week="[0, 6]"
											></b-datepicker>
										</div>
									</b-field>
								</div>

								<!-- Boolean -->
								<div v-if="field.fieldType === 'boolean'">
									<b-field :label="field.name">
										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<b-switch
												v-model="field.value"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
											></b-switch>

											<b-icon
												:icon="field.icon"
												:type="field.value ? 'is-primary' : ''"
											></b-icon>
										</b-tooltip>

										<div v-else>
											<b-switch
												v-model="field.value"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
											></b-switch>

											<b-icon
												:icon="field.icon"
												:type="field.value ? 'is-primary' : ''"
											></b-icon>
										</div>
									</b-field>
								</div>

								<!-- tags Array -->
								<div v-if="field.fieldType === 'Array'">
									<b-field :label="field.name">
										<b-icon :icon="field.icon"></b-icon>

										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<div style="display: grid; grid-template-columns: repeat(2, 1fr)">
												<b-checkbox
													class="mb-2"
													v-model="field.value"
													v-for="(option, index) in field.options"
													:key="`option-${index}`"
													:native-value="option"
													:disabled="isDisabled"
												>
													{{ option }}
												</b-checkbox>
											</div>
										</b-tooltip>

										<div v-else>
											<div style="display: grid; grid-template-columns: repeat(2, 1fr)">
												<b-checkbox
													class="mb-2"
													v-model="field.value"
													v-for="(option, index) in field.options"
													:key="`option-${index}`"
													:native-value="option"
													:disabled="isDisabled"
												>
													{{ option }}
												</b-checkbox>
											</div>
										</div>
									</b-field>
								</div>

								<!-- select Array -->
								<div v-if="field.fieldType === 'Select'">
									<b-field :label="field.name">
										<b-tooltip
											v-if="field.tooltip"
											:label="field.tooltip"
										>
											<b-select
												v-model="field.value"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												expanded
											>
												<option
													v-for="(option, index) in field.options"
													:value="option"
													:key="`option-${index}`"
												>
													{{ option }}
												</option>
											</b-select>
										</b-tooltip>

										<div v-else>
											<b-select
												v-model="field.value"
												:disabled="isDisabled"
												:placeholder="field.placeholder"
												:icon="field.icon"
												expanded
											>
												<option
													v-for="(option, index) in field.options"
													:value="option"
													:key="`option-${index}`"
												>
													{{ option }}
												</option>
											</b-select>
										</div>
									</b-field>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal de vacancyLogs -->
		<b-modal
			:active.sync="vacancyLogsModal"
			has-modal-card
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-modal
		>
			<div
				class="modal-card"
				style="width: 70rem; margin-left: -5rem"
			>
				<div class="modal-card-head">
					<p class="subtitle">
						<span class="has-text-weight-semibold">
							Historial de cambios en la vacante {{ vacancy.name }} de {{ vacancy.company.name }}
						</span>
					</p>
				</div>
				<div class="modal-card-body">
					<b-table
						:data="tableLogs.data"
						:columns="tableLogs.columns"
						:striped="true"
						:narrowed="true"
						sticky-header
						height="400px"
					>
						<b-table-column
							label="Fecha y hora"
							centered
							v-slot="props"
							class="columns"
						>
							<span
								class="column p-0 mb-1"
								style="text-wrap: nowrap"
							>
								<b-icon
									class="mr-1"
									icon="calendar"
									size="is-small"
								>
								</b-icon>
								{{ props.row.createdAt | formatToDate }}
							</span>
							<span
								class="column p-0"
								style="text-wrap: nowrap"
							>
								<b-icon
									class="mr-1"
									icon="clock"
									size="is-small"
								>
								</b-icon>
								{{ props.row.createdAt | formatToHours }}
							</span>
						</b-table-column>

						<b-table-column
							label="Responsable"
							centered
							v-slot="props"
						>
							{{ props.row.author.fullName || props.row.author.email }}
						</b-table-column>

						<b-table-column
							label="Cambio"
							centered
							v-slot="props"
						>
							<span class="tag is-blue">
								{{ logsActionsText[props.row.action] }}
							</span>
						</b-table-column>

						<b-table-column
							label="Valor anterior"
							centered
							v-slot="props"
						>
							<span
								class="tag"
								v-if="props.row.previousValue"
							>
								{{ getVacancyLogValueField(props.row.previousValue) }}
							</span>
						</b-table-column>

						<b-table-column
							label="Valor"
							centered
							v-slot="props"
						>
							<span
								class="tag is-info"
								v-if="props.row.value"
							>
								{{ getVacancyLogValueField(props.row.value) }}
							</span>
						</b-table-column>

						<b-table-column
							label="Mensaje"
							v-slot="props"
						>
							{{ props.row.message }}
						</b-table-column>
					</b-table>
				</div>
				<div class="modal-card-foot justify-flex-end">
					<b-button
						rounded
						@click="closeLogsModal"
					>
						Cerrar
					</b-button>
				</div>
			</div>
		</b-modal>

		<b-loading :active.sync="isLoading" />
	</div>
</template>

<script>
	import moment from "moment";
	import logsActionsText from "../lib/logsActionsText";
	import academicLevels from "../lib/academicLevels";
	import languageSkills from "../lib/languageSkills";
	import days from "../lib/days";
	import vacancyStatuses from "../lib/vacancyStatuses";
	import { vacancyManagementAllowedRoles } from "../lib/permissions";

	export default {
		data() {
			return {
				isLoading: false,
				noInfo: "...",
				vacancy: {
					name: null,
					duration: "",
					places: null,
					location: {
						state: null,
						city: null,
					},
					schedule: null,
					showScholarship: false,
					scholarship: null,
					modality: null,
					degree: null,
					academicLevel: [],
					minimumSemester: null,
					maximumSemester: null,
					excelLevel: null,
					englishLevels: null,
					graduateDate: null,
					degreeDate: null,
					knowledges: null,
					goal: null,
					benefits: null,
					activities: null,
					status: null,
					phoneCallDate: null,
					deliveryDate: null,
					project: null,
					comments: null,
					newSchedule: {},
					phone:
						this.$store.state.session.user.profile && this.$store.state.session.user.profile.phone
							? this.$store.state.session.user.profile.phone
							: null,
					company: {
						name: null,
					},
					otherLanguages: null,
					otherFields: [],
					mondayVacancyId: "",
					projectMondayId: "",
					projectId: "",
				},
				days,
				tableLogs: {
					data: [],
					isPaginated: true,
					isPaginationSimple: false,
					paginationPosition: "bottom",
					defaultSortDirection: "asc",
					sortIcon: "arrow-up",
					sortIconSize: "is-small",
					currentPage: 1,
					perPage: 15,
				},
				vacancyLogsModal: false,
				logsActionsText,
				vacancyManagementAllowedRoles,
				isDisabled: true,
				academicLevels,
				vacancyStatuses,
				languageSkills,
				fieldsNames: {
					name: "Nombre de la vacante",
					duration: "Duración",
					places: "Número de Posiciones",
					state: "Estado",
					city: "Ciudad",
					schedule: "Horario",
					showScholarship: "Mostrar Beca",
					scholarship: "Beca",
					modality: "Modalidad",
					degree: "Licenciatura(s)",
					academicLevel: "Nivel académico",
					minimumSemester: "Semestre Mínimo",
					maximumSemester: "Semestre Máximo",
					excelLevel: "Nivel de Excel",
					englishLevel: "Nivel de Inglés",
					graduateDate: "Termino de Materias",
					degreeDate: "Titulación",
					skills: "Habilidades",
					knowledges: "Conocimientos",
					goal: "Objetivo",
					benefit: "Beneficios",
					activities: "Actividades Diarias",
					author: "Concato de la empresa",
					authorName: "Nombre",
					authorEmail: "Correo electrónico",
					authorPhone: "Teléfono",
					project: "Proyecto",
					comments: "Comentarios",
				},
				renamedFields: [],
				killerQuestions: [],
				hasOptions: ["single-option", "multiple-option"],
				questionTypes: {
					"open-short": "Abierta corta",
					"open-long": "Abierta larga",
					"single-option": "Opción única",
					"multiple-option": "Opción múltiple",
				},
			};
		},
		computed: {
			user() {
				return this.$store.state.session.user;
			},
			id() {
				return this.$route.params.id;
			},
			level() {
				const level = [];
				if (this.vacancy.academicLevel) {
					this.vacancy.academicLevel.forEach((element) => {
						this.academicLevels.find((x) => {
							if (x.value === element) level.push(x.text);
						});
					});
					return level;
				}
				return [];
			},
			status() {
				if (this.vacancy.status) {
					const status = [
						{ text: "Creada", value: "created" },
						{ text: "Publicada", value: "published" },
						{ text: "Cerrada", value: "closed" },
						{ text: "Cancelada", value: "canceled" },
					].find((x) => x.value === this.vacancy.status);
					return status.text;
				}
				return "";
			},
			isSpain() {
				return process.env.VUE_APP_LOCATION === "es";
			},
			mxLocation () {
				return process.env.VUE_APP_LOCATION === "mx";
			},
			getReturningPageParams(){
				if (this.$route.query.from == 'projects'){
					return { name: 'Projects', params: { id: this.vacancy.company?.id  }, query: { project: this.vacancy.projectId}}
				}
				else {
					return { name: 'Vacancies', params: { id: this.vacancy.company?.id } }
				}
			},
		},
		mounted() {
			this.getVacancy();
			this.getVacancyKillerQuestions();
		},
		methods: {
			async getVacancy() {
				const response = await this.$api.get(`/vacancies/${this.id}`);
				this.vacancy = response.data;
				if (!this.vacancy.benefits.length > 0) this.vacancy.benefits.push(response.data.benefit);
				this.vacancy.phoneCallDate = this.vacancy.phoneCallDate ? new Date(response.data.phoneCallDate) : null;
				this.vacancy.deliveryDate = this.vacancy.deliveryDate ? new Date(response.data.deliveryDate) : null;
				this.vacancy.phone =
					this.$store.state.session.user.profile && this.$store.state.session.user.profile.phone
						? this.$store.state.session.user.profile.phone
						: null;
				await this.getRenamedFields(this.vacancy.company.id);
			},
			async publish() {
				this.isLoading = true;
				try {
					const { data } = await this.$api.post(`/vacancies/${this.id}/publish`);
					this.vacancy = data;
					this.$buefy.toast.open({
						message: "La vacante se publicó",
						type: "is-success",
					});
				} catch (error) {
					this.$buefy.toast.open({
						message: !error.response ? error : error.response.data.message,
						type: "is-danger",
					});
				}
				this.isLoading = false;
			},
			wichType(field) {
				if (field.options === "default") return "column is-4";
				if (field.options === "textarea") return "column is-6";
				if (field.fieldType === "number") return "column is-4";
				if (field.fieldType === "Date") return "column is-4";
				if (field.fieldType === "boolean") return "column is-2";
				if (field.fieldType === "Array") {
					return field.options.length > 4 ? "column is-6" : "column is-4";
				}
				if (field.fieldType === "Select") return "column is-4";
			},
			async getRenamedFields(companyId) {
				const { data } = await this.$api.get(`aggregate-fields/renamedFields/${companyId}`);
				this.renamedFields = data;
			},
			renameField(actualName) {
				/* Adapting ES messages */
				if (this.isSpain) {
					for (const fieldKey in this.fieldsNames) {
						// Looking for key
						if (this.fieldsNames[fieldKey] === actualName) {
							if (this.$te(`screens.vacancies.edit.${fieldKey}`)) return this.$t(`screens.vacancies.edit.${fieldKey}`);
						}
					}
				}

				let newNamed = null;
				this.renamedFields.forEach((field) => {
					if (actualName === field.actualName) {
						newNamed = field.newName;
					}
				});
				return newNamed ? newNamed : actualName;
			},
			newPlaceholder(actualName) {
				let newPlaceholder = null;
				this.renamedFields.forEach((field) => {
					if (actualName === field.actualName) {
						newPlaceholder = field.newPlaceholder;
					}
				});
				return newPlaceholder ? newPlaceholder : "";
			},
			openLogsModal() {
				this.getVacancyLogs();
				this.vacancyLogsModal = true;
			},
			closeLogsModal() {
				this.vacancyLogsModal = false;
			},
			async getVacancyLogs() {
				this.isLoading = true;
				try {
					const { data } = await this.$api.get(`/vacancies/${this.id}/logs`);
					this.tableLogs.data = data;
				} catch (error) {
					this.$buefy.toast.open({
						message: !error.response ? error : error.response.data.message,
						type: "is-danger",
					});
				}
				this.isLoading = false;
			},
			getVacancyLogValueField(value) {
				// Verifying if it's date
				const date = moment(value);
				if (date.isValid()) return date.locale("es").format("DD-MMM-YYYY");

				// Verifying if it's status
				if (this.vacancyStatuses[value]) return this.vacancyStatuses[value].text;

				return value;
			},
			async getVacancyKillerQuestions() {
				try {
					const { data } = await this.$api.get(`/vacancies/${this.id}/killer-questions`);
					this.killerQuestions = data;
				} catch (error) {
					this.$buefy.toast.open({
						message: !error.response ? error : error.response.data.message,
						type: "is-danger",
					});
				}
			},
		},
	};
</script>
