<template>
	<div>
		<div class="column pl-0 is-full">
			<div class="title is-4 has-text-blue">
				Killer Questions
			</div>
		</div>

		<div class="column is-full">
			<ol class="ml-3">
				<li
					v-for="(answer, index) in killerQuestionsAnswers"
					:key="`answer-${index}`"
				>
					<div class="label">
						{{ answer.killerQuestion.question }}
					</div>
					<div v-if="answer.killerQuestion.questionType !== 'multiple-option'">
						<strong> R: </strong>
						{{ answer.answer }}
					</div>
					<div v-else>
						<strong> R: </strong>
						<span
							v-for="(item, index) in answer.answer"
							:key="`item-${index}`"
						>
							{{ item }}
							<span v-if="index < answer.answer.length - 1"> , </span>
						</span>
					</div>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			killerQuestionsAnswers: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
			};
		},
	};
</script>

<style lang="scss" scoped></style>
