<template>
  <div>
    <div class="columns is-multiline mb-1">
      <div class="column is-full">
        <div class="title is-4 has-text-blue">
          {{ additionalCommentsTranslation.aditionalComments }}
        </div>
        <div v-if="profile.aditionalComments">
          {{ profile.aditionalComments }}
        </div>
        <div v-else>
          {{ additionalCommentsTranslation.noAditionalComments }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    profile: {
      default: () => ({}),
      required: true
    },
  },
  data() {
    return {
    };
  },
  computed: {
    additionalCommentsTranslation() {
      return this.$t("screens.candidate.detail");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>