<template>
  <div>
    <div class="relative has-text-white">
      <div v-if="$hasPermissionsByRole(companyCreationEditionRoles)">
        <b-dropdown
          aria-role="list"
          class="is-pulled-right absolute is--right10 is--top10 pointer"
          style="z-index: 5"
          position="is-bottom-left"
        >
          <b-icon
            slot="trigger"
            icon="ellipsis-stroke"
          />

          <b-dropdown-item
            v-if="!deleted"
            aria-role="listitem"
            @click="openUpdateDialog(company)"
          >
            <b-icon icon="pencil" />
            Editar empresa
          </b-dropdown-item>

          <b-dropdown-item
            v-if="deleted"
            aria-role="listitem"
            @click="openRestoreDialog(company)"
          >
            <b-icon icon="trash-undo" />
            Restaurar empresa
          </b-dropdown-item>

          <b-dropdown-item
            v-if="!deleted && $hasPermissionsByRole(superAdminRoles)"
            aria-role="listitem"
            @click="openDeleteDialog(company)"
          >
            <div class="has-text-danger">
              <b-icon icon="trash" />
              Eliminar empresa
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <router-link
        v-if="!company.deleted"
        :to="{
          name: toLink,
          params: { id: company.id },
        }"
      >
        <div
          class="box is-flex direction-column justify-space-between"
          :style="`
            height: 100%;
            min-height: 200px;
            background: ${company.mainColor || '#42CBB6'};`"
        >
          <div>
            <div class="title is-3 has-text-white">
              {{ company.name }}
            </div>
            <div>
              <img
                :src="company.logo ? company.logo.url : ''"
                style="max-height: 60px"
              />
            </div>
          </div>

          <div>
            <div class="has-text-white is-flex justify-flex-end is-size-7">
              <div><b-icon icon="calendar-star" /> Creada: {{ timeAgo(company.createdAt) }}</div>
            </div>
          </div>
        </div>
      </router-link>

      <div
        v-else
        class="box is-flex direction-column justify-space-between opacity-6"
        :style="`
        height: 100%;
        min-height: 200px;
        background: ${company.mainColor || '#42CBB6'};`"
      >
        <div>
          <div class="title is-3 has-text-white">
            {{ company.name }}
          </div>
          <div>
            <img
              :src="company.logo ? company.logo.url : ''"
              style="max-height: 60px"
            />
          </div>
        </div>

        <div>
          <div class="has-text-white is-flex justify-flex-end is-size-7">
            <div>
              <b-icon
                size="is-small"
                icon="calendar-star"
              />
              Creada: {{ timeAgo(company.createdAt) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <CompanyModal
      ref="CompanyModal"
      :company="company"
      @update="$emit('update')"
    />

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import states from "../lib/states";
  import levels from "../lib/levels";
  import businessSector from "../lib/businessSector";
  import moment from "moment";
  import CompanyModal from "../components/modals/CompanyModal.vue";
  import { companyCreationEditionRoles, superAdminRoles } from '../lib/permissions'

  export default {
    props: {
      company: {
        type: Object,
        default: () => ({}),
      },
      deleted: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      CompanyModal,
    },
    data() {
      return {
        companyCreationEditionRoles,
        superAdminRoles,
        isLoading: false,
        updateDialog: false,
        deleteModal: false,
        file: null,
        fileName: null,
        form: {
          bureaucracyLevel: null,
          customerImpact: null,
          description: null,
          headRecruiter: null,
          headRecruiterManager: null,
          id: null,
          logo: null,
          mainColor: "",
          name: null,
          objective: null,
          remuneration: null,
          sector: null,
          state: null,
        },
        businessSector,
        states,
        levels,
        recruiters: null,
        recruiterManagers: null,
        recruiterSelected: null,
        recruiterManagerSelected: null,
        sectorSelected: null,
        stateSelected: null,
      };
    },
    computed: {
      user() {
        return this.$store.state.session.user;
      },
      toLink() {
        if (["designer", "designer-manager"].includes(this.$isRole())) return "LandingPage";
        if (["account", "account-manager"].includes(this.$isRole())) return "AccountHome";
        return "Vacancies";
      },
    },
    watch: {
      file: function (val) {
        if (val) {
          this.fileName = this.file.name ? this.file.name : this.fileName;
        }
      },
    },
    methods: {
      timeAgo(dated) {
        if (dated) {
          const date = moment(dated).locale("es");
          return date.fromNow();
        }
        return "";
      },
      async openUpdateDialog() {
        this.$refs.CompanyModal.open();
      },
      findSelect(idRecruiter) {
        this.recruiterSelected = this.recruiters.find((element) => element.id === idRecruiter);
      },
      openDeleteDialog(company) {
        this.$buefy.dialog.confirm({
          title: `¿Desea eliminar la empresa?`,
          message: `Al confirmar esta acción, se eliminará la empresa " ${company.name} " de nuestra plataforma.
                  <p>¿Desea continuar?</p>`,
          confirmText: "Sí, eliminar",
          cancelText: "No, cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => await this.deleteCompany(company),
        });
      },
      async deleteCompany(company) {
        this.isLoading = true;
        try {
          await this.$api.delete(`/companies/${company.id}`);
          this.$emit("update");
          this.$buefy.toast.open({
            message: "La empresa se ha eliminado con éxito",
            type: "is-success",
          });
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }
        this.isLoading = false;
      },
      openRestoreDialog(company) {
        this.$buefy.dialog.confirm({
          title: `¿Desea restaurar la empresa?`,
          message: `La empresa " ${company.name} " fue eliminada de la plataforma y actualmente se encuentra inactiva. Al
          confirmar esta acción, la empresa volverá a estar activa en nuestra plataforma. <p>¿Desea continuar?</p>`,
          confirmText: "Sí, restaurar",
          cancelText: "No, cancelar",
          type: "is-warning",
          hasIcon: true,
          onConfirm: async () => await this.restoreCompany(company),
        });
      },
      async restoreCompany(company) {
        this.isLoading = true;
        try {
          await this.$api.post(`/companies/${company.id}/restore`);
          this.$emit("update");
          this.$router.go();
          this.$buefy.toast.open({
            message: "La empresa se ha restaurado con éxito",
            type: "is-success",
          });
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }
        this.isLoading = false;
      },
    },
  };
</script>
