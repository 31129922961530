<template>
  <div class="container">
    <div v-if="data._id">
      <div class="pb-20 pt-20 is-flex justify-flex-end">
        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="{ name: 'AccountHome', params: { id: companyId } }">
          Regresar
        </b-button>
      </div>
      <div>
        <div class="pt-20 is-flex justify-space-between">
          <h1 class="title has-text-primary">
            {{ data.profile.firstName }} {{ data.profile.firstSurname }} {{ data.profile.lastSurname }}
          </h1>

          <div>
            <b-button
              rounded
              icon-left="files"
              type="is-primary"
              @click="openDocuments">
              Ver documentos
            </b-button>
          </div>
        </div>
        <b-button class="mb-30" tag="a" rounded target="_blank" :href="data.profile.resume.url" >
          Ver CV
        </b-button>
        <h2 class="title is-4 has-text-blue">
          Empresa: {{ company.name }}
        </h2>
        <h2 class="title is-4 has-text-blue">
          Vacante: {{ vacancy.name }}
        </h2>
      </div>
      <hr/>

      

      <div class="columns">
        <div class="column">
          <div class="card is-shadowless">
            <div class="card-content">

              <h1 class="title is-4 has-text-blue">
                Fechas de Convenio
              </h1>
              <div class="columns">                
                <div class="overflow-ellipsis align-center column label">
                  Inicio de Convenio:
                </div>
                <div class="overflow-ellipsis column label">
                  Término de Convenio:
                </div>

                <div class="column">
                </div>
                <div></div>
              </div>
              <div class="pb-30 is-flex justify-space-between">           
                <div class="overflow-ellipsis align-center">
                  {{ data.hiring.entry | formatToDate  }} 
                </div>
                <div class="overflow-ellipsis ml-45">
                  {{ data.hiring.finish | formatToDate }}
                </div>

                <div class="align-center">
                  <b-button
                    rounded
                    type="is-primary"
                    @click="openEdit()"
                    icon-right="floppy-disk">
                    Editar Fechas
                  </b-button>
                </div>
                <div></div>
              </div>

              <h1 class="title is-4 has-text-blue">
                Información del becario
              </h1>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    RFC:
                  </div>
                  <div>
                    {{ data.profile.rfc || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    CURP:
                  </div>
                  <div>
                    {{ data.profile.curp }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    País de Nacimiento
                  </div>
                  <div>
                    {{ data.profile.nativeCountry || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Estado de Nacimiento
                  </div>
                  <div>
                    {{ data.profile.nativeState || "Pendiente..." }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    Género
                  </div>
                  <div>
                    {{ data.profile.gender }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Fecha de Nacimiento
                  </div>
                  <div>
                    {{ data.profile.birthdate | formatDate }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    e-mail corporativo
                  </div>
                  <div>
                    {{ data.profile.companyEmail || "Pendiente..." }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    Número de celular
                  </div>
                  <div>
                    {{ data.profile.phone }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Teléfono
                  </div>
                  <div>
                    {{ data.profile.phoneHome || "Pendiente..." }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    Calle
                  </div>
                  <div>
                    {{ data.profile.nameStreet || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Número exterior
                  </div>
                  <div>
                    {{ data.profile.outdoorNumber || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Número interior
                  </div>
                  <div>
                    {{ data.profile.indoorNumber || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Código postal
                  </div>
                  <div>
                    {{ data.profile.postalCode }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Colonia
                  </div>
                  <div>
                    {{ data.profile.neighborhood || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    País de residencia
                  </div>
                  <div>
                    {{ data.profile.country || "Pendiente..."  }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Estado de residencia
                  </div>
                  <div>
                    {{ data.profile.state || "Pendiente..."  }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    Nombre de Contacto de Emergencia
                  </div>
                  <div>
                    {{ data.profile.nameContactEmergency || "Pendiente..."  }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Teléfono de Emergencia
                  </div>
                  <div>
                    {{ data.profile.phoneContactEmergency || "Pendiente..."  }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Parentesco
                  </div>
                  <div>
                    {{ data.profile.relationshipEmergency || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Otro Parentesco
                  </div>
                  <div>
                    {{ data.profile.anotherRelationshipEmergency || "Pendiente..." }}
                  </div>
                </div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="label">
                    Carrera
                  </div>
                  <div>
                    {{ data.profile.career }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Plan de Estudios
                  </div>
                  <div>
                    {{ data.profile.studyPlan || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Otro plan de Estudios
                  </div>
                  <div>
                    {{ data.profile.relationshipEmergency || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Periodo
                  </div>
                  <div>
                    {{ data.profile.periodSchool || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Fecha de Inicio de Periodo
                  </div>
                  <div>
                    {{ data.profile.startPeriod || "Pendiente..." }}
                  </div>
                </div>
                <div class="column is-4">
                  <div class="label">
                    Vigencia de Constancia
                  </div>
                  <div>
                    {{ data.profile.startPeriod || "Pendiente..." }}
                  </div>
                </div>
              </div>
              <hr>

              <div class="columns is-multiline">
                <div class="column is-full">
                  <div class="label">
                    Documentos:
                  </div>
                  <div class="overflow-ellipsis">
                    {{ "Pendiente..."  }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-loading />
    </div>

    <Documents
      :data="data"
      ref="documents"
    />

    <CandidateEdit ref="edit" @updated="getCandidate"/>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import Documents from '@/components/modals/Documents'
import CandidateEdit from '../components/modals/CandidateEdit.vue'


export default {
  components: {
    Documents,
    CandidateEdit,
  },
  data() {
    return {
      isLoading: false,
      vacancy: null,
      data: {},
    }
  },
  computed: {
    user () {
      return this.$store.state.session.user
    },
    id () {
      return this.$route.params.id
    },
    company () {
      return this.$store.state.company
    },
    companyId () {
      return this.$store.state.company.id
    }
  },
  mounted () {
    this.getCandidate()
  },
  methods: {
    async getCandidate () {
      this.isLoading = true
      try {
        const { data } = await this.$api.get(`/candidates/${this.id}`)
        this.data = data
        this.vacancy = data.vacancy
        if(!this.data.hiring){
          this.data.hiring = {}
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    openDocuments () {
      this.$refs.documents.open()
    },
    openPDF (link) {
      const url = this.$router.resolve({ name: 'PDFViewer', query: { empty: true, link: link } })
      window.open(url.href)
      return false
    },
    openEdit () {
      this.$refs.edit.open(this.data)
    },
  },
}
</script>
