<template>
  <div>
    <table v-if="candidateData">
      <tr>
        <th style="vertical-align: middle">
          <h1>Información del candidato</h1>
        </th>
        <th style="vertical-align: middle">
          <div style="text-align: right;">
            <img
              style="width: 150px;"
              src="@/assets/logo-blue.png"
              alt="ProMeritum"
            >
          </div>
        </th>
      </tr>
    </table>

    <div>
      <div v-for="(document, index) in candidateData" :key="`document-${index}`">
        <CandidateProfile
          v-if="document.fat4"
          :candidateProfile="document.fat4"
        />

        <CandidateKillerQuestions
          v-if="document.killerQuestions"
          :killerQuestionAnswers="document.killerQuestions"
        />
        
        <CandidatePromeritumFeeback
          v-if="document.fat6"
          :promeritumFeedback="document.fat6"
        />

        <CandidateCustomEvaluation
          v-if="document.customEvaluation"
          :customEvaluation="document.customEvaluation"
        />

        <CandidateCompanyFeedback
          v-if="document.companyInterviewFeedback"
          :companyInterviewFeedback="document.companyInterviewFeedback"
        />
        
      </div>
    </div>
  </div>
</template>

<script>
import CandidateProfile from '@/components/download-candidate-profile/CandidateProfile.vue'
import CandidateKillerQuestions from '@/components/download-candidate-profile/CandidateKillerQuestions.vue'
import CandidatePromeritumFeeback from '@/components/download-candidate-profile/CandidatePromeritumFeedback.vue'
import CandidateCustomEvaluation from '@/components/download-candidate-profile/CandidateCustomEvaluation.vue'
import CandidateCompanyFeedback from '@/components/download-candidate-profile/CandidateCompanyFeedback.vue'

export default {
  data () {
    return {
      candidateData: null,
    }
  },
  components: {
    CandidateProfile,
    CandidateKillerQuestions,
    CandidatePromeritumFeeback,
    CandidateCustomEvaluation,
    CandidateCompanyFeedback
  },
  beforeMount() {
    try {
      const candidateProfileStored = window.localStorage.getItem('PM_CANDIDATE_PROFILE')
      this.candidateData = JSON.parse(candidateProfileStored)
    } catch (error) {
      this.$buefy.toast.open( {
        message: 'No se encontró el perfil.',
        type: 'is-danger'
      })
    }
  },
  mounted () {
    document.title = 'Pro Meritum'
    window.onafterprint = this.closeWindow
    window.print()
  },
  methods: {
    closeWindow () {
      const getCandidateProfile = window.localStorage.getItem('PM_CANDIDATE_PROFILE')
      if (getCandidateProfile) {
        window.localStorage.removeItem('PM_CANDIDATE_PROFILE')
      }
      this.candidateData = null
      window.close()
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 20px;
  color: #04395c;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>
