const dashboardTerms = {
  searcher: {
    'recruiter-manager': 'Coordinador',
    'recruiter': 'Reclutador',
  },
  contactsSearcher: {
    'hrmanager': 'Manager de RH',
    'solicitant': 'Solicitante',
  },
  funnel: {
    'psychometricSent': 'Test psicométrico enviado',
    'pmInterviewSent': 'Entrevista PM enviada',
    'selected': 'Seleccionados',
    'sent': 'Enviados',
    'hired': 'Contratados',
  },
  applicationsGrossProfile: {
    byStatus: {
      'applying': 'En aplicación',
      'selected': 'Seleccionados',
      'sent': 'Enviados',
      'pre-hired': 'Seleccionados por empresa',
      'hired': 'Contratados',
      'rejected': 'Rechazados',
    },
  }
}


module.exports = {
  dashboardTerms,
}