<template>
  <div>
    <div class="column pl-0 pt-0 mb-2 is-full">
			<div class="title is-4 has-text-blue">
				{{ promeritumInterviewTranslation.characterProfile }}
			</div>
		</div>

    <!-- Recomendable -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.recommendable }}
      </div>
      <div>
        {{ recommendable[form.recommendable] }}
      </div>
    </div>
    <hr />

    <!-- Ambiante familiar -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.familyEnviroment }}
      </div>
      <div>
        {{ form.familyEnviroment }}
      </div>
    </div>
    <hr />

    <!-- Tiempo libre -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.freeTime }}
      </div>
      <div>
        {{ form.freeTime }}
      </div>
    </div>
    <hr />

    <!-- Deportes -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.sports }}
      </div>
      <div>
        {{ form.sports }}
      </div>
    </div>
    <hr />

    <!-- Personaje -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.character }}
      </div>
      {{ form.character }}
    </div>
    <hr />

    <!-- Retos -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.challenges }}
      </div>
      <div>
        {{ form.challenges }}
      </div>
    </div>
    <hr />

    <!-- Logros -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.achievements }}
      </div>
      <div>
        {{ form.achievements }}
      </div>
    </div>
    <hr />

    <!-- metas mediano plazo -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.mediumTermGoals }}
      </div>
      <div>
        {{ form.mediumTermGoals }}
      </div>
    </div>
    <hr />

    <!-- Metas largo plazo -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.longTermGoals }}
      </div>
      <div>
        {{ form.longTermGoals }}
      </div>
    </div>
    <hr />

    <!-- Fortalezas -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.strengths }}
      </div>
      <div>
        {{ form.strengths }}
      </div>
    </div>
    <hr />

    <!-- Habilidades por mejorar -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.skillToImprove }}
      </div>
      <div>
        {{ form.skillToImprove }}
      </div>
    </div>
    <hr />

    <!-- Trayecto profesional -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.careerPath }}
      </div>
      <div>
        {{ form.careerPath }}
      </div>
    </div>
    <hr />

    <!-- Capacidad 1 -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.capabilityOne }}
      </div>
      <div>
        {{ form.capabilityOne }}
      </div>
    </div>
    <hr />

    <!-- Capacidad 2 -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.capabilityTwo }}
      </div>
      <div>
        {{ form.capabilityTwo }}
      </div>
    </div>
    <hr />

    <!-- capacidad 3 -->
    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.capabilityThree }}
      </div>
      <div>
        {{ form.capabilityThree }}
      </div>
    </div>

    <!-- Video aplicacion -->
    <div v-if="form.applicationLinkVideo">
      <hr />
      <div class="label">
        {{ promeritumInterviewTranslation.urlVideo }}
      </div>

      <div>
        <a
          :href="form.applicationLinkVideo"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ form.applicationLinkVideo }}
        </a>
      </div>
    </div>

    <!-- documento aplicacion -->
    <div v-if="form.applicationFile">
      <hr />
      <div class="label">
        {{ promeritumInterviewTranslation.uploadFile }}
      </div>

      <div>
        <a
          :href="form.applicationFile.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ form.applicationFile.name }}
        </a>
      </div>
    </div>
    <hr />

    <div>
      <div class="label">
        {{ promeritumInterviewTranslation.comments }}
      </div>
      <div>
        {{ form.comments }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    form: {
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
      recommendable: {
        "recommendable": "Recomendable",
        "recommended-with-reservations": "Recomendable con reservas",
        "non-recommendable": "No recomendable",
      }
    };
  },
  computed:{
    promeritumInterviewTranslation() {
      return this.$t("screens.candidate.detail");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>