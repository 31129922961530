export default {
    validation: {
        confirmed: "Confirma la contraseña",
        required: "Este campo es requerido",
        requiredArray: "Debes seleccionar al menos una opción",
        email: "Please enter a valid email address",
        numeric: "El valor debe ser numérico",
        digits: "Ingresa {length} dígitos",
        min_value: "El valor debe ser mayor que {min}",
        max: "El número de caracteres debe ser menor a {length}",
        min: "El número de caracteres debe ser mayor a {length}",
        max_value: "El valor debe ser menor que {max}",
        length: "La longitud del valor debe ser igual a {length}",
        image: "El tipo de archivo {name} no es una imagen",
        hour: "Ingresa la hora en formato de 24hrs",
        fileSize: "El archivo debe pesar máximo {size} MB"
    }
};
