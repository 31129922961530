<template>
  <div class="card mt-5 mb-5">
    <div class="card-content p-2 column is-12 is-multiline">
      <div class="column is-12">
        <h4 class="title is-size-6 mb-4">Secciones</h4>

        <!-- Resolution selector -->
        <div class="columns is-multiline">
          <b-field class="column is-12">
            <b-select
              placeholder="Selecciona una resolución"
              icon="laptop-mobile"
              icon-pack="far"
              expanded
              @focus="saveFirstResolutions"
              v-model="selectedResolution"
            >
              <option 
                v-for="availableMediaQuery in availableMediaQueries" 
                :key="availableMediaQuery._id" 
                :value="availableMediaQuery"
              >
                <span class="has-text-weight-semibold">
                  {{ availableMediaQuery.alias }}
                </span>
                <span class="has-text-weight-medium">
                  ({{ availableMediaQuery.minWidth }}px - {{ availableMediaQuery.maxWidth }}px)
                </span>
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- Add section button -->
        <div class="is-flex is-justify-content-flex-end">
          <b-button 
            type="is-primary"
            :disabled="!selectedResolution"
            @click="addMediaSection"
          >
            <f-icon icon="fa-regular fa-square-plus" class="mr-1"/>
            Agregar sección
          </b-button>
        </div>

        <hr>

        <!-- Media sections container -->
        <div style="height: fit-content;" v-if="filteredMediaSections.length">
          <MediaSectionLandingPage
            v-for="(mediaSection, index) in filteredMediaSections" :key="index"
            :defaultMediaSection="mediaSection"
            :selectedSection="selectedSection"
            :availableMediaQueries="availableMediaQueries"
            @activeSection="activeSection"
            @activeMediaSource="activeMediaSource" 
            @updateMediaSection="updateMediaSection"
          />
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center has-text-grey-light" v-else>
          <f-icon icon="fa-regular fa-glass-empty" class="is-size-4 mb-2"/>
          Aun no hay secciones agregadas
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaSectionLandingPage from "./MediaSectionLandingPage.vue";
export default {
  props: {
    defaultMediaSections: {
      type: Array,
    },
    availableMediaQueries: {
      type: Array,
    }
  },
  components: {
    MediaSectionLandingPage,
  },
  data() {
    return {
      mediaSections: [],
      filteredMediaSections: [],
      selectedResolution: null,
      selectedSection: null,
      selectedMediaSource: null,
      isLoading: false,
    }
  },
  mounted() {
    this.mediaSections = [...this.defaultMediaSections];
  },
  watch: {
    defaultMediaSections: {
      handler: function (val) {
        this.mediaSections = [...val]
      }
    },
    mediaSections: {
      handler: function (val) {
        let newSelectedSection = null
        this.filteredMediaSections = val.filter(section => {
          // Updating selectedSection
          if (section._id == this.selectedSection?._id)
            newSelectedSection = section

          const mediaQueriesIds = section.mediaQueries.map(query => query._id);

          return mediaQueriesIds.includes(this.selectedResolution?._id)
        });
        this.selectedSection = newSelectedSection
      },
      deep: true
    },
    selectedResolution: {
      handler: function (val) {
        this.filteredMediaSections = this.mediaSections.filter(section => {
          const mediaQueriesIds = section.mediaQueries.map(query => query._id);

          return mediaQueriesIds.includes(val?._id)
        });
        this.selectedSection = null
      }
    }
  },
  methods: {
    updateMediaSections() {
        this.$emit('updateLandingProperty', this.mediaSections, 'mediaSections')
    },
    updateMediaSection(mediaSection){
      this.mediaSections = this.mediaSections.map(section => {
        if(section._id == mediaSection._id){
          return mediaSection;
        }
        return section;
      });
      
      this.updateMediaSections();
    },
    activeSection(mediaSection) {
      this.selectedSection = mediaSection;
    },
    activeMediaSource(mediaSource){
      this.selectedMediaSource = mediaSource
    },
    addMediaSection(){
      this.mediaSections.push({
        name: `section-${this.mediaSections.length + 1}`,
        backgroundImage: null,
        backgroundImageFile: null,
        mediaQueries: [this.selectedResolution],
        multimediaSources: [],
      });
      this.updateMediaSections();
    },
    riseSection(){
      // Checking element before
      const filteredCurrentIndex = this.filteredMediaSections.findIndex(section => section._id === this.selectedSection._id)
      if(filteredCurrentIndex === 0)
        return

      // Finding current and before index in general array
      const sectionIndex = this.mediaSections.findIndex(section => 
      section._id === this.selectedSection._id)
      const beforeSectionIndex = this.mediaSections.findIndex(section => 
      section._id === this.filteredMediaSections[filteredCurrentIndex - 1]._id)  

      // Adding item before  
      this.mediaSections.splice(beforeSectionIndex, 0, this.mediaSections[sectionIndex]);

      // Removing item
      this.mediaSections.splice(sectionIndex + 1, 1)


      this.updateMediaSections();
    },
    descendSection(){
      // Checking element after
      const filteredCurrentIndex = this.filteredMediaSections.findIndex(section => section._id === this.selectedSection._id)
      if(filteredCurrentIndex === this.filteredMediaSections.length - 1)
        return

      // Finding current and afet index in general array
      const sectionIndex = this.mediaSections.findIndex(section => 
      section._id === this.selectedSection._id)
      const afterSectionIndex = this.mediaSections.findIndex(section => 
      section._id === this.filteredMediaSections[filteredCurrentIndex + 1]._id) + 1 

      // Adding item after
      this.mediaSections.splice(afterSectionIndex, 0, this.mediaSections[sectionIndex]);

      // Removing item
      this.mediaSections.splice(sectionIndex, 1)

      this.updateMediaSections();
    },
    saveFirstResolutions(){
      const needsSaving = this.availableMediaQueries.some(query => !query._id);
      if (needsSaving) {
        this.updateMediaSections();
      }
    }
  }
}
</script>

<style scoped>
</style>
