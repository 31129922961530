<template>
  <button 
    class="upButton"
    @click="goUp"
  >
    <f-icon icon="fa-regular fa-up"/>
  </button>
</template>

<script>
export default {
  props: {
    viewId: {
      type: String,
      required: true
    }
  },
  methods: {
    goUp() {
      document.getElementById(this.viewId).scrollIntoView();
    }
  }
}
</script>

<style scoped>
  .upButton{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #0f486d;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
</style>