<template>
  <div class="hero is-fullheight justify-center">
    <div class="columns is-centered">
      <div class="column is-3">
        <div>
          <img style="width: 50%" src="@/assets/logo-white.png" alt="ProMeritum" />
        </div>
        <div class="card">
          <div class="card-header">
            <div class="column">
              <div class="subtitle">Inicia sesión</div>
            </div>
          </div>
          <div class="card-content">
            <div v-if="!selectedForm" class="buttons">
              <b-button icon-left="envelope" type="is-primary" expanded @click="selectForm('emailLoginForm')">
                Correo electrónico
              </b-button>
              <b-button icon-left="user-lock" type="is-primary" outlined expanded
                @click="selectForm('userPasswordLoginForm')">
                Usuario y Contraseña
              </b-button>
            </div>

            <div v-else>
              <component :is="currentFormComponent" @go-back="clearSelection" />
            </div>
          </div>

          <footer class="card-footer">
            <p class="card-footer-item">
              <span>Versión {{ version }}</span>
            </p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { version } from "../../../package.json";
import EmailLoginForm from "@/components/auth/EmailLoginForm.vue";
import UserPasswordLoginForm from "@/components/auth/UserPasswordLoginForm.vue";

export default {
  name: "AuthLogin",
  data() {
    return {
      selectedForm: null,
      version: version,
    };
  },
  components: {
    EmailLoginForm,
    UserPasswordLoginForm,
  },
  mounted() {
    this.$store.dispatch("logout");
  },
  methods: {
    selectForm(formType) {
      this.selectedForm = formType;
    },
    clearSelection() {
      this.selectedForm = null;
    },
  },
  computed: {
    currentFormComponent() {
      if (this.selectedForm === "emailLoginForm") {
        return EmailLoginForm;
      } else if (this.selectedForm === "userPasswordLoginForm") {
        return UserPasswordLoginForm;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  // background: linear-gradient(
  //   90deg,
  //   rgba(158, 212, 220, 1) 0%,
  //   rgba(178, 139, 223, 1) 30%,
  //   rgba(237, 233, 155, 1) 70%,
  //   rgba(138, 214, 168, 1) 100%
  // );
  background: linear-gradient(339deg, #0f486d 0%, rgba(66, 203, 182, 1) 100%);
}
</style>
