import Vue from "vue";
import VueI18n from "vue-i18n";
import content from '../content';

Vue.use(VueI18n)

const i18n = new VueI18n(
  {
    locale: content.getLocales(process.env.VUE_APP_LOCATION || 'mx'),
    messages: content.getLocaleMessages(process.env.VUE_APP_LOCATION || 'mx')
  }
);

export default i18n;