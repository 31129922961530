<template>
  <div>
    <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
			<div class="title is-4 mb-0 has-text-blue">
				{{ customEvaluationTranslation.customEvaluation }}
			</div>
      <!-- Icono guardar cambios de customevaluation -->
      <a 
        class="ml-2"
        @click="sendCustomEvaluation"
      >
        <b-tooltip
          animated
          class="is-flex is-align-items-end"
          label="Guardar cambios"
          position="is-top"
          type="is-success"
        >
          <b-icon
            class="icons"
            icon="floppy-disk"
          ></b-icon>
        </b-tooltip>
      </a>
		</div>
    
    <ValidationObserver ref="observer">
      <!-- Application video url -->
      <b-field :label="customEvaluationTranslation.urlVideo">
        <span class="is-flex">
          <b-input
            :placeholder="customEvaluationTranslation.urlVideo"
            type="text"
            v-model="form.applicationLinkVideo"
            class="is-flex-grow-1"
          />
          <b-tooltip
            animated
            class="is-flex-grow-0 is-flex-shrink-1 is-flex is-flex-direction-column is-justify-content-center ml-2"
            label="Abrir enlace"
            position="is-top"
            type="is-success"
          >
            <a
              :href="form.applicationLinkVideo"
              target="_blank"
              disabled
            >
              <f-icon
                class="mx-4"
                icon="fa-regular fa-up-right-from-square"
              />
            </a>
          </b-tooltip>
        </span>
      </b-field>

      <!-- Application file -->
      <div class="mb-2 mt-2 columns is-multiline">
        <div class="column is-12 mt-2 pt-0 pb-0 label">
          {{ customEvaluationTranslation.uploadFile }} formato PDF con peso máximo 5MB
        </div>
        <ValidationProvider
          rules="fileSize:5"
          name="applicationFile"
          v-slot="{ errors, valid }"
        >
          <b-field
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
            class="ml-3 mb-1"
          >
            <b-upload
              v-model="form.applicationFile"
              accept=".pdf"
            >
              <a class="button is-primary is-fullwidth">
                <b-icon icon="upload" />
                <span>Subir {{ customEvaluationTranslation.uploadFile }}</span>
              </a>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <b-tooltip
          label="Abrir archivo"
          position="is-top"
          type="is-success"
          animated
        >
          <a
            v-if="form.applicationFileMetadata"
            class="file-name"
            :href="form.applicationFileMetadata.url"
            target="_blank"
          >
            {{ form.applicationFileMetadata.name }}
          </a>
        </b-tooltip>
        <b-button
          v-if="form.applicationFileMetadata"
          class="ml-10"
          type="is-danger"
          icon-left="trash"
          @click="deleteApplicationFile"
        />
        <div
          class="mt-0 mb-0 pt-0 pb-0 column is-12"
          v-if="form.applicationFile && form.applicationFile.size"
        >
          Peso del archivo: {{ Number(form.applicationFile.size / (1024 * 1024)).toFixed(2) }} MB
        </div>
      </div>
      
      <!-- Additional comments -->
      <b-field :label="customEvaluationTranslation.aditionalComments">
        <textarea
          :placeholder="customEvaluationTranslation.aditionalComments"
          v-model="form.aditionalComments"
          rows="3"
          class="is-flex-grow-1 textarea"
        />
      </b-field>
    </ValidationObserver>
  </div>
</template>

<script>
import { customEvaluationForm } from '@/mixins/customEvaluationForm';
import { sendCustomEvaluation } from "../../../api/candidate";


export default {
  props: {
    profile: {
      default: () => null,
      required: false
    }
  },
  mixins: [customEvaluationForm], 
  data() {
    return {
      
    };
  },
  mounted() {
    this.setcustomEvaluationForm()
  },
  computed: {
    customEvaluationTranslation() {
      return this.$t("screens.candidate.detail");
    },
    customEvaluation() {
      return {
        applicationLinkVideo: this.profile.applicationLinkVideo || "",
        applicationFile: this.profile.applicationFile || null,
        aditionalComments: this.profile.aditionalComments || "",
      };
    },
    id() {
      return this.$route.params.id;
    }
  },
  watch: {
    "form.applicationFile": function () {
        this.uploadApplicationFile();
      }
  },
  methods: {
    setcustomEvaluationForm(){
      if(this.customEvaluation){
        this.form = this.customEvaluation
      }
    },
    async validateS3FileSize(file) {
        /* Cheking */
        if (file?.size && file.size / (1024 * 1024) > 5) {
          this.$buefy.dialog.alert({
            title: "¡Oooops!",
            message: "Lo siento, pero el archivo de aplicación debe pesar menos de 5MB",
            type: "is-danger",
            hasIcon: true,
            confirmText: "Ok, lo entiendo",
          });
          this.form.applicationFile = null;
          if (this.form.applicationFileMetadata) await this.deleteApplicationFile();
          return false;
        }
        return true;
    },
    async uploadApplicationFile() {
      const file = this.form.applicationFile;
      if (!file) return;

      // Validating file size
      const isAdmittedSize = await this.validateS3FileSize(file);
      if (!isAdmittedSize) return;

      const payload = new FormData();
      payload.append("file", file);

      try {
        this.isLoading = true;
        const { data } = await this.$api.post(`application/${this.id}/application-file`, payload);
        this.form.applicationFileMetadata = data;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
        });
        this.form.applicationFile = null;
      }
      this.isLoading = false;
    },
    async deleteApplicationFile() {
      const fileMetadata = this.form.applicationFileMetadata;
      if (!fileMetadata) return;

      const payload = {
        key: fileMetadata.key,
      };

      try {
        await this.$api.post(`application/${this.id}/application-file/delete`, payload);
        this.form.applicationFile = null;
        this.form.applicationFileMetadata = null;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
        });
      }
    },
    buildCustomEvaluationPayload() {
        return {
          applicationLinkVideo: this.form.applicationLinkVideo,
          applicationFile: this.form.applicationFileMetadata,
          aditionalComments: this.form.aditionalComments,
        };
      },
    async sendCustomEvaluation() {
      this.isLoading = true;

      const payload = this.buildCustomEvaluationPayload();
      await sendCustomEvaluation(this.id, payload);
      this.$emit("customEvaluationUpdated");

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>