<template>
  <div>
    <div class="column pl-0 pt-0 is-full">
			<div class="title is-4 has-text-blue">
				{{ promeritumInterviewTranslation.characterProfile }}
			</div>
		</div>

    <ValidationObserver ref="observer">
      <!-- Recommendable -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.recommendable"
        v-slot="{ errors, valid }"
      >
        <div class="label mt-2">
          {{ promeritumInterviewTranslation.recommendable }}
        </div>
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-radio-button
            v-for="(option, index) in options"
            :key="`option-${index}`"
            :native-value="option.value"
            v-model="interview.recommendable"
            type="is-primary"
          >
            <span>{{ option.text }}</span>
          </b-radio-button>
        </b-field>
      </ValidationProvider>

      <!-- Family environment -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.familyEnviroment"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.familyEnviroment"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.familyEnviroment"
            type="textarea"
            v-model="interview.familyEnviroment"
          />
        </b-field>
      </ValidationProvider>

      <!-- Free time -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.freeTime"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.freeTime"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.freeTime"
            type="textarea"
            v-model="interview.freeTime"
          />
        </b-field>
      </ValidationProvider>

      <!-- Sports -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.sports"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.sports"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.sports"
            type="textarea"
            v-model="interview.sports"
          />
        </b-field>
      </ValidationProvider>

      <!-- Character -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.character"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.character"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.character"
            type="textarea"
            v-model="interview.character"
          />
        </b-field>
      </ValidationProvider>

      <!-- Challenges -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.challenges"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.challenges"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.challenges"
            type="textarea"
            v-model="interview.challenges"
          />
        </b-field>
      </ValidationProvider>

      <!-- Achievements -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.achievements"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.achievements"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.achievements"
            type="textarea"
            v-model="interview.achievements"
          />
        </b-field>
      </ValidationProvider>

      <!-- Short term goals -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.mediumTermGoals"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.mediumTermGoals"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.mediumTermGoals"
            type="textarea"
            v-model="interview.mediumTermGoals"
          />
        </b-field>
      </ValidationProvider>

      <!-- Medium term goals -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.longTermGoals"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.longTermGoals"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.longTermGoals"
            type="textarea"
            v-model="interview.longTermGoals"
          />
        </b-field>
      </ValidationProvider>

      <!-- Strengths -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.strengths"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.strengths"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.strengths"
            type="textarea"
            v-model="interview.strengths"
          />
        </b-field>
      </ValidationProvider>

      <!-- Skills to improve -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.skillToImprove"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.skillToImprove"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.skillToImprove"
            type="textarea"
            v-model="interview.skillToImprove"
          />
        </b-field>
      </ValidationProvider>

      <!-- Career path -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.careerPath"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.careerPath"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.careerPath"
            type="textarea"
            v-model="interview.careerPath"
          />
        </b-field>
      </ValidationProvider>

      <!-- Capability one -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.capabilityOne"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.capabilityOne"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.capabilityOne"
            type="textarea"
            v-model="interview.capabilityOne"
          />
        </b-field>
      </ValidationProvider>

      <!-- Capability two -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.capabilityTwo"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.capabilityTwo"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.capabilityTwo"
            type="textarea"
            v-model="interview.capabilityTwo"
          />
        </b-field>
      </ValidationProvider>

      <!-- Capability three -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.capabilityThree"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.capabilityThree"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.capabilityThree"
            type="textarea"
            v-model="interview.capabilityThree"
          />
        </b-field>
      </ValidationProvider>

      <!-- Comments -->
      <ValidationProvider
        rules="required"
        :name="promeritumInterviewTranslation.comments"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="promeritumInterviewTranslation.comments"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="mt-4"
        >
          <b-input
            :placeholder="promeritumInterviewTranslation.comments"
            type="textarea"
            v-model="interview.comments"
          />
        </b-field>
      </ValidationProvider>
    </ValidationObserver>

    <div class="is-flex justify-flex-end mt-5">
      <b-button
        type="is-primary"
        @click="donePromeritumInterview"
      >
        {{ promeritumInterviewTranslation.addComments }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { promeritumInterviewForm } from '@/mixins/promeritumInterview';
export default {
  mixins:[promeritumInterviewForm],
  data() {
    return {
      options: [
        { text: "Recomendable", value: "recommendable" },
        { text: "Recomendable con reservas", value: "recommended-with-reservations" },
        { text: "No recomendable", value: "non-recommendable" },
      ],
    };
  },

  mounted() {
    
  },
  computed:{
    promeritumInterviewTranslation() {
      return this.$t("screens.candidate.detail");
    },
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async donePromeritumInterview() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.$buefy.dialog.confirm({
          title: `¿Cerrar la entrevista?`,
          message: `Al cerrarla, tu feedback del candidato no podrá ser editado, asegúrate de haber escrito
                  un feedback completo de tu candidato.`,
          confirmText: "Ok, estoy de acuerdo",
          cancelText: "Ooops, mejor no",
          onConfirm: async () => await this.sendPromeritumFeedback(),
        });
      }
    },
    async sendPromeritumFeedback() {
      this.isLoading = true;

      const payload = this.interview;
      try {
        await this.$api.post(`application/${this.id}/promeritum-feedback`, payload);
        this.$emit('update')
        this.$buefy.toast.open({
          message: "Se guardó el feedback del candidato",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.$emit('promeritumInterviewUpdated')
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>