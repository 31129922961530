import httpApi from '@/axios'
import { ToastProgrammatic as Toast } from 'buefy'

const createUser = async function (payload) {
  try {
    await httpApi.post(`/users`, payload)
    Toast.open({
      message: 'El usuario se ha creado con éxito',
      type: 'is-success'
    })
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data?.message,
      type: 'is-danger',
      duration: 5000
    })

    return Promise.reject(error)
  }
}

const getRecruiters = async function () { // Get all recruiters (including recruiter managers)
  try {
    const { data } = await httpApi.get(`/users/all-recruiters`)
    return data

  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data?.message,
      type: 'is-danger',
      duration: 5000
    })

    return Promise.reject(error)
  }
}


export {
  createUser,
  getRecruiters,
}
