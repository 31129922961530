export default {
    validation: {
        confirmed: "Password confirmation is required",
        required: "This field is required",
        requiredArray: "Select at least one option",
        email: "Please enter a valid email address",
        numeric: "The value must be numeric",
        digits: "Enter {length} digits",
        min_value: "Value must be greater than {min}",
        max: "The number of characters must be less than {length}",
        min: "The number of characters must be greater than {length}",
        max_value: "The value must be less than {max}",
        length: "The value length must be equal to {length}",
        image: "File type {name} is not an image",
        hour: "Enter the time in 24-hour format",
        fileSize: "The file must not exceed {size} MB"
    }
};
