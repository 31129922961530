<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-full is-flex is-align-content-center">
        <div class="title is-4 mb-0 has-text-blue">
          {{ generalDataTranslation.generalProfile }}
        </div>
        <!-- Botón de Edición-->
        <a v-if="canEditProfile" class="ml-2" @click="$emit('editGeneralProfile')">
          <b-tooltip animated class="is-flex is-align-items-end" label="Editar perfil general" position="is-top"
            type="is-success">
            <b-icon class="icons" icon="pen-to-square"></b-icon>
          </b-tooltip>
        </a>
      </div>

      <!-- Birthday -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.birthday }}
        </div>
        <div>
          {{ profile.birthdate | formatDate }}
        </div>
      </div>

      <!-- Gender -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.gender }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.gender }}
        </div>
      </div>

      <!-- Pronoun -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.pronoun }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.pronoun || "Consultar con candidatx" }}
        </div>
      </div>

      <!-- Disability -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.anyDisability }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.hasDisability ? "Sí" : "No" }}
        </div>
      </div>

      <!-- Disability type -->
      <div class="column is-6" v-if="profile.hasDisability">
        <div class="label">
          {{ generalDataTranslation.typeDisability }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.disability }}
          <span v-if="profile.disability === 'Otro'"> - {{ profile.otherDisability }}</span>
        </div>
      </div>

      <!-- Phone number -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.telephone }}
        </div>
        <div class="overflow-ellipsis">{{ profile.phoneCode }} {{ profile.phone }}</div>
      </div>

      <!-- Email -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.mail }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.email }}
        </div>
      </div>

      <!-- National identifier -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.nationalIdentifier }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.curp }}
        </div>
      </div>

      <!-- Address -->
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.state }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.state }}
        </div>
      </div>
      <div class="column is-6">
        <div class="label">
          {{ generalDataTranslation.city }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.city }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
    canEditProfile:Boolean

  },
  computed: {
    generalDataTranslation() {
      return this.$t("screens.candidate.detail");
    },
  },
};
</script>

<style lang="scss" scoped></style>
