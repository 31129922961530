<template>
  <SimpleCollapse
    title="Vacantes de contacto"
    description="Resumen de vacantes del contacto seleccionado."
    icon="fa-regular fa-list-timeline"
    :data="data"
    @open="getData"
    ref="collapse"
  >
    <template #content="{ data }">
      <div class="vacancies-contact-list">
        <ContactSeacher
          @getData="getData"
        />

        <div class="vacancies-list">
          <VacancyContact
            v-for="vacancy in data"
            :key="vacancy._id"
            :vacancy="vacancy"
          />
          <div
            v-if="!data.length"
            class="has-text-centered is-flex is-flex-direction-column is-justify-content-center mt-6 mb-6"
          >
            <f-icon icon="fa-regular fa-mug-hot" class="has-text-primary is-size-3 mb-3"/>
            <p class="has-text-weight-normal">No se encontraron datos</p>
          </div>

          <b-loading 
            :is-full-page="false"
            :active="isLoading" 
          />
        </div>
      </div>
    </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from "./SimpleCollapse.vue";
import VacancyContact from "./VacancyContact.vue";
import ContactSeacher from "./ContactSeacher.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCollapse,
    VacancyContact,
    ContactSeacher
  },
  data() {
    return {
      data: [],
      option: null,
      isLoading: false
    }
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData(this.option)
      },
      deep: true
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(option){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      let payload = { ...this.search }

      if (option){
        payload = {...payload, contacts: [option.id]}
        delete payload.company
      }

      const data = await this.loadFunction(payload)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
    ...mapGetters(["companyId"]),
  }
};
</script>

<style lang="scss" scoped>
  .vacancies-contact-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .vacancies-list{
    margin-top: 1rem;
    width: 100%;
    max-height: 530px;
    min-height: 100px;
    overflow-y: auto;
    position: relative;
    border-radius: 0.8rem;
  }
</style>
