<template>
  <div>
    <div class="title">
      404, no hay nada que ver aquí.
    </div>
  </div>
</template>

<script>
export default {
  name: '404Error'
}
</script>

<style>

</style>