<template>
  <div class="vacancy-card">
    <!-- Nombre y status de la vacante -->
    <div class="title-box">
      <router-link :to="{
        name: 'VacancyDetail',
        params: { id: vacancy._id }}"
        class="has-text-weight-semibold is-size-4 title-text has-text-primary" 
        target="_blank"
      >
        {{ vacancy.name }}
      </router-link>

      <b-tag 
        :type="`is-${vacancyStatuses[vacancy.status].color}`"
        class="is-flex is-jusfiy-content-flex-end"
        size="is-medium"
        rounded
      >
        {{ vacancyStatuses[vacancy.status].text }}
      </b-tag>
    </div>

    <!-- INFORMACIÓN VACANTE -->
    <div class="main-content">
      <!-- info general -->
      <div class="column px-3 py-2">
        <!-- Reclutador -->
        <p class="has-text-weight-semibold is-size-6 mt-1">
          <b-tooltip
            position="is-bottom"
            label="Reclutador asignado"
          >
            <f-icon icon="fa-regular fa-user-magnifying-glass" class="has-text-primary"/>
            <span>
              {{ vacancy.recruiter?.name }}
            </span>
          </b-tooltip>
        </p>

        <!-- Solicitante -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Solicitante designado"
          >
            <f-icon icon="fa-regular fa-person-circle-question" class="has-text-primary mr-1"/>
            <span>
                {{ vacancy.author?.name }}
            </span>
          </b-tooltip>
        </p>
      </div>

      <!-- Info particular -->
      <div class="column px-3 py-2"
        style="border-left: 1px solid #dcdcdc40;"
      >
          <p class="mb-1 has-text-weight-medium has-text-primary">
            Agenda
          </p>

          <!-- Fecha de creación -->
          <p>
            <b-tooltip
              position="is-top"
              label="Fecha de creación"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-arrow-up" class="has-text-grey-light mr-1"/>
              {{ vacancy.createdAt | formatToDate }}
            </span>
            </b-tooltip>
          </p>

          <!-- Fecha de entrega de candidatos -->
          <p
            v-if="vacancy.deliveryDate || vacancy.realDeliveryDate"
          >
            <b-tooltip
              position="is-top"
              label="Fecha de entrega de candidatos"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-paper-plane" class="has-text-grey-light mr-1"/>
              {{ (vacancy.realDeliveryDate || vacancy.deliveryDate) | formatToDate }}
            </span>
            </b-tooltip>
          </p>

      </div>

    </div>

    <!-- POSICIONES -->
    <div style="width: 100%;" class="mt-1">
      <b-progress 
            type="is-primary"
            :value="vacancy.sentApplications" 
            size="is-medium" 
            show-value
            :max="vacancy.places"
        >
        <f-icon icon="fa-regular fa-user-check" class="mr-1"/>
          {{ vacancy.sentApplications }} / {{ vacancy.places }} posiciónes enviadas
        </b-progress>
    </div>
  </div>
</template>

<script>
import vacancyStatuses from '@/lib/vacancyStatuses'

export default {
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      vacancyStatuses,
    }
  },
};
</script>

<style lang="scss" scoped>
  .vacancy-card{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #dcdcdc;
    background-color: white;
  }
  .vacancy-card:hover{
    box-shadow: 0 0 5px 0 #dcdcdc;
  }
  .title-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem;
    padding-top: 0.6rem;
  }
  .title-text{
    max-width: 80%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .main-content{
    padding: 0 0.3rem;
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
</style>
