<template>
    <div
      class="vacancies-container scrolleable"
    >
      <!-- En caso de no habeer vacantes -->
      <div class="card mb-4 p-4 vacancy-card has-text-centered" v-if="vacancies.length < 1">
        <p class=" is-size-5">
          Sin vacantes para mostrar
        </p>
      </div>

      <div class="card mb-4 p-4 vacancy-card" v-for="(vacancy, index) in vacancies" :key="`vacancy-${index}`" v-else>
        <!-- Datos de la vacante -->
        <div class="columns is-flex is-justify-content-space-between mb-0">
          <div class="column is-9">
            <p class="has-text-weight-semibold is-size-4 mb-2">{{ vacancy.name }}</p>
            <p class="is-size-6 is-flex is-align-items-center mb-2"
              v-if="vacancy.active"
            >
              <b-icon
                icon="fa-eye"
                size="is-small"
                type="is-success"
                class="mr-2"
              />
              Visible
            </p>
            <p class="is-size-6 is-flex is-align-items-center mb-2"
              v-else
            >
              <b-icon
                icon="fa-eye"
                size="is-small"
                type="is-danger"
                class="mr-2"
              />
              No visible
            </p>
            <p class="is-size-6"><b>Plazas disponibles: </b>{{ vacancy.places }}</p>
            <p class="is-size-6"><b>Duración del proyecto: </b>{{ vacancy.duration }}</p>
          </div>
          <div class="column is-3 is-flex is-justify-content-flex-end">
            <b-button class="share" icon-right="envelope" rounded @click="shareVacancy(vacancy)"
              :style="`background-color:${companyColor}; color: white`"
            >
              <span>Compartir</span>
            </b-button>
          </div>
        </div>
        <!-- Requisitos vacante -->
        <p class="is-size-5 mb-2 has-text-weight-medium">Requisitos de la vacante</p>
        <div class="requirements">
          <!-- Carrera -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareCareer(vacancy.degree)" />
            <b>Carrera(s)</b>
            <ul>
              <li v-for="(career, index) in vacancy.degree" :key="`career-${index}`">
                - {{ career }}
              </li>
            </ul>
          </div>
          <!-- Nivel academico -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareAcademicLevel(vacancy.academicLevel)" />
            <p class="mt-1 is-5">
              <b>Nivel Academico</b>
            </p>
            <ul>
              <li v-for="(level, index) in vacancy.academicLevel" :key="`level-${index}`">
                {{ academicLevels(level) }}
              </li>
            </ul>
          </div>
          <!-- Termino de materias -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareGraduateDate(vacancy.graduateDate)" />
            <p>
              <b>Termino de materias</b>
            </p>
            <p>
              {{ vacancy.graduateDate | formatToDate }}
            </p>
          </div>
          <!-- Nivel de Excel -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareExcelLevel(vacancy.excelLevel)" />
            <p><b>Nivel de Excel</b></p>
            <p>{{ vacancy.excelLevel }}</p>
          </div>
          <!-- Nivel de Ingles -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareEnglishLevel(vacancy.englishLevel)" />
            <p><b>Nivel de Ingles</b></p>
            <p>{{ vacancy.englishLevel }}</p>
          </div>
          <!-- Lugar -->
          <div class="has-text-centered requirement box">
            <b-icon icon="badge-check" :type="compareLocation(vacancy.location.state)" />
            <p><b>Ubicacion:</b></p>
            <p> {{ vacancy.location.city }}, {{ vacancy.location.state }}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    vacancies: {
      default: null,
      required: true
    },
    companyColor: {
      default: '#618093',
      required: true
    },
    user: {
      default: null,
      required: true
    },
  },
  methods: {
    shareVacancy(vacancy) {
      this.$emit('vacancyShared', vacancy);
    },
    academicLevels(level) {
      if (level === 'student') return 'Estudiante'
      if (level === 'recently-graduated') return 'Recién graduado'
      if (level === 'experienced-graduated') return 'Graduado con experiencia'
    },
    compareCareer(degrees) {
      let deg = []
      degrees.forEach(degree => deg.push(degree.toLowerCase()))
      if (deg.includes(this.user.candidate.profile.career.toLowerCase())) return 'si-success'
      else return 'is-danger'
    },
    compareAcademicLevel(academicLevels) {
      if (academicLevels.includes(this.user.candidate.profile.academicLevel[0])) return 'is-success'
      else return 'is-danger'
    },
    compareGraduateDate(date) {
      const vacancyDate = new Date(date);
      const candidateDate = new Date(this.user.candidate.profile.graduateDate);
      if (vacancyDate <= candidateDate) {
        return 'is-success';
      } else {
        return 'is-danger';
      }

    },
    compareExcelLevel(vacancyLevel) {
      if (vacancyLevel === 'Intermedio') {
        if (this.user.candidate.profile.excelLevel === 'Básico') return 'is-danger'
        else return 'is-success'
      }
      else if (vacancyLevel === 'Avanzado') {
        if (this.user.candidate.profile.excelLevel === 'Avanzado') return 'is-success'
        else return 'is-danger'
      }
      else return 'is-success'
    },
    compareEnglishLevel(vacancyLevel) {
      if (vacancyLevel === 'Intermedio') {
        if (this.user.candidate.profile.englishLevel === 'Básico') return 'is-danger'
        else return 'is-success'
      }
      else if (vacancyLevel === 'Avanzado') {
        if (this.user.candidate.profile.englishLevel === 'Avanzado') return 'is-success'
        else return 'is-danger'
      }
      else return 'is-success'
    },
    compareLocation(state) {
      if (state === this.user.candidate.profile.state) return 'is-success'
      else return 'is-danger'
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancies-container {
  min-width: 40%;
  height: 100%;
  flex-grow: 1;
}

.vacancy-card {
  border-radius: 7px;
  border-width: 2px;
  border-style: solid;
  border-color: v-bind('companyColor');
}

.share {
  padding-right: 2rem;
}

.share span {
  max-width: 0;
  -webkit-transition: max-width 1s;
  transition: max-width 800ms;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;

}

.share:hover span {
  max-width: 10rem;
}

.requirements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
}

.requirement {
  flex-basis: 32%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem !important;
}
</style>
