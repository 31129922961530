<template>
  <div>
    <!-- General user -->
    <div class="profile-box">
      <CandidateGeneralData :profile="profile" @editGeneralProfile="editingGeneralProfile = true"
      v-if="!editingGeneralProfile" :canEditProfile="canEditProfile" />
      <EditGeneralProfileForm v-if="editingGeneralProfile" :profile="profile"
        @generalProfileUpdated="handleProfileUpdate($event, 'general')" />
    </div>

    <hr />

    <!-- Academic user -->
    <div class="profile-box">
      <CandidateAcademicData :profile="profile" @editAcademicProfile="editingAcademicProfile = true"
      v-if="!editingAcademicProfile" :canEditProfile="canEditProfile" />
      <EditAcademicProfile v-if="editingAcademicProfile" :profile="profile"
        @academicProfileUpdated="handleProfileUpdate($event, 'academic')" />
    </div>

    <hr />

    <!-- Skills and knowledge user -->
    <div class="profile-box">
      <CandidateSkillsKnowledges :profile="profile" @editskillsknowledgesForm="editingSkillsKnowProfile = true" v-if="!editingSkillsKnowProfile" :canEditProfile="canEditProfile" />
      <EditSkillsKnowledgesForm v-if="editingSkillsKnowProfile" :profile="profile"
        @SkillsKnowledgesProfileUpdated="handleProfileUpdate($event, 'skillsknowledges')" />
    </div>


    <!-- Professional user -->
    <div class="profile-box">
      <CandidateProfessionalData :profile="profile" @editprofessionaldataform="editingProfileProffessional = true"
      v-if="!editingProfileProffessional" :canEditProfile="canEditProfile"/>
      <EditProfessionalDataForm v-if="editingProfileProffessional" :profile="profile"
        @ProfileProfessionalUpdated="handleProfileUpdate($event, 'professionaldata')" />

    </div>

    <hr />

    <!-- Vacancy found source -->
    <CandidateVacancySource :application="application" />
    <hr />

    <!-- Additional Comments -->
    <CandidateAdditionalComments :profile="profile" />
  </div>
</template>

<script>
import CandidateGeneralData from './general-data/CandidateGeneralData.vue';
import EditGeneralProfileForm from './general-data/EditGeneralProfileForm.vue';
import CandidateAcademicData from './academic-data/CandidateAcademicData.vue';
import EditAcademicProfile from './academic-data/EditAcademicProfile.vue';
import CandidateAdditionalComments from './CandidateAdditionalComments.vue';
import CandidateVacancySource from './CandidateVacancySource.vue';
import CandidateSkillsKnowledges from './skills-knowledges/CandidateSkillsKnowledges.vue';
import EditSkillsKnowledgesForm from './skills-knowledges/EditSkillsKnowledgesForm.vue';
import CandidateProfessionalData from './professional-data/CandidateProfessionalData.vue';
import EditProfessionalDataForm from './professional-data/EditProfessionalDataForm.vue';
import { editProfileAllowedRolesWithSteps } from '@/lib/permissions';


export default {
  props: {
    application: {
      default: () => ({}),
      required: true,
    },
  },
  components: {
    CandidateGeneralData,
    EditGeneralProfileForm,
    CandidateAcademicData,
    EditAcademicProfile,
    CandidateSkillsKnowledges,
    EditSkillsKnowledgesForm,
    CandidateProfessionalData,
    CandidateVacancySource,
    CandidateAdditionalComments,
    EditProfessionalDataForm,
  },
  data() {
    return {
      editingGeneralProfile: false,
      editingAcademicProfile: false,
      editingSkillsKnowProfile: false,
      editingProfileProffessional: false,
      profile: { ...this.application.profile },
      status : null
    };
  },
  computed: {
    canEditProfile() {
      const userRole = this.$isRole();

      const allowedEditingStatuses = editProfileAllowedRolesWithSteps[userRole]
      if (!this.status || !allowedEditingStatuses) return false;
      
      return allowedEditingStatuses.includes(this.status) ;
    }
  },
  watch: {
    application() {
      this.profile = { ...this.application.profile }
    },
  },
  methods: {
    handleProfileUpdate(updatedProfile, type) {
      this.profile = { ...this.profile, ...updatedProfile };
      const editingStates = {
        general: 'editingGeneralProfile',
        academic: 'editingAcademicProfile',
        skillsknowledges: 'editingSkillsKnowProfile',
        professionaldata: 'editingProfileProffessional',
      };
      if (editingStates[type]) {
        this[editingStates[type]] = false;
      }
    },
  },
  beforeMount () {
    // Obteniendo Status del candidato
    this.status = this.application.status
  }

};
</script>



<style lang="scss" scoped>
.profile-box {
  border-radius: 15px;
  padding: .5rem;
  margin-bottom: 2rem;
  background-color: white;
}
</style>
