<template>
  <div class="page mb-2">
    <h2 class="mb-2 mt-2">Entrevista Pro Meritum</h2>

    <table class="block">
      <tr>
        <th>
          <div class="label">Recomendable:</div>
        </th>
        <th>
          <div class="label">Entorno familiar:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.recommendable }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.familyEnviroment }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Tiempo libre:</div>
        </th>
        <th>
          <div class="label">Deportes:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.freeTime }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.sports }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Personaje:</div>
        </th>
        <th>
          <div class="label">Retos:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.character }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.challenges }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Logros:</div>
        </th>
        <th>
          <div class="label">Metas a mediano plazo:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.achievements }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.mediumTermGoals }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Metas a largo plazo:</div>
        </th>
        <th>
          <div class="label">Fortalezas:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.longTermGoals }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.strengths }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Habilidades por mejorar:</div>
        </th>
        <th>
          <div class="label">Trayectoria profesional:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.skillToImprove }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.careerPath }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Competencia 1:</div>
        </th>
        <th>
          <div class="label">Competencia 2:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.capabilityOne }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.capabilityTwo }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Competencia 3:</div>
        </th>
        <th>
          <div class="label">Observaciones:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ promeritumFeedback.capabilityThree }}</div>
        </td>
        <td>
          <div class="text">{{ promeritumFeedback.comments }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    promeritumFeedback: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #0F486D;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.label {
  font-size: 10px;
  color: #42CBB6;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
}

.page {
  margin-bottom: 30px !important;
}

.block {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>