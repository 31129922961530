<template>
  <ValidationObserver ref="observer">
    <div class="columns is-multiline px-3 pb-3">
    
      <!-- Nombre -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Nombre"
          v-slot="{ errors, valid }"
        >
          <b-field label="Nombre"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input maxlength="64" style="width: 100%" 
              v-model="projectForm.name"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Objetivo -->
      <div class="column is-full">
        <ValidationProvider rules="required" name="Objetivo"
          v-slot="{ errors, valid }"
        >
          <b-field
            label="Objetivo"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input type="textarea" maxlength="256" style="width: 100%" 
              v-model="projectForm.objective"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Duración -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Duración del proyecto"
          v-slot="{ errors, valid }"
        >
          <b-field label="Duración del proyecto"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="hourglass-start"
              v-model="projectForm.duration"
              expanded
              >
              <option
                :value="duration.value"
                v-for="(duration, index) in durationOptions"
                :key="`duration-${index}`">
                {{ duration.text }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Cantidad de posiciones -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Cantidad de vacantes"
          v-slot="{ errors, valid }"
        >
          <b-field label="Cantidad de posiciones"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input icon="hashtag"
              type="number"
              v-model="projectForm.positions"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Remuneración -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Remuneración"
          v-slot="{ errors, valid }"
        >
          <b-field label="Remuneración"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
            >
            <b-input
              v-model="projectForm.remuneration"
              type="number"
              style="width: 100%"
              icon="money-bill"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Mostrar remuneración -->
      <div class="column is-6">
        <b-field label="¿Mostrar remuneración?"
          class=""
        >
          <div class="is-flex align-center">
            <b-switch
              size="is-medium"
              v-model="projectForm.showRemuneration"
              ></b-switch>
            <b>{{ projectForm.showRemuneration ? 'Si' : 'No' }}</b>
          </div>
        </b-field>
      </div>

      <!-- Es de tecnología -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="¿Es de tecnología?"
          v-slot="{ errors, valid }"
        >
          <b-field label="¿Es de tecnología?"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
            class=" "
          >
            <div class="is-flex align-center" >
              <b-switch
                size="is-medium"
                v-model="projectForm.isTechnology"
              ></b-switch>
              <b>
                {{ projectForm.isTechnology ? 'Si' : 'No' }}
              </b>
            </div>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Beneficios -->
      <b-field label="Beneficios" class="column is-12">
        <div style="width: 100%;">
          <div class="is-flex justify-space-between mt-3 mb-3"
            v-for="(benefit, index) in projectForm.benefits"
            :key="`benefit-${index}`"
          >
            <b-input icon="hand-holding-heart" style="width: 100%;"
              v-model="projectForm.benefits[index]"
            />
              <b-button class="ml-10" type="is-danger" icon-left="trash"
                @click="removeBenefit(index)"
              />
          </div>

          <b-button type="is-primary" expanded
            @click="addBenefit"
          >
            Agregar beneficio
          </b-button>
        </div>
      </b-field>

      <!-- Ubicación de proyecto -->
      <div class="column is-full">
        <hr style="margin:0;">
        <p class="title is-5 mt-3"> Ubicación del proyecto</p>
      </div>
      <div class="column is-6">
        <ValidationProvider rules="required" name="Estado"
          v-slot="{ errors, valid }"
        >
          <b-field :label="`${projectTranslation.state}`"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select expanded icon="map-location"
              v-model="projectForm.location.state"
              v-if="isMexico"
            >
              <option
                v-for="(state, index) in states"
                :key="`state-${index}`"
                :value="state"
              >
              {{ state.name }}
              </option>
            </b-select>

            <b-select expanded icon="map-location"
              v-model="projectForm.location.state"
              v-else
            >
              <option
                v-for="(state, index) in provinces"
                :key="`state-${index}`"
                :value="state"
              >
              {{ state.name }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Ciudad -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Ciudad"
          v-slot="{ errors, valid }"
        >
          <b-field :label="`${projectTranslation.city}`"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="location-dot" expanded
              v-model="projectForm.location.city"
              :disabled="projectForm.location.state.length === 0"
              v-if="isMexico"
            >
              <option
                v-for="(city, index) in stateCities"
                :key="`city-${index}`"
                :value="city"
              >
              {{ city.name }}
              </option>
            </b-select>

            <b-select icon="location-dot" expanded
              v-model="projectForm.location.city"
              :disabled="projectForm.location.state.length === 0"
              v-else
            >
              <option
                v-for="(city, index) in provincesMunicipalities"
                :key="`city-${index}`"
                :value="city"
              >
              {{ city.name }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- información de reclutamiento -->
      <div class="column is-full">
        <hr style="margin:0;">
        <p class="title is-5 mt-3"> Reclutamiento </p>
      </div>

      <!-- Reclutador -->
      <div  class="column is-12">
        <ValidationProvider rules="required" name="Reclutador asignado"
          v-slot="{ errors, valid }"
        >
          <b-field label="Reclutador asignado"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <div class="is-flex align-center">
              <b-select expanded style="width: 100%;" icon="hand-pointer"
                v-if="!mainRecruiter"
                v-model="projectForm.headRecruiter"
                :disabled="!recruiters.length"
              >
                <option
                  :value="recruiter.id"
                  v-for="(recruiter, index) in recruiters"
                  :key="`recruiter-${index}`">
                  {{ index + 1 }}.- {{ recruiter.fullName }}
                </option>
              </b-select>

              <b-tooltip
                label="Obtener recomendación de reclutador"
                position="is-bottom"
                type="is-info">
                <b-button
                  class="ml-3"
                  type="is-info"
                  outlined
                  @click="getRecommendation">
                  <f-icon
                    :icon="!loadRecom ? 'fa-regular fa-person-circle-question' : 'fa-regular fa-spinner'"
                    size="xl"
                    :class="`selectable ${ loadRecom ? 'fa-spin' : '-'}`" />
                </b-button>
              </b-tooltip>
            </div>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Reclutadores adicionales -->
      <div class="column is-12">
        <div class="columns">
          <div class="column is-6">
            <b-field
              label="Reclutadores adicionales disponibles"
            >
              <b-select
                @input="addRecruiter"
                expanded
              >
                <option
                    :disabled="projectForm.additionalRecruiters.includes(recruiter.id)"
                    :value="recruiter"
                    v-for="(recruiter, index) in recruiters"
                    :key="`additionalrecruiter-${index}`">
                    {{ index + 1 }}.- {{ recruiter.fullName }}
                  </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Reclutadores adicionales seleccionados"
            >
              <b-taginput
                @remove="removeRecruiter"
                v-model="showRecruiters"
                ellipsis
                aria-close-label="Delete this tag">
              </b-taginput>
            </b-field>
          </div>
        </div>
      </div>

      <!-- Contacto de la empresa -->
      <div class="column is-12">
        <ValidationProvider rules="required" name="Contacto de la empresa"
          v-slot="{ errors, valid }"
        >
          <b-field label="Contacto de la empresa"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="address-book" expanded style="width: 100%;"
              v-model="projectForm.author._id"
              :disabled="!companyUsers.length > 0 "
            >
              <option
                :value="user.id"
                v-for="(user, index) of companyUsers"
                :key="`user-${index}`">
                {{ index + 1 }}.- {{ user.fullName }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Contactos adicionales -->
      <div class="column is-12">
        <div class="columns">
          <div class="column is-6">
            <b-field
              label="Contactos adicionales disponibles"
            >
              <b-select  
                expanded
                @input="addSolicitant"
                :disabled="!companyUsers.length > 0 "
              >
                <option
                  :disabled="projectForm.additionalAuthors.includes(user.id)"
                  :value="user"
                  v-for="(user, index) in companyUsers"
                  :key="`user-${index}`">
                  {{ index + 1 }}.- {{ user.fullName }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Contactos adicionales seleccionados"
            >
              <b-taginput
                @remove="removeSolicitant"
                v-model="showSolicitants"
                ellipsis
                aria-close-label="Delete this tag">
              </b-taginput>
            </b-field>
          </div>
        </div>
      </div>

      <!-- Id de requisión de monday -->
      <div class="column is-6"
        v-if="isMexico"
      >
        <ValidationProvider rules="required" name="Id de Requisición Monday"
          v-slot="{ errors, valid }"
        >
          <b-field label="Id de Requisición Monday"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input icon="id-card" maxlength="10"
              v-model="projectForm.projectMondayId" 
            />
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Esquema de requisición -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Esquema de requisición"
          v-slot="{ errors, valid }"
        >
          <b-field label="Esquema de requisición"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="file-contract" expanded
              v-model="projectForm.schemaType"
            >
              <option
                v-for="(schema, index) in servicesSchema"
                :key="`schema-${index}`"
                :value="schema"
              >
                {{ schema }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Tipo de servicio -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Tipo de servicio"
          v-slot="{ errors, valid }"
        >
          <b-field label="Tipo de servicio"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="list-radio" expanded
              v-model="projectForm.serviceType"
            >
              <option
                v-for="(service, index) in recruitmentServices"
                :key="`service-${index}`"
                :value="service"
              >
                {{ service }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Tipo de reclamación -->
      <div class="column is-6">
        <ValidationProvider rules="required" name="Tipo de reclamación"
          v-slot="{ errors, valid }"
        >
          <b-field label="Tipo de reclamación"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="game-console-handheld" expanded
              v-model="projectForm.claimType"
            >
              <option
                v-for="(claim, index) in servicesClaims"
                :key="`service-${index}`"
                :value="claim"
              >
                {{ claim }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Conocimientos del proyecto -->
      <div class="column is-full">
        <hr style="margin:0;">
        <p class="title is-5 mt-3">Conocimientos del proyecto</p>
      </div>
      <div class="column is-6">
        <ValidationProvider rules="required" name="Nivel de Excel"
          v-slot="{ errors, valid }"
        >
          <b-field label="Nivel de Excel"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-select icon="file-excel" expanded
              v-model="projectForm.excelLevel"
            >
              <option
                :value="level"
                v-for="(level, index) in levels"
                :key="`excelLevel-${index}`">
                {{ level  }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Nivel de inglés -->
      <div class="column is-full">
        <hr style="margin:0;">
        <p class="title is-5 mt-3">Idiomas del proyecto</p>
      </div>
      <div class="column is-full">
        <div class="language-table">
          <p class="is-size-5 has-text-left has-text-weight-semibold">
            {{ projectForm.englishLevels.language }}
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Deseable
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Básico
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Intermedio
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Avanzado
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-info-dark">
            Representativa
          </p>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Escritura
          </p>
          <b-radio name="englishWriting" native-value="Deseable" required
            v-model="projectForm.englishLevels.writing"
            ></b-radio>
          <b-radio name="englishWriting" native-value="Básico" required
            v-model="projectForm.englishLevels.writing"
            ></b-radio>
          <b-radio name="englishWriting" native-value="Intermedio" required
            v-model="projectForm.englishLevels.writing"
            ></b-radio>
          <b-radio name="englishWriting" native-value="Avanzado" required
            v-model="projectForm.englishLevels.writing"
            ></b-radio>
          <b-checkbox
            v-model="projectForm.englishLevels.representativeSkill" 
            native-value="writing" 
            required></b-checkbox>
          
          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Lectura
          </p>
          <b-radio name="englishReading" native-value="Deseable" required
            v-model="projectForm.englishLevels.reading"
            ></b-radio>
          <b-radio name="englishReading" native-value="Básico" required
            v-model="projectForm.englishLevels.reading"
            ></b-radio>
          <b-radio name="englishReading" native-value="Intermedio" required
            v-model="projectForm.englishLevels.reading"
            ></b-radio>
          <b-radio name="englishReading" native-value="Avanzado" required
            v-model="projectForm.englishLevels.reading"
            ></b-radio>
          <b-checkbox
            v-model="projectForm.englishLevels.representativeSkill" 
            native-value="reading" 
            required></b-checkbox>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Escucha
          </p>
          <b-radio name="englishListening" native-value="Deseable" required
            v-model="projectForm.englishLevels.listening"
          ></b-radio>
          <b-radio name="englishListening" native-value="Básico" required
            v-model="projectForm.englishLevels.listening"
          ></b-radio>
          <b-radio name="englishListening" native-value="Intermedio" required
            v-model="projectForm.englishLevels.listening"
          ></b-radio>
          <b-radio name="englishListening" native-value="Avanzado" required
            v-model="projectForm.englishLevels.listening"
          ></b-radio>
          <b-checkbox
            v-model="projectForm.englishLevels.representativeSkill" 
            native-value="listening" 
            required></b-checkbox>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Conversación
          </p>
          <b-radio name="englishSpeaking" native-value="Deseable" required
            v-model="projectForm.englishLevels.speaking"
          ></b-radio>
          <b-radio name="englishSpeaking" native-value="Básico" required
            v-model="projectForm.englishLevels.speaking"
          ></b-radio>
          <b-radio name="englishSpeaking" native-value="Intermedio" required
            v-model="projectForm.englishLevels.speaking"
          ></b-radio>
          <b-radio name="englishSpeaking" native-value="Avanzado" required
            v-model="projectForm.englishLevels.speaking"
          ></b-radio>
          <b-checkbox
            v-model="projectForm.englishLevels.representativeSkill" 
            native-value="speaking" 
            required></b-checkbox>
        </div>
      </div>

      <!-- Otros idiomas -->
      <div class="column is-full"
        v-for="(language, index) in projectForm.otherLanguages"
        :key="`language-${index}`"
      >
        <hr style="margin:0; margin-bottom: 1rem;">
        <div class="is-flex mb-2 mt-2"
        >
          <b-input class="mr-2" style="width: 100%;" maxlength="24"
            v-model="projectForm.otherLanguages[index].language"
            ></b-input>

          <b-button type="is-danger"
            @click="removeLenguaje(index)">
            <f-icon icon="fa-regular fa-trash"/>
          </b-button>
        </div>

        <div class="language-table">
          <p class="is-size-5 has-text-weight-semibold" >
            {{ projectForm.otherLanguages[index].language }}
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Deseable
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Básico
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Intermedio
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
            Avanzado
          </p>
          <p class="is-size-6 has-text-weight-medium has-text-info-dark">
            Representativa
          </p>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Escritura
          </p>
          <b-radio native-value="Deseable" required
            v-model="projectForm.otherLanguages[index].writing"
            :name="`lenguage${index}Writing`"
            ></b-radio>
          <b-radio native-value="Básico" required
            v-model="projectForm.otherLanguages[index].writing"
            :name="`lenguage${index}Writing`"
            ></b-radio>
          <b-radio native-value="Intermedio" required
            :name="`lenguage${index}Writing`"
            v-model="projectForm.otherLanguages[index].writing"
          ></b-radio>
          <b-radio native-value="Avanzado" required
            :name="`lenguage${index}Writing`"
            v-model="projectForm.otherLanguages[index].writing"
          ></b-radio>
          <b-checkbox
            v-model="projectForm.otherLanguages[index].representativeSkill" 
            native-value="writing" 
            required></b-checkbox>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Lectura
          </p>
          <b-radio native-value="Deseable" required
            v-model="projectForm.otherLanguages[index].reading"
            :name="`lenguage${index}Reading`"
            ></b-radio>
          <b-radio native-value="Básico" required
            v-model="projectForm.otherLanguages[index].reading"
            :name="`lenguage${index}Reading`"
          ></b-radio>
          <b-radio native-value="Intermedio" required
            v-model="projectForm.otherLanguages[index].reading"
            :name="`lenguage${index}Reading`"
          ></b-radio>
          <b-radio native-value="Avanzado" required
            v-model="projectForm.otherLanguages[index].reading"
            :name="`lenguage${index}Reading`"
            ></b-radio>
          <b-checkbox
            v-model="projectForm.otherLanguages[index].representativeSkill" 
            native-value="reading" 
            required></b-checkbox>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Escucha
          </p>
          <b-radio native-value="Deseable" required
            v-model="projectForm.otherLanguages[index].listening"
            :name="`lenguage${index}Listening`"
            ></b-radio>
          <b-radio native-value="Básico" required
            v-model="projectForm.otherLanguages[index].listening"
            :name="`lenguage${index}Listening`"
          ></b-radio>
          <b-radio native-value="Intermedio" required
            v-model="projectForm.otherLanguages[index].listening"
            :name="`lenguage${index}Listening`"
          ></b-radio>
          <b-radio native-value="Avanzado" required
            v-model="projectForm.otherLanguages[index].listening"
            :name="`lenguage${index}Listening`"
            ></b-radio>
          <b-checkbox
            v-model="projectForm.otherLanguages[index].representativeSkill" 
            native-value="listening" 
            required></b-checkbox>

          <p class="is-size-6 has-text-left has-text-weight-medium has-text-primary">
            Conversación
          </p>
          <b-radio native-value="Deseable" required
            v-model="projectForm.otherLanguages[index].speaking"
            :name="`lenguage${index}Speaking`"
            ></b-radio>
          <b-radio native-value="Básico" required
            v-model="projectForm.otherLanguages[index].speaking"
            :name="`lenguage${index}Speaking`"
          ></b-radio>
          <b-radio native-value="Intermedio" required
            v-model="projectForm.otherLanguages[index].speaking"
            :name="`lenguage${index}Speaking`"
          ></b-radio>
          <b-radio native-value="Avanzado" required
            v-model="projectForm.otherLanguages[index].speaking"
            :name="`lenguage${index}Speaking`"
            ></b-radio>
          <b-checkbox
            v-model="projectForm.otherLanguages[index].representativeSkill" 
            native-value="speaking" 
            required></b-checkbox>
        </div>
      </div>

      <div class="column is-full">
        <b-button expanded type="is-primary"
          @click="addLenguaje"
        >
          Agregar idioma
        </b-button>
      </div>

    </div>
  </ValidationObserver>
</template>
<script>
import states from '@/lib/states'
import cities from '@/lib/cities'
import provinces from '@/lib/ES/provinces'
import municipalities  from '@/lib/ES/municipalities'
import servicesSchema from '@/lib/servicesSchema'
import servicesClaims from '@/lib/servicesClaims'
import recruitmentServices from '@/lib/recruitmentServices'
import { mapGetters } from 'vuex'
import durationOptions from '@/lib/durationOptions'

export default {
  props:{
    project:{
      type: Object,
      required: true
    },
  },
  components: {
  },
  data(){
    return{
      states,
      cities,
      provinces,
      municipalities,
      levels: [
        'Básico',
        'Intermedio',
        'Avanzado',
      ],
      durationOptions,
      projectForm: {
        name: null,
        isTechnology: false,
        positions: null,
        projectMondayId: '',
        benefits: [''],
        duration: '',
        location: {
          state: '',
          city: '',
        },
        englishLevels: {
          language: 'Inglés',
          listening: '',
          reading: '',
          speaking: '',
          writing: '',
          representativeSkill: []
        },
        excelLevel: '',
        otherLanguages: [],
        objective: '',
        remuneration: null,
        showRemuneration:  false,
        headRecruiter: null,
        author: {
          _id: '',
          fullName: ''
        },
        schemaType: '',
        serviceType: null,
        claimType: null,
        additionalRecruiters: [],
        additionalAuthors: []
      },
      servicesSchema,
      servicesClaims,
      recruitmentServices,
      companyInfo: null,
      recruiters: [],
      companyUsers: [],
      loadRecom: false,
      mainRecruiter: false,
      isLoading: false,
      showRecruiters: [],
      showSolicitants: []
    }
  },
  async mounted(){
    if (this.project.id)
      this.projectForm = {...this.project}
    await this.companyData()
    await this.getCompanyUsers()
    await this.getRecruiters()
  },
  computed: {
    ...mapGetters(['companyId']),
    user() {
      return this.$store.state.session.user
    },
    id () {
      return this.$route.params.id
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION === 'es'
    },
    projectTranslation(){
      return this.$t('screens.projects.detail')
    },
    stateCities () {
      if (this.projectForm.location.state) {
        const state = this.states.find(st => st.id === this.projectForm.location.state.id)
        const cities = [...this.cities]
          .filter(city => city.state_id === state.id)
          .sort((a, b) => {
            const nameA = this.replaceAccent(a.name)
            const nameB = this.replaceAccent(b.name)
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
            return 0
          })
        return cities
      }
      return []
    },
    provincesMunicipalities() {
      const provinces = this.projectForm.location.state
        ? this.municipalities.filter(item => item.state_id == this.projectForm.location.state.id)
            .sort((a, b) => {
              const nameA = this.replaceAccent(a.name);
              const nameB = this.replaceAccent(b.name);
              if (nameA > nameB) {
                return 1;
              }
              if (nameA < nameB) {
                return -1;
              }
              return 0;
            })
        : [];

      return provinces;
    },
  },
  watch:{
    "projectForm.positions" (val) {
      if(val && val < 1){
        this.$buefy.toast.open({
          message: 'La cantidad de vacantes mínima es 1',
          type: 'is-warning'
        })
        setTimeout(() => {
          this.projectForm.positions = 1
        }, 1000);
      }
      if(val && val > 100){
        this.$buefy.toast.open({
          message: 'La cantidad de vacantes máxima es 100',
          type: 'is-warning'
        })
        setTimeout(() => {
          this.projectForm.positions = null
        }, 1000);
      }
    },
    "projectForm.remuneration" (val) {
      if(val && val > 100000){
        this.$buefy.toast.open({
          message: 'El valor del beneficio no puede ser mayor a $100000',
          type: 'is-warning'
        })
        setTimeout(() => {
          this.projectForm.remuneration = null
        }, 1000)
      }
    },
    "projectForm.location.state" (){
      if(this.projectForm.location.state.id == '00' && this.isSpain){
        this.citySelected = this.provincesMunicipalities[0]
      }
    },
    recruiters (val){
      const recruiters = val.filter(user => this.projectForm.additionalRecruiters.includes(user.id))
      this.showRecruiters = recruiters.map(recruiter => recruiter.fullName)
    },
    companyUsers(val){
      const solicitants = val.filter(user => this.projectForm.additionalAuthors.includes(user.id))
      this.showSolicitants = solicitants.map(solicitant => solicitant.fullName)
    },
  },
  methods: {
    async companyData(){
      try{
        const { data } = await this.$api.get(`companies/${this.companyId}`)
        this.companyInfo = data
      }catch(error){
        this.$buefy.toast.open({
          message : 'Información de la empresa no obtenida',
          type : 'is-danger'
        })
      }
    },
    addBenefit(){
      this.projectForm.benefits.push('')
    },
    removeBenefit(index){
      this.projectForm.benefits.splice(index, 1)
    },
    addLenguaje(){
      this.projectForm.otherLanguages.push({
        language: '',
        writing: '',
        listening: '',
        reading: '',
        speaking: '',
        representativeSkill: []
      })
    },
    removeLenguaje(index){
      this.projectForm.otherLanguages.splice(index, 1)
    },
    replaceAccent (term) {
      if (term) {
        const accents = /á|é|í|ó|ú/
        const word = term.toLowerCase()
        return word.replace(accents, (s) => {
          if (s === 'á') return 'a'
          if (s === 'é') return 'e'
          if (s === 'í') return 'i'
          if (s === 'ó') return 'o'
          if (s === 'ú') return 'u'
        })
      }
      return term
    },
    async createProject () {
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.toast.open({
          message: 'Hay campos pendientes por llenar',
          type: 'is-warning'
        })
        return
      }

      const payload = {
        ...this.projectForm,
        location: {
          state: this.projectForm.location.state.name,
          city: this.projectForm.location.city.name
        },
        author: this.projectForm.author._id
      }
      if(payload.projectMondayId === '')
        delete payload.projectMondayId
      
      try{
        await this.$api.post(`/companies/${this.companyId}/projects`, payload)
        this.$buefy.toast.open({
          message: 'Proyecto creado exitosamente',
          type: 'is-success',
          duration: 3000
        })
        this.$emit('projectCreated')
      }catch(error){
        const message = error.response?.data?.message || error.response?.data?.error || error
        this.$buefy.toast.open({
          message: `El proyecto no se creó correctamente: ${message}`,
          type: 'is-danger'
        })
      }
    },
    async updateProject(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.toast.open({
          message: 'Hay campos pendientes por llenar',
          type: 'is-warning'
        })
        return
      }

      const payload = {
        name: this.projectForm.name,
        positions: this.projectForm.positions,
        projectMondayId: this.projectForm.projectMondayId,
        benefits: this.projectForm.benefits,
        duration: String(this.projectForm.duration),
        location: {
          state: this.projectForm.location.state.name,
          city: this.projectForm.location.city.name
        },
        englishLevels: this.projectForm.englishLevels,
        otherLanguages: this.projectForm.otherLanguages,
        excelLevel: this.projectForm.excelLevel,
        remuneration: this.projectForm.remuneration,
        showRemuneration: this.projectForm.showRemuneration,
        isTechnology: this.projectForm.isTechnology,
        objective: this.projectForm.objective,
        author: this.projectForm.author._id,
        headRecruiter: this.projectForm.headRecruiter,
        schemaType: this.projectForm.schemaType,
        serviceType: this.projectForm.serviceType,
        claimType: this.projectForm.claimType,
        additionalRecruiters: this.projectForm.additionalRecruiters,
        additionalAuthors: this.projectForm.additionalAuthors
      }

      if(payload.projectMondayId === '')
        delete payload.projectMondayId

      try {
        await this.$api.put(`/companies/${this.companyId}/projects/update/${this.project.id}`, payload)
        this.$emit('projectUpdated')
        this.$buefy.toast.open({
          message: 'Proyecto actualizado exitosamente',
          type: 'is-success',
          duration: 3000
        })

        document.getElementById('projects-view').scrollIntoView();
      }catch(error){
        const message = error.response?.data?.message || error.response?.data?.error || error
        this.$buefy.toast.open({
          message: `El proyecto no se creó correctamente: ${message}`,
          type: 'is-danger'
        })
      }
    },
    async getCompanyUsers () {
      this.isLoading = true
      try{
        const { data: solicitants } = await this.$api.get(`companies/${this.companyId}/solicitants`)
        const { data: hrManagers } = await this.$api.get(`/companies/${this.companyId}/hr-managers`)

        this.companyUsers = [...solicitants, ...hrManagers]
      }catch(error){
        this.$buefy.toast.open={
          message : 'Información de solicitantes de la empresa no obtenida',
          type : 'is-danger'
        }
      }
      this.isLoading = false
    },
    async getRecommendation(){
      if(this.projectForm.location.state)
      {
        this.loadRecom = !this.loadRecom
        const params = [
          `sectorSelected=${this.companyInfo.sector}`,
          `scholarshipSelected=${ this.projectForm.remuneration ? this.projectForm.remuneration : 0 }`,
          `projectForm.location.state=${this.projectForm.location.state.name}`
        ].join('&')
        try{
          const { data } = await this.$api.get(`/users/recruiters-recommendation?${params}`)
          setTimeout( () => {
            this.recruiters = data
            this.loadRecom = !this.loadRecom
            this.mainRecruiter = false
          }, 1500)
        }
        catch(error){
          this.$buefy.toast.open({
            message: 'Recomendación de especialista no obtenida'
          })
        }
      }
      else{
        this.$buefy.toast.open({
          message: 'Selecciona un estado para obtener una recomendación',
          type: 'is-warning',
          duration: 3000
        })
      }

    },
    async getRecruiters(){
      try{
        const { data } = await this.$api.get(`/users/all-recruiters`)
        this.recruiters = data
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información de los especialistas no obtenida',
          type: 'is-danger'
        })
      }
    },
    addRecruiter(recruiter){
      this.projectForm.additionalRecruiters.push(recruiter.id)
      this.showRecruiters.push(recruiter.fullName)
    },
    removeRecruiter(removed){
      const recruiter = this.recruiters.find(recruiter => recruiter.fullName === removed)
      this.projectForm.additionalRecruiters = this.projectForm.additionalRecruiters.filter(additional => additional !== recruiter.id )
    },
    addSolicitant(solicitant){
      this.projectForm.additionalAuthors.push(solicitant.id)
      this.showSolicitants.push(solicitant.fullName)
    },
    removeSolicitant(removed){
      const solicitant = this.companyUsers.find(user => user.fullName === removed)
      this.projectForm.additionalAuthors =this.projectForm.additionalAuthors.filter(additional => additional !== solicitant.id)
    }
  }
}
</script>
<style lang="scss" scoped>
  .language-table{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
</style>
