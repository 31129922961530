<template>
  <div class="container main-vacancies-container" rounded>
    <ProjectVacancies />
  </div>
</template>

<script>
import ProjectVacancies from '../components/projects/ProjectVacancies.vue'

export default {
  name: 'VacanciesList',
  components: {
    ProjectVacancies
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  async mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.main-vacancies-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1.3rem;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0px 0 1px rgba(10, 10, 10, 0.1);
  border-radius: 20px;
}
</style>