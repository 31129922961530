<template>
  <SimpleCollapse
    title="Registros completos de candidatos"
    description="Registros de candidatos anteriores a la nueva versión de dashboard."
    icon="fa-regular fa-clock-rotate-left"
    :data="stats"
    @open="getData"
    ref="collapse"
  >
    <template #content="{ data }">
      <div class="columns is-multiline legacy-dashboard">
        <div class="column is-12">
          <div class="hero is-info is-bold">
            <div class="hero-body">
              <div class="container">
                <div class="columns">
                  <div class="column">
                    <div class="subtitle">
                      Candidatos:
                    </div>
                    <div class="title is-2">
                      {{ data.candidatesTotal }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="title is-6">
            Candidatos por género:
          </div>
          <div class="box pd-10">
            <ChartPie
              :chartData="data.candidatesByGender.data"
            />
          </div>
        </div>
        <div class="column is-6">
          <div class="title is-6">
            Candidatos por edad:
          </div>
          <div class="box pd-10">
            <ChartPie
              :chartData="data.candidatesByAge.data"
            />
          </div>
        </div>
        <div class="column is-6">
          <div class="title is-6">
            Candidatos por ubicación:
          </div>
          <div class="box pd-10">
            <ChartPie
              :chartData="data.candidatesByLocation.data"
            />
          </div>
        </div>
        <div class="column is-6">
          <div class="title is-6">
            Candidatos por universidad:
          </div>
          <div class="box pd-10">
            <ChartPie :chartData="data.candidatesByUniversity.data" />
          </div>
        </div>
        <div class="column is-6">
          <div class="title is-6">
            Candidatos por estatus:
          </div>
          <div class="box pd-10">
            <ChartPie :chartData="data.candidatesByStatus.data" />
          </div>
        </div>
        <b-loading 
          :is-full-page="false"  
          :active.sync="isLoading" 
        />
      </div>
    </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from "./SimpleCollapse.vue";
import ChartPie from '@/components/charts/ChartPie'

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCollapse,
    ChartPie,
  },
  data() {
    return {
      isLoading: false,
      stats: {
        candidatesTotal: 0,
        candidatesByUniversity: {
          data: null
        },
        candidatesByGender: {
          data: null
        },
        candidatesByAge: {
          data: null
        },
        candidatesByStatus: {
          data: null
        },
        candidatesByLocation: {
          data: null
        },
      },
      candidateStatuses: {
        applying: 'Aplicando',
        selected: 'Seleccionado por Pro Meritum',
        sent: 'En revisión con la empresa',
        rejected: 'Rechazado',
        'pre-hired': 'Seleccionado por empresa',
        hired: 'Contratado'
      },
    }
  },
  computed: {
    user () {
      return this.$store.state.session.user
    },
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = this.transformData(data)
      this.isLoading = false
    },
    async transformData (data) {
      this.stats.candidatesTotal = data.candidates
      this.stats.candidatesByUniversity.data = this.chartTableData(data.applicationsByUniversity, ['Candidatos', 'Cantidad'])
      this.stats.candidatesByGender.data = this.chartTableData(data.applicationsByGender, ['Candidatos', 'Cantidad'])
      this.stats.candidatesByStatus.data = this.chartTableData(data.applicationsByStatus, ['Candidatos', 'Cantidad'], this.candidateStatuses)
      this.stats.candidatesByAge.data = this.chartTableData(data.applicationsByAge, ['Candidatos', 'Cantidad'])
      this.stats.candidatesByLocation.data = this.chartTableData(data.applicationsByLocation, ['Candidatos', 'Cantidad'])
    },
    translateKey (key, list) {
      return list[key]
    },
    chartTableData (data = {}, headers = [], list = null) {
      const keys = Object.keys(data)
      let body = []
      if (keys.length) {
        if (list) {
          body = keys.map(key => [list[key], data[key].length])
        } else {
          body = keys.map(key => [key, data[key].length])
        }
        body.unshift(headers)
        return body
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
.legacy-dashboard{
  width: 100%;
  position: relative;
}
</style>