<template>
  <div class="container">
    <div class="columns is-multiline">
      <BasicLandingPageForm ref="basicLandingPage" v-show="landing.template === 'basic'" :defaultLanding="landing"
        @updateBasicLanding="updateLandingPayload" />
      <CustomLandingPageForm ref="customLandingPage" v-show="landing.template === 'custom'" :defaultLanding="landing"
        @updateCustomUrl="updateCustomUrl" />
      <MultimediaLandingPageForm ref="multimediaLandingPage" v-show="landing.template === 'multimedia'"
        :defaultLanding="landing" @updateMultimediaLanding="updateLandingPayload" :deleteImage="deleteImage"/>

      <div class="column is-4 p-0 m-0">
        <div class="mb-4" id="firstContainer">
          <LandingTypeSelector :defaultType="landing.template" :lastUpdate="landing.updatedAt" :isLoading="isLoading"
            @updateTemplate="updateTemplate" />
        </div>

        <div class="card edition-card" id="secondContainer" v-show="landing.template === 'multimedia'">
          <SectionEditor
            :availableMediaQueries="landing.availableMediaQueries"
            :defaultMediaSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedSection"
            :uploadImage="uploadImage" 
            :deleteImage="deleteImage"
            :riseSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.riseSection"
            :descendSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.descendSection"
            :deleteSections="$refs.multimediaLandingPage?.deleteSections"
            @updateMediaSection="e => $refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.updateMediaSection(e)" 
            class="inner-card"
          />

          <ResourcesEditor
            :defaultMediaSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedSection"
            :defaultMediaSource="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedMediaSource"
            :uploadImage="uploadImage" 
            :deleteImage="deleteImage"
            @updateMediaSection="e => $refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.updateMediaSection(e)"
            class="inner-card"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicLandingPageForm from "../components/landings/BasicLandingPageForm.vue";
import CustomLandingPageForm from "../components/landings/CustomLandingPageForm.vue";
import MultimediaLandingPageForm from "../components/landings/MultimediaLandingPageForm.vue";
import LandingTypeSelector from "../components/landings/LandingTypeSelector.vue";
import SectionEditor from '../components/landings/multimedia/SectionEditor.vue';
import ResourcesEditor from "../components/landings/multimedia/ResourcesEditor.vue";

export default {
  components: {
    BasicLandingPageForm,
    CustomLandingPageForm,
    MultimediaLandingPageForm,
    LandingTypeSelector,
    SectionEditor,
    ResourcesEditor
  },
  data() {
    return {
      isLoading: false,
      landing: {
        template: "basic",
        customUrl: "",
        sections: [],
        availableMediaQueries: [],
        mediaSections: [],
        colors: { title: "#42CBB6", benefits: "#42CBB6" }
      },
    };
  },
  mounted() {
    this.getLanding();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    urlLanding() {
      return `${process.env.VUE_APP_PUBLIC_URL}/company/${this.companyId}`;
    },
  },
  methods: {
    async updateTemplate(template) {
      this.landing.template = template;
      await this.saveChanges();
    },
    async updateLandingPayload(landing) {
      this.landing = { ...this.landing, ...landing };
      await this.saveChanges();
    },
    async updateCustomUrl(customUrl) {
      this.landing.customUrl = customUrl;
      await this.saveChanges();
    },
    async getLanding() {
      this.isLoading = true;
      try {
        const response = await this.$api.get(`/companies/${this.companyId}/landings`);
        this.landing = response.data;
      } catch (error) {
        const message = error.response?.data?.message || error.response || error;
        this.$buefy.toast.open({
          duration: 3000,
          message,
          type: "is-warning",
        });

        if (error.response?.status === 404) { // creating landing if not exists
          await this.createLanding();
        }
      }
      this.isLoading = false;
    },
    async saveChanges() {
      const landingRefs = {
        basic: this.$refs.basicLandingPage,
        custom: this.$refs.customLandingPage,
        multimedia: this.$refs.multimediaLandingPage,
      };

      const landing = landingRefs[this.landing.template].landing;

      if (landing.id) await this.updateLanding();
      else await this.createLanding();
    },
    async updateLanding() {
      this.isLoading = true;
      try {
        await this.$api.put(`/companies/${this.companyId}/landings`, { ...this.landing });
        this.getLanding();
        this.$buefy.toast.open({
          message: "Los cambios se guardaron correctamente",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
          duration: 5000,
        });
      }
      this.isLoading = false;
    },
    async createLanding() {
      this.isLoading = true;
      try {
        await this.$api.post(`/companies/${this.companyId}/landings`, { ...this.landing });
        this.getLanding();
        this.$buefy.toast.open({
          message: "Los cambios se guardaron correctamente",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    async uploadImage(file, fileIdentifier, fileMetadata) {
      if (!file) return;

      const payload = new FormData();
      payload.append("file", file);
      payload.append("fileIdentifier", fileIdentifier);
      payload.append("fileMetadata", JSON.stringify(fileMetadata ?? {}));

      try {
        const { data } = await this.$api.put(`/companies/${this.companyId}/landings/image`, payload);
        return data;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
          duration: 5000
        });
        throw error
      }
    },
    async deleteImage(fileKey) {
      try {
        const payload = {
          fileKey
        };
        const { data } = await this.$api.post(`/companies/${this.companyId}/landings/delete-image`, payload);

        return data;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
          duration: 5000
        });
        throw error
      }
    },
    handleScroll() {
      const firstContainer = document.getElementById('firstContainer');
      const secondContainer = document.getElementById('secondContainer');

      if (firstContainer && secondContainer) {
        const rect = firstContainer.getBoundingClientRect();
        if (rect.bottom <= 60) {
          secondContainer.classList.add('sticky');
        } else {
          secondContainer.classList.remove('sticky');
        }
      }
    },
  },


};
</script>


<style>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.edition-card {
  width: 95%;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>
