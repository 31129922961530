export default {
    validation: {
        confirmed: "Se requiere confirmación de la contraseña",
        required: "Este campo es requerido",
        requiredArray: "Debes seleccionar al menos una opción",
        email: "Introduce un email válido",
        numeric: "El valor debe ser numérico",
        digits: "Introduce {length} dígitos",
        min_value: "El valor debe ser mayor que {min}",
        max: "El número de caracteres debe ser menor a {length}",
        min: "El número de caracteres debe ser mayor a {length}",
        max_value: "El valor debe ser menor que {max}",
        length: "La longitud del valor debe ser igual a {length}",
        image: "El tipo de archivo {name} no es una imagen",
        hour: "Introduce la hora en formato de 24hrs",
        fileSize: "El archivo debe pesar máximo {size} MB"
    }
};
