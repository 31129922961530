<template>
  <div class="column is-8">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title is-size-4 has-text-weight-semibold">
          <div>
            Landing Page básica
          </div>
        </div>
      </div>
    </div>

    <CustomUrlLandingPage
      :defaultCustomUrl="landing.customUrl"
      @updateLandingProperty="updateLandingProperty"
    />
    <CompanyUrlLandingPage
      :defaultUrl="landing.url"
      @updateLandingProperty="updateLandingProperty"
    />
    
    <div class="columns is-multiline sort-container" >
      <div v-for="(section, index) in landing.sections" :key="`section-${section.name}`" :class="[
        'column is-12',
        (index > 0) ? 'sort-element' : null
/*      (index > 0) ? '' : null*/
      ]">
        <HeaderLandingPage
          v-if="section.name === 'header'"
          :defaultSection="section"
          :uploadImage="uploadImage"
          :deleteImage="deleteImage"
          @updateSection="updateSection"
        />
        <VacantesLandingPage
          v-else-if="section.name === 'vacancies'"
        />
        <RecruitmentLandingPage
          v-else-if="section.name === 'recruitment'"
          :defaultSection="section"
          @updateSection="updateSection"
        />
        <BenefitsLandingPage
          v-else-if="section.name === 'benefits'"
          :defaultSection="section"
          :uploadImage="uploadImage"
          :deleteImage="deleteImage"
          @updateSection="updateSection"
        />
        <PrescenceLandingPage
          :sectionDisabled = true
          v-else-if="section.name === 'prescence'"
          :defaultSection="section"
          @updateSection="updateSection"
        />
        <AttributesLandingPage
          :sectionDisabled = true
          v-else-if="section.name === 'attributes'"
          :defaultSection="section"
          @updateSection="updateSection"
        />
        <DevAreasLandingPage
          :sectionDisabled = true
          v-else-if="section.name === 'devAreas'"
          :defaultSection="section"
          :uploadImage="uploadImage"
          :deleteImage="deleteImage"
          @updateSection="updateSection"
        />
        <AnnouncementsLandingPage
          :sectionDisabled = true
          v-else-if="section.name === 'announcements'"
          :defaultSection="section"
          :uploadImage="uploadImage"
          :deleteImage="deleteImage"
          @updateSection="updateSection"
        />
        <FaqsLandingPage
          :sectionDisabled = true
          v-else-if="section.name === 'faqs'"
          :defaultSection="section"
          @updateSection="updateSection"
        />
      </div>
    </div>
    <ColorsLandingPage
      :defaultColors="landing.colors"
      @updateLandingProperty="updateLandingProperty"
      :sectionDisabled = true
    />
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import ColorsLandingPage from './basic/ColorsLandingPage.vue'
import CompanyUrlLandingPage from './basic/CompanyUrlLandingPage.vue'
import HeaderLandingPage from './basic/HeaderLandingPage.vue'
import PrescenceLandingPage from './basic/PrescenceLandingPage.vue'
import AttributesLandingPage from './basic/AttributesLandingPage.vue'
import BenefitsLandingPage from './basic/BenefitsLandingPage.vue'
import DevAreasLandingPage from './basic/DevAreasLandingPage.vue'
import RecruitmentLandingPage from './basic/RecruitmentLandingPage.vue'
import VacantesLandingPage from './basic/VacantesLandingPage.vue'
import AnnouncementsLandingPage from './basic/AnnouncementsLandingPage.vue'
import FaqsLandingPage from './basic/FaqsLandingPage.vue'
import CustomUrlLandingPage from './CustomUrlLandingPage.vue'

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onEnd: function (evt) {
      const data = vnode.context.landing.sections
      const item = data[evt.oldIndex]
      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1]
        }
      }
      if (evt.newIndex < evt.oldIndex) {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1]
        }
      }
      data[evt.newIndex] = item
      data.forEach((section, index) => {
        section.position = index
      })
    }
  })
}

const sortable = {
  name: 'sortable',
  bind(el, binding, vnode) {
    const container = el
    container._sortable = createSortable(container, binding.value, vnode)
  },
  update(el, binding, vnode) {
    const container = el
    container._sortable.destroy()
    container._sortable = createSortable(container, binding.value, vnode)
  },
  unbind(el) {
    const container = el
    container._sortable.destroy()
  }
}

export default {
  directives: { sortable },
  components: {
    CustomUrlLandingPage,
    CompanyUrlLandingPage,
    ColorsLandingPage,
    HeaderLandingPage,
    PrescenceLandingPage,
    AttributesLandingPage,
    BenefitsLandingPage,
    DevAreasLandingPage,
    RecruitmentLandingPage,
    VacantesLandingPage,
    AnnouncementsLandingPage,
    FaqsLandingPage,
  },
  props: {
    defaultLanding: {
      template: String,
      sections: Array,
      colors: Object,
      url: String,
      customUrl: String
    },
  },
  data() {
    return {
      isLoading: false,
      sortableOptions: {
        chosenClass: 'is-primary',
        draggable: '.sort-element',
        animation: 150,
        ghostClass: 'has-background-primary',
        sort: true
      },
      landing: {
        template: "basic",
        sections: [
          {
            name: "header",
            position: 0,
            header: {
              title: "",
              subtitle: "",
              description: "",
              file: null,
              image: {
                tag: "",
                url: "",
              },
            },
          },
          {
            name: "vacancies",
            position: 1,
            vacancies: {}
          },
          {
            name: "benefits",
            position: 2,
            benefits: {
              description: "",
              benefits: [],
              active: false,
            },
          },
          {
            name: "recruitment",
            recruitment: {
              recruitment: [],
              steps: [],
            },
            position: 3,
          },
          {
            name: "prescence",
            position: 4,
            prescence: {
              stats: [],
              description: "",
              active: false,
            },
          },
          {
            name: "attributes",
            position: 5,
            attributes: {
              attributes: [],
              active: false,
            },
          },
          {
            name: "devAreas",
            position: 6,
            devAreas: {
              description: "",
              devAreas: [],
              active: false,
            },
          }, 
          {
            name: "announcements",
            position: 7,
            announcements: {
              active: false,
              title: "",
              subtitle: "",
              description: "",
              file: null,
              image: {
                tag: "",
                url: "",
              },
            },
          },
          {
            name: "faqs",
            position: 8,
            faqs: {
              active: false,
              questions: [],
              file: null,
              image: {
                tag: "",
                url: "",
              },
            },
          },
        ],
        colors: {
          title: "#42CBB6",
          benefits: "#42CBB6"
        },
        url: "",
        customUrl: "",
      },
    }
  },
  watch: {
    defaultLanding: {
      handler: function () {
        const auxLanding = { ...this.landing }
        this.landing = { ...this.landing, ...this.defaultLanding }

        // Adding sections that are not present in the default landing
        auxLanding.sections.forEach(section => {
          if (!this.landing.sections.find(s => s.name === section.name)) {
            this.landing.sections.push(section)
          }
        })
      }
    },
    landing: {
      handler: function () {
        if(this.landing.sections?.length > 0)
          this.sortSections()
      }
    },
  },
  mounted() {
    const auxLanding = { ...this.landing }
    this.landing = { ...this.landing, ...this.defaultLanding }

    // Adding sections that are not present in the default landing
    auxLanding.sections.forEach(section => {
      if (!this.landing.sections.find(s => s.name === section.name)) {
        this.landing.sections.push(section)
      }
    })
  },
  computed: {
    companyId() {
      return this.$route.params.id
    }
  },
  methods: {
    updateBasicLanding() {
      this.$emit('updateBasicLanding', this.landing)
    },
    updateSection(section) {
      const index = this.landing.sections.findIndex(s => s.name === section.name)
      this.landing.sections[index] = section
      this.updateBasicLanding()
    },
    updateLandingProperty(propertyValue, propertyName) {
      this.landing[propertyName] = propertyValue
      this.updateBasicLanding()
    },
    async uploadImage(file, fileIdentifier, fileMetadata) {
      if (!file) return;

      const payload = new FormData();
      payload.append("file", file);
      payload.append("fileIdentifier", fileIdentifier);
      payload.append("fileMetadata", JSON.stringify(fileMetadata ?? {}));

      try {
        const { data } = await this.$api.put(`/companies/${this.companyId}/landings/image`, payload);
        return data;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
        });
      }
    },
    async deleteImage(fileKey) {
      try {
        const payload = {
          fileKey
        };
        const { data } = await this.$api.post(`/companies/${this.companyId}/landings/delete-image`, payload);

        return data;
      } catch (error) {
        const message = error.response.data?.message || error;
        this.$buefy.toast.open({
          message: message,
          type: "is-danger",
        });
      }
    },
    sortSections() {
      this.landing.sections.sort((a, b) => {
        if (a.position > b.position) return 1
        if (a.position < b.position) return -1
        return 0
      })
    },
  }
}
</script>

<style>
.sort-element {
  cursor: grab !important;
}

.disabled-input .input:disabled, 
.disabled-input .textarea:disabled {
    background-color: #ababab;
    color: rgb(243, 243, 243);
    margin-bottom: 0;
}

.disabled-input .input:disabled::placeholder,
.disabled-input .textarea:disabled::placeholder {
  color: rgb(209, 209, 209);
}

</style>
