<template>
  <SimpleCollapse
    title="Perfiles de contratados"
    :isLoading="isLoading"
    description="Información general de los perfiles que son contratados en las vacantes de la empresa."
    icon="fa-regular fa-handshake"
    :data="data"
    @open="getData"
    ref="collapse"
  >
  <template #content="{ data }">
    <PieTable
      :pieTableData="data.byCareer"
      title="Carreras"
      :headers="['Carrera', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byUniversity"
      title="Universidad"
      :headers="['Universidad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byGraduateDate"
      title="Término de materias"
      :headers="['Fecha de término de materia', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byDegreeDate"
      title="Titulación"
      :headers="['Fecha de titulación', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byLocation"
      title="Ubicación"
      :headers="['Ciudad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.bySkills"
      title="Skills"
      :headers="['Skill', 'Cantidad']"
      chartHeight="250px"
    />

  </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from './SimpleCollapse.vue';
import PieTable from './PieTable.vue';

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCollapse,
    PieTable
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        byCareer: {},
        byUniversity: {},
        byGraduateDate: {},
        byDegreeDate: {},
        byLocation: {},
        bySkills: {},
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.separation-line{
  margin: 1.5rem 0;
  height: 2px;
}
</style>
