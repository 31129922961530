<template >
  <b-modal 
  :active.sync="isOpen" 
  trap-focus 
  has-modal-card
  :can-cancel="false" 
  :destroy-on-hideCompanies="false" 
  aria-role="dialog"
  aria-modal width="80vw"
  >
  <div
		class="modal-card"
		style="width: 50rem; margin-left: -5rem"
	>
    <div class="modal-card-head has-text-weight-medium">
      Compartir evaluación personalizada
    </div>
    <div class="modal-card-body">
      ¿Deseas compartir la evaluación personalizada de el(los) candidato(s) de esta vacante a la vacante donde se está invitando en caso de ser aceptada? 
    </div>
    <div class="modal-card-foot justify-flex-end">
      <b-button
        rounded
        @click="invitingWithCustomEvaluation"
			>
        Compartir evaluación personalizada
      </b-button>
      <b-button
        rounded
        @click="invitingWithoutCustomEvaluation"
        type="is-warning"
			>
        Continuar sin compartir evaluación personalizada
      </b-button>
    </div>
  </div>
  </b-modal>
</template>

<script>

export default {
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    invitingWithCustomEvaluation() {
      this.isOpen = false
      this.$emit('inviteCandidate', true)
    },
    invitingWithoutCustomEvaluation() {
      this.isOpen = false
      this.$emit('inviteCandidate', false)
    }
  }
}
</script>
<style  lang="scss"  scoped></style>
