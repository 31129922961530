<template>
  <div v-if="true" class="abecedary has-background-light scrolleable">
    <p v-for="(letter, index) in abecedary"
      :key="`letter-${index}`"
      :class="isDisabled(letter) ? 'disabledLetter' : 'letter'"
      @click="filterCompanies(letter)"
    >
      {{ letter }}
    </p>
    <b-icon icon="rotate-right" class="letter" @click.native="resetCompanies" />
  </div>
</template>

<script>
export default {
  props: {
    saveCompanies: {
      default: null,
      required: true
    }
  },
  methods: {
    filterCompanies(letter) {
      if (this.isDisabled(letter)) return

      this.$emit('letterSelected', letter)
    },
    resetCompanies(){
      this.$emit('lettersReseted')
    },
    isDisabled(letter){
      return !this.saveCompanies.some( company => company.name.charAt(0) === letter)
    }
  },
  computed: {
    abecedary() {
      return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
        'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',]
    }
  }
}
</script>

<style lang="scss" scoped>
.abecedary {
  padding: 0.5rem 0;
  min-width: 4rem;
  width: 5rem;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
  text-align: center;
}

.letter {
  margin-top: 0.3rem;
  font-size: 1.1rem;
  transition: transform 200ms;
  color: #618093;
}

.disabledLetter{
  margin-top: 0.3rem;
  font-size: 1.1rem;
  transition: transform 200ms;
  color: #61809368;
}

.letter:hover {
  transform: scale(1.5);
  cursor: pointer;
  font-weight: bold;
}

.disabledLetter {
  pointer-events: none;
}
</style>
