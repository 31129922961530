<template>
  <div class="container pl-10 pr-10">
    <div class="card hideable-container pl-5" style="border-radius: 20px;">

      <div :class="`${project?.id ? 'project-info' : 'project-create'}`"
        v-if="showProjectInfo"
      >
        <div class="py-3 columns is-multiline">
            <!-- Botones -->
            <div class="column is-full is-flex justify-space-between">
              <p  class="title is-3">
                Proyecto
              </p>

              <div class="is-flex">
                <b-button type="is-primary" outlined
                  v-if="projectId && $hasPermissionsByRole(projectEditionAllowedRoles)"
                  @click="editingMode = !editingMode"
                  :icon-left="`${editingMode ? 'arrow-left' : 'pen-to-square'}`"
                />
              </div>
            </div>

            <!-- Formulario del proyecto -->
            <ProjectEdit
              ref="projectEdit"
              :project="project"
              @projectCreated="$emit('projectCreated')"
              @projectUpdated="getProject"
              v-if="editingMode && $hasPermissionsByRole(projectEditionAllowedRoles)"
            />

            <!-- Descripción del proyecto -->
            <div class="columns is-multiline px-3 pb-3"
              v-else
            >
              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Nombre del proyecto
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey is-normal is-wrap" >
                  {{ project.name || 'Por definir' }}
                </p>
              </div>

              <div class="column is-full">
                <span class="is-size-6 has-text-weight-bold">
                  Objetivo
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey is-normal is-wrap">
                  {{ project.objective}}
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Duración del proyecto
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.duration}} meses
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Cantidad de posiciones
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.positions}} posicione(s)
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Remuneración
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ projectTranslation.currencySign }} {{ project.remuneration}}
                </p>
              </div>

              <div class="column is-6 is-flex">
                <span class="is-size-6 has-text-weight-bold">
                  ¿Mostrar remuneración?
                </span>
                <p class="ml-3">
                  <f-icon
                    :class="project.showRemuneration ? ' has-text-success' : ' has-text-danger' "
                    :icon="project.showRemuneration ? 'fa-regular fa-circle-check': 'fa-regular fa-circle-xmark' "
                    size="xl"/>
                </p>
              </div>

              <div class="column is-6 is-flex">
                <span class="is-size-6 has-text-weight-bold">
                  ¿Es de tecnología?
                </span>
                <p class="ml-3">
                  <f-icon
                  :class="project.isTechnology ? ' has-text-success' : ' has-text-danger' "
                  :icon="project.isTechnology ? 'fa-regular fa-circle-check': 'fa-regular fa-circle-xmark' "
                  size="xl"/>
                </p>
              </div>

              <div class="column is-6"></div>

              <div class="column is-12">
                <span class="is-size-6 has-text-weight-bold">
                  Beneficios
                </span>
                <ul style="list-style-type: disc; margin-left: 2rem;">
                  <li class="has-text-weight-medium has-text-grey is-size-6"
                    v-for="(benefit, index) in project.benefits"
                    :key="`benefit-${index}`">
                    {{ benefit }}
                  </li>
                </ul>
              </div>

              
              <div class="column is-full">
                <hr style="margin:0;">
                <p class="title is-5 mt-3">
                  Ubicación del proyecto
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  {{ projectTranslation.state }}
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.location?.state?.name || 'Sin definir' }}
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  {{ projectTranslation.city }}
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.location?.city?.name ||  'Sin definir' }}
                </p>
              </div>

              <div class="column is-full">
                <hr style="margin:0;">
                <p class="title is-5 mt-3">
                  Reclutamiento
                </p>
              </div>

              <div class="column is-12">
                <span class="is-size-6 has-text-weight-bold">
                  Reclutador principal
                </span>
                <p class="has-text-weight-medium has-text-grey is-size-6" >
                  {{ project.recruiter }}
                </p>
              </div>

              <div class="column is-12">
                <span class="is-size-6 has-text-weight-bold">
                  Reclutadores adicionales
                </span>
                <p class="has-text-weight-medium has-text-grey is-size-6" 
                  v-for="(recruiter, index) in project.additionalRecruiters"
                  :key="`recruiter-${index}`"
                >
                  {{ getRecruiterName(recruiter) }}
                </p>
              </div>

              <div class="column is-12">
                <span class="is-size-6 has-text-weight-bold">
                  Contacto de la empresa
                </span>
                <p class="has-text-weight-medium is-size-6 has-text-grey" >
                  {{ project.author.fullName }}
                </p>
              </div>

              <div class="column is-12">
                <span class="is-size-6 has-text-weight-bold">
                  Contacto adicionales de la empresa
                </span>
                <p class="has-text-weight-medium has-text-grey is-size-6" 
                  v-for="(user, index) in project.additionalAuthors"
                  :key="`user-${index}`"
                >
                  {{ getAuthorName(user) }}
                </p>
              </div>

              <div class="column is-6"
                v-if="isMexico"
                >
                <span class="is-size-6 has-text-weight-bold">
                  Id de Requisición Monday
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.projectMondayId || "Por definir" }}
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Esquema de requisición
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.schemaType||'Por definir'}}
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Tipo de servicio
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                    {{ project.serviceType||'Por definir'}}
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Tipo de reclamación
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                    {{ project.claimType||'Por definir'}}
                </p>
              </div>

              <div class="column is-full">
                <hr style="margin:0;">
                <p class="title is-5 mt-3">
                  Conocimientos del proyecto
                </p>
              </div>

              <div class="column is-6">
                <span class="is-size-6 has-text-weight-bold">
                  Nivel de Excel
                </span>
                <p class="is-size-6 has-text-weight-medium has-text-grey">
                  {{ project.excelLevel }} 
                </p>
              </div>

              <div class="column is-full">
                <hr style="margin:0;">
                <p class="title is-5 mt-3">
                  Idiomas del proyecto
                </p>
              </div>

              <div class="column is-full">
                <div class="language-table">
                  <p class="is-size-5 has-text-left has-text-weight-semibold">
                    {{ project.englishLevels.language }}
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Deseable
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Básico
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Intermedio
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Avanzado
                  </p>
                  
                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.englishLevels, 'writing') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Escritura
                  </p>
                  <b-radio native-value="Deseable" name="englishWriting" disabled
                    v-model="project.englishLevels.writing"
                  ></b-radio>
                  <b-radio native-value="Básico" name="englishWriting" disabled
                    v-model="project.englishLevels.writing"
                  ></b-radio>
                  <b-radio native-value="Intermedio"  name="englishWriting" disabled
                    v-model="project.englishLevels.writing"
                  ></b-radio>
                  <b-radio native-value="Avanzado"  name="englishWriting" disabled
                    v-model="project.englishLevels.writing"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.englishLevels, 'reading') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Lectura
                  </p>
                  <b-radio native-value="Deseable" name="englishReading" disabled
                    v-model="project.englishLevels.reading"
                  ></b-radio>
                  <b-radio native-value="Básico" name="englishReading" disabled
                    v-model="project.englishLevels.reading"
                  ></b-radio>
                  <b-radio native-value="Intermedio" name="englishReading" disabled
                    v-model="project.englishLevels.reading"
                  ></b-radio>
                  <b-radio native-value="Avanzado" name="englishReading" disabled
                    v-model="project.englishLevels.reading"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.englishLevels, 'listening') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Escucha
                  </p>
                  <b-radio native-value="Deseable" name="englishListening" disabled
                    v-model="project.englishLevels.listening"
                  ></b-radio>
                  <b-radio native-value="Básico" name="englishListening" disabled
                    v-model="project.englishLevels.listening"
                  ></b-radio>
                  <b-radio native-value="Intermedio" name="englishListening" disabled
                    v-model="project.englishLevels.listening"
                  ></b-radio>
                  <b-radio native-value="Avanzado" name="englishListening" disabled
                    v-model="project.englishLevels.listening"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.englishLevels, 'speaking') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Conversación
                  </p>
                  <b-radio native-value="Deseable" name="englishSpeaking" disabled
                    v-model="project.englishLevels.speaking"
                  ></b-radio>
                  <b-radio native-value="Básico" name="englishSpeaking" disabled
                    v-model="project.englishLevels.speaking"
                  ></b-radio>
                  <b-radio native-value="Intermedio" name="englishSpeaking" disabled
                    v-model="project.englishLevels.speaking"
                  ></b-radio>
                  <b-radio native-value="Avanzado" name="englishSpeaking" class="mr-2" disabled
                    v-model="project.englishLevels.speaking"
                  ></b-radio>
                </div>
              </div>

              <div class="column is-full"
                v-for="(language, index) in project.otherLanguages"
                :key="`language-${index}`">

                <hr style="margin:0; margin-bottom: 1rem;">
                <div class="language-table">
                  <p class="is-size-5 has-text-left has-text-weight-semibold">
                    {{ project.otherLanguages[index].language }}
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Deseable
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Básico
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Intermedio
                  </p>
                  <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
                    Avanzado
                  </p>
                  
                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.otherLanguages[index], 'writing') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Escritura
                  </p>
                  <b-radio native-value="Deseable" :name="`language${index}Writing`" disabled
                    v-model="project.otherLanguages[index].writing"
                  ></b-radio>
                  <b-radio native-value="Básico" :name="`language${index}Writing`" disabled
                    v-model="project.otherLanguages[index].writing"
                  ></b-radio>
                  <b-radio native-value="Intermedio"  :name="`language${index}Writing`" disabled
                    v-model="project.otherLanguages[index].writing"
                  ></b-radio>
                  <b-radio native-value="Avanzado" :name="`language${index}Writing`" disabled
                    v-model="project.otherLanguages[index].writing"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.otherLanguages[index], 'reading') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Lectura
                  </p>
                  <b-radio native-value="Deseable" :name="`language${index}Reading`" disabled
                    v-model="project.otherLanguages[index].reading"
                  ></b-radio>
                  <b-radio native-value="Básico" :name="`language${index}Reading`" disabled
                    v-model="project.otherLanguages[index].reading"
                  ></b-radio>
                  <b-radio native-value="Intermedio" :name="`language${index}Reading`" disabled
                    v-model="project.otherLanguages[index].reading"
                  ></b-radio>
                  <b-radio native-value="Avanzado" :name="`language${index}Reading`" disabled
                    v-model="project.otherLanguages[index].reading"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.otherLanguages[index], 'listening') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Escucha
                  </p>
                  <b-radio native-value="Deseable" :name="`language${index}Listening`" disabled
                    v-model="project.otherLanguages[index].listening"
                  ></b-radio>
                  <b-radio native-value="Básico" :name="`language${index}Listening`" disabled
                    v-model="project.otherLanguages[index].listening"
                  ></b-radio>
                  <b-radio native-value="Intermedio" :name="`language${index}Listening`" disabled
                    v-model="project.otherLanguages[index].listening"
                  ></b-radio>
                  <b-radio native-value="Avanzado" :name="`language${index}Listening`" disabled
                    v-model="project.otherLanguages[index].listening"
                  ></b-radio>

                  <p :class="`is-size-6 has-text-left has-text-primary ${isRepresentativeLanguageSkill(project.otherLanguages[index], 'speaking') ? 'has-text-weight-bold' : ' has-text-weight-normal'}`"
                  >
                    Conversación
                  </p>
                  <b-radio native-value="Deseable" :name="`language${index}Speaking`"  disabled
                    v-model="project.otherLanguages[index].speaking"
                  ></b-radio>
                  <b-radio native-value="Básico" :name="`language${index}Speaking`"  disabled
                    v-model="project.otherLanguages[index].speaking"
                  ></b-radio>
                  <b-radio native-value="Intermedio" :name="`language${index}Speaking`" disabled
                    v-model="project.otherLanguages[index].speaking"
                  ></b-radio>
                  <b-radio native-value="Avanzado" :name="`language${index}Speaking`" class="mr-2" disabled
                    v-model="project.otherLanguages[index].speaking"
                  ></b-radio>
                </div>
              </div>
            </div>

            <!-- Botones -->
            <div class="column is-full is-flex justify-flex-end mt-3">
              <b-button 
                v-if="$hasPermissionsByRole(projectEditionAllowedRoles) && editingMode"
                @click="saveChanges"
                icon-left="floppy-disk"
                class="ml-2"
                type="is-primary"
              >
              {{ project.id ? 'Guardar cambios' : 'Crear proyecto' }}
              </b-button>
            </div>
        </div>
      </div>

      <div v-if="project?.id" class="vacancies-section">
        <!-- Botón de mostrar u ocultar empresas -->
        <b-icon size="is-medium"
          :icon="showProjectInfo ? 'angle-left' : 'angle-right' "
          class="has-text-blue hideButton "
          @click.native="changeShowProjectInfo"
        />
        
        <ProjectVacancies
          :project="project"
        />
      </div>
    </div>
    
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import states from '@/lib/states'
import cities from '@/lib/cities'
import provinces from '@/lib/ES/provinces'
import municipalities  from '@/lib/ES/municipalities'
import { mapGetters } from 'vuex'
import ProjectVacancies from './ProjectVacancies.vue'
import ProjectEdit from './ProjectEdit.vue'
import { projectEditionAllowedRoles } from '@/lib/permissions'

export default {
  props:{
    projectId:{
      type: String,
      required: false
    },
  },
  components: {
    ProjectVacancies,
    ProjectEdit
  },
  data(){
    return{
      states,
      cities,
      provinces,
      municipalities,
      project: {
        name: null,
        isTechnology: false,
        positions: null,
        projectMondayId: '',
        benefits: [''],
        duration: '',
        location: {
          state: {},
          city: {},
        },
        englishLevels: {
          language: 'Inglés',
          listening: '',
          reading: '',
          speaking: '',
          writing: '',
          representativeSkill: []
        },
        excelLevel: '',
        otherLanguages: [],
        objective: '',
        remuneration: null,
        showRemuneration:  false,
        headRecruiter: null,
        author: {
          _id: null,
          fullName: ''
        },
        schemaType: '',
        serviceType: null,
        claimType: null
      },
      projectEditionAllowedRoles,
      editingMode: false,
      showProjectInfo: true,
      isLoading: false,
      additionalRecruiters: [],
      additionalAuthors: [],
      companyUsers: [],
      recruiters: []
    }
  },
  async mounted(){
    this.isLoading = true

    if(this.projectId) 
    {
      await this.getCompanyUsers()
      await this.getRecruiters()
      await this.getProject()
    }
    else
      this.editingMode = true

    this.isLoading = false
  },
  computed: {
    ...mapGetters(['companyId']),
    id () {
      return this.$route.params.id
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION === 'es'
    },
    projectTranslation(){
      return this.$t('screens.projects.detail')
    },
  },
  watch:{
  },
  methods: {
    async getProject(){
      try{
        const { data } = await this.$api.get(`companies/${this.companyId}/projects/detail/${this.projectId}`)
        this.project = data
        this.project.id = data._id

        const statesToSearch = this.isMexico ? this.states : this.provinces
        const citiesToSearch = this.isMexico ? this.cities : this.municipalities

        const state = statesToSearch.find(state => state.name === data.location.state)
        const city =  citiesToSearch.find(city => city.name === data.location.city && city.state_id === state.id)

        this.project.location = {
          state,
          city
        }

        this.project.additionalRecruiters = data.additionalRecruiters
        this.project.additionalAuthors = data.additionalAuthors

        this.project.author = await this.getAuthor(data.author)
        this.project.recruiter = await this.getRecruiter(data.headRecruiter)
        this.project.requisitionDate = data.createdAt
        this.$store.dispatch('getProjectName', data.name)
        this.editingMode = false
        this.showProjectInfo = false
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información del proyecto no obtenida',
          type:'is-danger'
        })
      }
    },
    async getAuthor(authorId){
      try{
        const { data } = await this.$api.get(`/users/${authorId}`)
        return data
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información del contacto de la empresa no obtenida',
          type: 'is-danger'
        })
      }
    },
    async getRecruiter(recruiterId){
      try{
        const { data } = await this.$api.get(`/users/detail/${recruiterId}`)
        return data.fullName ? data.fullName : null
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información del especialista asignado no obtenida',
          type: 'is-danger'
        })
      }
    },
    async getCompanyUsers () {
      this.isLoading = true
      try{
        const { data: solicitants } = await this.$api.get(`companies/${this.companyId}/solicitants`)
        const { data: hrManagers } = await this.$api.get(`/companies/${this.companyId}/hr-managers`)

        this.companyUsers = [...solicitants, ...hrManagers]
      }catch(error){
        this.$buefy.toast.open={
          message : 'Información de solicitantes de la empresa no obtenida',
          type : 'is-danger'
        }
      }
      this.isLoading = false
    },
    async getRecruiters(){
      try{
        const { data } = await this.$api.get(`/users/all-recruiters`)
        this.recruiters = data
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información de los especialistas no obtenida',
          type: 'is-danger'
        })
      }
    },
    async saveChanges(){
      if(this.project.id)
        this.$refs.projectEdit.updateProject()
      else
        this.$refs.projectEdit.createProject()
    },
    isRepresentativeLanguageSkill(language, skill){
      return language.representativeSkill?.includes(skill)
    },
    changeShowProjectInfo(){
      this.showProjectInfo = !this.showProjectInfo
    },
    getAuthorName(id){
      const author = this.companyUsers.find( user => user.id === id)
      return author?.fullName ? author.fullName : 'Sin Asignar'
    },
    getRecruiterName(id){
      const recruiter = this.recruiters.find( user => user.id === id)
      return recruiter?.fullName ? recruiter.fullName : 'Sin Asignar'
    }
  }
}
</script>
<style lang="scss" scoped>
  .language-table{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
  .hideable-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;

    .vacancies-section{
      display: flex;
      flex-grow: 1;
    }
  }
  .project-info{
    max-width: 45%;
  }
  .project-create{
    max-width: 100%;
    padding: 1rem 2rem;
  }
  .hideButton{
    margin-left: 0.5rem;
    cursor: pointer;
    color: #4a4a4a;
  }
</style>
