<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :can-cancel="false">
      <div class="modal-card">
        <div class="modal-card-head">
        </div>
        <div class="modal-card-body">
          <div class="subtitle">
            Renovaciones de convenio
          </div>
          <div class="pa-10">
            <div class="mb-20">
              <b-button
                @click="create">
                Agregar renovación de convenio
              </b-button>
            </div>
            <div
              v-for="(agreement, index) in agreements"
              :key="`agreement-${index}`"
              class="card mb-20">
              <div class="card-header justify-space-between">
                <div class="card-header-title">
                  <div class="is-flex justify-space-between">
                    <div>
                      Convenio creado {{ agreement.createdAt | formatDate }}
                    </div>
                  </div>
                </div>
                <div class="pd-20">
                  <b-button
                    type="is-danger"
                    class="ml-10"
                    @click="openRemoveDialog(agreement._id)">
                    <b-icon
                      icon="trash"
                    />
                  </b-button>
                </div>
              </div>
              <div class="card-content">
                <div>
                  <div class="label">
                    Convenio firmado:
                  </div>
                  <div class="is-flex justify-space-between align-center">
                    <div>
                      <a
                        v-if="agreement.agreementSignedDocument"
                        :href="(agreement.agreementSignedDocument || {}).url"
                        target="_blank"
                        rel="noopener noreferrer">
                        Ver documento
                      </a>
                      <div v-else>
                        Sin convenio firmado
                      </div>
                    </div>
                    <b-switch
                      v-model="(agreement.agreementSignedDocument || {}).valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'agreementSignedDocument', agreement._id)">
                      Documento verificado
                    </b-switch>
                  </div>
                </div>
                <hr>
                <div>
                  <div class="label">
                    Seguro:
                  </div>
                  <div class="is-flex justify-space-between align-center">
                    <div>
                      <a
                        v-if="agreement.secureDocument"
                        :href="(agreement.secureDocument || {}).url"
                        target="_blank"
                        rel="noopener noreferrer">
                        Ver documento
                      </a>
                      <div v-else>
                        Sin documento de seguro
                      </div>
                    </div>
                    <b-switch
                      v-model="(agreement.secureDocument || {}).valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'secureDocument', agreement._id)">
                      Documento verificado
                    </b-switch>
                  </div>
                </div>
                <hr>
                <div>
                  <div class="label">
                    Seguro:
                  </div>
                  <div class="is-flex justify-space-between align-center">
                    <div>
                      <a
                        v-if="agreement.certificationsDocument"
                        :href="(agreement.certificationsDocument || {}).url"
                        target="_blank"
                        rel="noopener noreferrer">
                        Ver documento
                      </a>
                      <div v-else>
                        Sin certificaciones
                      </div>
                    </div>
                    <b-switch
                      v-model="(agreement.certificationsDocument || {}).valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'certificationsDocument', agreement._id)">
                      Documento verificado
                    </b-switch>
                  </div>
                </div>

                <div class="mt-20">
                  <b-button
                    type="is-primary"
                    @click="sendRenewalAgreement(agreement._id)">
                    Enviar recordatorio de renovación de contrato
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <b-button
            @click="close">
            Cerrar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isOpen: false,
      application: {},
      agreements: []
    }
  },
  watch: {
    async isOpen (val) {
      if (val) {
        await this.load()
      }
    }
  },
  methods: {
    open (application) {
      this.application = application
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    async load () {
      this.isLoading = true
      try {
        const { data } = await this.$api.get(`/candidates/${this.application._id}/agreements`)
        this.agreements = data
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async create () {
      this.isLoading = true
      try {
        await this.$api.post(`/candidates/${this.application._id}/agreements`)
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async sendRenewalAgreement (id) {
      try {
        await this.$api.post(`/candidates/${this.application._id}/agreements/${id}/send-reminder`)
        this.$buefy.toast.open({
          message: 'El correo se envió correctamente',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    openRemoveDialog (agreementId) {
      this.$buefy.dialog.confirm({
        title: `¿Eliminar convenio?`,
        message: `Al eliminarlo, confirmas que el convenio y sus datos guardados ya no aparecerán para este usuario.`,
        confirmText: 'Asumo ese riesgo',
        cancelText: 'No, siempre no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => await this.deleteAgreement(agreementId)
      })
    },
    async deleteAgreement (agreementId) {
      this.isLoading = true
      try {
        await this.$api.delete(`/candidates/${this.application._id}/agreements/${agreementId}`)
        this.$buefy.toast.open({
          message: 'El correo se envió correctamente',
          type: 'is-success'
        })
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async validateDocument (val, document, agreementId) {
      this.isLoading = true
      try {
        const { data } = await this.$api.post(`/candidates/${this.application._id}/agreements/${agreementId}/verify-document`, { document: document, value: val })
        this.data = data
        this.$buefy.toast.open({
          message: 'Status cambiado',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    }
  }
}
</script>
