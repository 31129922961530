import axios from 'axios'
import store from '@/store'
import router from '@/routes'

const httpApi = {
  get (url, options) {
    return this.request('get', url, options)
  },

  put (url, data, options) {
    return this.request('put', url, data, options)
  },

  post (url, data, options) {
    return this.request('post', url, data, options)
  },

  delete (url, options) {
    return this.request('delete', url, options)
  },

  request (method, endpoint, data, options) {
    axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/v1`

    if (store.state.session) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.session.token}`
    }

    return axios[method](endpoint, data, options)
  }
}

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error)
})

export default httpApi