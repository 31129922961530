<template>
    <div class="columns is-multiline ml-2 mr-2" id="dashboard-main">
      <GoUp 
        viewId="dashboard-main"
      />
      <MultiSearcher
        @search="updateStats"
        :activeSections="['startDate', 'endDate']"
      />

      <VacanciesBreakdown
        :loadFunction="searchVacancyBreakdown"
        :search="search"
      />

      <HistoricApplications
        :loadFunction="searchApplicationsBreakdown"
        :search="search"
      />
      
      <div class="is-flex is-justify-content-space-between column is-12 p-0"
        style="gap: 1.5rem;"
      >
        <VacanciesIndicators
          :loadFunction="searchIndicators"
          :search="search"
        />

        <VacanciesRecruitment
          :loadFunction="searchRecruitment"
          :search="search"
        />
      </div>

      <VacanciesGrossProfile
        :loadFunction="searchVacanciesGrossProfile"
        :search="search"
      />

      <ApplicationsGrossProfile
        :loadFunction="searchApplicationsGrossProfile"
        :search="search"
      />

      <ApplicationsHiringProfile
        :loadFunction="searchApplicationsHiringProfile"
        :search="search"
      />

      <ApplicationsRejection
        :loadFunction="searchRejections"
        :search="search"
      />

      <ApplicationsFunnel
        :loadFunction="searchFunnelApplications"
        :search="search"
      />

      <VacanciesContactList
        :loadFunction="searchVacanciesContactList"
        :search="search"
      />

      <LegacyComponents 
        v-if="!$hasPermissionsByRole(externalRoles)"
        :loadFunction="searchLegacyData"
        :search="search"
      />
    </div>
</template>

<script>
import GoUp from '../components/dashboard/GoUp.vue'
import MultiSearcher from '@/components/dashboard/MultiSearcher.vue'
import VacanciesBreakdown from '@/components/dashboard/VacanciesBreakdown.vue'
import VacanciesIndicators from '../components/dashboard/VacanciesIndicators.vue'
import VacanciesRecruitment from '../components/dashboard/VacanciesRecruitment.vue'
import ApplicationsRejection from '../components/dashboard/ApplicationsRejection.vue'
import ApplicationsFunnel from '../components/dashboard/ApplicationsFunnel.vue'
import ApplicationsGrossProfile from '../components/dashboard/ApplicationsGrossProfile.vue'
import ApplicationsHiringProfile from '../components/dashboard/ApplicationsHiringProfile.vue'
import VacanciesGrossProfile from '../components/dashboard/VacanciesGrossProfile.vue'
import VacanciesContactList from '../components/dashboard/VacanciesContactList.vue'
import HistoricApplications from '../components/dashboard/HistoricApplications.vue';

import LegacyComponents from '../components/dashboard/LegacyComponents.vue'

import { 
  searchFunnelApplications, 
  searchVacancyBreakdown, 
  searchApplicationsBreakdown,
  searchIndicators, 
  searchRecruitment, 
  searchRejections,
  searchApplicationsGrossProfile,
  searchApplicationsHiringProfile,
  searchVacanciesGrossProfile,
  searchVacanciesContactList,
  searchLegacyData
} from '@/api/stats';

import {externalRoles} from '@/lib/permissions'
export default {
  components: {
    GoUp,
    MultiSearcher,
    VacanciesBreakdown,
    VacanciesIndicators,
    VacanciesRecruitment,
    ApplicationsRejection,
    ApplicationsFunnel,
    ApplicationsGrossProfile,
    ApplicationsHiringProfile,
    VacanciesGrossProfile,
    VacanciesContactList,
    HistoricApplications,
    LegacyComponents
  },
  data() {
    return {
      searchFunnelApplications,
      searchVacancyBreakdown,
      searchIndicators,
      searchRecruitment,
      searchRejections,
      searchApplicationsGrossProfile,
      searchApplicationsBreakdown,
      searchApplicationsHiringProfile,
      searchVacanciesGrossProfile,
      searchVacanciesContactList,
      searchLegacyData,
      externalRoles,
      search: {
        startDate: null,
        endDate: null,
        company: '',
      }
    }
  },
  methods: {
    async updateStats (search) {
      this.search = search
    }
  }
}
</script>
