<template>
  <div>

    <div class="columns">

      <div
        v-if="!['solicitant', 'hrmanager', 'designer', 'designer-manager'].includes($isRole(user))"
        class="column is-2 has-background-blue">

        <div class="pl-10 pt-10">

          <SideBar />

        </div>

      </div>

      <div class="column">

        <div class="pr-10 pt-10">

          <AppBreadcrumbs v-if="!['solicitant', 'hrmanager'].includes($isRole(user))"/>
          
          <section class="has-background-light layout">
            
            <div class="container mt-50">
              
              <transition name="fade">
                
                <router-view />
                
              </transition>
              
            </div>
            
          </section>
          
        </div>
        
      </div>
      
    </div>
    

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import AppBreadcrumbs from '@/components/AppBreadcrumbs'

export default {
  name: 'PromeritumLayout',
  components: {
    SideBar,
    AppBreadcrumbs,
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id || null
    },
    user () {
      return this.$store.state.session.user
    }
  },
  methods: {
    async getCompany () {
      this.isLoading = true
      const response = await this.$api.get(`/companies/${this.id}`)
      this.$store.commit('setCompany', response.data)
      this.isLoading = false
    }
  },
  beforeMount: async function () {
    await this.getCompany()
  }
}
</script>