<template>
  <div class="page mb-2">
    <h2>Perfil del candidato</h2>

    <h2 class="text mb-2">
      {{ candidateProfile.profile.firstName }} {{ candidateProfile.profile.firstSurname }}
      {{ candidateProfile.profile.lastSurname }}
    </h2>

    <table class="block">
      <tr>
        <th>
          <div class="label">Nacimiento:</div>
        </th>
        <th>
          <div class="label">Género:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.birthdate }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.gender }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Pronombre:</div>
        </th>
        <th>
          <div class="label">Alguna discapacidad:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.pronoun }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.hasDisability ? candidateProfile.profile.disability ||
            candidateProfile.profile.otherDisability : "No" }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Teléfono:</div>
        </th>
        <th>
          <div class="label">Correo:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">
            {{ candidateProfile.profile.phoneCode }} {{ candidateProfile.profile.phone }}
          </div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.user.email }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Estado:</div>
        </th>
        <th>
          <div class="label">Ciudad:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.state }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.city }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">CURP:</div>
        </th>
        <th></th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.curp }}</div>
        </td>
        <td></td>
      </tr>
    </table>

    <h3 class="mb-2">Perfil académico</h3>

    <table class="block">
      <tr>
        <th>
          <div class="label">Universidad:</div>
        </th>
        <th>
          <div class="label">Campus:</div>
        </th>
        <th>
          <div class="label">Carrera:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.university }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.campus }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.career }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Área de estudio:</div>
        </th>
        <th>
          <div class="label">Nivel académico:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.studyArea }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.academicLevel }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Semestre actual:</div>
        </th>
        <th>
          <div class="label">Fecha de graduación:</div>
        </th>
        <th>
          <div class="label">Fecha de titulacion:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.currentSemester }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.graduateDate }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.degreeDate }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Horario lunes:</div>
        </th>
        <th>
          <div class="label">Horario martes:</div>
        </th>
        <th>
          <div class="label">Horario miércoles:</div>
        </th>
        <th>
          <div class="label">Horario jueves:</div>
        </th>
        <th>
          <div class="label">Horario viernes:</div>
        </th>
        <th>
          <div class="label">Horario sábado:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.mondaysSchedule }}</div>
        </td>
        <td>
          <div>{{ candidateProfile.profile.tuesdaysSchedule }}</div>
        </td>
        <td>
          <div>{{ candidateProfile.profile.wednesdaysSchedule }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.thursdaysSchedule }}</div>
        </td>
        <td>
          <div>{{ candidateProfile.profile.fridaysSchedule }}</div>
        </td>
        <td>
          <div>{{ candidateProfile.profile.saturdaysSchedule }}</div>
        </td>
      </tr>
    </table>

    <h3 class="mb-2">Perfil de competencias y conocimientos</h3>

    <table class="block">
      <tr>
        <th>
          <div class="label">Habilidades:</div>
        </th>
        <th>
          <div class="label">Intereses:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">
            <div v-for="(skill, index) in candidateProfile.profile.skills" :key="`skill-${index}`" class="text">
              <span>{{ skill.name }}</span> - <span>{{ skill.level }}</span>
            </div>
          </div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.interestOne }}</div>
          <div v-if="candidateProfile.profile.interestTwo" class="text">
            {{ candidateProfile.profile.interestTwo }}
          </div>
          <div v-if="candidateProfile.profile.interestThree" class="text">
            {{ candidateProfile.profile.interestThree }}
          </div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Nivel de inglés:</div>
        </th>
        <th>
          <div class="label">Nivel de excel:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ candidateProfile.profile.englishLevel }}</div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.excelLevel }}</div>
        </td>
      </tr>
    </table>

    <h3 class="mb-2">Perfil profesional</h3>

    <table class="block">
      <tr>
        <th>
          <div class="label">Curriculum:</div>
        </th>
        <th
          v-if="candidateProfile.profile.applicationLinkVideo"  
        >
          <div class="label">Video de presentación:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">
            <a :href="candidateProfile.profile.resume.url" target="_blank" rel="noopener noreferrer">
              Ver curriculum
            </a>
          </div>
        </td>
        <td
          v-if="candidateProfile.profile.applicationLinkVideo"  
        >
          <div class="text">
            <a :href="candidateProfile.profile.applicationLinkVideo" target="_blank" rel="noopener noreferrer">
              Abrir video de presentación
            </a>
          </div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th
          v-if="candidateProfile.profile.applicationFile"
        >
          <div class="label">Archivo de aplicación del candidato:</div>
        </th>
        <th>
          <div class="label">Fecha de aplicación de psicometría:</div>
        </th>
      </tr>

      <tr>
        <td
          v-if="candidateProfile.profile.applicationFile"
        >
          <div class="text">
            <a :href="candidateProfile.profile.applicationFile?.url" target="_blank" rel="noopener noreferrer">
              Ver archivo de aplicación
            </a>
          </div>
        </td>
        <td>
          <div class="text">{{ candidateProfile.profile.psychometricTest?.appliedAt }}</div>
        </td>
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Portafolio / Blog:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">
            <a :href="candidateProfile.profile.portfolio" target="_blank" rel="noopener noreferrer">
              {{ candidateProfile.profile.portfolio }}
            </a>
          </div>
        </td>
      </tr>
    </table>

    
    <h3 class="mb-2">Comentarios adicionales</h3>
      
    <div class="text">{{ candidateProfile.profile.aditionalComments || 'Sin comentarios adicionales' }}</div>
  </div>
</template>

<script>
export default {
  props: {
    candidateProfile: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #0F486D;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.label {
  font-size: 10px;
  color: #42CBB6;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
}

.page {
  margin-bottom: 30px !important;
}

.block {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>