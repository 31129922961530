<template>
  <div class="contacts-searcher">
    <b-field class="contactField">
      <template #label>
      <span class="has-text-white has-text-weight-medium">Listar vacantes de contacto</span>
    </template>
    
    <b-autocomplete
      v-model="name"
      group-field="type"
      group-options="items"
      open-on-focus
      clearable
      :data="filteredContacts"
      @select="$emit('getData', $event)"
      field="fullName"
      class="contactsField"
      icon="user"
      />
    </b-field>
  </div>
</template>

<script>
import { getCompanyContacts } from "../../api/vacancy";
import { dashboardTerms } from "../../lib/dashboardDictionaries";
import { mapGetters } from "vuex";

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
      name: '',
      organizedContacts: [],
      data: [],
      isLoading: false
    }
  },
  watch: {
  },
  async mounted() {
    const contacts = await getCompanyContacts(this.companyId)
    this.organizedContacts = this.getOrganizedContacts(contacts)
  },
  methods: {
    getOrganizedContacts(contacts){ // Organize contacts by role for autocomplete
      const items = {}
      const contactsList = []

      contacts.forEach(contact => {
        const role = dashboardTerms.contactsSearcher[contact.role]

        if(!items[role])
          items[role] = []
        
        items[role].push(contact)
      })
      
      for (const [key, value] of Object.entries(items)) {
        contactsList.push({
          type: key,
          items: value
        })
      }
      
      return contactsList
    },
  },
  computed: {
    ...mapGetters(["companyId"]),
    filteredContacts() { // Filter contacts by name
      const newData = []
      const name = new RegExp(this.name, 'gi')

      this.organizedContacts.forEach(element => {
          const items = element.items.filter((item) =>{
            return name.test(item.fullName)
          })

          if (items.length)
            newData.push({ type: element.type, items })
      })

      return newData
    },
  }
};
</script>

<style lang="scss" scoped>
  .vacancies-contact-list{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contacts-searcher{
    width: 100%;
    background-color: #42cbb6;
    padding: 1rem;
    border-radius: 4px;
  }
  .contactField{
    width: 300px;
  }
</style>
