<template>
  <div class="page mb-2">
    <h2>Killer Questions</h2>

    <table class="block">
      <tr>
        <td>
          <div class="text">
            <div class="text"
              v-for="(killerQuestion, index) in killerQuestionAnswers" 
              :key="`answer-${index}`"
            >
              <span class="label">{{ killerQuestion.killerQuestion.question }}</span>

              <span v-if="killerQuestion.killerQuestion.questionType === 'multiple-option'">
                <span v-for="(answer, index) in killerQuestion.answer" :key="`answer-${index}`">
                  {{ `${answer}${index < killerQuestion.answer.length - 1 ? ", " : ""} ` }}
                </span>
              </span>

              <span v-else>
                {{ killerQuestion.answer }}
              </span>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
export default {
  props: {
    killerQuestionAnswers: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #0F486D;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.label {
  font-size: 10px;
  color: #42CBB6;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
}

.page {
  margin-bottom: 30px !important;
}

.block {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>