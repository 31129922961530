import { render, staticRenderFns } from "./DevAreasLandingPage.vue?vue&type=template&id=713f5208&scoped=true"
import script from "./DevAreasLandingPage.vue?vue&type=script&lang=js"
export * from "./DevAreasLandingPage.vue?vue&type=script&lang=js"
import style0 from "./DevAreasLandingPage.vue?vue&type=style&index=0&id=713f5208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713f5208",
  null
  
)

export default component.exports