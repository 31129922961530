<template>
    <div class="column is-2 box candidate-card scrolleable">
      <div>
        <p class="has-text-weight-semibold is-size-5 mb-2">Datos de <br/> candidat@</p>
        <p class="is-size-6"><b>Carrera:</b><br> {{ user.candidate.profile.career }}</p>
        <p class="is-size-6"><b>Nivel academico:</b> <br> {{
          academicLevels(user.candidate.profile.academicLevel[0]) }} </p>
        <p class="is-size-6"><b>Termino de materias:</b> <br> {{ user.candidate.profile.graduateDate |
          formatToDate }}</p>
        <p class="is-size-6"><b>Nivel de Excel:</b> <br> {{ user.candidate.profile.excelLevel }}</p>
        <p class="is-size-6"><b>Nivel de Ingles:</b> <br> {{ user.candidate.profile.englishLevel }}</p>
        <p class="is-size-6"><b>Residencia:</b> <br> {{ user.candidate.profile.city }}, <br>
          {{ user.candidate.profile.state }}</p>

      </div>
    </div>
</template>

<script>
export default {
  props: {
    user: {
      default: null,
      required: true
    },
    companyColor: {
      default: '#618093',
      required: true
    }
  },
  methods: {
    academicLevels(level) {
      if (level === 'student') return 'Estudiante'
      if (level === 'recently-graduated') return 'Recién graduado'
      if (level === 'experienced-graduated') return 'Graduado con experiencia'
    }
  }
}
</script>

<style lang="scss" scoped>
  .candidate-card {
    border-width: 1px;
    border-style: solid;
    border-color: v-bind('companyColor');
    padding: 1rem;
    border-radius: 10px;
    height: 100%;
    min-width: 18rem;
  }
</style>
