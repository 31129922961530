<template>
  <div class="candidates-container scrolleable">
    <div class="candidate-card card mb-3 " v-for="(user, index) in selectedUsersToInvite" :key="`user-${index}`">
      <!-- Datos del usuario -->
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="is-size-4 px-2 py-2">{{ user.profile.firstName }} {{ user.profile.firstSurname }}</p>
        <b-button icon-right="xmark" type="is-danger" rounded outlined size="is-small" @click="deleteCandidate(index)">
        </b-button>
      </div>

      <!-- Status de la invitación -->
      <div class="is-flex is-justify-content-space-between is-align-items-center is-size-4 mb-4 ml-1" v-if="user.invitationStatus">
         <b-tag type="is-success" v-if="user.invitationStatus.wasInvited">Invitado correctamente</b-tag>
          <b-tag type="is-danger" v-else>{{ user.invitationStatus.rejectInvitationReason }}</b-tag>
      </div>

      <div class="requirements has-text-centered">
        <!-- Carrera -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareCareer(user.profile)" />
          <p><b>Carrera:</b><br> {{ user.profile.career }}</p>
        </div>

        <!-- Nivel academico -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareAcademicLevel(user.profile)" />
          <p><b>Nivel academico:</b> <br> {{
            checkLevel(user.profile.academicLevel[0]) }} </p>
        </div>

        <!-- Termino de materias -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareGraduateDate(user.profile)" />
          <p><b>Termino de materias:</b> <br> {{ user.profile.graduateDate |
            formatToDate }}</p>
        </div>

        <!-- Nivel de excel -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareExcelLevel(user.profile)" />
          <p><b>Nivel de Excel:</b> <br> {{ user.profile.excelLevel }}</p>
        </div>

        <!-- Nivel de ingles -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareEnglishLevel(user.profile)" />
          <p><b>Nivel de Ingles:</b> <br> {{ user.profile.englishLevel }}</p>
        </div>

        <!-- Residencia -->
        <div class="has-text-centered requirement box">
          <b-icon icon="badge-check" :type="compareLocation(user.profile)" />
          <p><b>Residencia:</b> <br> {{ user.profile.city }},
            {{ user.profile.state }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedUsersToInvite: {
      type: Array,
      default: () => {
        return []
      }
    },
    companyColor: {
      default: '#618093',
      required: true
    },
    comparedVacancy: {
      default: null,
      required: true
    }
  },
  methods: {
    academicLevels(level) {
      if (level === 'student') return 'Estudiante'
      if (level === 'recently-graduated') return 'Recién graduado'
      if (level === 'experienced-graduated') return 'Graduado con experiencia'
    },
    compareCareer(candidate) {
      if (!this.comparedVacancy) return ''
      else if (this.comparedVacancy.degree.includes(candidate.career)) return 'is-success'
      else return 'is-danger'
    },
    compareAcademicLevel(candidate) {
      if (!this.comparedVacancy) return ''
      else if (this.comparedVacancy.academicLevel.includes(candidate.academicLevel[0])) return 'is-success'
      else return 'is-danger'
    },
    compareGraduateDate(candidate) {
      if (!this.comparedVacancy) return ''
      else {
        const vacancyDate = new Date(this.comparedVacancy.graduateDate);
        const candidateDate = new Date(candidate.graduateDate);
        if (vacancyDate <= candidateDate) {
          return 'is-success';
        } else {
          return 'is-danger';
        }
      }
    },
    compareExcelLevel(candidate) {
      if (!this.comparedVacancy) return ''
      else {
        if (this.comparedVacancy.excelLevel === 'Intermedio') {
          if (candidate.excelLevel === 'Básico') return 'is-danger'
          else return 'is-success'
        }
        else if (this.comparedVacancy.excelLevel === 'Avanzado') {
          if (candidate.excelLevel === 'Avanzado') return 'is-success'
          else return 'is-danger'
        }
        else return 'is-success'
      }
    },
    compareEnglishLevel(candidate) {
      if (!this.comparedVacancy) return ''
      else {
        if (this.comparedVacancy.englishLevel === 'Intermedio') {
          if (candidate.englishLevel === 'Básico') return 'is-danger'
          else return 'is-success'
        }
        else if (this.comparedVacancy.englishLevel === 'Avanzado') {
          if (candidate.englishLevel === 'Avanzado') return 'is-success'
          else return 'is-danger'
        }
        else return 'is-success'
      }
    },
    compareLocation(candidate) {
      if (!this.comparedVacancy) return ''
      else {
        if (this.comparedVacancy.location.state === candidate.state) return 'is-success'
        else return 'is-danger'
      }
    },
    checkLevel(userLevel) {
      return userLevel === 'student' ?
        'Estudiante' : userLevel === 'recently-graduated' ?
          'Recién graduado' : 'Graduado con experiencia'
    },
    deleteCandidate(index) {
      this.$emit('deletedCandidate', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.candidates-container {
  height: 100%;
  width: 50%;
}

.candidate-card {
  border-width: 1px;
  border-style: solid;
  border-color: v-bind('companyColor');
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
}

.requirements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
}

.requirement {
  flex-basis: 32%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.4rem !important;
}
</style>
