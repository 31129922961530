<template>
  <div class="pie-table-wrapper">
    <div class="columns">
      <p 
        class="is-size-5 column is-7 mt-0 mb-0"
        v-if="title"
      >
        {{ title }}
      </p>
      <p v-else class="is-size-5 column is-7 mt-0 mb-0"></p>
      <span 
        class="column is-5 has-text-primary has-text-weight-medium has-text-right"
        v-if="total"
       >Mostrando {{ total }} elemento(s)</span>
    </div>
    <div class="pie-table-content"
      v-if="total"
      style="min-height: 330px;"
    > 
      <div class="simple-pie">
        <ChartPie :chartData="getTransformedDataChart()"
          :style="`min-height: ${chartHeight};`"
        />
      </div>

      <div class="simple-table">
        <b-table
          :data="transformedData"
          :hoverable="true"
          :mobile-cards="false"
          :paginated="true"
          :pagination-simple="false"
          :pagination-rounded="true"
          :per-page="tableRows"
          striped
          pagination-position="bottom"
        >
          <b-table-column
            centered
            field="stat"
            :label="headers[0]"
            v-slot="props"
            :searchable="isStatSearchable"
            sortable
          >
            {{ props.row.stat }}
          </b-table-column>

          <b-table-column
            centered
            field="value"
            :label="headers[1]"
            v-slot="props"
            sortable
          >
            {{ props.row.value }}
          </b-table-column>

          <b-table-column
            centered
            v-if="showRowsPercentage"
            field="percentage"
            label="Porcentaje"
            v-slot="props"
          >
            {{ ((props.row.value / total) * 100).toFixed(2) }}%
          </b-table-column>
        </b-table>        
      </div>
    </div>
    
    <div
      v-else
      class="has-text-centered is-flex is-flex-direction-column is-justify-content-center"
    >
      <f-icon icon="fa-regular fa-mug-hot" class="has-text-primary is-size-3 mb-3"/>
      <p class="has-text-weight-normal">No se encontraron datos</p>
    </div>

  </div>
</template>

<script>
import ChartPie from '@/components/charts/ChartPie';
import { dashboardTerms } from '@/lib/dashboardDictionaries';
const _ = require('lodash');

export default {
  name: "PieTable",
  props: {
    pieTableData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
    },
    termsKey: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      default: () => ['Stat', 'Cantidad', 'Porcentaje']
    },
    showRowsPercentage: {
      type: Boolean,
      default: true
    },
    isStatSearchable: {
      type: Boolean,
      default: true
    },
    defaultTotal: {
      type: Number,
      default: 0
    },
    chartHeight: {
      type: String,
      default: '200px'
    },
    tableRows: {
      type: Number,
      default: 5
    }
  },
  components: {
    ChartPie,
  },
  data() {
    return {
      transformedData: null,
      total: 0,
    }
  },
  watch: {
    pieTableData: {
      handler: function(val){
        this.transformedData = this.transformDataTable(val)
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    transformDataTable(val){
      const tableData = []
      this.total = this.defaultTotal

      if (!val)
        return null

      // Creating table format
      for (const key in val) {
        const stat = _.get(dashboardTerms, this.termsKey) ? _.get(dashboardTerms, this.termsKey)[key] : key
        const value = val[key]

        if (this.defaultTotal === 0)
          this.total += value

        tableData.push({stat, value})
      }

      return tableData
    },
    getTransformedDataChart(){
      const transformedDataChart = [this.headers]

      this.transformedData.forEach((item) => {
        transformedDataChart.push([item.stat, item.value])
      })

      return transformedDataChart
    }
  }
};
</script>

<style lang="scss" scoped>
  .pie-table-wrapper{
    position: relative;
    min-height: fit-content;
  }
  .pie-table-content{
    display: flex;
  }
  .simple-pie{
    width: 50%;
  }
  .simple-table{
    width: 50%;
  }
</style>
