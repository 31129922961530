export const baseRoles = [
  { value: 'recruiter-manager', text: 'Coordinador de Adquisición de Talento' },
  { value: 'recruiter', text: 'Especialista en Adquisición de Talento' },
  { value: 'designer-manager', text: 'Coordinador de Comunicación' },
  { value: 'designer', text: 'Especialista de Comunicación' },
  { value: 'account-manager', text: 'Coordinador de Cuentas Clave' },
  { value: 'account', text: 'Analista de Cuentas Clave' },
  { value: 'manager', text: 'Manager' }
]

export const searchRoles = {
  'recruiter-manager': 'Coordinador de Adquisición de Talento',
  'recruiter': 'Especialista en Adquisición de Talento',
  'designer': 'Especialista de Comunicación',
  'designer-manager': 'Coordinador de Comunicación',
  'account': 'Analista de Cuentas Clave',
  'account-manager': 'Coordinador de Cuentas Clave',
  'manager': 'Manager',
  'hrmanager': 'Manager de RH',
  'solicitant': 'Solicitante'
}

export const contactRoles = [
  { value: 'hrmanager', text: 'Manager de RH' },
  { value: 'solicitant', text: 'Solicitante' }
]

export const allowedRecruitersManagersEditionRoles = [
  { value: 'recruiter-manager', text: 'Coordinador de Adquisición de Talento' },
  { value: 'recruiter', text: 'Especialista en Adquisición de Talento' },
  { value: 'hrmanager', text: 'Manager de RH' },
  { value: 'solicitant', text: 'Solicitante' }
]

export const allowedRecruitersManagersCreationRoles = [
  { value: 'recruiter-manager', text: 'Coordinador de Adquisición de Talento' },
  { value: 'recruiter', text: 'Especialista en Adquisición de Talento' },
]

export const allowedDesignersManagersCreationRoles = [
  { value: 'designer-manager', text: 'Coordinador de Comunicación' },
  { value: 'designer', text: 'Especialista de Comunicación' },
]

export const authRoles = {
  recruitmentRoles: ['recruiter-manager', 'recruiter'],
  contactRoles: ['hrmanager', 'solicitant']
}