import { render, staticRenderFns } from "./LegacyComponents.vue?vue&type=template&id=edf73ec2&scoped=true"
import script from "./LegacyComponents.vue?vue&type=script&lang=js"
export * from "./LegacyComponents.vue?vue&type=script&lang=js"
import style0 from "./LegacyComponents.vue?vue&type=style&index=0&id=edf73ec2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf73ec2",
  null
  
)

export default component.exports