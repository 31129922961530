export default [
  {
    "id": "0",
    "key": "00",
    "name": "Todo México",
    "shortname": "mexico"
  },
  {
    "id": "1",
    "key": "01",
    "name": "Aguascalientes",
    "shortname": "Ags."
  },
  {
    "id": "2",
    "key": "02",
    "name": "Baja California",
    "shortname": "BC"
  },
  {
    "id": "3",
    "key": "03",
    "name": "Baja California Sur",
    "shortname": "BCS"
  },
  {
    "id": "4",
    "key": "04",
    "name": "Campeche",
    "shortname": "Camp."
  },
  {
    "id": "5",
    "key": "05",
    "name": "Coahuila de Zaragoza",
    "shortname": "Coah."
  },
  {
    "id": "6",
    "key": "06",
    "name": "Colima",
    "shortname": "Col."
  },
  {
    "id": "7",
    "key": "07",
    "name": "Chiapas",
    "shortname": "Chis."
  },
  {
    "id": "8",
    "key": "08",
    "name": "Chihuahua",
    "shortname": "Chih."
  },
  {
    "id": "9",
    "key": "09",
    "name": "Ciudad de México",
    "shortname": "CDMX"
  },
  {
    "id": "10",
    "key": "10",
    "name": "Durango",
    "shortname": "Dgo."
  },
  {
    "id": "11",
    "key": "11",
    "name": "Guanajuato",
    "shortname": "Gto."
  },
  {
    "id": "12",
    "key": "12",
    "name": "Guerrero",
    "shortname": "Gro."
  },
  {
    "id": "13",
    "key": "13",
    "name": "Hidalgo",
    "shortname": "Hgo."
  },
  {
    "id": "14",
    "key": "14",
    "name": "Jalisco",
    "shortname": "Jal."
  },
  {
    "id": "15",
    "key": "15",
    "name": "México",
    "shortname": "Mex."
  },
  {
    "id": "16",
    "key": "16",
    "name": "Michoacán de Ocampo",
    "shortname": "Mich."
  },
  {
    "id": "17",
    "key": "17",
    "name": "Morelos",
    "shortname": "Mor."
  },
  {
    "id": "18",
    "key": "18",
    "name": "Nayarit",
    "shortname": "Nay."
  },
  {
    "id": "19",
    "key": "19",
    "name": "Nuevo León",
    "shortname": "NL"
  },
  {
    "id": "20",
    "key": "20",
    "name": "Oaxaca",
    "shortname": "Oax."
  },
  {
    "id": "21",
    "key": "21",
    "name": "Puebla",
    "shortname": "Pue."
  },
  {
    "id": "22",
    "key": "22",
    "name": "Querétaro",
    "shortname": "Qro."
  },
  {
    "id": "23",
    "key": "23",
    "name": "Quintana Roo",
    "shortname": "Q. Roo"
  },
  {
    "id": "24",
    "key": "24",
    "name": "San Luis Potosí",
    "shortname": "SLP"
  },
  {
    "id": "25",
    "key": "25",
    "name": "Sinaloa",
    "shortname": "Sin."
  },
  {
    "id": "26",
    "key": "26",
    "name": "Sonora",
    "shortname": "Son."
  },
  {
    "id": "27",
    "key": "27",
    "name": "Tabasco",
    "shortname": "Tab."
  },
  {
    "id": "28",
    "key": "28",
    "name": "Tamaulipas",
    "shortname": "Tamps."
  },
  {
    "id": "29",
    "key": "29",
    "name": "Tlaxcala",
    "shortname": "Tlax."
  },
  {
    "id": "30",
    "key": "30",
    "name": "Veracruz de Ignacio de la Llave",
    "shortname": "Ver."
  },
  {
    "id": "31",
    "key": "31",
    "name": "Yucatán",
    "shortname": "Yuc."
  },
  {
    "id": "32",
    "key": "32",
    "name": "Zacatecas",
    "shortname": "Zac."
  }
]