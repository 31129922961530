export default [
  { id: "00", name: "Todas las provincias" },
  { id: "01", name: "Araba" },
  { id: "02", name: "Albacete" },
  { id: "03", name: "Alacant" },
  { id: "04", name: "Almería" },
  { id: "05", name: "Ávila" },
  { id: "06", name: "Badajoz" },
  { id: "07", name: "Illes Balears" },
  { id: "08", name: "Barcelona" },
  { id: "09", name: "Burgos" },
  { id: "10", name: "Cáceres" },
  { id: "11", name: "Cádiz" },
  { id: "12", name: "Castelló" },
  { id: "13", name: "Ciudad Real" },
  { id: "14", name: "Córdoba" },
  { id: "15", name: "A Coruña" },
  { id: "16", name: "Cuenca" },
  { id: "17", name: "Girona" },
  { id: "18", name: "Granada" },
  { id: "19", name: "Guadalajara" },
  { id: "20", name: "Gipuzkoa" },
  { id: "21", name: "Huelva" },
  { id: "22", name: "Huesca" },
  { id: "23", name: "Jaén" },
  { id: "24", name: "León" },
  { id: "25", name: "Lleida" },
  { id: "26", name: "La Rioja" },
  { id: "27", name: "Lugo" },
  { id: "28", name: "Madrid" },
  { id: "29", name: "Málaga" },
  { id: "30", name: "Murcia" },
  { id: "31", name: "Navarra" },
  { id: "32", name: "Ourense" },
  { id: "33", name: "Asturies" },
  { id: "34", name: "Palencia" },
  { id: "35", name: "Las Palmas" },
  { id: "36", name: "Pontevedra" },
  { id: "37", name: "Salamanca" },
  { id: "38", name: "Santa Cruz de Tenerife" },
  { id: "39", name: "Cantabria" },
  { id: "40", name: "Segovia" },
  { id: "41", name: "Sevilla" },
  { id: "42", name: "Soria" },
  { id: "43", name: "Tarragona" },
  { id: "44", name: "Teruel" },
  { id: "45", name: "Toledo" },
  { id: "46", name: "València" },
  { id: "47", name: "Valladolid" },
  { id: "48", name: "Bizkaia" },
  { id: "49", name: "Zamora" },
  { id: "50", name: "Zaragoza" },
  { id: "51", name: "Ceuta" },
  { id: "52", name: "Melilla" }
]
