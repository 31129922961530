export default {
  generalData: {
    newVacancy: "New Vacancy",
    title: "Main Information",
    returnButton: "Go back",
    vacancyAction: "Edit",
    fields: {
      alignamentDate: {
        label: "Alignament Date",
        select: "Select a date"
      },
      name: {
        name: 'name',
        label: "Vacancy name",
        validation: "required",
      },
      duration: {
        name: 'duration',
        label: "Duration",
        validation: "required",
      },
      places: {
        name: 'places',
        label: "Number of Positions",
        validation: "required|numeric|min_value:1",
      },
      state: {
        name: 'state',
        label: "State",
        validation: "required",
      },
      city: {
        name: 'city',
        label: "City",
        validation: "required",
      },
      showScholarship: {
        name: 'showScholarship',
        label: "Show Scholarship",
        validation: "required",
      },
      scholarship: {
        name: 'scholarship',
        label: "Scholarship",
        validation: "required|numeric",
      },
      schedule: {
        name: 'schedule',
        label: "Schedule",
        validation: "required",
        tooltip: "6 hours daily, 30 hours weekly, Morning shift, 8 a.m. to 2 p.m."
      },
      modality: {
        name: 'modality',
        label: "Modality",
        validation: "required",
      },
    },
  },
  academicData: {
    title: "Academic Background",
    fields: {
      degree: {
        name: 'degree',
        label: "Degree(s)",
        validation: "required",
        placeholder: "Press 'Enter' to add"
      },
      academicLevel: {
        name: 'academicLevel',
        label: "Academic Level",
        validation: "required",
        options: {
          student: "Student",
          graduated: "Graduated",
          experienced: "Graduated with experience"
        }
      },
      minimumSemester: {
        name: 'minimumSemester',
        label: "Minimum Semester",
        validation: "required",
      },
      maximumSemester: {
        name: 'maximumSemester',
        label: "Maximum Semester",
        validation: "required",
      },
      excelLevel: {
        name: 'excelLevel',
        label: "Excel Level",
        validation: "required",
      },
      graduateDate: {
        name: 'graduateDate',
        label: "Subject Completion",
        validation: "required",
        tooltip: "Maximum allowed month for course completion."
      },
      degreeDate: {
        name: 'degreeDate',
        label: "Graduation",
        validation: "required",
        tooltip: "Maximum allowed month for degree aquisition"
      },
    },
  },
  languageData: {
    title: "Vacancy Languages",
    fields: {
      englishLevels: {
        label: "English",
        proficiency: {
          desirable: "Desirable",
          basic: "Basic",
          intermediate: "Intermediate",
          advanced: "Advanced",
          representative: "Representative",
          writing: "Writing",
          reading: "Reading",
          listening: "Listening",
          speaking: "Speaking",
        },
      },
      otherLanguages: {
        label: "Other Languages",
        proficiency: {
          desirable: "Desirable",
          basic: "Basic",
          intermediate: "Intermediate",
          advanced: "Advanced",
          representative: "Representative",
          writing: "Writing",
          reading: "Reading",
          listening: "Listening",
          speaking: "Speaking",
        },
      },
      addLanguageButton: {
        label: "Add Language",
      },
    },
  },
  habilities: {
    title: "Skills and Knowledge",
    fields: {
      skills: {
        label: "Skills",
        info:
          "Skills, competencies, and personal characteristics necessary for the position.",
        addSkillButton: {
          label: "Add Skill",
        }
      },
      knowledges: {
        label: "Knowledge",
        info:
          "Knowledge such as software, programs, hardware, etc., necessary for the position.",
        level: {
          label: "Level",
        },
        addKnowledgeButton: {
          label: "Add Knowledge",
        },
        infoLimit: "Maximum knowledge limit reached"
      },
    },
  },
  description: {
    title: "Vacancy Description",
    fields: {
      goal: {
        name: 'goal',
        label: "Objective",
        validation: "required",
      },
      benefit: {
        name: 'benefit',
        label: "Benefits",
        validation: "required",
        addBenefitButton: {
          label: "Add Benefit",
        },
      },
      activities: {
        name: 'activities',
        label: "Daily Activities",
        validation: "required",
        addActivityButton: {
          label: "Add Activity",
        },
      },
    },
  },
  solicitants: {
    title: "Contact Information",
    availableAuthors: "Available Contacts",
    fields: {
      author: {
        name: 'author',
        label: "Selected Contact",
        unassigned: "Unassigned"
      },
      additionalSolicitants: {
        label: "Add Other Contacts",
        tooltip: {
          label: "Unchecking this option removes any additional contacts added",
        }
      },
      availableContacts: {
        label: "Available Contacts",
        add: "Add"
      },
      additionalContacts: {
        availableLabel: "Additional Available Contacts",
        assignedLabel: "Additional Assigned Contacts",
        info: "Assign at least 1 additional contact"
      }
    },
    buttonLabels: {
      addUser: "Add User",
      selectAction: " Select"
    },
    privacyDisclaimer: "This data is confidential and for the exclusive Pro Meritum use.",
    modal: {
      title: 'Create User',
      name: "Names (s)",
      maternalSurname: "Maternal Surname",
      paternalSurname: "Paternal Surname",
      email: 'Email',
      recruiter: "Recruiter",
      calendlyUrl: "Calendly URL",
      company: "Company",
      buttons: {
        cancel: "Cencel",
        save: "Save"
      }
    }
  },
  recruitment: {
    title: "About Recruitment",
    fields: {
      requisitionScheme: {
        label: "Requisition Scheme",
      },
      serviceType: {
        label: "Service Type",
      },
      claimType: {
        label: "Claim Type",
      },
      serviceDetail: {
        label: "Service Detail"
      }
    },
  },
  project: {
    title: "Project",
    fields: {
      killerQuestions: {
        label: "Killer Questions",
        desc: "Questions to help identify ideal candidates for the position.",
        buttonAction: "Add Question",
        answerType: "Answer Type",
        option: "Option",
        multiple: "Options"
      },
      comments: {
        title: "Comments",
        label: "Comments",
        placeholder: "Comments, observations, explanations, and more...",
      },
      additionalFields: {
        label: "Aditional Fields",
      },
      uploadFile: {
        placeholder: "Supported formats: .pdf, .docx, .csv (Max: 5MB).",
        label: "Upload File",
        viewFile: "View File",
        successFile: "File uploaded successfully"
      }
    },
  },
  languageInfo: {
    title: "Vacancy language"
  },
  vacancyFormLanguageOptions: {
    spanish: "Spanish",
    english: "English"
  },
  saveData: {
    disclaimer: "Check carefully all fields before save",
    projects: {
      title: "Project",
      lable: "Select a project",
      disclaimer: "No projects available"
    },
    descMonday: "Write Monday's requisition ID",
    idMonday: "Requisition ID",
    saveButton: {
      label: "Save",
      create: "Create Vacancy",
      update: "Update Vacancy",
      error: "One or more fields are incomplete"
    }
  },
  additionalFields: {
    label: "Additional Fields",
    info: "No additional fields found"
  }
};
