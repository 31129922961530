<template>
  <div>
    <div>
      <div>
        <b-table
          class="has-shadow"
          :columns="table.columns"
          :data="table.data"
          :paginated="table.isPaginated"
          :per-page="table.perPage"
          :current-page.sync="table.currentPage"
          :pagination-simple="table.isPaginationSimple"
          :pagination-position="table.paginationPosition"
          :default-sort-direction="table.defaultSortDirection"
          :sort-icon="table.sortIcon"
          :sort-icon-size="table.sortIconSize"
          default-sort="candidate_name"
        >
          <b-table-column label="Documentos válidos" width="10" centered v-slot="props">
            <b-tooltip
              style="width: 100%;"
              :label="!props.row.application.hirignDocumentsValid.valid
                ? `Faltan: ${props.row.application.hirignDocumentsValid.missing}`
                : props.row.application.hirignDocumentsValid.missing
              "
              position="is-top"
              multilined
              animated>
              <b-icon
                size="is-medium"
                :icon="props.row.application.hirignDocumentsValid.valid ? 'check' : 'close'"
                :type="props.row.application.hirignDocumentsValid.valid ? 'is-success' : 'is-danger'"
              />
            </b-tooltip>
          </b-table-column>
          <b-table-column
            label="Nombre"
            sortable
            centered
            v-slot="props"
          >
            <router-link :to="{
            name: 'AccountCandidateDetail',
            params: { id: props.row.application._id }}">
              {{ props.row.profile.firstName }}
              {{ props.row.profile.firstSurname }}
              {{ props.row.profile.lastSurname }}
            </router-link>
          </b-table-column>
          <b-table-column
            label="Vacante"
            sortable
            centered
            v-slot="props"
          >
            {{ props.row.application.vacancy.name }}
          </b-table-column>
          <b-table-column
            label="Inicio Convenio"
            sortable
            width="10"
            centered
            v-slot="props"
          >
            <span class="tag is-primary">
              {{ (props.row.application.hiring || {}).entry | formatToDate }}
            </span>
          </b-table-column>
          <b-table-column
            label="Término Convenio"
            sortable
            width="10"
            centered
            v-slot="props"
          >
            <span class="tag is-primary">
              {{ (props.row.application.hiring || {}).finish | formatToDate }}
            </span>
          </b-table-column>
          <b-table-column
            label="Editar fechas"
            sortable
            width="10"
            centered
            v-slot="props"
          >
            <b-button
              class="align-center"
              outlined
              type="is-danger"
              @click="openEdit(props.row.application)">
              <b-icon
                icon="pencil"
              />
            </b-button>
          </b-table-column>
          <b-table-column
            label="Beca"
            sortable
            width="10"
            centered
            v-slot="props"
          >
            ${{ props.row.application.vacancy.scholarship }}
          </b-table-column>
          <b-table-column label="" centered v-slot="props">
            <b-dropdown
              position="is-bottom-left"
              :triggers="['hover']" aria-role="list">
              <button class="button" slot="trigger">
                <b-icon icon="ellipsis-stroke"></b-icon>
              </button>

              <b-dropdown-item
                has-link
                aria-role="listitem">
                <a
                  target="_blank"
                  :href="downloadLink(props.row.application._id)">
                  <b-icon size="is-small" icon="file-arrow-down" /> Descargar perfil
                </a>
              </b-dropdown-item>

              <b-dropdown-item
              v-if="company.name === 'Elementary Lab'"
                aria-role="listitem"
                @click="sendMailToHireElementaryLab(props.row.application._id)">
                <b-icon size="is-small" icon="envelope" /> Enviar correo con formulario (EL)
              </b-dropdown-item>

              <b-dropdown-item
              v-if="company.name != 'Elementary Lab'"
                aria-role="listitem"
                @click="sendMailToHire(props.row.application._id)">
                <b-icon size="is-small" icon="envelope" /> Enviar correo con formulario
              </b-dropdown-item>

              <b-dropdown-item
                aria-role="listitem"
                @click="sendRenewAgreement(props.row.application)">
                <b-icon size="is-small" icon="close" /> Ver convenios renovados
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </b-table>
      </div>
    </div>

    <renewal-agreement ref="renewalAgreement"/>

    <CandidateEdit ref="edit" @updated="load"/>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import RenewalAgreement from '@/components/modals/RenewalAgreement.vue'
import CandidateEdit from '../components/modals/CandidateEdit.vue'

export default {
  components: {
    RenewalAgreement,
    CandidateEdit
  },
  data() {
    return {
      isLoading: false,
      table: {
        data: [],
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 15
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.session.user
    },
    company () {
      return this.$store.state.company
    },
    companyId () {
      return this.$store.state.company.id
    },
    baseUrl () {
      return process.env.VUE_APP_API_URL
    },
    token () {
      return this.$store.state.session.token
    },
  },
  watch: {
    companyId (val) {
      if (val) {
        this.load()
      }
    }
  },
  mounted () {
    if (this.companyId) {
      this.load()
    }
  },
  methods: {
    async load () {
      this.isLoading = true
      try {
        const { data } = await this.$api.get(`/companies/${this.companyId}/scholars`)
        this.table.data = data.scholars
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    downloadLink (id) {
      return `${this.baseUrl}/v1/candidates/${id}/download-report?token=${this.token}`
    },
    async sendMailToHire (id) {
      try {
        await this.$api.post(`/candidates/${id}/send-hiring-form`)
        this.$buefy.toast.open({
          message: 'El correo se envió correctamente',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    async sendMailToHireElementaryLab (id) {
      try {
        await this.$api.post(`/candidates/${id}/send-hiring-form-elementary-lab`)
        this.$buefy.toast.open({
          message: 'El correo se envió correctamente',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    async sendMissingDocuments (id) {
      try {
        await this.$api.post(`/candidates/${id}/send-missing-documents`)
        this.$buefy.toast.open({
          message: 'El correo se envió correctamente',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    async sendRenewAgreement (application) {
      this.$refs.renewalAgreement.open(application)
    },
    openEdit (candidate) {
      this.$refs.edit.open(candidate)
    }
  }
}
</script>
