<template>
  <div class="container">
    <div class="is-flex justify-flex-end align-flex-end mb-10">
      <div class="mr-12">
        <b-button rounded type="is-primary" @click="sendTrackingNotificationsSent">
          Enviar notificaciones de seguimiento
        </b-button>
      </div>
      <b-field class="ml-10 mb-0 mr-2 mt-5">
        <b-input v-model="nameToSearch" placeholder="Buscar por nombre..." icon="magnifying-glass" />
      </b-field>
    </div>

    <div>
      <b-table class="has-shadow" :columns="table.columns" :data="table.filteredData" :paginated="table.isPaginated"
        :per-page="table.perPage" :page-input="table.hasInput" :current-page.sync="table.currentPage"
        :pagination-simple="table.isPaginationSimple" :pagination-position="table.paginationPosition"
        :default-sort-direction="table.defaultSortDirection" :sort-icon="table.sortIcon"
        :sort-icon-size="table.sortIconSize" :checked-rows.sync="checkedCandidates"
        :is-row-checkable="(row) => isCheckable(row)"
        :checkable="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)" default-sort="candidate_name">
        <b-table-column label="Evaluad@" v-slot="props">
          <div>
            <div v-if="props.row.application.seen === false">
              <b-icon icon="check" size="is-medium" />
            </div>

            <div v-else>
              <b-icon i con="check-double" size="is-medium" />
            </div>
          </div>
        </b-table-column>

        <b-table-column field="candidate_name" label="Nombre" sortable v-slot="props">
          <router-link :to="{
            name: 'CandidateDetail',
            query: {
              p: table.currentPage,
              s: status,
            },
            params: { id: props.row.application._id },
          }">
            {{ props.row.profile.firstName }}
            {{ props.row.profile.firstSurname }}
            {{ props.row.profile.lastSurname }}
          </router-link>
        </b-table-column>

        <b-table-column label="Email" sortable centered v-slot="props">
          {{ props.row.profile.email }}

          <br />

          <div>
            <b-tooltip label="Copiar correo" position="is-bottom">
              <b-icon class="iconos" icon="copy" style="color: teal" v-clipboard:copy="props.row.profile.email"
                v-clipboard:success="onCopy" v-clipboard:error="onError" />
            </b-tooltip>

            <b-tooltip label="Enviar correo" position="is-bottom">
              <a class="email" :href="`https://mail.google.com/mail/u/0/?fs=1&to=${props.row.profile.email}&tf=cm`"
                target="_blank">
                <b-icon class="iconos" icon="envelope-open-text" />
              </a>
            </b-tooltip>
          </div>
        </b-table-column>

        <b-table-column v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)" label="Telefono" centered
          v-slot="props">
          {{ props.row.profile.phone }}
        </b-table-column>

        <b-table-column label="Licenciatura" centered v-slot="props">
          {{ props.row.profile.career }}
        </b-table-column>

        <b-table-column label="Termino de materias" centered v-slot="props">
          {{ props.row.profile.graduateDate | formatToDate }}
        </b-table-column>

        <b-table-column label="Residencia" v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)" v-slot="props">
          {{ props.row.profile.state }}
          <br />
          {{ props.row.profile.city }}
        </b-table-column>

        <b-table-column label="Habilidades" v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
          v-slot="props">
          <span class="has-text-weight-semibold"> Excel: </span>
          {{ props.row.profile.excelLevel }}

          <br />

          <span class="has-text-weight-semibold"> Inglés: </span>
          {{ props.row.profile.englishLevel }}
        </b-table-column>

        <b-table-column v-slot="props">
          <b-dropdown position="is-bottom-left" :triggers="['hover']" aria-role="list">
            <button class="button" slot="trigger">
              <b-icon icon="ellipsis-stroke" />
            </button>

            <b-dropdown-item v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
              :disabled="!!props.row.application.promeritumInterviewSent" @click="openPDF(props.row.profile.resume.url)"
              aria-role="listitem">
              <b-icon size="is-small" icon="file" />
              Ver CV
            </b-dropdown-item>

            <b-dropdown-item v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
              :disabled="!!props.row.profile.psychometricTest && !!props.row.profile.psychometricTest.appliedAt"
              aria-role="listitem">
              <div v-if="props.row.profile.psychometricTest && props.row.profile.psychometricTest.appliedAt">
                <b-icon size="is-small" icon="calendar-day" />
                Fecha que aplicó:
                {{ props.row.profile.psychometricTest.appliedAt | formatToDate }}
              </div>

              <div v-else>
                <div v-if="props.row.profile.psychometricTest" @click="openDialogPsychometricDate(props.row)">
                  <b-icon size="is-small" icon="calendar-day" />
                  Agregar fecha de test
                </div>

                <div v-else @click="openDialogPsychometricTest(props.row)">
                  <b-icon size="is-small" icon="file-export" />
                  Enviar psicometría
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
              :disabled="!!props.row.application.promeritumInterviewSent"
              @click="setInterview(props.row.application._id)" aria-role="listitem">
              <b-icon size="is-small" icon="comments-question" />
              Agendar entrevista con Pro Meritum
            </b-dropdown-item>

            <b-dropdown-item v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)" :disabled="!['applying'].includes(props.row.application.status) || !props.row.application.promeritumInterviewDone
              " @click="
                selectCandidate(props.row._id, `${props.row.profile.firstName} ${props.row.profile.firstSurname}`)
                " aria-role="listitem">
              <b-icon size="is-small" icon="poll-people" />
              Agregar candidato a lista de candidatos
            </b-dropdown-item>

            <b-dropdown-item @click="preHireCandidate(props.row._id)" :disabled="!['sent'].includes(props.row.application.status) ||
              (!props.row.application.companyInterview && ['solicitant', 'hrmanager'].includes($isRole()))
              " aria-role="listitem">
              <b-icon size="is-small" icon="hand-back-point-right" />
              Seleccionar candidato
            </b-dropdown-item>

            <b-dropdown-item v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
              :disabled="vacancy.places + 1 === hiredCandidates" @click="openDialogRejectCandidate(props.row)"
              aria-role="listitem">
              <div class="has-text-danger">
                <b-icon size="is-small" icon="hand" />
                Rechazar candidato
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
    </div>

    <b-modal :active.sync="psychometricTestModal" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">Enviar prueba psicométrica</div>

        <div class="modal-card-body">
          <div>Enviar mail al candidato para que haga su prueba psicométrica.</div>
        </div>

        <div class="modal-card-foot justify-flex-end">
          <b-button type="is-primary" @click="sendPsychometricTest">
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="psychometricDateModal" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">Enviar prueba psicométrica</div>

        <div class="modal-card-body">
          <div>
            <b-field label="Fecha que aplicó la prueba">
              <b-datepicker inline placeholder="Selecciona una fecha" icon="calendar-today"
                v-model="psychometricDateForm.appliedAt" />
            </b-field>
          </div>
        </div>

        <div class="modal-card-foot justify-flex-end">
          <b-button type="is-primary" @click="applyPsychometricTest">
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="preHireCandidateModal" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">Selecionar a {{ preHireCandidateForm.candidate }}</div>

        <div class="modal-card-body">
          <ValidationObserver ref="observer">
            <div class="columns">
              <div class="column">
                <div>
                  <ValidationProvider rules="required" name="Dirección" v-slot="{ errors, valid }">
                    <b-field label="Dirección" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input placeholder="Dirección a la que se presentará" type="textarea"
                        v-model="preHireCandidateForm.address" />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div>
                  <ValidationProvider rules="required" name="Persona con quien se presentará"
                    v-slot="{ errors, valid }">
                    <b-field label="Persona con quien se presentará"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                      <b-input placeholder="Persona con quien se presentará" type="text"
                        v-model="preHireCandidateForm.name" />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div>
                  <ValidationProvider rules="required" name="Hora a la que se presenta" v-slot="{ errors, valid }">
                    <b-field label="Hora a la que se presenta" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <div class="is-flex">
                        <b-input placeholder="hh:mm" v-model="preHireCandidateForm.hour" style="width: 100%" />
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div>
                  <b-field>
                    <template #label>
                      HRBP
                      <span class="has-text-grey" style="font-size: 12px">
                        Si aplica
                      </span>
                    </template>

                    <b-input v-model="preHireCandidateForm.hrbp" />
                  </b-field>
                </div>

                <div>
                  <b-field>
                    <template #label>
                      Información adicional
                      <span class="has-text-grey" style="font-size: 12px">
                        Si aplica
                      </span>
                    </template>

                    <b-input type="textarea" v-model="preHireCandidateForm.info" />
                  </b-field>
                </div>
              </div>

              <div class="column">
                <div>
                  <b-field label="Fecha de ingreso">
                    <b-datepicker inline size="is-small" placeholder="Selecciona una fecha"
                      :unselectableDaysOfWeek="[0, 6]" :min-date="threeDaysAfter" icon="calendar-today"
                      v-model="preHireCandidateForm.entry" />
                  </b-field>

                  <b-message type="is-info">
                    <p>
                      La fecha de ingreso deberá ser
                      <b> dentro de 72 hrs </b>.
                    </p>
                  </b-message>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <div class="modal-card-foot justify-flex-end">
          <b-button @click="closePreHireModal"> Cerrar </b-button>

          <b-button type="is-primary" @click="preHireCandidate">
            Seleccionar candidato
          </b-button>
        </div>
      </div>
    </b-modal>

    <ReasonRejection @candidateUpdated="getCandidates" :vacancy-id="vacancy._id" ref="reasonRejection" />

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { manageAllCandidatesAllowedRoles } from "../lib/permissions";
import ReasonRejection from "@/components/modals/ReasonRejection.vue";

export default {
  components: {
    ReasonRejection,
  },
  data() {
    return {
      //activeTab: 'Todos',
      isLoading: false,
      nameToSearch: "",
      vacancy: {
        name: null,
        id: null,
      },
      company: {
        name: null,
        id: null,
      },
      status: "sent",
      table: {
        data: [],
        filteredData: [],
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 15,
        hasInput: true,
      },
      psychometricTestModal: false,
      psychometricDateModal: false,
      psychometricDateForm: {
        appliedAt: new Date(),
      },
      candidate: {},
      checkedCandidates: [],
      preHireCandidateModal: false,
      preHireCandidateForm: {
        candidate: "",
        candidateId: "",
        address: "",
        // dates: [],
        entry: new Date(),
        name: "",
        hour: "",
        hrbp: "",
        info: "",
      },
      manageAllCandidatesAllowedRoles,
    };
  },
  watch: {
    async status(val) {
      if (val && val.length > 3) {
        const params = [`status=${val}`];
        if (this.nameToSearch) {
          params.push(`candidate=${this.nameToSearch}`);
        }
        params.join("&");
        await this.getCandidates(params);
      } else {
        await this.getCandidates();
      }
    },
    async nameToSearch(val) {
      const regex = new RegExp(val, "gi");

      this.table.filteredData = this.table.data.filter((element) => {
        const firstName = element.profile.firstName.trim().toLowerCase();
        const firstSurname = element.profile.firstSurname.trim().toLowerCase();
        const lastSurname = element.profile.lastSurname.trim().toLowerCase();

        const name = `${firstName} ${firstSurname} ${lastSurname}`;

        return new RegExp(regex).test(name);
      });
    },
    'table.data'() {
      this.table.filteredData = this.table.data;
    },
  },
  computed: {
    ...mapGetters(["companyId"]),
    route() {
      return this.$route.name;
    },
    id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.state.session.user;
    },
    thereAreSelected() {
      if (!this.table.data.length) return false;
      return this.table.data.some(
        (item) => item.application.status === "selected" || item.application.status === "sent"
      );
    },
    selectedStatus() {
      return `status=${this.status}`;
    },
    hiredCandidates() {
      return !!this.table.data.filter((candidate) => candidate.status === "hired").length;
    },
    appUrl() {
      return `${process.env.VUE_APP_BASE_URL}`;
    },
    threeDaysAfter() {
      const date = moment().add(3, "day").toDate();
      return date;
    },
    thisCompany() {
      return this.$store.state.company;
    },
    minTime() {
      const min = moment(this.preHireCandidateForm.entry).startOf("day").add(8, "hours").toDate();
      return min;
    },
    maxTime() {
      const max = moment(this.preHireCandidateForm.entry).startOf("day").add(18, "hours").toDate();
      return max;
    },
    queryPage() {
      return this.$route.query.p;
    },
  },
  async mounted() {
    this.table.currentPage = Number(this.queryPage || 1);
    await this.getCandidates();
  },
  methods: {
    hiredDash() {
      const win = window.open("https://forms.monday.com/forms/bf54d5cd9df8a029eb807a2d022f8e7b?r=use1", "_blank");
      win.focus();
    },
    onCopy: function () {
      this.$buefy.toast.open({
        message: "Correo copiado",
        type: "is-success",
      });
    },
    onError: function (e) {
      alert("No se pudo copiar el texto al portapapeles: " + e.text);
    },
    isCheckable(row) {
      const hasInterviewDone = row.application.promeritumInterviewDone;
      const hasStatusApplyingOrSelected =
        row.application.status === "applying" || row.application.status === "selected";
      return hasInterviewDone && hasStatusApplyingOrSelected;
    },
    async getCandidates(params) {
      this.isLoading = true;
      params = params || [this.selectedStatus];
      params = params.join("&");
      try {
        const { data } = await this.$api.get(`/vacancies/${this.id}/candidates?${params}`);
        this.table.data = data.candidates;
        this.vacancy = data.vacancy;
        this.company = data.company;
      } catch (error) {
        this.$buefy.toast.open({
          message: "Hubo un error al cargar los candidatos",
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },

    async sendTrackingNotificationsSent() {
      this.isLoading = true;
      try {
        const response = await this.$api.post(`/vacancies/${this.vacancy._id}/send-tracking-notifications`, {
          applicationStatus: 'sent', // O el estado que desees enviar
        });

        if (response.status === 200) {
          const totalEmailsSent = response.data.totalEmailsSent;
          this.$buefy.toast.open({
            message: `Se enviaron ${totalEmailsSent} correos de seguimiento`,
            type: "is-success",
          });
        } else if (response.status === 204) {
          this.$buefy.toast.open({
            message: "No se encontraron candidatos para la vacante especificada",
            type: "is-warning",
          });
        }
      } catch (error) {
        console.error('Error al enviar notificaciones:', error);
        const errorMessage = !error.response ? error : error.response.data.message;
        this.$buefy.toast.open({
          message: errorMessage,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },

    async selectCandidate(candidateId, candidate) {
      this.isLoading = true;
      this.$buefy.dialog.confirm({
        title: "Seleccionar candidato",
        message: `¿Quieres seleccionar a ${candidate}?`,
        confirmText: "Seleccionarlo",
        cancelText: "¡Noup!",
        onConfirm: async () => await this.sendRequest(candidateId, "select-candidate", "Se seleccionó al candidato"),
      });
      this.isLoading = false;
    },
    async closePreHireModal() {
      this.preHireCandidateModal = false;
      this.preHireCandidateForm = {
        candidate: "",
        candidateId: "",
        address: "",
        entry: new Date(),
        finish: new Date(),
        name: "",
        hour: "",
        hrbp: "",
        info: "",
      };
      this.$refs.observer.reset();
    },
    async preHireCandidate(candidate) {
      this.isLoading = true;
      const payload = {
        candidate,
      };
      try {
        await this.$api.post(`/vacancies/${this.vacancy._id}/prehire-candidate`, payload);
        await this.getCandidates();
        this.checkedCandidates = [];
        this.$buefy.toast.open({
          message: "Se seleccionó al candidato",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
        this.isLoading = false;
      }
    },
    async hireCandidate(candidateId, candidate) {
      this.isLoading = true;
      this.$buefy.dialog.confirm({
        title: "Contratar candidato",
        message: `¿Quieres contratar a ${candidate}?`,
        confirmText: "Contratarlo",
        cancelText: "¡Noup!",
        onConfirm: async () => {
          await this.sendRequest(candidateId, "hire-candidate", "Se contrató al candidato");
          this.hiredDash();
        },
      });
      this.isLoading = false;
    },
    async sendRequest(candidateId, endpoint, message) {
      this.isLoading = true;
      try {
        await this.$api.post(`/vacancies/${this.vacancy._id}/${endpoint}`, { candidate: candidateId });
        await this.getCandidates();
        this.checkedCandidates = [];
        this.$buefy.toast.open({
          message: message,
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    openDialogRejectCandidate(candidate) {
      this.$refs.reasonRejection.open(
        candidate._id,
        `${candidate.profile.firstName} ${candidate.profile.firstSurname}`
      );
    },
    async checkPsychometricTest(user) {
      this.isLoading = true;
      try {
        const payload = user.profile;
        payload.psychometricTest = true;
        await this.$api.put(`/candidates/${user._id}`, { profile: payload });
        await this.getCandidates();
        this.$buefy.toast.open({
          message: "El candidato completó le prueba",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    openDialogPsychometricTest(candidate) {
      this.psychometricTestModal = true;
      this.candidate = candidate;
    },
    closeDialogPsychometricTest() {
      this.psychometricTestModal = false;
      this.candidate = {};
    },
    async sendPsychometricTest() {
      this.isLoading = true;
      try {
        const payload = { recruiter: this.vacancy?.recruiter?.email, vacancyId: this.vacancy?._id };
        await this.$api.post(`/candidates/${this.candidate._id}/send-psychometric`, payload);
        await this.getCandidates();
        this.closeDialogPsychometricTest();
        this.$buefy.toast.open({
          message: "El test se envió correctamente",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    openDialogPsychometricDate(candidate) {
      this.psychometricDateModal = true;
      this.candidate = candidate;
    },
    closeDialogPsychometricDate() {
      this.psychometricDateModal = false;
      this.candidate = {};
    },
    async applyPsychometricTest() {
      this.isLoading = true;
      try {
        await this.$api.post(`/candidates/${this.candidate._id}/apply-psychometric`, this.psychometricDateForm);
        await this.getCandidates();
        this.closeDialogPsychometricDate();
        this.$buefy.toast.open({
          message: "Se agregó la fecha de aplicación psicométrica",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    goTo(to) {
      this.$router.push(to);
    },
    openPDF(link) {
      const url = this.$router.resolve({ name: "PDFViewer", query: { empty: true, link: link } });
      window.open(url.href);
      return false;
    },
    async setInterview(id) {
      this.isLoading = true;
      try {
        await this.$api.post(`/application/${id}/promeritum-interview`);
        this.$buefy.toast.open({
          message: "Se agendó la entrevista",
          type: "is-success",
        });
        await this.getCandidates();
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.isLoading = false;
    },
    cleanDate() {
      this.preHireCandidateForm.dates = [];
    },
  },
};
</script>
<style scoped>
@import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

.iconos :hover {
  color: #4dc0b5;
}

.email {
  color: teal;
}
</style>
