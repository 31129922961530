export default[
  'Enigma Room',
  'Shark Tank',
  'Assessment',
  'Speed Job Dating',
  'Champs',
  'Mi historia con _',
  'Coffe Date',
  '21 Questions',
  'Tik Tok',
  'Unboxing',
  'Otro'
]