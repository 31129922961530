<template>
  <div class="has-background-light layout">
    <div v-if="$isRole(user)">
      <NavBar />
      <div>
        <router-view />
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  name: 'ProMeritum',
  components: {
    NavBar
  },
  computed: {
    user () {
      return this.$store.state.session.user
    }
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$blue: #0f486d;
$blue-light: findLightColor($blue);
$blue-invert: findColorInvert($blue);
$blue-dark: findDarkColor($blue);
$primary: #42CBB6;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$scondary: #0f486d;
$scondary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$twitter-dark: findDarkColor($twitter);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
        $white,
        $black,
    ),
    "black": (
        $black,
        $white,
    ),
    "light": (
        $light,
        $light-invert,
    ),
    "dark": (
        $dark,
        $dark-invert,
    ),
    "primary": (
        $primary,
        $primary-invert,
        $primary-light,
        $primary-dark,
    ),
    "link": (
        $link,
        $link-invert,
        $link-light,
        $link-dark,
    ),
    "info": (
        $info,
        $info-invert,
        $info-light,
        $info-dark,
    ),
    "success": (
        $success,
        $success-invert,
        $success-light,
        $success-dark,
    ),
    "warning": (
        $warning,
        $warning-invert,
        $warning-light,
        $warning-dark,
    ),
    "danger": (
        $danger,
        $danger-invert,
        $danger-light,
        $danger-dark,
    ),
    "blue": (
      $blue,
      $blue-invert,
      $blue-light
      $blue-dark
    )
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Card
$card-header-padding: 1rem;
$card-content-padding: 1rem;
$card-shadow: 0 0px 0 1px rgba($black, 0.1);

// Box
$box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import '~bulma-timeline';

html, body, #app, .layout{
  min-height: 100vh;
}

.scrollable {
  max-height: 470px;
  overflow-y: auto;
}

.navbar {
  margin-bottom: 1rem;

  &-item {
    img {
      width: initial !important;
    }
  }
}

.navbar-start {
  .navbar-item {
    border-bottom: 2px solid transparent;

    &:hover,
    &.router-link-active {
        border-bottom-color: darken($primary, 20%);
    }
  }
}

.module {
  padding: 120px 0;

  &-title {
    margin-bottom: 30px;
  }
}

.mr-bottom {
  margin-bottom: 30px;
}

$direction: column, column-reverse, row, row-reverse;
$align: space-between, flex-start, flex-end, space-around, center;

.direction {
  @each $i in $direction {
    &-#{$i} {
      flex-direction: $i;
    }
  }
}

.justify {
  @each $i in $align {
    &-#{$i} {
      justify-content: $i;
    }
  }
}

.align {
  @each $i in $align {
    &-#{$i} {
      align-items: $i;
    }
  }
}

.p {
  @for $i from 1 to 100 {
    &t-#{$i} {
      padding-top: #{$i}px;
    }

    &b-#{$i} {
      padding-bottom: #{$i}px;
    }

    &r-#{$i} {
      padding-right: #{$i}px;
    }

    &l-#{$i} {
      padding-left: #{$i}px;
    }

    &d-#{$i} {
      padding: #{$i}px;
    }
  }
}

.m {
  @for $i from 1 to 100 {
    &t-#{$i} {
      margin-top: #{$i}px;
    }

    &b-#{$i} {
      margin-bottom: #{$i}px;
    }

    &r-#{$i} {
      margin-right: #{$i}px;
    }

    &l-#{$i} {
      margin-left: #{$i}px;
    }

    &g-#{$i} {
      margin: #{$i}px;
    }
  }
}

.sticky {
  @media screen and (min-width: $desktop){
    position: sticky;
  }
  @for $i from 1 to 100 {
    &.is--top#{$i} {
      top: #{$i}px;
    }
  }
}

.fixed {
  @media screen and (min-width: $desktop){
    position: fixed;
  }
  @for $i from 1 to 100 {
    &.is--top#{$i} {
      top: #{$i}px;
    }
  }
}

.relative {
  @media screen and (min-width: $desktop){
    position: relative;
  }
  @for $i from 1 to 100 {
    &.is--top#{$i} {
      top: #{$i}px;
    }
  }
}

.absolute {
  @media screen and (min-width: $desktop){
    position: absolute;
  }
  @for $i from 1 to 100 {
    &.is--top#{$i} {
      top: #{$i}px;
    }

    &.is--right#{$i} {
      right: #{$i}px;
    }
  }
}

.opacity {
  @for $i from 0 to 10 {
    &-#{$i} {
      opacity: #{calc($i / 10)};
    }
  }
}

.pointer {
  cursor: pointer;
}

.hover {
  &-border {
    &:hover {
      border: 2px solid darken($primary, 10);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter{
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
  display: none;
}

.border{
  &-b {
    &--blue{
      border-bottom: 1px solid $blue;
    }
    &--green{
      border-bottom: 1px solid $primary;
    }
    &--dark{
      border-bottom: 1px solid $dark;
    }
    &--light{
      border-bottom: 1px solid $light;
    }
  }

  &-r {
    &--blue{
      border-right: 1px solid $blue;
    }
    &--green{
      border-right: 1px solid $primary;
    }
    &--dark{
      border-right: 1px solid $dark;
    }
    &--light{
      border-right: 1px solid $light;
    }
  }
}

// Override styles

.menu-list {
  a {
    color: $white;

    &.is-active {
      background-color: $primary;
    }
  }
}

.navbar {
  margin-bottom: 0;

  &-item img {
    width: 175px;
  }
}

.fc-media-screen .fc-timegrid-event {
  position: relative !important;
}

.overflow {
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

span {
  &.icon svg {
    height: 1rem;
    width: 1rem;
  }
}
</style>