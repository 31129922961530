<template >
  <div class="">
    <!-- Projects actions -->
    <div
      class="is-flex justify-flex-end pr-20"
      v-if="$hasPermissionsByRole(projectCreationAllowedRoles)">
      <b-button
        type="is-primary"
        @click="$emit('showCreateProject')"
        icon-left="paintbrush-pencil">
        Crear nuevo proyecto
      </b-button>
    </div>

    <!-- Projects length -->
    <div class="is-flex justify-flex-start pl-4"
      v-if="total">
      <p class="has-text-right mr-2 has-text-weight-medium">
        Mostrando {{ total }} poyectos
      </p>
    </div>

    <!-- Without projects -->
    <div class="pr-60 pl-60 mt-5" v-if="!total">
      <b-message
        type="is-info">
        <p
          class="is-size-4 has-text-weight-semibold">
          <f-icon
            icon="fa-regular fa-circle-info"
            class="mr-2"
            size="xl" />
            <span>
              Aún no existen proyectos para esta empresa
            </span>
          </p>
      </b-message>
    </div>

    <!-- Projects -->
    <div v-else>
      <div
        class="projects-container "
      >
        <ProjectCard
          :project="project"
          @selection="projectSelected"
          style="width: 100%"
          v-for="(project, index) in filteredProjects"
          :key="`project-${index}`"
        />
      </div>
      <div class="px-5">
        <b-pagination
          :current.sync="current"
          :per-page="perPage"
          :total="total"
          order="is-right"
          :page-input="true"
          page-input-position="is-input-right"
          range-before="2"
          range-after="1"
          rounded
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          >
        </b-pagination>
      </div>
    </div>

  </div>

</template>

<script>
import ProjectCard from './ProjectCard.vue'
import { projectCreationAllowedRoles } from '@/lib/permissions'

export default{
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  components: {
    ProjectCard
  },
  data(){
    return{
      projectCreationAllowedRoles,
      current: 0,
      perPage: 10,
      total: 0,
      filteredProjects: []
    }
  },
  watch: {
    current(){
      this.filteredProjects = this.projects.slice((this.current - 1) * this.perPage, this.current * this.perPage)
    },
    projects(){
      this.total = this.projects.length
      this.current = 1
    }
  },
  methods: {
    projectSelected(projectId){
      this.$emit('selectProject', projectId)
    },
  },
  computed: {
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    }
  } 
}
</script>

<style lang="scss" scoped>
.projects-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
}
</style>