<template>
  <div class="container">
    <div class="card" style="height:fit-content;" >
      <div class="card-content">
        <div class="content">
          <div>
            <div v-if="((candidate.user || {} ).profile || {} ).psychometricTest && ((candidate.user || {} ).profile || {} ).psychometricTest.appliedAt">
              <div class="texto">
                {{ ((candidate.user || {} ).profile || {} ).psychometricTest.appliedAt | formatToDate }}
              </div>
            </div>
            <div v-else>
              <b-button
                v-if="((candidate.user || {} ).profile || {} ).psychometricTest"
                rounded
                expanded
                type="is-blue"
                @click="openDialogPsychometricDate ()">
                  Agregar fecha del test
              </b-button>
              <b-button
                v-else
                rounded
                expanded
                icon-left="file-export"
                type="is-blue"
                @click="sendPsychometricTest">
                  Enviar psicometría
              </b-button>
            </div>
          </div>
          <div>
            <b-button
              class="mt-2"
              rounded
              expanded
              icon-left="bolt"
              type="is-dark is-outlined"
              @click="invite"
              >
                Invitar candidato
            </b-button>
          </div>
          <div>
            <b-button
              class="mt-2"
              rounded
              expanded
              icon-left="hand"
              type="is-danger is-outlined"
              @click="openDialogRejectCandidate()"
              :disabled="candidate.status==='rejected'">
                Rechazar candidato
            </b-button>
          </div>
          <div class="columns mt-3">
            <div class="column" @click="wasSeenPrev">
              <b-button
                rounded
                expanded
                icon-left="arrow-left"
                type="is-blue is-outlined"
                tag="router-link"
                :to="{ name:'CandidateDetail' , params:{ id:(paginate.previous || {} )._id } , query:{ s:candidate.status } }"
                :disabled="!(paginate.previous || {} )._id">
                  Anterior
              </b-button>
            </div>
            <div class="column" @click="wasSeenNext">
              <b-button
                rounded
                expanded
                icon-right="arrow-right"
                type="is-blue is-outlined"
                tag="router-link"
                :to="{ name:'CandidateDetail' , params:{ id:(paginate.next || {} )._id } , query:{ s:candidate.status } }"
                :disabled="!(paginate.next || {} )._id">
                Siguiente
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :active.sync="psychometricDateModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <div class="modal-card" style="width: auto">
        <div class="modal-card-head">
          Prueba Psicométrica
        </div>
        <div class="modal-card-body">
          <div>
            <b-field label="Fecha de aplicación">
              <b-datepicker
                inline
                placeholder="Selecciona una fecha"
                icon="calendar-today"
                v-model="psychometricDateForm.appliedAt"
              />
            </b-field>
          </div>
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button
            type="is-primary"
            @click="applyPsychometricTest">
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <InviteCandidate
      :user="userSelected"
      :company-id="candidate.company?._id"
      :vacancy-id="candidate.vacancy?._id"
      ref="inviteCandidate"
    />

    <ReasonRejection
      v-if="candidate.vacancy?._id"
      :vacancy-id="candidate.vacancy._id"
      ref="reasonRejection"
    />
  </div>
</template>

<script>
import InviteCandidate from "@/components/modals/invite-candidate/InviteCandidate";
import ReasonRejection from "@/components/modals/ReasonRejection.vue";

export default {
  components: {
    InviteCandidate,
    ReasonRejection
  },
  props: {
    candidate: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      paginate: {},
      psychometricDateModal: false,
      psychometricDateForm: {
        appliedAt: new Date()
      },
      userSelected: {},
    }
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.session.user;
    },
  },
  mounted(){
    this.getPaginate()
  },
  watch: {
    async candidate() {
      this.getPaginate()
    }
  },
  methods: {
    async sendPsychometricTest () {
      this.isLoading = true
      try {
        const payload = { vacancyId: this.candidate.vacancy?._id };
        await this.$api.post(`/candidates/${this.candidate.user._id}/send-psychometric`, payload)
        this.$buefy.toast.open({
          message: 'El test se envió correctamente',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    invite() {
      this.userSelected = {
        recruiter: this.user,
        candidate: {
          id: this.candidate.user._id,
          ...this.candidate.user,
          application: {
            vacancy: {
              _id: this.candidate.vacancy._id,
            }
          }
        },
      }
      this.$refs.inviteCandidate.open();
    },
    openDialogRejectCandidate() {
      const candidate = this.candidate;

      this.$refs.reasonRejection.open(candidate.user._id, `${candidate.profile.firstName} ${candidate.profile.firstSurname}`);
    },
    async getPaginate () {
      this.isLoading = true
      try {
        const { data } = await this.$api.get(`/candidates/${ this.candidate._id }/paginate?status=${ this.candidate.status }`)
        this.paginate = data
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Hubo un error al cargar los candidatos',
          type: 'is-danger'
        })

      }
      this.isLoading = false
    },
    async wasSeenNext () {
      if(this.paginate.next.seen===false){
        this.isLoading = true
        try {
          await this.$api.post(`application/${this.paginate.next._id}/set-seen-true`)
        } catch (error) {
          this.$buefy.toast.open({
            message: 'Hubo un error al marcar como evaluado al candidato',
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    },
    async wasSeenPrev () {
      if(this.paginate.previous.seen===false){
        this.isLoading = true
        try {
          await this.$api.post(`application/${this.paginate.previous._id}/set-seen-true`)
        } catch (error) {
          this.$buefy.toast.open({
            message: 'Hubo un error al marcar como evaluado al candidato',
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    },
    openDialogPsychometricDate () {
      this.psychometricDateModal = true
    },
    closeDialogPsychometricDate () {
      this.psychometricDateModal = false
    },
    async applyPsychometricTest () {
      this.isLoading = true
      try {
        await this.$api.post(`/candidates/${this.candidate.user._id}/apply-psychometric`, this.psychometricDateForm)
        this.closeDialogPsychometricDate()
        this.$buefy.toast.open({
          message: 'Se agregó la fecha de aplicación psicométrica',
          type: 'is-success'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    closeDialogRejectCandidate () {
      this.rejectCandidateModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.card {
  width: 20rem;
  position: fixed;
  z-index: calc(99999999/2);
  bottom: 20px;
  right: 20px;
  left: auto;
  top: auto;
  border-radius: 15px;
  padding-top: 1.3%;
  div {
    margin-bottom: 0.5%;
  }
}

.texto {
  text-align: center;
  font-weight: bold;
  color: #0F486D;
  padding: 8px 0 8px;
}

@media only screen and (max-width: 650px) {
  .card {
    position: absolute;
    top: 100%;
    height: 6.8%;
    margin-top: 1rem;
    left: 2rem;
    right: auto;
  }

  .column {
    padding: 0.75rem 1rem 0rem 1rem;
  }

  .c {
    padding: 0rem 1rem 1rem 1rem;
  }
}

@media only screen and (min-width: 651px) {
  .card {
    height: 27%;
  }

  .column {
    padding: 0.75rem 1rem 0rem 1rem;
  }

  .c {
    padding: 0rem 1rem;
  }
}

@media only screen and (min-width: 771px) {
  .card {
    height: 21%;
  }

  .column {
    padding: 0.75rem;
  }
}
</style>
