export const modalities = ["Híbrido", "Presencial", "Home Office"];

export const availableModalities = {
  en: {
    "hybrid": "Hybrid",
    "on-site": "On-site",
    "home-office": "Home Office",
    "home-office-covid": "Home office due to COVID protocol"
  },
  mx: {
    "hybrid": "Híbrido",
    "on-site": "Presencial",
    "home-office": "Home Office",
    "home-office-covid": "Remoto por protocolo COVID",
  },
  es: {
    "hybrid": "Híbrido",
    "on-site": "Presencial",
    "home-office": "Home Office",
    "home-office-covid": "Remoto por protocolo COVID",
  },
}

export const durations = {
  en: {
    "3-months": "3 months",
    "5-months": "5 months",
    "6-months": "6 months",
    "9-months": "9 months",
    "12-months": "12 months",
    "15-months": "15 months",
    "18-months": "18 months",
    "21-months": "21 months",
    "24-months": "24 months",
  },
  mx: {
    "3-months": "3 meses",
    "5-months": "5 meses",
    "6-months": "6 meses",
    "9-months": "9 meses",
    "12-months": "12 meses",
    "15-months": "15 meses",
    "18-months": "18 meses",
    "21-months": "21 meses",
    "24-months": "24 meses",
  },
  es: {
    "3-months": "3 meses",
    "5-months": "5 meses",
    "6-months": "6 meses",
    "9-months": "9 meses",
    "12-months": "12 meses",
    "15-months": "15 meses",
    "18-months": "18 meses",
    "21-months": "21 meses",
    "24-months": "24 meses",
  },
};

export const academicLevels = {
  en: {
    "student": "Student",
    "recently-graduated": "Recently graduated",
    "experienced-graduated": "Experienced graduated",
  },
  mx: {
    "student": "Estudiante",
    "recently-graduated": "Recién graduado",
    "experienced-graduated": "Graduado con experiencia",
  },
  es: {
    student: "Estudiante",
    "recently-graduated": "Recién licenciado",
    "experienced-graduated": "Graduado con experiencia",
  },
};

export const excelLevels = {
  en: {
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",
  },
  mx: {
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",
  },
  es: {
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",
  },
};
//ky es value
export const requisitionSchemes = {
  en: {
    trainee: "Trainee",
    intern: "Intern",
    "other-services": "Other Services",
  },
  mx: {
    trainee: "Trainee",
    intern: "Becario",
    "other-services": "Otros servicios",
  },
  es: {
    trainee: "Trainee",
    intern: "Becario",
    "other-services": "Otros servicios",
  },
};

export const serviceTypes = {
  en: {
    traditional: "Traditional recruitment",
    alternative: "Alternative recruitment",
    ambos: "Both",
  },
  mx: {
    traditional: "Reclutamiento tradicional",
    alternative: "Reclutamiento alternativo",
    ambos: "Ambos",
  },
  es: {
    traditional: "Reclutamiento tradicional",
    alternative: "Reclutamiento alternativo",
    ambos: "Ambos",
  },
};
export const claimTypes = {
  en: {
    "on-demand": "On demand",
    "by-generation": "By generation",
  },
  mx: {
    "on-demand": "On demand",
    "by-generation": "Por generación",
  },
  es: {
    "on-demand": "On demand",
    "by-generation": "Por generación",
  },
};

export const knowledgeLevels = {
  en: {
    desirable: "Desirable",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",
    certificate: "Certificate",
  },
  mx: {
    desirable: "Deseable",
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",
    certificate: "Certificado",
  },
  es: {
    desirable: "Deseable",
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",
    certificate: "Certificado",
  },
};

export const semesterLevels = {
  mx: [
    { text: 'Primer semestre', value: 1 },
    { text: 'Segundo semestre', value: 2 },
    { text: 'Tercer semestre', value: 3 },
    { text: 'Cuarto semestre', value: 4 },
    { text: 'Quinto semestre', value: 5 },
    { text: 'Sexto semestre', value: 6 },
    { text: 'Séptimo semestre', value: 7 },
    { text: 'Octavo semestre', value: 8 },
    { text: 'Noveno semestre', value: 9 },
    { text: 'Décimo semestre', value: 10 },
    { text: 'Décimo primer semestre', value: 11 },
    { text: 'Décimo segundo semestre', value: 12 },
  ],
  en: [
    { text: 'First semester', value: 1 },
    { text: 'Second semester', value: 2 },
    { text: 'Third semester', value: 3 },
    { text: 'Fourth semester', value: 4 },
    { text: 'Fifth semester', value: 5 },
    { text: 'Sixth semester', value: 6 },
    { text: 'Seventh semester', value: 7 },
    { text: 'Eighth semester', value: 8 },
    { text: 'Ninth semester', value: 9 },
    { text: 'Tenth semester', value: 10 },
    { text: 'Eleventh semester', value: 11 },
    { text: 'Twelfth semester', value: 12 },
  ],
  es: [
    { text: 'Primer curso', value: 1 },
    { text: 'Segundo curso', value: 2 },
    { text: 'Tercer curso', value: 3 },
    { text: 'Cuarto curso', value: 4 },
    { text: 'Quinto curso', value: 5 },
    { text: 'Sexto curso', value: 6 },
  ],
};

export const killerQuestionOptions = {
  mx: {
    "short": "Respuesta corta",
    "long": "Respuesta larga",
    "single": "Opción única",
    "multiple": "Opción múltiple"
  },
  en: {
    "short": "Open-short",
    "long": "Open-long",
    "single": "Single-option",
    "multiple": "Multiple-option"
  },
  es: {
    "short": "Respuesta corta",
    "long": "Respuesta larga",
    "single": "Opción única",
    "multiple": "Opción múltiple"
  },
}

