<template>
  <div class="column is-7">
    <div class="columns">
      <div class="column is-5 is-flex is-justify-content-flex-end">
        <div class="mr-1" v-if="['superqueen', 'manager'].includes($isRole(user))">
          <b-taglist attached>
            <b-tag type="is-blue">Candidatos</b-tag>
            <b-tag type="is-dark"> {{ totals.totalU }} </b-tag>
          </b-taglist>
        </div>
        <div>
          <b-taglist attached>
            <b-tag type="is-blue">Aplicaciones</b-tag>
            <b-tag type="is-dark"> {{ totals.totalA }} </b-tag>
          </b-taglist>
        </div>
      </div>

      <div class="column is-7" v-if="authRoles.one.includes($isRole(user))">
        <b-progress
          size="is-small"
          :max="max">
          <template #bar>
            <b-progress-bar :value="totals.totalUforMonth" type="is-primary" show-value></b-progress-bar>
            <b-progress-bar :value="15000-totals.totalUforMonth" type="is-blue"></b-progress-bar>
            <b-progress-bar :value="2500" type="is-blue" show-value>¡15,000!</b-progress-bar>
          </template>
        </b-progress>
        <b-progress
          size="is-small"
          :max="maxx">
          <template #bar>
            <b-progress-bar :value="totals.totalAforMonth" type="is-primary" show-value></b-progress-bar>
            <b-progress-bar :value="20000-totals.totalAforMonth" type="is-blue"></b-progress-bar>
            <b-progress-bar :value="3250" type="is-blue" show-value>¡20,000!</b-progress-bar>
          </template>
        </b-progress>
        <b-progress v-if="authRoles.two.includes($isRole(user))" size="is-small" :max="totals.totalC+14">
          <template #bar>
            <b-progress-bar :value="totals.totalL" type="is-primary" show-value></b-progress-bar>
            <b-progress-bar :value="totals.totalC-totals.totalL" type="is-blue"></b-progress-bar>
            <b-progress-bar :value="14" type="is-blue" show-value>¡{{totals.totalC}}!</b-progress-bar>
          </template>
        </b-progress>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';

import api from '@/api'
import store from '@/store'

const max = ref(17500)
const maxx = ref(23250)
const totals = reactive({
  totalA: 0,
  totalAforMonth: 0,
  totalU: 0,
  totalUforMonth: 0,
  totalC: 0,
  totalL: 0
})
const authRoles = reactive({
  one: [ 'superqueen', 'manager', 'recruiter-manager', 'recruiter', 'designer-manager', 'designer' ],
  two: [ 'superqueen', 'manager','designer-manager', 'designer' ],
  three: [ 'manager',
    'recruiter-manager','recruiter',
    'designer-manager', 'designer',
    'account-manager','account' ],
})

const user = computed(function () {
  return store.state.session.user
})

const getTotals = async function () {
  const response = await api.getTotals()
  if (response) {
    totals.totalA = response.data.totalA
    totals.totalAforMonth = response.data.totalAforMonth
    totals.totalU = response.data.totalU
    totals.totalUforMonth = response.data.totalUforMonth
    totals.totalC = response.data.totalC
    totals.totalL = response.data.totalL
  }
}

onMounted(function () {
  getTotals()
})

</script>

<style lang="scss" scoped>

</style>