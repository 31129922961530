<template>
  <div class="column is-8">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title is-size-4 has-text-weight-semibold">
          <div>
            Landing Page personalizada
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-5 mb-5">
      <div class="card-content columns is-multiline">
        <div class="column is-12">
          <b-field label="Ruta de Landing Page">
            <div
              class="columns is-multiline"
            >
              <b-input
                v-model="landing.customUrl"
                placeholder="Url la landing page"
                type="text"
                class="column is-12"
                @blur="updateCustomUrl"
              />
              <div
                class="column is-12 is-flex is-align-items-center pt-0 pb-2"
              >
                <b-icon 
                  size="is-small" 
                  icon="circle-info" 
                  type="is-warning"
                />  
                <p class="ml-2">Recuerda que el directorio de componentes de la LP debe estar relacionado al nombre de la empresa (slug).</p>
              </div>
            </div>
          </b-field>
        </div>
      </div>
    </div>
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>

export default {
  props: {
    defaultLanding: {
      template: String,
      sections : Array,
      colors: Object,
      url: String,
      customUrl: String
    },
  },
  data() {
    return {
      isLoading: false,
      landing: {
        template: 'basic',
        sections: [],
        colors: { title: '#42CBB6', benefits: '#42CBB6' },
        url: '',
        customUrl: '',
      },
    }
  },
  watch: {
    defaultLanding: {
      handler: function (val) {
        this.landing = { ...val }
      }
    },
    'landing.customUrl': {
      handler(val) {
        if (val.includes(' ')){
          this.landing.customUrl = val.replace(' ', '-')
        }
      }
    }
  },
  mounted() {
    this.landing = { ...this.defaultLanding }
  },
  computed: {
    companyId () {
      return this.$route.params.id
    }
  },
  methods: {
    async updateCustomUrl() {
      this.$emit('updateCustomUrl', this.landing.customUrl)
    }
  }
}
</script>

<style>
</style>
