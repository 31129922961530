<template>
  <SimpleCollapse
    title="Perfiles de vacantes"
    :isLoading="isLoading"
    description="Información general de las vacantes que se solicitan."
    icon="fa-regular fa-memo-circle-info"
    :data="data"
    @open="getData"
    ref="collapse"
  >
  <template #content="{ data }">
    <div class="columns is-multiline">
      <SimplePie
        class="column is-6"
        :pieData="data.englishLevel"
        title="Nivel de inglés"
        :headers="['Nivel', 'Cantidad']"
        chart-height="250px"
      />
      <SimplePie
        class="column is-6"
        :pieData="data.excelLevel"
        title="Nivel de excel"
        :headers="['Nivel', 'Cantidad']"
        chart-height="250px"
      />
    </div>

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.scholarship"
      title="Beca"
      :headers="['Beca', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.careers"
      title="Carreras"
      :headers="['Carrera', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.location"
      title="Ubicación"
      :headers="['Ciudad', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.duration"
      title="Duración"
      :headers="['Duración', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.schemaType"
      title="Tipo de esquema"
      :headers="['Tipo de esquema', 'Cantidad']"
      :isStatSearchable="false"
      chartHeight="300px"
    />

  </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from './SimpleCollapse.vue';
import SimplePie from './SimplePie.vue';
import PieTable from './PieTable.vue';

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimplePie,
    SimpleCollapse,
    PieTable
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        location: {},
        duration: {},
        scholarship: {},
        schemaType: {},
        careers: {},
        englishLevel: {},
        excelLevel: {}
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.separation-line{
  margin: 1.5rem 0;
  height: 2px;
}
</style>
