<template>
  <div class="simple-card">
    <div class="is-flex title is-4 has-text-primary is-align-items-center is-justify-content-space-between">
      <p class="">{{ title }}</p>
      <f-icon :icon="icon"/>
    </div>
    <div>
      <div
        v-if="!isLoading"
      >
        <slot name="content"
          v-bind="$props"
        ></slot>
      </div>
      <div
        class="loading-wrapper"
        v-else
      >
        <b-loading 
          :is-full-page="false"
          :active="isLoading" 
        />
      </div>
    </div>

    <div 
      class="description"
      v-if="description"
    >
      <f-icon icon="fa-regular fa-info-circle" class="has-text-primary"/>
      <span>{{ description }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fa-regular fa-chart-pie'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
.simple-card {
  margin-bottom: 2rem;
  width: 100%;
  height: fit-content;
  border-radius: 0.8rem;
  box-shadow: 0 0 5px 0 #dcdcdc;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.7rem;

  .description{
    margin-top: 2rem;
    border-top: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    gap: 0.5rem;
  }
}

.loading-wrapper{
  position: relative;
  min-height: 100px;
}
</style>
