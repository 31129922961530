<template>
  <div class="card mt-5 mb-5">
    <div class="card-content columns is-multiline">
      <div class="column is-12">
        <b-field label="Ruta de Landing Page">
          <div class="columns is-multiline">
            <b-input 
              v-model="customUrl" 
              placeholder="Url la landing page" type="text" class="column is-12" 
              @blur="updateCustomUrl"
            />
            <div class="column is-12 is-flex is-align-items-center pt-0 pb-2">
            </div>
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultCustomUrl: {
      type: String,
    },
  },
  data() {
    return {
      customUrl: ''
    }
  },
  mounted() {
    this.customUrl = this.defaultCustomUrl;
  },
  watch: {
    defaultCustomUrl: {
      handler: function (val) {
        this.customUrl = val
      }
    },
    customUrl: {
      handler(val) {
        if (val.includes(' ')){
          this.customUrl = val.replace(' ', '-')
        }
      }
    }
  },
  methods: {
    updateCustomUrl() {
      this.$emit('updateLandingProperty', this.customUrl, 'customUrl')
    },
  }
}
</script>

<style>
</style>
