<template>
  <div class="page mb-2">
    <h2>Evaluación personalizada</h2>

    <table class="block">
      <tr>
        <th
          v-if="customEvaluation.applicationLinkVideo"
        >
          <div class="label">Video de presentación:</div>
        </th>
        <th
          v-if="customEvaluation.applicationFile"
        >
          <div class="label">Archivo de aplicación del candidato:</div>
        </th>
      </tr>

      <tr>
        <td
          v-if="customEvaluation.applicationLinkVideo"
        >
          <div class="text">
            <a
              :href="customEvaluation.applicationLinkVideo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Abrir video de presentación
            </a>
          </div>
        </td>
        <td
          v-if="customEvaluation.applicationFile"
        >
          <div class="text">
            <a
              :href="customEvaluation.applicationFile?.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver archivo de aplicación
            </a>
          </div>
        </td>
        
      </tr>
    </table>

    <table class="block">
      <tr>
        <th>
          <div class="label">Comentarios adicionales:</div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="text">{{ customEvaluation.aditionalComments || 'Sin comentarios adicionales' }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    customEvaluation: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #0F486D;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.label {
  font-size: 10px;
  color: #42CBB6;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
}

.page {
  margin-bottom: 30px !important;
}

.block {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>