export const CANDIDATE_PROFILE_DIC = {
    "firstName": "Nombre",
    "firstSurname": "Primer apellido",
    "lastSurname": "Segundo apellido",
    "birthdate": "Fecha de nacimiento",
    "gender": "Género",
    "phoneCode": "Código de teléfono",
    "phone": "Teléfono",
    "postalCode": "Código postal",
    "state": "Estado",
    "city": "Ciudad",
    "country": "País",
    "originCountry": "País de origen",
    "email": "Correo electrónico",
    "university": "Universidad",
    "campus": "Campus",
    "career": "Carrera",
    "studyArea": "Área de estudio",
    "currentSemester": "Semestre actual",
    "academicLevel": "Nivel académico",
    "graduateDate": "Fecha de graduación",
    "degreeDate": "Fecha de titulación",
    "excelLevel": "Nivel de Excel",
    "englishLevel": "Nivel de inglés",
    "mondaysSchedule": "Horario de los lunes",
    "tuesdaysSchedule": "Horario de los martes",
    "wednesdaysSchedule": "Horario de los miércoles",
    "thursdaysSchedule": "Horario de los jueves",
    "fridaysSchedule": "Horario de los viernes",
    "saturdaysSchedule": "Horario de los sábados",
    "interestOne": "Interés uno",
    "interestTwo": "Interés dos",
    "interestThree": "Interés tres",
    "skills": "Habilidades",
    "resume": "Curriculum",
    "portfolio": "Portafolio",
    "curp": "CURP",
    "pronoun": "Pronombre",
    "hasDisability": "¿Tiene discapacidad?",
    "disability": "Discapacidad",
    "otherDisability": "Otra discapacidad",
    'status': 'Estatus',
}

