import Home from '@/layouts/Home'
import Promeritum from '@/layouts/Promeritum'

import Companies from '@/screens/Companies'
import Dashboard from '@/screens/Dashboard'
import CompaniesArchived from '@/screens/CompaniesArchived'
import Wallet from '@/screens/CandidateWallet'
import Users from '@/screens/Users'

import CompanyDetail from '@/screens/CompanyDetail'

import CompanyUsers from '@/screens/CompanyUsers'
import CompanyUsersContact from '@/screens/CompanyUsersContact'
import LandingPage from '@/screens/LandingPage'
import CompanySettings from '@/screens/CompanySettings'
import Vacancies from '@/screens/Vacancies'
import Scholars from '@/screens/Scholars'
import AccountHome from '@/screens/AccountHome'
import AccountCandidateDetail from '@/screens/AccountCandidateDetail'

// Pantalla de proyectos
import Projects from '@/screens/Projects'
import store from '@/store'

const cleanProject = (to, from, next) => {
  store.dispatch('deleteProjectName')
  next(); // Continúa con la navegación
}


const company = [
  {
    path: '/empresa/:id',
    component: Promeritum,
    name: 'Company',
    redirect: { name: 'CompanyDetail' },
    children: [
      {
        path: '',
        component: CompanyDetail,
        name: 'CompanyDetail',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager',]
        },
        beforeEnter: cleanProject
      },
      {
        path: 'proyectos',
        component: Projects,
        name: 'Projects',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter-manager', 'recruiter']
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('deleteProjectName')
          next()
        }
      },
      {
        path: 'landing',
        component: LandingPage,
        name: 'LandingPage',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'designer', 'designer-manager', 'manager']
        },
        beforeEnter: cleanProject
      },
      {
        path: 'usuarios',
        component: CompanyUsers,
        name: 'CompanyUsers',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'recruiter-manager', 'account-manager']
        },
        beforeEnter: cleanProject
      },
      {
        path: 'usuarios-de-empresa',
        component: CompanyUsersContact,
        name: 'CompanyUsersContact',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'recruiter', 'recruiter-manager']
        },
        beforeEnter: cleanProject
      },
      {
        path: 'configuracion',
        component: CompanySettings,
        name: 'CompanySettings',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager']
        },
        beforeEnter: cleanProject
      },
      {
        path: 'vacantes',
        name: 'Vacancies',
        component: Vacancies,
        beforeEnter: cleanProject
      },
      {
        path: 'becarios',
        component: Scholars,
        name: 'ScholarActual',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter']
        },
        beforeEnter: cleanProject
      },
      {
        path: 'cuentas',
        component: AccountHome,
        name: 'AccountHome',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'account-manager', 'account']
        },
        beforeEnter: cleanProject
      }
    ],
    meta: { requireAuth: true },
  },
  {
    path: '/becario',
    component: {
      render: r => r('router-view')
    },
    meta: { requireAuth: true },
    children: [
      {
        path: ':id',
        name: 'AccountCandidateDetail',
        component: AccountCandidateDetail,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'account-manager', 'account']
        },
        beforeEnter: cleanProject
      }
    ]
  },
  {
    path: '/',
    component: Home,
    name: 'Home',
    redirect: { name: 'Companies' },
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager', 'account-manager', 'account']
    },
    children: [
      {
        path: 'empresas',
        component: Companies,
        name: 'Companies',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'account', 'account-manager'],
        },
      },
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'Dashboard',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'recruiter-manager', 'recruiter'],
        },
      },
      {
        path: 'empresas-archivadas',
        component: CompaniesArchived,
        name: 'CompaniesArchived',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager'],
        },
      },
      {
        path: 'cartera-candidatos',
        component: Wallet,
        name: 'Wallet',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager'],
        },
      },
      {
        path: 'usuarios',
        component: Users,
        name: 'Users',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'manager', 'recruiter-manager', 'designer-manager'],
        },
      },
    ]
  },
]

export default company