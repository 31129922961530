<template>
  <div class="column is-12 m-0 pb-0">
    <div class="card">
      <div class="card-content">
        <div class="is-flex is-flex-direction-column">
          <h4 class="title is-size-6 mb-0">Tipo de Landing Page</h4>

          <b-select class="mt-4 mb-2" placeholder="Selecciona un tipo de landing page" expanded :value="defaultType"
            @input="$emit('updateTemplate', $event)">
            <option value="basic">Básica</option>
            <option value="custom">Personalizada</option>
            <option value="multimedia">Multimedia</option>
          </b-select>

          <div v-if="!isLoading" class="mb-5 pb-3">
            <span class="has-text-grey" v-if="lastUpdate">
              <f-icon icon="fa-regular fa-circle-check" class="has-text-success" />
              Actualizado
              {{ lastUpdate | ago }}
            </span>
            <span v-else>
              <f-icon icon="fa-regular fa-circle-xmark" class="has-text-warning" />
              Sin cambios
            </span>
          </div>
          <div class="has-text-grey mb-5" v-else>
            Guardando...
          </div>

          <b-button tag="a" target="_blank" :href="urlLanding" type="is-info" icon-right="arrow-right"
            v-if="!isLoading">
            Ver landing page
          </b-button>
          <div class="loading-wrapper" v-else>
            <b-loading :active="isLoading" :is-full-page="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultType: {
      type: String,
      default: "basic",
    },
    lastUpdate: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    urlLanding() {
      return `${process.env.VUE_APP_PUBLIC_URL}/company/${this.$route.params.id}`;
    },
  },
  methods: {
    updateMediaSection(mediaSection) {
      this.$emit('updateMediaSection', mediaSection);
    },
  },
};
</script>
<style scoped>
.loading-wrapper {
  position: relative;
  min-height: 50px;
}
</style>
