<template>
  <div>
    <div>
      <div class="title is-4 has-text-blue mb-4">{{ feedbackTranslation.companyComments }}</div>
    </div>
    <div
      v-if="companyInterviewFeedback"
    >
      {{ companyInterviewFeedback }}
    </div>
    <div
      v-else
    >
    {{ feedbackTranslation.noCompanyComments  }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyInterviewFeedback: {
      required: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    feedbackTranslation() {
        return this.$t("screens.candidate.detail");
    }
  },
};
</script>

<style lang="scss" scoped>

</style>