<template>
  <div class="card" v-if="section.name">
    <!-- Section name -->
    <div class="card-header">
      <div class="column">
        <div class="columns">
          <div class="column">
            Header
          </div>
        </div>
      </div>
    </div>
    <!-- Section content -->
    <div class="card-content">
      <!-- Section titles -->
      <div class="columns">
        <div class="column">
          <b-field label="Slogan">
            <b-input 
              v-model="section.header.title" 
              placeholder="Slogan" 
              type="text" 
              @blur="updateSection"
            />
          </b-field>
          <b-field label="Subtítulo">
            <b-input 
              v-model="section.header.subtitle" 
              placeholder="Subtítulo" 
              type="text"
              @blur="updateSection"
            />
          </b-field>
          <b-field label="Descripción">
            <b-input 
              v-model="section.header.description" 
              placeholder="Descripción en el banner"
              type="textarea"
              @blur="updateSection"
            />
          </b-field>
        </div>
        <!-- Section image -->
        <div class="column is-4">
          <b-field v-if="!isLoading">
            <b-upload v-model="section.header.file" drag-drop accept="image/*"
              @input="uploadLocalImage($event, section)">
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large">
                    </b-icon>
                  </p>
                  <p>Da click o arrastra el archivo que deseas subir</p>
                  <p>La imagen debe medir 590px x 684px</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="loading-wrapper" v-else>
            <b-loading :active="isLoading"  :is-full-page="false"/>
          </div>
        </div>
      </div>
      <div class="tags" v-if="section.header.image?.url">
        <span class="tag is-primary">
          {{ section.header.image.url.substring(77) }}
          <button class="delete is-small" type="button" @click="deleteLocalImage(section)">
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
    uploadImage: {
      type: Function,
    },
    deleteImage: {
      type: Function,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = { ...this.defaultSection };
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = { ...val }
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
    async uploadLocalImage(e, section){
      this.isLoading = true;
      const file = e;
      const fileIdentifier = section.name;
      const fileMetadata = section[section.name].image;
      section[section.name].image = await this.uploadImage(file, fileIdentifier, fileMetadata);

      this.updateSection();
      this.isLoading = false;
    },
    async deleteLocalImage(section){
      this.isLoading = true;
      section[section.name].image = await this.deleteImage(section[section.name].image.key);

      this.updateSection();
      this.isLoading = false;
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
