<template>
  <vue-draggable-resizable
    class="media-source"
    :resizable="true"
    :x="multimediaSource.coordinates.x"
    :y="multimediaSource.coordinates.y"
    :h="multimediaSource.sizes.height"
    :w="multimediaSource.sizes.width"
    @dragstop="updateMultimediaSource"
    @resizestop="updateMultimediaSource"
    @activated="$emit('activeMediaSource')"
    :enable-native-drag="false"
  >
    <!-- Image -->
    <img
      :src="multimediaSource.source.url"
      :alt="`multimedia-${multimediaSource._id}`"
      class="fit-box"
      v-if="multimediaSource.type == 'image' && multimediaSource.source.url.length"
    />

    <div
      v-else-if="multimediaSource.type == 'image' && !getPlatformIcon(multimediaSource.source.url)"
      class="fit-box image-blank"
    >
      Agrega una imagen
    </div>

    <!-- Video -->
    <div
      :alt="`multimedia-${multimediaSource._id}`"
      class="fit-box video-and-embeded"
      v-if="multimediaSource.type == 'video' && multimediaSource.source.url.length"
    >
      <img :src="getPlatformIcon(multimediaSource.source.url)" alt="" class="fit-box video-and-embeded">
    </div>

    <div
      v-else-if="multimediaSource.type == 'video' && !getPlatformIcon(multimediaSource.source.url)"
      class="fit-box youtube-blank"
    >
      Agrega un video
    </div>

    <!-- Embeded -->
    <div
      :alt="`multimedia-${multimediaSource._id}`"
      class="fit-box video-and-embeded "
      v-if="multimediaSource.type == 'embeded' && multimediaSource.source.url.length"
    >
      <img :src="getPlatformIcon(multimediaSource.source.url)" alt="" class="video-and-embeded"/>
    </div>

    <div
      v-else-if="multimediaSource.type == 'embeded' && !getPlatformIcon(multimediaSource.source.url)"
      class="fit-box embeded-blank"
    >
      Agrega un TikTok o Instagram Post
    </div>
  </vue-draggable-resizable>
</template>

<script>
export default {
  props: {
    defaultMultimediaSource: {
      type: Object,
      default: () => {},
    },
    containerSizes: {
      type: Object
    }
  },
  data() {
    return {
      multimediaSource: {
        coordinates: {},
        sizes: {},
        source: {
          url: ""
        },
      },
    };
  },
  watch: {
    defaultMultimediaSource: {
      handler(val) {
        if (val) {
          this.updateDraggableResizable()
        }
      },
    },
    containerSizes: {
      handler(val) {
        if (val) {
          this.updateDraggableResizable()
        }
      },
    },
  },
  methods: {
    updateDraggableResizable(){
      this.multimediaSource = {...this.defaultMultimediaSource}
      this.multimediaSource.coordinates = {
        x: this.getPercentNumber(this.multimediaSource.coordinates.x) * this.containerSizes.width / 100,
        y: this.getPercentNumber(this.multimediaSource.coordinates.y) * this.containerSizes.height / 100,
      }
      this.multimediaSource.sizes = {
        width: this.getPercentNumber(this.multimediaSource.sizes.width) * this.containerSizes.width / 100,
        height: this.getPercentNumber(this.multimediaSource.sizes.height) * this.containerSizes.height / 100,
      }
    },
    updateMultimediaSource(x, y, z, w) {
      const auxMultimediaSource = {...this.multimediaSource}

      auxMultimediaSource.coordinates = {
        x: this.getPercentage(x / this.containerSizes.width * 100),
        y: this.getPercentage(y / this.containerSizes.height * 100),
      }

      if (z !== undefined && w !== undefined)
        auxMultimediaSource.sizes = {
          width: this.getPercentage(z / this.containerSizes.width * 100),
          height: this.getPercentage(w / this.containerSizes.height * 100),
        }
      else
        auxMultimediaSource.sizes = {
          width: this.getPercentage(auxMultimediaSource.sizes.width / this.containerSizes.width * 100),
          height: this.getPercentage(auxMultimediaSource.sizes.height / this.containerSizes.height * 100),
        }
      this.$emit('updateMultimediaSource', auxMultimediaSource)
    },
    getPercentNumber(percentage){
      return Number(percentage.replace('%', ''))
    },
    getPercentage(number){
      return `${number.toFixed(2)}%`
    },
    getPlatformIcon(url) {
      if (!url) return;
      let plaformIcon = '';

      // Making youtube template video
      if (url?.includes("youtu")) {
        plaformIcon = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_4N37TIgWC_QLpspNwGddZH8DhzljeYMFnA&s'
      }
      // Making tiktok template video
      if (url?.includes("tiktok")) {
        plaformIcon = 'https://static.vecteezy.com/system/resources/previews/031/737/228/non_2x/tiktok-logo-tiktok-app-social-media-icons-free-png.png'
      }
      // Making instagram template video
      if (url?.includes("instagram")) {
        plaformIcon = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/1200px-Instagram-Icon.png'
      }

      return plaformIcon
    },
  },
};
</script>

<style scoped lang="scss">
.media-source {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
}
.fit-box {
  width: 100%;
  height: 100%;
}
.image-blank{
  background-color: rgba(255, 255, 255, 0.629);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
}
.youtube-blank {
  background-color: rgba(196, 48, 48, 0.629);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  text-align: center;
}
.embeded-blank {
  background: linear-gradient(to top, red, gold);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  text-align: center;
}
.video-and-embeded {
  border-radius: 4px;
  background-color: white;
  max-width: 100%;
  max-height: 100%;
}
</style>
