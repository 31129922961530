<template>
  <div>
    <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
			<div class="title is-4 mb-0 has-text-blue">
				{{ customEvaluationTranslation.customEvaluation }}
			</div>

      <a
        class="ml-2"
        @click="$emit('editCustomEvaluation')"
      >
        <b-tooltip
          animated
          class="is-flex is-align-items-end"
          :label="`Editar evaluación personalizada`"
          position="is-top"
          type="is-success"
        >
          <b-icon
            b-icon
            class="icons"
            icon="pen-to-square"
          ></b-icon>
        </b-tooltip>
      </a>
		</div>

    <!-- Application url video -->
    <div>
      <div class="label">
        {{ customEvaluationTranslation.urlVideo }}
      </div>
      <div v-if="customEvaluation.applicationLinkVideo">
        <a
          :href="customEvaluation.applicationLinkVideo"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ customEvaluation.applicationLinkVideo }}
        </a>
      </div>
      <div v-else>
        {{ customEvaluationTranslation.noUrlVideo }}
      </div> 
    </div>

    <!-- Application file -->
    <div>
      <hr/>
      <div class="label">
        {{ customEvaluationTranslation.uploadFile }}
      </div>
      <div v-if="customEvaluation.applicationFile">
        <a
          :href="customEvaluation.applicationFile.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ customEvaluation.applicationFile.name }}
        </a>
      </div>
      <div v-else>
        {{ customEvaluationTranslation.noUploadFile }}
      </div>
    </div>

    <!-- Aditional comments -->
    <div>
      <hr/>
      <div class="label">
        {{ customEvaluationTranslation.aditionalComments }}
      </div>
      <div v-if="customEvaluation.aditionalComments">
        {{ customEvaluation.aditionalComments }}
      </div>
      <div v-else>
        {{ customEvaluationTranslation.noAditionalComments }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    profile:{
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
    };
  },
  computed:{
    customEvaluationTranslation() {
      return this.$t("screens.candidate.detail");
    },
    customEvaluation() {
      return {
        applicationLinkVideo: this.profile.applicationLinkVideo || "",
        applicationFile: this.profile.applicationFile || null,
        aditionalComments: this.profile.aditionalComments || "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>