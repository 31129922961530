// Getting provinces and municipalities
import provinces from "@/lib/ES/provinces";
import municipalities from "@/lib/ES/municipalities";

export const genders = {
  en: ["Masculine", "Feminine", "Prefer not to say"],
  mx: ["Masculino", "Femenino", "Prefiero no mencionar"],
  es: ["Masculino", "Femenino", "Prefiero no mencionar"],
};

export const pronouns = {
  en: ["He", "She", "Ze", "Xe", "Prefer not to say"],
  mx: ["Él", "Ella", "Elle", "Prefiero no mencionar"],
  es: ["Él", "Ella", "Elle", "Prefiero no mencionar"],
};

export const disabilities = {
  en: [
    "Motor disability",
    "Visual disability",
    "Hearing disability",
    "Psychosocial disability",
    "Intellectual disability",
    "Other",
  ],
  mx: [
    "Discapacidad motriz",
    "Discapacidad visual",
    "Discapacidad auditiva",
    "Discapacidad psicosocial",
    "Discapacidad intelectual",
    "Otra",
  ],
  es: [
    "Discapacidad motriz",
    "Discapacidad visual",
    "Discapacidad auditiva",
    "Discapacidad psicosocial",
    "Discapacidad intelectual",
    "Otra",
  ],
};

export const studyAreas = {
  en: [
    "STEM",
    "Biological and health sciences",
    "Social sciences",
    "Arts and humanities",
    "Economic - Administrative",
    "Other",
  ],
  mx: [
    "Físico-matemático e Ingenierias",
    "Ciencias biológicas y de la salud",
    "Ciencias sociales",
    "Artes y humanidades",
    "Económico - Administrativos",
    "Otros",
  ],
  es: [
    "Físico-matemático e Ingenierias",
    "Ciencias biológicas y de la salud",
    "Ciencias sociales",
    "Artes y humanidades",
    "Económico - Administrativos",
    "Otros",
  ],
};

export const semesters = {
  en: [
    { text: "First semester", value: 1 },
    { text: "Second semester", value: 2 },
    { text: "Third semester", value: 3 },
    { text: "Fourth semester", value: 4 },
    { text: "Fifth semester", value: 5 },
    { text: "Sixth semester", value: 6 },
    { text: "Seventh semester", value: 7 },
    { text: "Eighth semester", value: 8 },
    { text: "Ninth semester", value: 9 },
    { text: "Tenth semester", value: 10 },
    { text: "Eleventh semester", value: 11 },
    { text: "Twelfth semester", value: 12 },
  ],
  mx: [
    { text: "Primer semestre", value: 1 },
    { text: "Segundo semestre", value: 2 },
    { text: "Tercer semestre", value: 3 },
    { text: "Cuarto semestre", value: 4 },
    { text: "Quinto semestre", value: 5 },
    { text: "Sexto semestre", value: 6 },
    { text: "Séptimo semestre", value: 7 },
    { text: "Octavo semestre", value: 8 },
    { text: "Noveno semestre", value: 9 },
    { text: "Décimo semestre", value: 10 },
    { text: "Décimo primer semestre", value: 11 },
    { text: "Décimo segundo semestre", value: 12 },
  ],
  es: [
    { text: "Recién graduado", value: 0 },
    { text: "Primer curso", value: 1 },
    { text: "Segundo curso", value: 2 },
    { text: "Tercer curso", value: 3 },
    { text: "Cuarto curso", value: 4 },
    { text: "Quinto curso", value: 5 },
    { text: "Sexto curso", value: 6 },
  ],
};

export const levels = {
  en: ["Basic", "Intermediate", "Advanced"],
  mx: ["Básico", "Intermedio", "Avanzado"],
  es: ["Básico", "Intermedio", "Avanzado"],
};

export const englishLevels = {
  en: ["Basic", "Intermediate", "Advanced", "Native"],
  mx: ["Básico", "Intermedio", "Avanzado", "Nativo"],
  es: ["Básico", "Intermedio", "Avanzado", "Nativo"],
};
export const excelLevels = {
  en: ["Basic", "Intermediate", "Advanced", "Native"],
  mx: ["Básico", "Intermedio", "Avanzado", "Nativo"],
  es: ["Básico", "Intermedio", "Avanzado", "Nativo"],
};

export const academicLevels = {
  en: {
    student: "Student",
    "recently-graduated": "Recently graduated",
    "experienced-graduated": "Experienced graduated",
  },
  mx: {
    student: "Estudiante",
    "recently-graduated": "Recién graduado",
    "experienced-graduated": "Graduado con experiencia",
  },
  es: {
    student: "Estudiante",
    "recently-graduated": "Recién licenciado",
    "experienced-graduated": "Graduado con experiencia",
  },
};

export const vacancySources = {
  en: [
    "Facebook",
    "LinkedIn",
    "Instagram",
    "University",
    "A friend",
    "On the Pro Meritum platform",
    "Other",
  ],
  mx: [
    "Facebook",
    "LinkedIn",
    "Instagram",
    "Universidad",
    "Un conocido",
    "En la plataforma de Pro Meritum",
    "Otro",
  ],
  es: [
    "Facebook",
    "LinkedIn",
    "Instagram",
    "Universidad",
    "Un conocido",
    "En la plataforma de Pro Meritum",
    "Otro",
  ],
};

export const defaultPhoneCode = {
  en: "+1",
  mx: "+52",
  es: "+34",
};

export function findSpainProvince(postalCode) {
  try {
    return provinces.find((province) => province.code === postalCode).province;
  } catch (error) {
    return null;
  }
}

export function filterMunicipalities(provinceCode) {
  return municipalities
    .filter((item) => item.provinceCode === provinceCode.slice(0, 2))
    .map((item) => item.municipality);
}
