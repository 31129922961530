<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-full is-flex is-align-content-center">
        <div class="title is-4 mb-0 has-text-blue">
          {{ academicDataTranslation.academicProfile }}
        </div>
        <!-- Botón de Edición -->
        <a v-if="canEditProfile" class="ml-2" @click="$emit('editAcademicProfile')">
          <b-tooltip animated class="is-flex is-align-items-end" label="Editar perfil acassssdémico" position="is-top"
            type="is-success">
            <b-icon class="icons" icon="pen-to-square"></b-icon>
          </b-tooltip>
        </a>
      </div>
      <!-- University -->
      <div class="column is-full">
        <div class="label">
          {{ academicDataTranslation.university }}
        </div>
        <div class="overflow-ellipsis is-capitalized">
          {{ profile.university }}
        </div>
      </div>

      <!-- Campus -->
      <div class="column is-full">
        <div class="label">
          {{ academicDataTranslation.campus }}
        </div>
        <div class="overflow-ellipsis is-capitalized">
          {{ profile.campus }}
        </div>
      </div>

      <!-- Career -->
      <div class="column is-full is-capitalized">
        <div class="label">
          {{ academicDataTranslation.career }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.career }}
        </div>
      </div>

      <!-- Study Area -->
      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.studyArea }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.studyArea }}
        </div>
      </div>

      <!-- Academic Level -->
      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.academicLevel }}
        </div>
        <div class="overflow-ellipsis">
          {{ academicLevels[profile.academicLevel[0]] }}
        </div>
      </div>

      <!-- Current Semester -->
      <div v-if="profile.academicLevel.includes('student')" class="column is-4">
        <div class="label">
          {{ academicDataTranslation.currentSemester }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.currentSemester }}
        </div>
      </div>

      <!-- Graduate Date -->
      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.graduateDate }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.graduateDate | formatToDateMonth }}
        </div>
      </div>

      <!-- Degree Date -->
      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.degreeDate }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.degreeDate | formatToDateMonth }}
        </div>
      </div>

      <!-- School Schedule -->
      <div class="column is-full">
        <div class="label">
          {{ academicDataTranslation.schoolSchedule }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.monday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.mondaysSchedule ?? "Sin asignar" }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.tuesday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.tuesdaysSchedule ?? "Sin asignar" }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.wednesday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.wednesdaysSchedule ?? "Sin asignar" }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.thursday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.thursdaysSchedule ?? "Sin asignar" }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.friday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.fridaysSchedule ?? "Sin asignar" }}
        </div>
      </div>

      <div class="column is-4">
        <div class="label">
          {{ academicDataTranslation.saturday }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.saturdaysSchedule ?? "Sin asignar" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { academicLevels } from '@/lib/vacancyDictionaries'

export default {
  props: {
    profile: {
      default: () => ({}),
      required: true
    },
    canEditProfile : Boolean
  },
  data() {
    return {
      academicLevels,
    };
  },
  computed: {
    academicDataTranslation() {
      return this.$t("screens.candidate.detail");
    },
  },
};
</script>

<style lang="scss" scoped></style>