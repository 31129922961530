var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"relative has-text-white"},[(_vm.$hasPermissionsByRole(_vm.companyCreationEditionRoles))?_c('div',[_c('b-dropdown',{staticClass:"is-pulled-right absolute is--right10 is--top10 pointer",staticStyle:{"z-index":"5"},attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('b-icon',{attrs:{"slot":"trigger","icon":"ellipsis-stroke"},slot:"trigger"}),(!_vm.deleted)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openUpdateDialog(_vm.company)}}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" Editar empresa ")],1):_vm._e(),(_vm.deleted)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openRestoreDialog(_vm.company)}}},[_c('b-icon',{attrs:{"icon":"trash-undo"}}),_vm._v(" Restaurar empresa ")],1):_vm._e(),(!_vm.deleted && _vm.$hasPermissionsByRole(_vm.superAdminRoles))?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openDeleteDialog(_vm.company)}}},[_c('div',{staticClass:"has-text-danger"},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Eliminar empresa ")],1)]):_vm._e()],1)],1):_vm._e(),(!_vm.company.deleted)?_c('router-link',{attrs:{"to":{
        name: _vm.toLink,
        params: { id: _vm.company.id },
      }}},[_c('div',{staticClass:"box is-flex direction-column justify-space-between",style:(`
          height: 100%;
          min-height: 200px;
          background: ${_vm.company.mainColor || '#42CBB6'};`)},[_c('div',[_c('div',{staticClass:"title is-3 has-text-white"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('div',[_c('img',{staticStyle:{"max-height":"60px"},attrs:{"src":_vm.company.logo ? _vm.company.logo.url : ''}})])]),_c('div',[_c('div',{staticClass:"has-text-white is-flex justify-flex-end is-size-7"},[_c('div',[_c('b-icon',{attrs:{"icon":"calendar-star"}}),_vm._v(" Creada: "+_vm._s(_vm.timeAgo(_vm.company.createdAt)))],1)])])])]):_c('div',{staticClass:"box is-flex direction-column justify-space-between opacity-6",style:(`
      height: 100%;
      min-height: 200px;
      background: ${_vm.company.mainColor || '#42CBB6'};`)},[_c('div',[_c('div',{staticClass:"title is-3 has-text-white"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('div',[_c('img',{staticStyle:{"max-height":"60px"},attrs:{"src":_vm.company.logo ? _vm.company.logo.url : ''}})])]),_c('div',[_c('div',{staticClass:"has-text-white is-flex justify-flex-end is-size-7"},[_c('div',[_c('b-icon',{attrs:{"size":"is-small","icon":"calendar-star"}}),_vm._v(" Creada: "+_vm._s(_vm.timeAgo(_vm.company.createdAt))+" ")],1)])])])],1),_c('CompanyModal',{ref:"CompanyModal",attrs:{"company":_vm.company},on:{"update":function($event){return _vm.$emit('update')}}}),_c('b-loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }