<template>
  <div>

    <div class="is-flex justify-flex-end align-flex-end">
      <b-field
        label="Nombre"
        class="ml-10">
        <b-input
          v-model="search"
          placeholder="Buscar"
          icon="magnifying-glass"
        />
      </b-field>
      <div></div>
    </div>

    <div>
      <b-table
        class="has-shadow"
        :columns="table.columns"
        :data="table.data"
        :paginated="table.isPaginated"
        :per-page="table.perPage"
        :current-page.sync="table.currentPage"
        :pagination-simple="table.isPaginationSimple"
        :pagination-position="table.paginationPosition"
        :default-sort-direction="table.defaultSortDirection"
        :sort-icon="table.sortIcon"
        :sort-icon-size="table.sortIconSize"
        default-sort="scholar_name"
      >
        <b-table-column
          field="scholar_name"
          label="Nombre"
          sortable
          v-slot="props"
        >
          <router-link :to="{
            name: 'ScholarDetail',
            params: { scholarId: props.row.application._id }}">
            {{ props.row.profile.firstName }}
            {{ props.row.profile.firstSurname }}
            {{ props.row.profile.lastSurname }}
          </router-link>
        </b-table-column>
        <b-table-column
          label="Fecha que aplicó"
          sortable
          centered
          v-slot="props"
        >
          {{ props.row.createdAt | formatDate }}
        </b-table-column>
        <b-table-column
          v-if="['superqueen', 'recruiter'].includes($isRole(user))"
          label="Entrevista con ProMeritum"
          centered
          v-slot="props"
        >
          <div v-if="props.row.application.promeritumInterview">
            <div>
              {{ props.row.application.promeritumInterview.event.startDate | formatDate }}
              <b-icon v-if="props.row.application.promeritumInterview.done" type="is-primary" icon="check"/>
            </div>
          </div>
          <div v-else>
            Sin agendar
          </div>
        </b-table-column>
        <b-table-column
          label="Entrevista con empresa"
          centered
          v-slot="props"
        >
          <div v-if="props.row.application.companyInterview">
            <div>
              {{ props.row.application.companyInterview.event.startDate | formatDate }}
              <b-icon v-if="props.row.application.companyInterview.done" type="is-primary" icon="check"/>
            </div>
          </div>
          <div v-else>
            Sin agendar
          </div>
        </b-table-column>
        <b-table-column
          field="cv"
          label="Curriculum"
          centered
          v-slot="props"
        >
          <b-button tag="a" rounded target="_blank" :href="props.row.profile.resume.url">
            Ver CV
          </b-button>
        </b-table-column>
        <b-table-column
          field="status"
          label="Estatus"
          v-slot="props"
        >
          {{ statuses[props.row.application.status] }}
        </b-table-column>
      </b-table>
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  name: 'ScholarsList',
  data() {
    return {
      isLoading: false,
      search: '',
      vacancy: {
        name: null,
        id: null
      },
      company: {
        name: null,
        id: null
      },
      statuses: {
        applying: 'En aplicación',
        selected: 'Seleccionad@',
        sent: 'En revisión con la empresa',
        rejected: 'Rechazad@',
        'pre-hired': 'Seleccionad@ por la empresa',
        hired: 'Contratad@'
      },
      table: {
        data: [],
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 15
      }
    }
  },
  watch: {
    async search (val) {
      if (val && val.length > 3) {
        const params = [
          `name=${val}`
        ]
        params.join('&')
        await this.getCandidates(params)
      } else {
        await this.getCandidates()
      }
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
    id () {
      return this.$route.params.id
    },
    user () {
      return this.$store.state.session.user
    }
  },
  async mounted () {
    await this.getCandidates()
  },
  methods: {
    async getCandidates (params) {
      params = params || [
        ''
      ]
      params = params.join('&')
      const { data } = await this.$api.get(`/companies/${this.id}/scholars?${params}`)
      this.table.data = data.scholars
      this.company = data.company
    }
  }
}
</script>
