<template>
  <SimpleCard
    title="Reclutamiento"
    :isLoading="isLoading"
    description="Tipos de reclamación y de servicios comúnmente solicitados."
    icon="fa-regular fa-chart-pie"
    :data="data"
  >
  <template #content="{ data }">
    <SimplePie
      :pieData="data?.claimType"
      title="Tipos de reclamación"
      :headers="['Tipo', 'Cantidad']"
    />
    <SimplePie
      :pieData="data?.serviceType"
      title="Tipos de servicio"
      :headers="['Tipo', 'Cantidad']"
      />
    </template>
  </SimpleCard>
</template>

<script>
import SimpleCard from './SimpleCard.vue';
import SimplePie from './SimplePie.vue';


export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCard,
    SimplePie
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        claimType: {},
        serviceType: {}
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>

</style>
