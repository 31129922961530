<template>
  <SimpleCollapse
    title="Embudo de postulados"
    :isLoading="isLoading"
    description="El embudo de aplicaciones muestra el número de candidatos que pasan por cada etapa del proceso de selección."
    icon="fa-regular fa-filters"
    :data="data"
    @open="getData"
    ref="collapse"
  >
  <template #content="{ data }">
    <PieTable
      :pieTableData="data"
      termsKey="funnel"
      :headers="['Estado', 'Cantidad']"
      :isStatSearchable="false"
      chartHeight="300px"
    />
  </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from './SimpleCollapse.vue';
import PieTable from './PieTable.vue';

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimpleCollapse,
    PieTable
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        psychometricSent: 0,
        pmInterviewSent: 0,
        selected: 0,
        sent: 0,
        hired: 0,
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.separation-line{
  margin: 1.5rem 0;
  height: 2px;
}
</style>
