<template>
  <div class="searcher has-background-primary">
    <h3 class="title is-5 has-text-white mb-3">Filtros de búsqueda</h3>

    <div class="searcherFields">
      <!-- A partir de -->
      <b-field
        v-if="activeSections.includes('startDate')"
      >
        <template #label>
          <span class="has-text-white has-text-weight-medium">A partir de</span>
        </template>

          <b-datepicker 
            icon="calendar"
            position="is-bottom-right" 
            icon-right-clickable
            class="dateField"
            v-model="search.startDate"
          >
          </b-datepicker>
      </b-field>

      <!-- Hasta  -->
      <b-field
        v-if="activeSections.includes('endDate')"
      >
        <template #label>
          <span class="has-text-white has-text-weight-medium">Hasta</span>
        </template>

        <b-datepicker 
          icon="calendar"
          position="is-bottom-right"
          icon-right-clickable
          class="dateField"
          v-model="search.endDate"
        >
        </b-datepicker>
      </b-field>

      <!-- Reclutadores -->
      <b-field
        v-if="activeSections.includes('recruiters') && $hasPermissionsByRole(allDashboardSeacherRoles)"
      >
        <template #label>
          <span class="has-text-white has-text-weight-medium">Agrega especialistas</span>
        </template>

        <b-autocomplete
          v-model="name"
          group-field="type"
          group-options="items"
          open-on-focus
          clear-on-select
          :data="filteredRecruiters"
          @select="addRecruiter"
          field="fullName"
          class="recruitersField"
          icon="user"
        />
      </b-field>

      <!-- Lista de reclutadores -->
      <b-field
        v-if="activeSections.includes('recruiters') && $hasPermissionsByRole(allDashboardSeacherRoles)"
      >
        <template #label>
          <span class="has-text-white has-text-weight-medium">Especialistas seleccionados</span>
        </template>

        <b-taginput
          ellipsis
          aria-close-label="Delete this tag"
          class="recruitersField"
          v-model="search.recruiters"
          field="fullName"
        >
        </b-taginput>
      </b-field>

      <div class="searchButtons">
        <!-- Botón de buscar -->
        <b-button
          type="is-primary-light"
          outlined
          expanded
          icon-right="fa-trash"
          class="searchButton has-text-primary has-text-weight-semibold"
          @click="cleanSearch"
        >
          Limpiar
        </b-button>
        <!-- Botón de buscar -->
        <b-button
          type="is-primary-light"
          outlined
          expanded
          icon-right="fa-magnifying-glass"
          class="searchButton has-text-primary has-text-weight-semibold"
          @click="sendSearchPayload"
        >
          Buscar
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import { dashboardTerms } from '@/lib/dashboardDictionaries';
import { getRecruiters } from '@/api/user';
import { allDashboardSeacherRoles } from '@/lib/permissions'
import moment from 'moment';

export default {
  props: {
    activeSections: {
      type: Array,
      default: () => ['startDate', 'endDate', 'recruiters']
    }
  },
  components: {
  },
  data() {
    return {
      allDashboardSeacherRoles,
      organizedRecruiters: [],
      name: '',
      search: {
        startDate: new Date(moment().startOf('month')),
        endDate: new Date(moment().endOf('month')),
        recruiters: [],
        company: ''
      },
    }
  },
  async mounted() {
    const recruiters = await getRecruiters()
    this.organizedRecruiters = this.getOrganizedRecruiters(recruiters)

    this.sendSearchPayload() // Make initial search
  },
  methods: {
    getOrganizedRecruiters(recruiters){ // Organize recruiters by role for autocomplete
      const items = {}
      const recruitersList = []

      recruiters.forEach(recruiter => {
        const role = dashboardTerms.searcher[recruiter.role]

        if(!items[role])
          items[role] = []
        
        items[role].push(recruiter)
      })
      
      for (const [key, value] of Object.entries(items)) {
        recruitersList.push({
          type: key,
          items: value
        })
      }
      
      return recruitersList
    },
    addRecruiter(option){ // Add recruiter to selected recruiters
      if(option){
        const noRepeatedRecruiters = new Set(this.search.recruiters)
        noRepeatedRecruiters.add(option)
        this.search.recruiters = Array.from(noRepeatedRecruiters)
      }
    },
    sendSearchPayload(){
      const payload = {
        startDate:  new Date(this.search.startDate),
        endDate: new Date(this.search.endDate),
        recruiters: this.search.recruiters.map(recruiter => recruiter.id),
        company: this.companyId
      }
      if (!this.search.startDate)
        delete payload.startDate
    
      if (!this.search.endDate)
        delete payload.endDate

      if (!this.search.recruiters.length)
        delete payload.recruiters

      if (!this.companyId)
        delete payload.company

      this.$emit('search', payload)
    },
    cleanSearch(){
      this.search  = {
        startDate: null,
        endDate: null,
        recruiters: [],
        company: this.companyId
      }
    }
  },
  computed: {
    filteredRecruiters() { // Filter recruiters by name
      const newData = []
      const name = new RegExp(this.name, 'gi')

      this.organizedRecruiters.forEach(element => {
          const items = element.items.filter((item) =>{
            return name.test(item.fullName) && !this.search.recruiters.includes(item)
          })

          if (items.length)
            newData.push({ type: element.type, items })
      })

      return newData
    },
    companyId () {
      return this.$store.state.company.id
    },
  }
};
</script>

<style lang="scss" scoped>
.searcher {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  border-radius: 0.3rem;
  padding: 1.2rem;
  box-shadow: 0 0 5px 0 #dcdcdc;
}
.searcherFields{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: #38bba7;
  border-radius: 0.3rem;
  padding: 0.7rem 1.2rem;
}

.dateField{
  width: 200px;
}

.recruitersField{
  width: 250px;
}

.searchButtons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
}

.searchButton{
  width: fit-content;
  margin-top: 2rem;
  justify-self: flex-end;
}
</style>
