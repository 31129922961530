<template>
  <div class="page mb-2">
    <h2>Feedback de la empresa</h2>

    <div class="text">{{ companyInterviewFeedback }}</div>
  </div>
</template>

<script>
export default {
  props: {
    companyInterviewFeedback: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #0F486D;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.label {
  font-size: 10px;
  color: #42CBB6;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
}

.page {
  margin-bottom: 30px !important;
}

.block {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table,
th,
td {
  text-align: left;
  border: none;
}

th,
td {
  padding-right: 20px;
  vertical-align: top;
}
</style>