import httpApi from '@/axios'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  getTotals: async function () {
    try {
      const response = await httpApi.get('/totals')
      return response
    } catch (error) {
      Toast.open( {
        message: !error.response ? error : error.response.data.message,
        type: 'is-danger'
      })
      return null
    }
  }
}