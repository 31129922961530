<template>
  <div class="vacancies-containter">
    <div class="pl-2 pr-5 py-2" style=" border-radius: 1rem;">
      
      <!-- MENÚ DESPLEGABLE -->
      <div class="is-flex justify-space-between align-flex-start">
        <p class="title is-3 mb-20 has-text-weight-semibold">Vacantes {{ project?.id ? `asociadas a ${project?.name}`: `de ${companyInfo?.name}` }}</p>

        <b-dropdown class="mt-2 mr-2" aria-role="list" position="is-bottom-left" :triggers="['click']">
          <template #trigger>
            <b-button class="px-4" rounded>
              <f-icon size="xl" icon="fa-regular fa-ellipsis-vertical" class="selectable angle"></f-icon>
            </b-button>
          </template>

          <b-dropdown-item aria-role="listitem"
              v-if="$hasPermissionsByRole(projectEditionAllowedRoles) || $hasPermissionsByCompany(companyInfo, false) || isSpain"
              @click="goCreateVacancy"
            >
              <f-icon icon="fa-regular fa-square-plus" class="has-text-primary mr-1" />
              Nueva vacante
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem"
              v-if="vacancies.length && project?.id"
            >
            <f-icon icon="fa-regular fa-download" class="has-text-info mr-1" />
            <a class="has-text-dark" :href="downloadVacancies" target="_blank">
              Descargar vacantes
            </a>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem"
            v-if="!$hasPermissionsByRole(externalRoles)"
          >
            <f-icon icon="fa-regular fa-users" class="has-text-info mr-1" />
            <a class="has-text-dark" :href="downloadCandidates" target="_blank">
              Descargar candidatos
            </a>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem"
            @click="openLanding"
          >
            <f-icon icon="fa-regular fa-globe-pointer" class="has-text-info mr-1" />
            <a class="has-text-dark" target="_blank">
              Ver página de empresa
            </a>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- FILTROS -->
      <div class="is-flex is-justify-content-flex-start mb-0">
        <!-- Nombre -->
          <b-field label="Filtrar nombre" 
            class="column is-4"
          >
            <b-input placeholder="Nombre" icon="input-text" v-model="keyword"/>
          </b-field>

        <!-- Status -->
        <b-field label="Filtrar estatus" class="column is-3" >
          <b-select icon="arrow-progress" placeholder="Estatus" expanded
            v-model="filterStatus"
          >
          <option
            v-for="(status, key, index) in vacancyStatuses"
            :value="key"
            :key="`status-${index}`">
              {{ status.text }}
          </option>
          </b-select>
        </b-field>

        <!-- Llamada de alineación -->
        <b-field label="Filtrar llamada de alineación" class="column is-4 align-self">
          <div class="is-flex align-center">
            <b-datepicker icon="calendar" position="is-bottom-left" icon-right-clickable
              v-model="filterDate"
              :icon-right="filterDate ? 'circle-xmark'  : '' "
              @icon-right-click="filterDate = null"
              :unselectable-days-of-week=" [0, 6]">
            </b-datepicker>
          </div>
        </b-field>
      </div>

      <!-- ORDENAMIENTO Y RECUENTO -->
      <div class="is-flex justify-space-between align-center mb-15">
        <p>
          <f-icon
            icon="fa-regular fa-arrow-down-a-z"
            class="selectable angle ml-1"
            size="xl"
            v-if="alphabetical"
            @click="orderVacancies('alphabetical')"
          />
          <f-icon
            icon="fa-regular fa-arrow-down-z-a"
            class="selectable angle ml-1"
            size="xl"
            v-else
            @click="orderVacancies('reverse')"
          />
          <b-tooltip label="Reestablecer vacantes" type="is-primary is-light" position="is-right">
            <f-icon icon="fa-regular fa-arrow-rotate-left" class="selectable angle ml-1"
              @click="resetFilter"
            />
          </b-tooltip>
        </p>

        <p class="has-text-right mr-2 has-text-weight-medium">
          Mostrando {{ total }} vacantes
        </p>
      </div>

      <!-- MENSAJE DE 0 VACANTES -->
      <transition name=fade v-if="!filteredVacancies.length">
        <div class="pt-10 pr-10 pl-10" >
          <b-message type="is-info" class="mt-2">
            <p class="is-size-4 has-text-weight-semibold">
              <f-icon icon="fa-regular fa-circle-info" class="mr-2" size="xl" />
              Sin vacantes para mostrar
            </p>
          </b-message>
        </div>
      </transition>

      <!-- VACANTES -->
      <transition name=fade v-else>
        <div class="project.id-vacancies" >
          <ProjectVacancy
            v-for="(vacancy, index) in filteredVacancies"
            :vacancy="vacancy"
            :company-info="companyInfo"
            :recruiters="recruiters"
            :company-users="companyUsers"
            @update="getVacancies"
            :key="`vacancie-${index}`"
          />
        </div>
      </transition>

      <!-- PAGINADOR -->
      <div class="pagination-container pr-20 pl-20 pt-20">
        <b-pagination
          :current.sync="current"
          :per-page="perPage"
          :total="total"
          order="is-right"
          :page-input="true"
          page-input-position="is-input-right"
          range-before="2"
          range-after="1"
          rounded
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          >
        </b-pagination>
      </div>
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vacancyStatuses from '@/lib/vacancyStatuses'
import { externalRoles, projectEditionAllowedRoles } from '@/lib/permissions'
import ProjectVacancy from './ProjectVacancy.vue'
import durationOptions from '@/lib/durationOptions'

export default {
  name: 'ProjectVacancies',
  props:{
    project:{
      type: Object
    },
  },
  components: {
    ProjectVacancy
  },
  data(){
    return{
      durationOptions,
      externalRoles,
      projectEditionAllowedRoles,
      vacancies: [],
      companyInfo: null,
      recruiters: [],
      companyUsers: [],
      vacancyStatuses,
      isLoading: false,
      keyword: '',
      filterDate: null,
      filteredVacancies: [],
      filterStatus: null,
      current: 1,
      perPage: 10,
      total: 0,
      alphabetical: true,
    }
  },
  computed: {
    ...mapGetters(['companyId']),
    handledVacancy () {
      return this.$route.query.vacancy
    },
    token () {
      return this.$store.state.session.token
    },
    downloadVacancies () {
      if (!this.project) return ``
      return `${process.env.VUE_APP_API_URL}/v1/vacancies/${this.project.id}/export?token=${this.token}`
    },
    downloadCandidates () {
      return `${process.env.VUE_APP_API_URL}/v1/companies/${this.companyId}/applications/export?token=${this.token}`
    },
    urlLanding () {
      return `${this.publicUrl}/company/${this.companyId}`
    },
    publicUrl () {
      return `${process.env.VUE_APP_PUBLIC_URL}`
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION === 'es'
    },
  },
  async mounted(){
    await this.getCompanyInfo()
  },
  watch:{
    async companyId(){
      await this.getCompanyInfo()
    },
    async project(){
      await this.getVacancies()

      if(this.handledVacancy) this.keyword = this.handledVacancy
    },
    async companyInfo(){
      await this.getCompanyUsers()
      await this.getRecruiters()
      await this.getCompanyUsers()
      await this.getVacancies()
    },
    async keyword () {
      this.current = 1
      await this.getVacancies()
    },
    async filterStatus () {
      this.current = 1
      await this.getVacancies()
    },
    async filterDate () {
      this.current = 1
      await this.getVacancies()
    },
    async current () {
      await this.getVacancies()
    }
  },
  methods: {
    openLanding () {
      window.open(this.urlLanding, '_blank');
    },
    async getCompanyInfo(){
      if (!this.companyId) return

      try{
        const { data } = await this.$api.get(`companies/${this.companyId}`)
        this.companyInfo = data
      }catch(error){
        this.$buefy.toast.open({
          message : 'Información de la empresa no obtenida',
          type : 'is-danger'
        })
      }
    },
    orderVacancies (order) {
      if(order === 'alphabetical'){
        this.filteredVacancies = this.filteredVacancies.sort( (a,b) => a.name.localeCompare(b.name) )
        this.alphabetical = !this.alphabetical
      }else{
        this.filteredVacancies = this.filteredVacancies.sort( (a, b) => b.name.localeCompare(a.name) )
        this.alphabetical = !this.alphabetical
      }
    },
    async getVacancies(){
      let params = [
        `skip=${(this.current - 1) * this.perPage}`,
        `limit=${this.perPage}`,
        `populate=phoneCallDate,commitmentDate,deliveryDate,projectId`,
        `fields=name,recruiter,status,positions,location,
                mondayVacancyId, projectMondayId, degree,englishLevel, closedReason,
                excelLevel,otherLanguages,createdAt, duplicateReason, isActive, relatedTo, 
                duration, newDuration, author, updatedAt, publishedAt, closedAt, projectId.name, additionalRecruiters, additionalAuthors, realDeliveryDate`,
      ]

      if (this.filterStatus && (this.filterStatus !== 'all')) 
        params.push(`status=${this.filterStatus}`)
      if (this.keyword)
        params.push(`name=/${this.keyword}/i`)
      if (this.filterDate)
        params.push(`phoneCallDate=${new Date(this.filterDate).toISOString()}`)
  
      params = params.join('&')

      let response = {}
      if (this.project?.id) 
        response = await this.getProjectVacancies(params)
      else
        response = await this.getCompanyVacancies(params)

      this.vacancies = response.data
      this.filteredVacancies = response.data
      this.total = response.total
    },
    async getProjectVacancies(params){
      this.isLoading = true
      
      try{
        const { data } = await this.$api.get(`companies/${this.companyId}/projects/vacancies/${this.project.id}?${params}`)
        return data

      }catch(error){
        const message = error.response?.data?.message || error

        this.$buefy.toast.open({
          message: message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    async getCompanyVacancies (params) {
      this.isLoading = true

      try {
        const { data } = await this.$api.get(`/companies/${this.companyId}/vacancies?${params}`)
        return data

      } catch (error) {
        const message = error.response?.data?.message || error

        this.$buefy.toast.open({
          message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    async goCreateVacancy(){
      if (!this.project?.id){
        this.$router.push({ name: 'VacancyCreate', params: { id: this.companyId, name: this.companyInfo.name} })
        return
      }

      const otherFields = await this.getOtherFields()
      const payload = {
        name: this.project.name,
        duration: this.durationOptions.find( option => option.value == this.project.duration).text,
        places: 1,
        location: {
          state: this.project.location.state.name,
          city: this.project.location.city.name
        },
        showScholarship: this.project.showRemuneration,
        scholarship: this.project.remuneration,
        excelLevel: this.project.excelLevel,
        englishLevel: this.project.englishLevels.speaking,
        englishLevels: this.project.englishLevels,
        goal: this.project.objective,
        benefit: this.project.benefits.join(','),
        benefits: this.project.benefits,
        schemaType: this.project.schemaType,
        serviceType: this.project.serviceType,
        claimType: this.project.claimType,
        author: this.project.author._id,
        otherLanguages: this.project.otherLanguages,
        recruiter: this.project.headRecruiter,
        projectId: this.project.id,
        projectMondayId: this.project.projectMondayId,
        vacancyLimit: this.project.positions,
        otherFields,
      }
      try{
        this.isLoading = true
        await this.$api.post(`/companies/${this.companyId}/vacancies`, payload)
        this.$buefy.toast.open({
          message: 'Vacante creada',
          type: 'is-success',
          duration: 3500
        })
        await this.getVacancies()
      }catch(error){
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger',
          duration: 3500
        })
      }
      this.isLoading = false

    },
    async getRecruiters(){
      try{
        const { data } = await this.$api.get(`/users/all-recruiters`)
        this.recruiters = data
      }catch(error){
        this.$buefy.toast.open({
          message: 'Información de los especialistas no obtenida',
          type: 'is-danger'
        })
      }
    },
    async getCompanyUsers () {
      this.isLoading = true
      try{
        const { data: solicitants } = await this.$api.get(`companies/${this.companyId}/solicitants`)
        const { data: hrManagers } = await this.$api.get(`/companies/${this.companyId}/hr-managers`)

        this.companyUsers = [...solicitants, ...hrManagers]
      }catch(error){
        this.$buefy.toast.open={
          message : 'Información de solicitantes de la empresa no obtenida',
          type : 'is-danger'
        }
      }
      this.isLoading = false
    },
    async getOtherFields () {
      try {
        const { data } = await this.$api.get(`/aggregate-fields/hasOtherFields/${this.companyId}`);
        return data.otherFields;
      } catch (error) {
        console.log('The company does not have other fields for vacancies.');
      }
    },
    async resetFilter(){
      this.isLoading = true
      await this.getVacancies()
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .project.id-vacancies{
    height: 90rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 1rem;
  }
  .vacancies-containter{
    height: 100%;
    width: 100%;
  }
</style>
