import Pusher from 'pusher-js'

Pusher.logToConsole = process.env.NODE_ENV !== 'production'

export default function plugin (Vue) {
  if (plugin.installed) {
    return
  }
  plugin.installed = true
  // eslint-disable-next-line no-param-reassign
  if (process.env.VUE_APP_PUSHER_APP_CLUSTER && process.env.VUE_APP_PUSHER_APP_KEY) {
    Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: true
    })
  }
}
