<template>
  <div class="column is-8">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title is-size-4 has-text-weight-semibold">
          <div>
            Landing Page Multimedia
          </div>
        </div>
      </div>
    </div>

    <CustomUrlLandingPage :defaultCustomUrl="landing.customUrl" @updateLandingProperty="updateLandingProperty" />

    <ResolutionsLandingPage :defaultAvailableMediaQueries="landing.availableMediaQueries"
      @updateLandingProperty="updateLandingProperty" />

    <MediaSectionsLandingPage ref="mediaSectionsLandingPage" :defaultMediaSections="landing.mediaSections"
      :availableMediaQueries="landing.availableMediaQueries" @updateLandingProperty="updateLandingProperty" />
  </div>
</template>

<script>
import CustomUrlLandingPage from "./CustomUrlLandingPage.vue";
import ResolutionsLandingPage from "./multimedia/ResolutionsLandingPage.vue";
import MediaSectionsLandingPage from "./multimedia/MediaSectionsLandingPage.vue";

export default {

  props: {
    defaultLanding: {
      template: String,
      availableMediaQueries: Array,
      mediaSections: Array,
      customUrl: String
    },
    deleteImage: {
      type: Function
    }
  },
  components: {
    ResolutionsLandingPage,
    CustomUrlLandingPage,
    MediaSectionsLandingPage
  },
  data() {
    return {
      isLoading: false,
      landing: {
        template: 'multimedia',
        customUrl: '',
        availableMediaQueries: [
          {
            alias: 'mobile',
            minWidth: '0',
            maxWidth: '578'
          },
          {
            alias: 'tablet',
            minWidth: '579',
            maxWidth: '992'
          },
          {
            alias: 'laptop',
            minWidth: '993',
            maxWidth: '1240'
          },
          {
            alias: 'desktop',
            minWidth: '1241',
            maxWidth: '10000'
          }
        ],
        mediaSections: [],
      },
    }
  },
  watch: {
    defaultLanding: {
      handler: function () {
        const auxLanding = { ...this.landing }
        this.landing = { ...this.landing, ...this.defaultLanding }

        if (this.defaultLanding.availableMediaQueries.length === 0) {
          this.landing.availableMediaQueries = auxLanding.availableMediaQueries;
        }
      },
    },
  },
  computed: {
    companyId() {
      return this.$route.params.id
    }
  },
  mounted() {
    const auxLanding = { ...this.landing }
    this.landing = { ...this.landing, ...this.defaultLanding }

    if (this.defaultLanding.availableMediaQueries.length === 0) {
      this.landing.availableMediaQueries = auxLanding.availableMediaQueries;
    }
  },
  methods: {
    updateMultimediaLanding() {
      this.$emit('updateMultimediaLanding', this.landing)
    },
    async updateLandingProperty(propertyValue, propertyName) {
      this.landing[propertyName] = propertyValue

      if (propertyName === 'availableMediaQueries') {
        await this.updateSectionsMediaQueries();
      }

      await this.updateMultimediaLanding();
    },
    async updateSectionsMediaQueries() {
      // Discarting sections that contains just an unregistered mediaQuery
      this.landing.mediaSections = this.landing.mediaSections.map(section => {
        section.mediaQueries = section.mediaQueries.map(query => {
          const mediaQuery = this.landing.availableMediaQueries.find(mediaQuery => mediaQuery._id === query._id)

          return mediaQuery || null
        }).filter(query => query !== null)

        return section
      })

      const sectionsToDelete = this.landing.mediaSections.filter(section => section.mediaQueries.length == 0).map(section => section._id)

      await this.deleteSections(sectionsToDelete)
    },
    async deleteSections(sectionsIds) {
      // Deleting sections images
      const backgroundToDelete = this.landing.mediaSections
        .filter(section => sectionsIds.includes(section._id) &&
          section.backgroundImage?.key) // Filtering non bg image sections
        .map(section => section.backgroundImage?.key);

      const sourcesToDelete = this.landing.mediaSections
        .filter(section => sectionsIds.includes(section._id) &&
          section.multimediaSources.length > 0) // Filtering non sources sections
        .map(section => {
          let sectionSourcesToDelete = []
          section.multimediaSources.forEach(sectionSource => {
            sectionSourcesToDelete.push(sectionSource.source?.key) // Adding multimedia source image

            sectionSource.effectSources?.forEach(effectSource => {
              sectionSourcesToDelete.push(effectSource.source?.key) // Adding effect multimedia source image
            });
          });

          return sectionSourcesToDelete
        })
        .flat();

      const imagesToDelete = [...backgroundToDelete, ...sourcesToDelete]
      await Promise.all(await imagesToDelete.map(async (image) => {
        await this.deleteImage(image)
      }))

      // Deleting sections from landing media sections
      this.landing.mediaSections = this.landing.mediaSections.filter(section => !sectionsIds.includes(section._id))
    },
    handleActiveSection(mediaSection) {
      this.selectedMediaSection = mediaSection;
      this.$emit('updateMediaSection', mediaSection);
    },
  }
}
</script>

<style></style>
