<template>
  <div class="historic-applications-container is-multiline">
    <SimpleCollapse
      title="Histórico de Aplicaciones"
      :isLoading="isLoading"
      icon="fa-regular fa-chart-line"
      description="Cantidad de aplicaciones recibidas por cada mes, en el tiempo seleccionado."
      :data="data"
      @open="getData"
      ref="collapse"
    >
    <template #content="{ data }">
      <SimpleBarColumn
        :barData="data.applicationsTimeline"
        :headers="['Fecha', 'Aplicaciones']"
        chart-height="300px"
        :rangeColors="rangeColors"
      />
    </template>
    </SimpleCollapse>
  </div>
</template>

<script>
import SimpleBarColumn from "./SimpleBarColumn.vue";
import SimpleCollapse from "./SimpleCollapse.vue";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },
  components: {
    SimpleBarColumn,
    SimpleCollapse,
  },
  watch: {
    search: {
      handler: async function () {
        await this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        applicationsTimeline: {},
      },
    };
  },
  async mounted() {},
  methods: {
    /* Top level function to get content */
    async getData() {
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true;
      const data = await this.loadFunction(this.search);
      this.data = data;
      this.isLoading = false;
    },
  },
  computed: {
    rangeColors() {
      return [{
        color: '#F14668',
        from: 0,
        to: 9999,
      },
      {
        color: '#FFE089',
        from: 10000,
        to: 19999,
      },
      {
        color: '#49C68D',
        from: 20000,
        to: 20000,
      },
      {
        color: '#3F8ED0',
        from: 20001,
      },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.historic-applications-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
