<template>
  <div>
    <embed style="width:100%; height:100vh" :src="link" type="application/pdf">
  </div>
</template>

<script>
export default {
  computed: {
    link () {
      return  this.$route.query ? this.$route.query.link || '' : ''
    }
  }
}
</script>

<style>

</style>