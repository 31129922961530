<template>
  <div class="companies-container has-background-light scrolleable">
    <div class="companies-cards">
      <div class="company-card box is-flex is-align-items-center is-justify-content-center"
        v-for="(company, index) in companies"
        :style="`background-color:${company.mainColor};`"
        :id="index"
        :key="`company-${index}`"
        @click="getCompanyVacancies(company)"
        >
        <img :src="company.logo.url" alt="" class="company-logo" v-if="company.logo">
        <p v-else class="has-text-light company-logo is-size-3 has-text-weight-semibold">{{ company.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      default: null,
      required: true
    }
  },
  methods: {
    getCompanyVacancies(company) {
      this.$emit('companySelected', company)
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.companies-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  height: 100%;
  width: fit-content;
  min-width: 15rem;
  flex-shrink: 1;
  flex-grow: 0;
  max-width: 100%;
  text-align: center;
}

.companies-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  width: fit-content;
  flex-shrink: 1;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .companies-container {
    max-width: 80%;
    padding: 1rem;
  }
}

.company-card {
  min-width: 12rem;
  max-width: 12rem;
  min-height: 7rem;
  max-height: 7rem;
  width: 12rem;
  height: 7rem;

  border-radius: 5px;
  text-align: center;
  padding: 0 1rem;
  margin: 0 !important;
}

.company-logo {
  max-height: 100%;
  max-width: 100%;
  transition: transform 250ms;
}

.company-card:hover {
  transform: scale(1.1);
  cursor: pointer
}
</style>
