<template>
  <div class="historic-candidates-container is-multiline">
    <SimpleCollapse 
      title="Histórico de Candidatos" 
      :isLoading="isLoading" 
      icon="fa-regular fa-chart-line"
      description="Cantidad de candidatos únicos por cada mes, en el tiempo seleccionado (no se toma en cuenta el filtro de especialistas)." 
      :data="data"
      @open="getData"
      ref="collapse"
     >
      <template #content="{ data }">
        <SimpleBarColumn :barData="data.timeline" :headers="['Fecha', 'Candidatos']" chart-height="300px"
          :goals="goals" />
      </template>
    </SimpleCollapse>
  </div>
</template>

<script>
import SimpleBarColumn from "./SimpleBarColumn.vue";
import SimpleCollapse from "./SimpleCollapse.vue";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },
  components: {
    SimpleBarColumn,
    SimpleCollapse,
  },
  watch: {
    search: {
      handler: async function () {
        await this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        timeline: {},
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      if (!this.$refs.collapse.getIsOpened())
        return
      
      this.isLoading = true;
      const data = await this.loadFunction(this.search);
      this.data.timeline = data.timeline;
      this.isLoading = false;
    },
  },
  computed: {
    goals() {
      return {
        'Meta': {
          value: 10000,
          type: 'line',
          color: '#47C78E'
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.historic-candidates-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
