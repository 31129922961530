import httpApi from "@/axios";
import { ToastProgrammatic as Toast } from "buefy";

const getCandidate = async function (id) {
  try {
    const { data } = await httpApi.get(`/candidates/${id}`);
    return data;
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};

const sendCustomEvaluation = async function (id, payload) {
  try {
    await httpApi.post(`application/${id}/set-custom-evaluation`, payload);
    Toast.open({
      message: "Se guardó la evaluación personalizada del candidato",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};

const sendCompanyFeedback = async function (id, payload) {
  try {
    await httpApi.post(`application/${id}/company-feedback`, payload);
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};
const updateGeneralProfile = async function (form) {
  try {
    // Asegúrate de que `form.id` contiene el ID correcto del perfil
    await httpApi.put(`application/${form.id}/update-general-profile`, form);
    Toast.open({
      message: "Perfil general actualizado con éxito",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};

const updateAcademicProfile = async function (form) {
  try {
    await httpApi.put(`application/${form.id}/update-academic-profile`, form);
    Toast.open({
      message: "Perfil académico actualizado con éxito",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};
const updateSkillsProfile = async function (form) {
  try {
    await httpApi.put(`application/${form.id}/update-skills-profile`, form);
    Toast.open({
      message: "Perfil de habilidades y conocimientos actualizado con éxito",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};

const updateProfessionalProfile = async function (id, form) {
  try {
    await httpApi.put(`application/${id}/update-professional-profile`, form);
    Toast.open({
      message: "Perfil profesional actualizado con éxito",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
};

export {
  getCandidate,
  sendCustomEvaluation,
  sendCompanyFeedback,
  updateGeneralProfile,
  updateAcademicProfile,
  updateSkillsProfile,
  updateProfessionalProfile,
};
