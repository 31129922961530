<template>
  <div class="pie-wrapper"> 
    <div class="columns">
      <p class="is-size-5 column is-7 pb-0">
        {{ title }}
      </p>
      <span 
        class="column is-5 has-text-primary has-text-weight-medium has-text-right pb-0"
        v-if="total"
       >Mostrando {{ total }} elemento(s)</span>
    </div>
    <div class="is-align-self-center pie">
      <ChartPie
        v-if="total"
        :chartData="transformedData"
        :style="`min-height: ${chartHeight};`"
      />
      <div
        v-else
        class="has-text-centered is-flex is-flex-direction-column is-justify-content-center mt-6 mb-6"
      >
        <f-icon icon="fa-regular fa-mug-hot" class="has-text-primary is-size-3 mb-3"/>
        <p class="has-text-weight-normal">No se encontraron datos</p>
      </div>
    </div>
  </div>
</template>

<script>
import ChartPie from '@/components/charts/ChartPie';
import { dashboardTerms } from '@/lib/dashboardDictionaries';
const _ = require('lodash');

export default {
  name: "SimplePie",
  props: {
    pieData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Grafica'
    },
    termsKey: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      default: () => ['Stat', 'Cantidad']
    },
    chartHeight: {
      type: String,
      default: '200px'
    }
  },
  components: {
    ChartPie,
  },
  data() {
    return {
      transformedData: null,
      total: 0
    }
  },
  watch: {
    pieData: {
      handler: function(val){
        this.transformedData = this.transformDataTable(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    transformDataTable(val){
      const transformedData = []

      if (!val)
        return null

      // Creating chart format
      for (const key in val) {
        const stat = _.get(dashboardTerms, this.termsKey) ? _.get(dashboardTerms, this.termsKey)[key] : key
        const value = val[key]
        this.total += value

        transformedData.push([stat, value])
      }

      return [this.headers, ...transformedData]
    },
  }
};
</script>

<style lang="scss" scoped>
  .pie-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
  .pie {
    width: 100%;
  }
</style>
