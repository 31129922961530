<template>
  <div class="pl-10">
    <div class="columns">
      <div class="column is-8">
        <div class="title is-4 pt-2">Killer Questions</div>
      </div>
      <div class="column is-4 has-text-right">
        <b-button
          @click="addKillerQuestion"
          type="is-primary"
          outlined
          :label="killerQueTranslations.buttonAction"
          icon-left="plus"
        />
      </div>
    </div>

    <ValidationObserver ref="observer">
      <div
        class="columns is-multiline"
        v-for="(killerQuestion, index) in killerQuestions"
        :key="`question-${index}`"
      >
        <div class="column is-8">
          <ValidationProvider
            :ref="'questionProvider' + index"
            rules="required"
            :name="`killer-question-${index}`"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="`${index + 1}.- ${killerQuestion.question}`"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input 
                v-model="killerQuestion.question"
                @blur="validateQuestions(index)"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-3">
          <ValidationProvider
            :ref="'answerProvider' + index"
            rules="required"
            :name="`Tipo de respuesta ${index}`"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="killerQueTranslations.answerType"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="killerQuestion.questionType"
                expanded
                @blur="validateAnswers(index)"
              >
                <option
                  v-for="(value, index) in killerQuestionsOptions"
                  :key="`option-${index}`"
                  :value="value"
                >
                  {{ value }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-1 is-flex align-flex-end pb-4">
          <b-button
            type="is-danger"
            @click="popKillerQuestion(index, killerQuestion._id)"
            outlined
            icon-left="trash"
          />
        </div>

        <div
          class="column is-8"
          v-if="hasOptionsValue.includes(killerQuestion.questionType)"
        >
          <div
            v-for="(option, index) in killerQuestion.optionsValues"
            :key="`option-${index}`"
          >
            <ValidationProvider
              :ref="'optionProvider' + index"
              rules="required"
              :name="`opción${index}`"
              v-slot="{ errors, valid }"
            >
              <b-field
                :label="`${killerQueTranslations.option} ${index + 1}`"
                :type="{ 'is-danger': errors.length > 0, 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input
                  v-model="killerQuestion.optionsValues[index]"
                  aria-label="Ingresa una opción"
                  @blur="validateOptions(index)"
                />
              </b-field>
            </ValidationProvider>
          </div>
        </div>

        <div
          class="column is-4 is-flex is-justify-content-space-evenly is-align-content-center control-panel"
          v-if="hasOptionsValue.includes(killerQuestion.questionType)"
        >
          <b-button
            @click="killerQuestion.optionsValues.push('')"
            icon-left="plus"
            type="is-blue"
            outlined
            v-show="!['una opción','single-option'].includes(killerQuestion.questionType)"
          />

          <div>{{ killerQueTranslations.multiple }}</div>

          <b-button
            @click="killerQuestion.optionsValues.pop()"
            icon-left="minus"
            type="is-danger"
            outlined
            :disabled="killerQuestion.optionsValues.length === 1"
            />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { killerQuestionOptions } from '../lib/vacancyDictionaryout';

export default {
    props: {
      vacancy: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        killerQuestions: [],
        hasOptionsValue: ["Single-option", "Multiple-option","Opción única","Opción múltiple"],
        deletedQuestions: [],
      };
    },
    async mounted() {
      await this.getKillerQuestions();
  },
  watch: {
    "$i18n.locale"(){
      this.revalidateFields();
    }
  },
  computed: {
      killerQueTranslations () {
        return this.$t("screens.vacancies.edit.project.fields.killerQuestions");
    },
    killerQuestionsOptions () {
        return killerQuestionOptions[this.$i18n.locale]
      }
  },
  methods: {
    addKillerQuestion() {
      this.killerQuestions.push({
        question: "",
        questionType: "",
        optionsValues: [""],
      });
    },
    popKillerQuestion(index, id) {
      this.killerQuestions.splice(index, 1);
      this.deletedQuestions.push({ id });
    },
    async deleteKillerQuestion() {
      try {
        const payload = this.deletedQuestions;
        await this.$api.put(`/vacancies/${this.vacancy}/killer-questions/delete`, payload);
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
    },
    async createKillerQuestion () {
      const id = this.$route.params.id;
      if (this.killerQuestions.length > 0) {
        const valid = await this.$refs.observer.validate();

        if (valid) {
          const payload = this.killerQuestions;
          try {
            await this.$api.post(`vacancies/${id}/killer-questions`, payload);
          } catch (error) {
            this.$buefy.toast.open({
              message: !error.response ? error : error.response.data.message,
              type: "is-danger",
            });
          }
        } else {
          this.$buefy.toast.open({
            message: "Killer Questions incompleto",
            type: "is-warning",
          });
        }
      }
    },
    async getKillerQuestions () {
      const id = this.$route.params.id;        
      if (id) {
        try {
          const { data } = await this.$api.get(`/vacancies/${id}/killer-questions`);
          this.killerQuestions = data;
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }
      }
      
    },
    async updateKillerQuestion() {
      try {
        const payload = this.killerQuestions;
        await this.$api.put(`vacancies/${this.vacancy}/killer-questions`, payload);
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }

      if (this.deletedQuestions.length > 0) {
        await this.deleteKillerQuestion();
      }
    },
    validateQuestions(index){
      const providerRef = this.$refs['questionProvider' + index];
      if (providerRef && providerRef[0]) {
        providerRef[0].validate();
      }
    },
    validateAnswers(index){
      const providerRef = this.$refs['answerProvider' + index];
      if (providerRef && providerRef[0]) {
        providerRef[0].validate();
      }
    },
    validateOptions(index){
      const providerRef = this.$refs['optionProvider' + index];
      if (providerRef && providerRef[0]) {
        providerRef[0].validate();
      }
    },
    revalidateFields() {
      Object.keys(this.$refs).forEach((key) => {
        const refs = this.$refs[key];
        // Verifica que 'refs' es un array antes de intentar validarlo
        if (Array.isArray(refs)) {
          refs.forEach((ref) => {
            if (ref.flags && ref.flags.touched) {
              ref.validate();
            }
          });
        }
      });
    }
  },
};
</script>

<style>
  .is-align-content-center.control-panel{
    align-items: center;
    flex-wrap: wrap;
  }
</style>
