<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ renameField(vacancyProjectTranslation.title) }}
            <f-icon icon="fa-regular fa-folder" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <div class="column">
          <b-tooltip
            :label="vacancyProjectTranslation.fields.uploadFile.placeholder"
            position="is-right"
            animated
          >
            <b-field
              v-if="
                ['superqueen', 'recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'].includes(
                  $isRole(user)
                )
              "
              class="file"
            >
              <b-upload
                v-model="file"
                accept=".pdf, .docx, .pptx"
              >
                <a
                  class="button is-primary"
                  style="border-radius: 30px"
                >
                  <b-icon icon="upload" />
                  <span>{{vacancyProjectTranslation.fields.uploadFile.label}}</span>
                </a>
              </b-upload>
            </b-field>
          </b-tooltip>
        </div>
        <div
          class="column"
          v-if="form.project"
        >
          <a
            :href="form.project.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b-button
              rounded
              outlined
              type="is-primary"
              target="_blank"
            >
              {{vacancyProjectTranslation.fields.uploadFile.viewFile}}
            </b-button>
          </a>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import { academicLevels } from "@promeritum/promeritum-utils";
  import states from "@/lib/states";
  import provinces from "@/lib/ES/provinces";
  import { getSemestersList } from "@/lib/semesters";
  import {
    roleLabel,
    levelOptions,
    modalities,
    getDefaultVacancyFields,
    getStateCities,
    knowledgeLevels,
  } from "@/lib/vacancyDictionaries";
  import { vacancyCreateForm } from "@/mixins/vacancy";
  import {
    createVacancy,
    getCompanyContacts,
    getOtherFields,
    getRenamedFields,
    notifyRecruiter,
    getKnowledges,
    getAvailableProjects,
  } from "../../api/vacancy";
  import { mapGetters } from "vuex";
  import servicesSchema from "@/lib/servicesSchema";
  import servicesClaims from "@/lib/servicesClaims";
  import alternativeRecruitmentServices from "@/lib/alternativeRecruitmentServices";
  import recruitmentServices from "@/lib/recruitmentServices";

  export default {
    components: {
    },
    mixins: [vacancyCreateForm],
    data() {
      return {
        academicLevels,
        knowledgeLevels,
        servicesSchema,
        servicesClaims,
        alternativeRecruitmentServices,
        recruitmentServices,
        authorForm: true,
        authors: [],
        citySelected: null,
        file: null,
        hasOtherFields: false,
        isLoading: false,
        otherFields: [],
        project: null,
        provinces,
        renamedFields: [],
        states,
        stateSelected: null,
        availableProjects: [],
        knowledges: [],
        knowledgeIndex: 0,
        selectedKnowledge: "",
        selectedProject: null,
        otherSolicitants: false,
      };
    },
    watch: {
      async file(val) {
        const formData = new FormData();
        if (val) {
          this.isLoading = true;
          try {
            formData.append("file", val);
            const response = await this.$api.post("/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            this.form.project = response.data;
            this.$buefy.toast.open({
              message: this.vacancyProjectTranslation.fields.uploadFile.successFile,
              type: "is-success",
            });
          } catch (error) {
            this.$buefy.toast.open({
              message: !error.response ? error : error.response.data.message,
              type: "is-danger",
            });
          }
          this.isLoading = false;
        }
      },
      "form.author": function (val) {
        if (val) {
          this.authors.forEach((obj) => {
            if (obj.id === val) {
              this.form.authorName = obj.fullName;
              this.form.authorEmail = obj.email;
              if (obj.profile) this.form.authorPhone = obj.profile.phone;
            }
          });
        }
      },
      stateSelected() {
        if (this.stateSelected.id == "00" && this.isSpain) {
          this.citySelected = this.provincesMunicipalities[0];
        }
      },
      selectedProject(val) {
        if (val) {
          this.form.projectMondayId = val.projectMondayId;
          this.form.projectId = val.projectId;
        }
      },
      otherSolicitants(val) {
        if (!val) {
          this.form.additionalAuthors = [];
        }
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      semesters() {
        return getSemestersList(process.env.VUE_APP_LOCATION);
      },
      user() {
        return this.$store.state.session.user;
      },
      levels() {
        return levelOptions;
      },
      modalities() {
        return modalities;
      },
      roles() {
        return roleLabel;
      },
      company() {
        return this.$store.state.company;
      },
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      stateCities() {
        return getStateCities(this.stateSelected?.id, process.env.VUE_APP_LOCATION);
      },
      isMexico() {
        return process.env.VUE_APP_LOCATION === "mx";
      },
      isSpain() {
        return process.env.VUE_APP_LOCATION === "es";
      },
      filteredKnowledges() {
        if (this.form.knowledges[this.knowledgeIndex].name) {
          return this.knowledges.filter((knowledge) => {
            if (this.knowledgeIndex >= 0) {
              return (
                knowledge.name.toLowerCase().indexOf(this.form.knowledges[this.knowledgeIndex].name.toLowerCase()) >= 0
              );
            }
          });
        } else return this.knowledges;
      },
      vacancyProjectTranslation () {
        return this.$t("screens.vacancies.edit.project");
      }
    },
    mounted() {
      this.mountedPromises();
    },
    methods: {
      async mountedPromises() {
        const { hasOtherFields, otherFields } = await getOtherFields(this.companyId);
        this.hasOtherFields = hasOtherFields;
        this.otherFields = otherFields;

        this.renamedFields = await getRenamedFields(this.companyId);
        this.authors = await getCompanyContacts(this.companyId);
        this.knowledges = await getKnowledges(this.companyId);
        this.availableProjects = await getAvailableProjects(this.companyId);
        this.authorForm = false;

        const roles = ["hrmanager", "solicitant"];
        if (roles.includes(this.user.role)) this.mountedAuthor();
      },
      mountedAuthor() {
        const roles = ["hrmanager", "solicitant"];
        if (roles.includes(this.user.role)) {
          this.authorForm = false;
          this.form.authorName = this.user.fullName;
          this.form.authorEmail = this.user.email;
          this.form.author = [this.user.id];
          if (this.user.profile) this.form.authorPhone = this.user.profile.phone;
        }
      },
      renameField(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newName || actualName;
      },
      newPlaceholder(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newPlaceholder || "";
      },
      addLenguaje() {
        this.form.otherLanguages.push({
          language: "",
          writing: "",
          listening: "",
          reading: "",
          speaking: "",
          representativeSkill: [],
        });
      },
      removeLenguaje(index) {
        this.form.otherLanguages.splice(index, 1);
      },
      buildPayloadForm() {
        this.form.location.state = this.stateSelected.name;
        this.form.location.city = this.citySelected.name;
        this.form.otherFields = this.$refs.otherFields.otherFields;
        this.form.projectId = this.form.projectId ? this.form.projectId : this.projectId;
        this.form.englishLevel = this.form.englishLevels.speaking;

        if (!this.form.recruiter && this.$isRole() === "recruiter") this.form.recruiter = this.user.id;
        else this.form.recruiter = this.company.headRecruiter?.id;

        if (this.form.projectId)
          this.form.projectMondayId = this.availableProjects.find(
            (project) => project.projectId === this.form.projectId
          ).projectMondayId;
      },
      async validateForm() {
        const valid = await this.$refs.observer.validate();
        // const lastValid = await this.$refs.lastObserver.validate() Descomentar posteriormente para hacer oobligatorio id de monday
        // if (valid && lastValid) { descomentar posteriormente para hacer obligatorio id de mondya
        if (!valid || !this.form.author) {
          this.$buefy.toast.open({
            message: "Uno o más campos están incompletos.",
            type: "is-danger",
          });
          return false;
        }
        return true;
      },
      async createVacancy() {
        if (await this.validateForm()) {
          this.buildPayloadForm();
          this.isLoading = true;

          try {
            const { data } = await createVacancy(this.companyId, this.form);

            this.$refs.killerQuestions.createKillerQuestion(data.id);

            if (["solicitant", "hrmanager"].includes(this.user.role)) await notifyRecruiter(this.companyId, data);

            this.$router.push({ name: "Vacancies", params: { id: this.companyId } });
          } finally {
            this.isLoading = false;
          }
        }
      },
      addActivity() {
        this.form.activities.push("");
      },
      removeActivity(index) {
        this.form.activities.splice(index, 1);
      },
      addSkill() {
        this.form.skills.push({
          name: "",
        });
      },
      deleteSkill(index) {
        this.form.skills.splice(index, 1);
      },
      addBenefit() {
        this.form.benefits.push("");
      },
      deleteBenefit(index) {
        this.form.benefits.splice(index, 1);
      },
      addKnowledge() {
        if (this.form.knowledges.length < 10) {
          this.form.knowledges.push({
            name: "",
            level: "",
          });
        } else
          this.$buefy.toast.open({
            message: "Límite máximo de conocimientos alcanzado",
            type: "is-warning",
          });
      },
      deleteKnowledge(index) {
        this.form.knowledges.splice(index, 1);
      },
      focusedKnowledge(index) {
        this.knowledgeIndex = index;
      },
      async getUsers() {
        this.authors = await getCompanyContacts(this.companyId);
      },
      openCreateDialog() {
        this.$refs.create.open();
      },
      setAuthor(id) {
        this.form.author = id;
      },
      addCompanyUser(id) {
        this.form.additionalAuthors.push(id);
      },
      popCompanyUser(index) {
        this.form.additionalAuthors.splice(index, 1);
      },
      getAuthorName(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.fullName ? author.fullName : "Sin Asignar";
      },
      getAuthorEmail(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.email;
      },
    },
  };
</script>

<style >

</style>
