export const userCreateForm = {
  data () {
    return {
      form: {
        names: '',
        paternalSurname: '',
        maternalSurname: '',
        email: '',
        role: '',
        recruiter: {
          calendlyUrl: null,
        },
        company: null
      }
    }
  }
}