<template>
  <div>
    <div>
      <div class="title is-4 has-text-blue mb-4">{{ feedbackTranslation.companyComments }}</div>
    </div>

    <b-input
      placeholder="Feedback del candidato"
      type="textarea"
      v-model="form.feedback"
    />
    <div class="is-flex justify-flex-end pt-10">
      <b-button
        type="is-primary"
        @click="sendCompanyInterview"
      >
        Agregar comentarios y concluir entrevista
      </b-button>
    </div>
  </div>
</template>

<script>
import { companyFeedback } from '@/mixins/companyFeedback';
import { sendCompanyFeedback } from "../../../api/candidate";


export default {
  props: {
  },
  mixins: [companyFeedback], 
  data() {
    return {
      
    };
  },
  computed: {
    feedbackTranslation() {
        return this.$t("screens.candidate.detail");
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async sendCompanyInterview() {
      this.$buefy.dialog.confirm({
        title: `¿Cerrar la entrevista?`,
        message: `Al cerrarla, tu feedback del candidato no podrá ser editado, asegúrate de haber escrito
                un feedback completo de tu candidato.`,
        confirmText: "Ok, estoy de acuerdo",
        cancelText: "Ooops, mejor no",
        onConfirm: async () =>{
          this.isLoading = true;
          try {
            await sendCompanyFeedback(this.id,  this.form);
             this.$emit("companyFeedbackUpdated");
          } catch (error) {
            console.log(error);
            this.$buefy.toast.open({
              message: "Hubo un error al enviar el feedback",
              type: "is-danger",
            });
          } finally{
            this.isLoading = false;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>