export default {
  // FAT-4
  generalProfile: 'Perfil general',
  birthday: 'Nacimiento',
  gender: 'Género',
  pronoun: 'Pronombre',
  anyDisability: '¿Alguna discapacidad?',
  typeDisability: 'Tipo de discapacidad',
  telephone: 'Teléfono',
  mail: 'Correo',
  nationalIdentifier: 'CURP',
  state: 'Estado',
  city: 'Ciudad',
  academicProfile: 'Perfil académico',
  university: 'Universidad',
  campus: 'Campus',
  career: 'Carrera',
  studyArea: 'Área de estudio',
  academicLevel: 'Nivel académico',
  currentSemester: 'Semestre actual',
  graduateDate: 'Fecha de graduación',
  degreeDate: 'Fecha de titulación',
  skillsKnowledgeProfile: 'Perfil de competencias y conocimientos',
  skills: 'Habilidades',
  interests: 'Intereses',
  englishLevel: 'Nivel de inglés',
  excelLevel: 'Nivel de excel',
  schoolSchedule: 'Horario escolar',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miercoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  professionalProfile: 'Perfil profesional',
  curriculum: 'Curriculum',
  psychometryApplicationDate: 'Fecha de aplicación de psicometría',
  portfolio: 'Portafolio / Blog',
  vacancyFoundSource: 'Fuente de candidato',
  noRecordedVacancyFound: 'No se ha registrado la fuente del candidato',

  // FAT-6
  characterProfile: 'Perfil de personalidad',
  recommendable: 'Recomendable',
  familyEnviroment: 'Entorno familiar',
  freeTime: 'Tiempo libre',
  sports: 'Deportes',
  character: 'Personaje',
  challenges: 'Retos',
  achievements: 'Logros',
  mediumTermGoals: 'Metas a mediano plazo',
  longTermGoals: 'Metas a largo plazo',
  strengths: 'Fortalezas',
  skillToImprove: 'Habilidades por mejorar',
  careerPath: 'Trayectoria profesional',
  capabilityOne: '1er competencia',
  capabilityTwo: '2da competencia',
  capabilityThree: '3ra competencia',
  comments: 'Observaciones',
  addComments: 'Agregar comentarios y concluir entrevista',
  companyComments: 'Comentarios de la empresa',
  noCompanyComments: 'No hay comentarios por parte de la empresa.',

  // Custom Evaluation
  customEvaluation: 'Evaluación personalizada',
  urlVideo: 'Vídeo de presentación',
  noUrlVideo: 'Aún no hay vídeo de presentación',
  uploadFile: 'Archivo de aplicación del candidato',
  noUploadFile: 'Aún no hay archivo de aplicación del candidato',
  addCustomEvaluation: 'Agregar evaluación personalizada',
  aditionalComments: 'Comentarios adicionales',
  noAditionalComments: 'Aún no hay comentarios adicionales',
}
