export default {
    all: {
        text: 'Todas',
        color: 'dark'
    },
    created: {
        text: 'Nueva',
        color: 'blue'
    },
    published: {
        text: 'Publicada',
        color: 'primary'
    },
    closed: {
        text: 'Cerrada',
        color: 'danger'
    },
    canceled: {
        text: 'Cancelada',
        color: 'grey'
    },
    reviewing: {
        text: 'En revisión',
        color: 'warning'
    }
}