export const vacancyCreateForm = {
  data () {
    return {
      form: {
        name: null,
        duration: null,
        places: 1,
        location: {
          state: null,
          city: null,
        },
        schedule: null,
        showScholarship: false,
        scholarship: null,
        modality: null,
        degree: null,
        academicLevel: [],
        minimumSemester: 0,
        maximumSemester: 0,
        excelLevel: null,
        englishLevel: null,
        englishLevels: {
          language: 'Inglés',
          listening: "",
          reading: "",
          speaking: "",
          writing: "",
          representativeSkill: [],
        },
        otherLanguages: [],
        graduateDate: null,
        degreeDate: null,
        skills: [],
        goal: null,
        benefits: [''],
        knowledges: [],
        activities: ['','',''],
        comments: null,
        otherFields: [],
        mondayVacancyId: null,
        projectMondayId: null,
        schemaType: null,
        serviceType: null,
        serviceDetail: null,
        claimType: null,
        author: '',
        authorName: null,
        authorEmail: null,
        authorPhone: null,
        newAuthorRole: 'newRole',
        recruiter: null,
        additionalAuthors: []
      },
    }
  },
}
