<template>
  <div class="companies-container is-multiline">
    <SimpleCollapse
      title="Histórico de Empresas"
      :isLoading="isLoading"
      icon="fa-regular fa-chart-line"
      description="Cantidad de empresas creadas por cada mes, en el tiempo seleccionado (no se toma en cuenta el filtro de especialistas)."
      :data="data"
      @open="getData"
      ref="collapse"
    >
      <template #content="{ data }">
        <SimpleBarColumn
          :barData="data.timeline"
          :headers="['Fecha', 'Empresa']"
          chart-height="300px"
        />
      </template>
    </SimpleCollapse>
  </div>
</template>

<script>
import SimpleBarColumn from "./SimpleBarColumn.vue";
import SimpleCollapse from "./SimpleCollapse.vue";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },
  components: {
    SimpleBarColumn,
    SimpleCollapse,
  },
  watch: {
    search: {
      handler: async function () {
        await this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        timeline: {},
      },
    };
  },
  async mounted() {},
  methods: {
    /* Top level function to get content */
    async getData() {
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true;
      const data = await this.loadFunction(this.search);
      this.data = data;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.companies-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.companies-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto, 10fr);
  gap: 0.4rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
</style>
