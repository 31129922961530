<template>
  <nav 
    class="breadcrumb" 
    aria-label="breadcrumbs">

    <ul>
      
      <li>
        <router-link
          to="/empresas">
          <b-icon 
            size="is-small" 
            icon="buildings" />
            Empresas
        </router-link>
      </li>

      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="`breadcrumb-${index}`"
        :class="$route.name === breadcrumb.name || breadcrumb.name === 'Company'
          ? 'is-active'
          : ''">
        <router-link
          :class="$route.name === breadcrumb.name ? 'has-text-weight-bold' : ''"
          :to="breadcrumb.path">
          {{ routeNames[breadcrumb.name] }}
        </router-link>
      </li>
      
      <transition name="fade">
        <li
          v-if="projectName"
          class="ml-3"
        >
          &nbsp;
          {{ projectName }}
        </li>
      </transition>

    </ul>

  </nav>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbs: [],
    }
  },
  computed: {
    company () {
      return this.$store.state.company
    },
    projectId () {
      return this.$store.state.project.id
    },
    projectName () {
      return this.$store.state.project.name
    },
    id () {
      return this.$route.params.id || null
    },
    routeNames () {
      return {
        Company: this.company && this.company.name
          ? this.company.name
          : '',
        CompanyDetail: 'Estadísticas de empresa',
        LandingPage: 'Landing Page',
        Projects: 'Proyectos',
        Vacancies: 'Vacantes',
        VacanciesNew: 'Nuevas',
        CompanyUsersContact: 'Contactos',
        AccountHome: 'Becarios',
        CompanyUsers: 'Usuarios',
        CompanySettings: 'Configuración',
      }
    }
  },
  watch: {
    $route (to) {
      this.breadcrumbs = to.matched
        // .filter(bc => !bc.redirect)
        .map(bc => Object.assign({}, bc, { path: bc.path.replace(':id', this.id) })) || []
    }
  },
  beforeMount: async function () {
    this.breadcrumbs = this.$route.matched
      // .filter(bc => !bc.redirect)
      .map(bc => Object.assign({}, bc, { path: bc.path.replace(':id', this.id) })) || []
  }
}
</script>

<style>

</style>