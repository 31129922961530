export default [
  'Automotriz',
  'Consumo',
  'Entretenimiento',
  'Farmacéutica',
  'Financiero',
  'Industrial',
  'Inmobiliario',
  'Logística y Transporte',
  'Marketing y Medios',
  'Organizaciones civiles',
  'Retail',
  'Servicios al consumidor',
  'Servicios de negocio',
  'Tecnología',
  'Telecomunicaciones'
]