export function replaceAccent (term) {
  if (term) {
    const accents = /á|é|í|ó|ú/
    const word = term.toLowerCase()
    return word.replace(accents, (s) => {
      if (s === 'á') return 'a'
      if (s === 'é') return 'e'
      if (s === 'í') return 'i'
      if (s === 'ó') return 'o'
      if (s === 'ú') return 'u'
    })
  }
  return term
}