<template>
  <SimpleCollapse
    title="Perfiles de aplicación"
    :isLoading="isLoading"
    description="Información general de los perfiles que aplican."
    icon="fa-regular fa-brain-circuit"
    :data="data"
    @open="getData"
    ref="collapse"
  >
  <template #content="{ data }">
    <PieTable
      :pieTableData="data.byStatus"
      title="Estado de aplicación"
      termsKey="applicationsGrossProfile.byStatus"
      :headers="['Estado', 'Cantidad']"
      chartHeight="300px"
    />

    <hr class="separation-line">
    <div class="columns is-multiline">
      <SimplePie
        class="column is-6"
        :pieData="data.byGender"
        title="Género"
        :headers="['Género', 'Cantidad']"
        chart-height="250px"
      />
      <SimplePie
        class="column is-6"
        :pieData="data.byArea"
        title="Área de estudio"
        :headers="['Área de estudio', 'Cantidad']"
        chart-height="250px"
      />
    </div>

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byLocation"
      title="Ubicación"
      :headers="['Ciudad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byUniversity"
      title="Universidad"
      :headers="['Universidad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byAge"
      title="Edad"
      :headers="['Edad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byDisability"
      title="Discapacidad"
      :headers="['Discapacidad', 'Cantidad']"
      chartHeight="250px"
    />

    <hr class="separation-line">
    <PieTable
      :pieTableData="data.byVacancyFoundSource"
      title="Fuente de vacante"
      :headers="['Fuente', 'Cantidad']"
      chartHeight="250px"
    />

  </template>
  </SimpleCollapse>
</template>

<script>
import SimpleCollapse from './SimpleCollapse.vue';
import SimplePie from './SimplePie.vue';
import PieTable from './PieTable.vue';

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      required: true
    }
  },
  components: {
    SimplePie,
    SimpleCollapse,
    PieTable
  },
  watch: {
    search: {
      handler: async function(){
        await this.getData()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        byStatus: {},
        byVacancyFoundSource: {},
        byGender: {},
        byLocation: {},
        byUniversity: {},
        byArea: {},
        byAge: {},
        byDisability: {},
      },
    }
  },
  async mounted() {
  },
  methods: {
    /* Top level function to get content */
    async getData(){
      if (!this.$refs.collapse.getIsOpened())
        return

      this.isLoading = true
      const data = await this.loadFunction(this.search)
      this.data = data
      this.isLoading = false
    },
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.separation-line{
  margin: 1.5rem 0;
  height: 2px;
}
</style>
