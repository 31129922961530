<template>
  <div>
    <div class="columns">
      <div class="column">
        <span class="is-size-3">Empresas archivadas</span>
      </div>
    </div>
    <div class="mb-20 is-flex justify-flex-end">
      <b-input
        v-model="search"
        class="mr-10"
        placeholder="Buscar empresa"
        icon="magnifying-glass"
        rounded
      />
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-multiline">
          <div
            v-for="company in companies"
            :key="company.id"
            class="column is-4"
          >
            <CompanyCard
              deleted
              :company="company"
              @update="getCompanies"
              @delete="getCompanies"
            />
          </div>
        </div>
      </div>
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import CompanyCard from "@/components/CompanyCard";

  export default {
    components: {
      CompanyCard,
    },
    data() {
      return {
        isLoading: false,
        search: "",
        companies: [],
      };
    },
    computed: {
      user() {
        return this.$store.state.session.user;
      },
    },
    watch: {
      async search(val) {
        if (val && val.length > 3) {
          let params = `name=${val}&deleted=true`;
          if (this.$isRole(this.user) === "recruiter") {
            params = `name=${val}&recruiter=${this.user.id}&deleted=true`;
          }
          await this.getCompanies(params);
        } else {
          await this.getCompanies();
        }
      },
    },
    async mounted() {
      await this.getCompanies();
    },
    methods: {
      updateColor(val) {
        this.form.mainColor = val.hex;
      },
      async getCompanies(params) {
        this.isLoading = true;
        try {
          params = params || [`deleted=true`];

          const response = await this.$api.get(`/companies?${params.join("&")}`);
          this.companies = response.data.data;
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }
        this.isLoading = false;
      },
    },
  };
</script>
