<template>
  <div>
    <b-modal :active.sync="isOpen" :destroy-on-hide="false" @cancel="close" aria-modal aria-role="dialog" has-modal-card
      trap-focus>

      <div class="modal-card">

        <div class="modal-card-head">
          <div class="modal-card-title">
            {{modalTranlation.title}}
          </div>
        </div>

        <div class="modal-card-body">
          <ValidationObserver ref="observer">

            <ValidationProvider rules="required" name="Rol" v-slot="{ errors, valid }">
              <b-field label="Rol" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-select v-model="form.role" icon="user" expanded>
                  <option v-for="(role, index) in roles" :key="`role-${index}`" :value="role.value">
                    {{ role.text }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="required|max:25" name="Nombre(s)" v-slot="{ errors, valid }">
              <b-field :label="modalTranlation.name" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input v-model="form.names" icon="signature" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="required|max:25" name="Apellido paterno" v-slot="{ errors, valid }">
              <b-field :label="modalTranlation.paternalSurname" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input v-model="form.paternalSurname" icon="signature" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="max:25" name="Apellido materno" v-slot="{ errors, valid }">
              <b-field :label="modalTranlation.maternalSurname" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input v-model="form.maternalSurname" icon="signature" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="required|email" name="Email" v-slot="{ errors, valid }">
              <b-field :label="modalTranlation.email" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input v-model="form.email" icon="at" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider v-if="authRoles.recruitmentRoles.includes(form.role)" rules="required" name="Calendly"
              v-slot="{ errors, valid }">
              <b-field :label="modalTranlation.calendlyUrl" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input v-model="form.recruiter.calendlyUrl" icon="link" />
              </b-field>
            </ValidationProvider>
          </ValidationObserver>
        </div>

        <div class="modal-card-foot is-justify-content-flex-end">
          <b-button rounded @click="close">
            {{ modalTranlation.buttons.cancel }}
          </b-button>
          <b-button rounded type="is-primary" @click="create">
            {{modalTranlation.buttons.save}}
          </b-button>
        </div>

      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import { createUser } from '@/api/user'
import { baseRoles, contactRoles, authRoles, allowedRecruitersManagersCreationRoles, allowedDesignersManagersCreationRoles } from '@/lib/roles.js'
import { userCreateForm } from '@/mixins/user.js'

export default {
  props: {
    contactUsers: {
      type: Boolean,
      default: false
    },
    company: {
      type: String,
      default: null
    }
  },
  mixins: [userCreateForm],
  data() {
    return {
      baseRoles,
      contactRoles,
      authRoles,
      allowedRecruitersManagersCreationRoles, allowedDesignersManagersCreationRoles,
      isLoading: false,
      isOpen: false,
      companies: []
    }
  },
  watch: {
    'form.email': function (val) {
      if (val) this.form.email = this.form.email.toLowerCase()
    }
  },
  computed: {
    roles() {
      return this.getPermittedRoles()
    },
    modalTranlation () {
      return this.$t('screens.vacancies.edit.solicitants.modal')
    }
  },
  methods: {
    open() {
      this.isOpen = true
      this.getCompanies()
    },
    close() {
      this.clean()
      this.isOpen = false
    },
    async create() {
      try {
        const valid = await this.$refs.observer.validate()
        if (valid) {
          this.isLoading = true
          const payload = this.buildPayload()
          await createUser(payload)
          this.$emit('updated')

          this.clean()
          this.close()
        }
      } finally {
        this.isLoading = false
      }
    },
    buildPayload() {
      const payload = { ...this.form }

      if (!this.allowedRecruitersManagersCreationRoles.includes(this.form.role) && !this.form.recruiter.calendlyUrl) {
        delete payload.recruiter;
      }


      if (this.company) {
        payload.company = this.company
      }
      return payload
    },
    async getCompanies() {
      this.isLoading = true
      try {
        const response = await this.$api.get(`/companies/all`)
        this.companies = response.data
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    getPermittedRoles() {
      if (this.contactUsers)
        return contactRoles

      if (this.$isRole() === 'recruiter-manager') {
        return allowedRecruitersManagersCreationRoles
      } else if (this.$isRole() === 'designer-manager') {
        return allowedDesignersManagersCreationRoles
      }
      return baseRoles
    },
    clean() {
      this.form = {
        names: '',
        paternalSurname: '',
        maternalSurname: '',
        email: '',
        role: '',
        recruiter: {
          calendlyUrl: null,
        },
        company: null
      }
      this.$refs.observer.reset()
    }
  }
}
</script>