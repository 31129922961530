import { render, staticRenderFns } from "./RecruitmentLandingPage.vue?vue&type=template&id=6071d70f&scoped=true"
import script from "./RecruitmentLandingPage.vue?vue&type=script&lang=js"
export * from "./RecruitmentLandingPage.vue?vue&type=script&lang=js"
import style0 from "./RecruitmentLandingPage.vue?vue&type=style&index=0&id=6071d70f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6071d70f",
  null
  
)

export default component.exports