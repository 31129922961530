<template>
  <div class="single"
    v-if="!isLoading"
    :style="`background-color: ${bgColor}; color: ${textColor}`"
  >
    <div class="single-half">
      <f-icon :class="`is-size-${size} opacity-8`" :icon="icon"/>
      <span :class="`is-size-${size} has-text-centered has-text-weight-semibold`">{{ format(value) }}</span>
    </div>
    
    <div class="single-half">
      <span :class="`is-size-${Number(size)+2} has-text-centered`">{{ title }}</span>
    </div>

    <div class="single-half" 
      v-if="percentageRange"
    >
      <p :class="`is-size-${Number(size)+3} opacity-9`">{{ format((value/percentageRange) * 100) }}%</p>
    </div>
  </div>

  <div class="single"
    v-else
    :style="`background-color: ${bgColor}; color: ${textColor}`"
  >
    <b-loading
      :is-full-page="false"
      :active="isLoading"
    />
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Grafica'
    },
    value: {
      type: Number,
      default: 0
    },
    percentageRange: {
      type: Number,
    },
    icon: {
      type: String,
      default: 'fa-regular fa-chart-pie'
    },
    bgColor: {
      type: String,
      default: 'white'
    },
    textColor: {
      type: String,
      default: '#38bba7'
    },
    size: {
      type: String,
      default: '3'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    format(value){
      return Number(value).toFixed(2).replace(/[.,]00$/, "")
    }
  }
};
</script>

<style lang="scss" scoped>
.single {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  position: relative;
  min-height: 100px;
}
.single-half{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-grow: 1;
}
</style>
