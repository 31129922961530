<template>
  <div>
    <div class="column is-full">
      <div class="is-flex justify-flex-end">
        <b-button
          :to="{ name: 'VacancyCandidates', params: { id: data.vacancy._id }, query: query }"
          icon-left="arrow-left"
          rounded
          tag="router-link"
          type="is-primary"
        >
          Regresar
        </b-button>
      </div>
    </div>
    
    <!-- Banner de candidato -->
    <div class="column is-full mt-2 candidate-header">
      <!-- Nombre y contacto -->
      <div class="is-flex is-justify-content-space-between">
        <h1 class="title has-text-primary mb-2 is-size-2">
          {{ data.profile.firstName }} {{ data.profile.firstSurname }} {{ data.profile.lastSurname }}
        </h1>

        <div>
          <!-- Enail -->
          <b-button
            size="is-small"
            type="is-info is-light"
            class="mr-2"
            rounded
            v-clipboard:copy="data.profile.email"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ data.profile.email }}
            <f-icon icon="fa-regular fa-copy" />
          </b-button>

          <!-- Whatsapp -->
          <b-button
            size="is-small"
            type="is-success is-light"
            class="mr-2"
            rounded
            @click="openWhatsapp"
          >
            <span
              class="is-flex is-align-items-center"
            >
              {{ data.profile.phoneCode }}
              {{ data.profile.phone }}
              <img
                src="@/assets/whatsapp_logo.webp"
                alt="ProMeritum"
                width="17px"
                class="ml-1"
              >
            </span>
          </b-button>
        </div>
      </div>

      <!-- Tags de aplicación -->
      <section class="is-flex is-justify-content-space-between">
        <!-- Tags primarios -->
        <div class="is-flex mt-2">
          <!-- Status de aplicación -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Estado de aplicación" position="is-bottom"     :type="`${statusTypes[data.status]}`">
            <b-tag
              rounded
              :type="`${statusTypes[data.status]}`"
              class="has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-user-magnifying-glass" />
              {{ statuses[data.status] }}
            </b-tag>
          </b-tooltip>

          <!-- Fecha de postulación -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Fecha de postulación" position="is-bottom" type="is-info is-light">
            <b-tag
              rounded
              type="is-info is-light has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-calendar-range" />
              {{ data.createdAt | formatDate }}
            </b-tag>
          </b-tooltip>
          
          <!-- Excel level -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Nivel de Excel" position="is-bottom" type="is-light">
            <b-tag
              rounded
              type="is-light has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-file-excel" />
              {{ data.profile.excelLevel }}
            </b-tag>
          </b-tooltip>

          <!-- Nivel de inglés -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Nivel de inglés" position="is-bottom" type="is-light">
            <b-tag
              rounded
              type="is-light has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-language" />
              {{ data.profile.englishLevel }}
            </b-tag>
          </b-tooltip>

          <!-- Fecha de término de materias -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Fecha de término de materias" position="is-bottom" type="is-light">
            <b-tag
              rounded
              type="is-light has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-graduation-cap" />
              {{ data.profile.graduateDate | formatDate }}
            </b-tag>
          </b-tooltip>

          <!-- Carrera -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Carrera" position="is-bottom" type="is-light">
            <b-tag
              rounded
              type="is-light has-text-weight-bold"
            >
              <f-icon icon="fa-regular fa-user-graduate" />
              {{ data.profile.career }}
            </b-tag>
          </b-tooltip>
        </div>

        <!-- Tags de aplicación secundarios -->
        <div class="is-flex mt-2">
          <!-- Status de aplicación -->
          <b-tooltip animated class="is-flex is-align-items-end mr-2" 
            label="Última aplicación seleccionada" 
            position="is-bottom"     
            :type="`${statusTypes[lastSelectedApplication?.status]}`" 
            v-if="lastSelectedApplication"
          >
            <b-button
              size="is-small"
              class="mr-2"
              rounded
              :type="`${statusTypes[lastSelectedApplication?.status]}`" 
              v-if="lastSelectedApplication && data._id !== lastSelectedApplication._id"
              @click="openApplication(lastSelectedApplication)"
            >
              <f-icon icon="fa-regular fa-person-to-door" />
              {{ statuses[lastSelectedApplication?.status] }}
              en
              {{ lastSelectedApplication?.company.name }}
              hace 
              {{ timeAgo(lastSelectedApplication?.createdAt) }}
              <f-icon icon="fa-regular fa-up-right-from-square" />
            </b-button>
          </b-tooltip>
        </div>
      </section>

      <!-- Banner de vacante y aplicacion -->
      <div class="column p-0 mt-5">
        <b-tooltip animated class="is-flex is-align-items-end mr-2" label="Vacante" position="is-top" type="is-light">
          <h2 class="title is-4 m-0" :style="`color: ${data.company.mainColor || '#42CBB6'}`">
            {{ data.vacancy.name }} en {{ data.company.name }}
            <b-tag
              rounded
              type="is-info is-light"
            >
              Reclutamiento: {{ data.typeCustomRecruitment ?? "Normal" }}
            </b-tag>
          </h2>
        </b-tooltip>
      </div>
    </div>

    <!-- Descarga de perfil e historial de logs-->
    <div class="columns column is-full" :style="{marginTop:'1rem'}">
      <div class="is-flex is-justify-content-space-between column is-8 p-0">
        <b-button
          @click="$refs.downloadProfile.open()"
          type="is-primary"
        >
          Descargar perfil en PDF
        </b-button>
        <b-button
          type="is-primary"
          icon-left="clock-rotate-left"
          v-if="canSeeLogs"
          @click="openLogsModal">
          Ver historial de cambios
        </b-button>
      </div>

      <DownloadCandidateProfile
        :application="data"
        ref="downloadProfile"
      />
    </div>
    <LogsModal v-if="toggleLogs" :isModalActive="toggleLogs" @close-modal="closeModal" :profileLogsData="profileLogsData" :id="data._id"/>
  </div>
</template>

<script>
  import DownloadCandidateProfile from "@/components/modals/DownloadCandidateProfile";
  import { allowedViewerToCandidateLogs } from '../../lib/permissions';
  import LogsModal from './LogsModal.vue';
  import moment from "moment";

  export default {
    props:{
      data: {
        type: Object,
        required: true,
      },
    },
    components: {
      DownloadCandidateProfile,
      LogsModal,
    },
    data() {
      return {
        statuses: {
          applying: "En aplicación",
          selected: "Seleccionad@",
          sent: "En revisión con la empresa",
          rejected: "Rechazad@",
          "pre-hired": "Seleccionad@ por la empresa",
          hired: "Contratad@",
        },
        statusTypes: {
          applying: 'is-success',
          selected: 'is-blue',
          sent: 'is-warning',
          "pre-hired": 'is-danger',
          rejected: 'is-black'
        },
        toggleLogs : false, //Boton para ver historial de cambio
        profileLogsData : {}
      };
    },
    computed: {
      query() {
        return this.$route.query;
      },
      lastSelectedApplication() {
        const lastSelected = this.data.timeline?.find(
          (application) => application.status === "selected" || application.status === "sent" || application.status === "pre-hired" || application.status === "hired"
        );
        return lastSelected;
      },
      canSeeLogs () {
        return allowedViewerToCandidateLogs.includes(this.$isRole())
      }
  },
  methods: {
    openLogsModal(){      
      this.profileLogsData = {
        name: this.data?.candidate || "",
      }
      this.toggleLogs = true
    },
    closeModal(){
      this.toggleLogs = false
    },
    onCopy () {
    this.$buefy.toast.open({
      message: 'Correo copiado',
      type: 'is-info'})
    },
    onError (e) { + e.text
      this.$buefy.toast.open({
        message: 'No se copió el correo ' + e.text,
        type: 'is-danger'})
    },
    openWhatsapp(){
      const whatsappLink = ` https://wa.me/${this.data.profile.phoneCode}${this.data.profile.phone}`

      window.open(whatsappLink, '_blank')
    },
    openApplication(application){
      this.$router.push({name: 'CandidateDetail', params: {id: application._id}})
    },
    timeAgo(d) {
      if (d) {
        const date = moment(d).locale("es");
        return date.fromNow();
      }
      return "";
    },
  }
    
  };
</script>

<style scoped lang="scss">
.candidate-header {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
}

</style>