import chunk_1 from './chunk-1.json'
import chunk_2 from './chunk-2.json'
import chunk_3 from './chunk-3.json'
import chunk_4 from './chunk-4.json'
import chunk_5 from './chunk-5.json'

export default [
    ...chunk_1,
    ...chunk_2,
    ...chunk_3,
    ...chunk_4,
    ...chunk_5
]