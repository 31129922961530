<template>
  <b-modal
    :active.sync="isOpen"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-moda
    full-screen
    :can-cancel="false">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head justify-space-between">
        <p class="modal-card-title">Documentos de {{ (documentsData.profile || {} ).firstName }} {{ (documentsData.profile || {} ).firstSurname }} {{ (documentsData.profile || {} ).lastSurname }}</p>

        <b-button
          label="Cerrar"
          type="is-primary"
          @click="close()" />
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).proofStudy && (documentsData.profile || {} ).proofStudy.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Comprobante de estudios
                  </div>
                  <div>
                    <div v-if="(documentsData.profile || {} ).proofStudy">
                      <a :href="(documentsData.profile || {} ).proofStudy.url" target="_blank" rel="noopener noreferrer">
                        Ver documento
                      </a>
                      <b-switch
                        v-model="(documentsData.profile || {} ).proofStudy.valid"
                        class="ml-10"
                        type="is-success"
                        passive-type="is-danger"
                        @input="validateDocument($event, 'proofStudy')">
                        Documento verificado
                      </b-switch>
                    </div>
                    <div v-else>
                      Sin documento
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).proofBankAccount && (documentsData.profile || {} ).proofBankAccount.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Comprobante de Cuenta Bancaria
                  </div>
                  <div v-if="(documentsData.profile || {} ).proofBankAccount">
                    <a :href="(documentsData.profile || {} ).proofBankAccount.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).proofBankAccount.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'proofBankAccount')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).secureDocument && (documentsData.profile || {} ).secureDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Comprobante de seguro
                  </div>
                  <div v-if="(documentsData.profile || {} ).secureDocument">
                    <a :href="(documentsData.profile || {} ).secureDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).secureDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'secureDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).birthCertificateDocument && (documentsData.profile || {} ).birthCertificateDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Acta de nacimiento
                  </div>
                  <div v-if="(documentsData.profile || {} ).birthCertificateDocument">
                    <a :href="(documentsData.profile || {} ).birthCertificateDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).birthCertificateDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'birthCertificateDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).idDocument && (documentsData.profile || {} ).idDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    INE o Pasaporte
                  </div>
                  <div v-if="(documentsData.profile || {} ).idDocument">
                    <a :href="(documentsData.profile || {} ).idDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).idDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'idDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).curpDocument && (documentsData.profile || {} ).curpDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    CURP
                  </div>
                  <div v-if="(documentsData.profile || {} ).curpDocument">
                    <a :href="(documentsData.profile || {} ).curpDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).curpDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'curpDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).rfcDocument && (documentsData.profile || {} ).rfcDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    RFC
                  </div>
                  <div v-if="(documentsData.profile || {} ).rfcDocument">
                    <a :href="(documentsData.profile || {} ).rfcDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).rfcDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'rfcDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).addressDocument && (documentsData.profile || {} ).addressDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Comprobante de Domicilio
                  </div>
                  <div v-if="(documentsData.profile || {} ).addressDocument">
                    <a :href="(documentsData.profile || {} ).addressDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).addressDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'addressDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).agreementSignedDocument && (documentsData.profile || {} ).agreementSignedDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Convenio firmado
                  </div>
                  <div v-if="(documentsData.profile || {} ).agreementSignedDocument">
                    <a :href="(documentsData.profile || {} ).agreementSignedDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).agreementSignedDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'agreementSignedDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div class="is-flex justify-space-between">
                  <div>
                    <b-icon v-if="(documentsData.profile || {} ).certificationsDocument && (documentsData.profile || {} ).certificationsDocument.valid" type="is-success" icon="check"/>
                    <b-icon v-else type="is-danger" icon="close"/>
                    Certificaciones
                  </div>
                  <div v-if="(documentsData.profile || {} ).certificationsDocument">
                    <a :href="(documentsData.profile || {} ).certificationsDocument.url" target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>

                    <b-switch
                      v-model="(documentsData.profile || {} ).certificationsDocument.valid"
                      class="ml-10"
                      type="is-success"
                      passive-type="is-danger"
                      @input="validateDocument($event, 'certificationsDocument')">
                      Documento verificado
                    </b-switch>
                  </div>
                  <div v-else>
                    Sin documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>

    <b-loading :active.sync="isLoading" />
  </b-modal>
</template>

<script>
export default {
  name: 'DocumentsModal',
  props: {
    data: {
      type: Object,
      default: () => ({
        profile: {}
      })
    }
  },
  data () {
    return {
      isLoading: false,
      isOpen: false,
      documentsData: {}
    }
  },
  watch: {
    data: {
      handler: function (val) {
        if (val) {
          this.documentsData = val
        }
      },
      deep: true
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.thanks = false
    },
    async validateDocument (val, document) {
      this.isLoading = true
      try {
        const { data } = await this.$api.post(`/candidates/${this.data._id}/verify-document`, { document: document, value: val })
        this.documentsData = data
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<style>

</style>