<template>
  <div>
    <b-field label="Correo electrónico">
      <b-input type="email" v-model="form.email" />
    </b-field>
    <b-field label="Contraseña">
      <b-input type="password" password-reveal v-model="form.password" />
    </b-field>
    <div class="columns is-multiline">
      <div class="column is-12 has-text-right is-flex is-flex-direction-row-reverse">
        <b-button type="is-primary" @click="login">
          Inicia sesión
        </b-button>
        <b-button icon-left="arrow-left" type="is-light" @click="goBack">
        </b-button>
      </div>
      <div class="column is-12 has-text-centered">
        <router-link to="/recovery-password">¿Olvidaste tu contraseña?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
  watch: {
    "form.email": function (val) {
      if (val) this.form.email = this.form.email.toLowerCase();
    },
  },
  methods: {
    async login() {
      try {
        const { data } = await this.$api.post("/auth/signin", this.form);
        this.$store.dispatch("login", data);
        this.$router.push("/");
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data?.message,
          type: "is-danger",
          duration: 6000,
        });
      }
    },
    goBack() {
      this.$emit("go-back");
    },
  },
};
</script>
