<template>
  <div class="column is-12 m-0 is-sticky">
    <!-- Multimedia Type -->
    <b-field class="is-fullwidth">
      <b-select v-model="multimediaSource.type" placeholder="Selecciona un tipo" expanded @input="verifyMediaType">
        <option v-for="option in multimediaOptions" :value="option.type" :key="option.type">
          {{ option.label }}
        </option>
      </b-select>
    </b-field>

    <!-- Video url -->
    <div v-if="multimediaSource.type === 'video' || multimediaSource.type === 'embeded'" class="is-fullwidth">
      <label class="label is-size-6 has-text-weight-medium">URL del recurso</label>
      <b-field class="is-fullwidth">
        <b-input v-model="multimediaSource.source.url" placeholder="Inserta el enlace" @blur="updateMultimediaSource"></b-input>
      </b-field>
    </div>

    <!-- Image file selector -->
    <div v-if="multimediaSource.type === 'image'">
      <div class="mb-3">
        <label class="label is-size-6 has-text-weight-medium">Imagen de fondo</label>
        
        <div class="is-flex is-justify-content-center" v-if="!isLoading">
          <b-upload v-model="multimediaSource.sourceFile" accept="image/*" @input="uploadMediaImage">
            <a class="button is-primary">
              <b-icon icon="upload" />
            </a>
          </b-upload>
          <span v-if="multimediaSource.source?.name" class="file-name mr-5">
            {{ multimediaSource.source.name }}
          </span>
          <span v-else class="file-name mr-2">
            Selecciona una imagen de fondo
          </span>
          <b-button 
            type="is-danger"
            @click="deleteMediaImage"
            :disabled="!multimediaSource.source?.key"
          >
            <f-icon icon="fa-regular fa-trash-can" />
          </b-button>
        </div>
        <div class="loading-wrapper" v-else>
          <b-loading :active="isLoading" :is-full-page="false" />
        </div>
      </div>
    </div>

    <!-- Coordinates and size inputs -->
    <div class="mt-3">
      <div class="columns is-multiline is-vcentered mt-2">
        <div class="column is-12 mt-0 mb-0 pt-0 pb-0">
          <label class="label is-size-6 has-text-weight-medium">Coordenadas</label>
        </div>
        <div class="column is-auto">
          <b-input 
            v-model="multimediaSource.coordinates.x" 
            @blur="updateMultimediaSource" 
            placeholder="X %" 
          />
        </div>
        <div class="column is-auto">
          <b-input 
            v-model="multimediaSource.coordinates.y" 
            @blur="updateMultimediaSource" 
            placeholder="Y %" 
          />
        </div>
      </div>
      <div class="columns is-multiline is-vcentered">
        <div class="column is-12 mt-0 mb-0 pt-0 pb-0">
          <label class="label is-size-6 has-text-weight-medium">Tamaño</label>
        </div>
        <div class="column is-auto">
          <b-input 
            v-model="multimediaSource.sizes.width" 
            @blur="updateMultimediaSource" placeholder="Ancho %" 
            :disabled="multimediaSource.type === 'embeded'"
          />
        </div>
        <div class="column is-auto">
          <b-input 
            v-model="multimediaSource.sizes.height" 
            @blur="updateMultimediaSource" placeholder="Altura %"
            :disabled="multimediaSource.type === 'embeded'" 
          />
        </div>
      </div>
    </div>

    <!-- onClick link -->
    <div class="columns is-multiline is-vcentered mt-2">
      <div class="column is-12 mt-0 mb-0 pt-0 pb-0">
        <label class="label is-size-6 has-text-weight-medium">Destino al hacer click</label>
      </div>
      <div class="column is-auto">
        <b-input 
          v-model="multimediaSource.onClick" 
          @blur="updateMultimediaSource" 
          placeholder="Link destino" 
          :disabled="multimediaSource.type === 'embeded' || multimediaSource.type === 'video'"
        />
      </div>
    </div>

    <hr class="separation-line">

    <!-- Effect source -->
    <div>
      <label class="label is-size-6 has-text-weight-medium">Recurso con efectos</label>

      <!-- Effects selector -->
      <div class="is-flex is-flex-wrap-wrap">
        <div class="p-0 pl-1" v-for="(effect, index) in multimediaEffects" :key="index">
          <b-field>
            <b-checkbox 
              v-model="multimediaSource.effectSource.effects" 
              :native-value="effect"
              @input="updateMultimediaSource"
            >
              {{ effect }}
            </b-checkbox>
          </b-field>
        </div>
      </div>

      <!-- EffectSource type selector -->
      <b-field class="is-fullwidth mt-2">
        <b-select v-model="multimediaSource.effectSource.type" placeholder="Selecciona un tipo" expanded @input="verifyMediaEffectType">
          <option v-for="option in multimediaOptions" :value="option.type" :key="option.type">
            {{ option.label }}
          </option>
        </b-select>
      </b-field>

      <!-- EffectSource Video url -->
      <div v-if="multimediaSource.effectSource.type === 'video' || multimediaSource.effectSource.type === 'embeded'" class="is-fullwidth">
        <label class="label is-size-6 has-text-weight-medium">URL del recurso</label>
        <b-field class="is-fullwidth">
          <b-input v-model="multimediaSource.effectSource.source.url" placeholder="Inserta el enlace" @blur="updateMultimediaSource"></b-input>
        </b-field>
      </div>

      <!-- EffectSource Image file selector -->
      <div v-if="multimediaSource.effectSource.type === 'image'">
        <div class="mb-3">
          <label class="label is-size-6 has-text-weight-medium">Imagen de fondo</label>
          
          <div class="is-flex is-justify-content-center" v-if="!isLoadingEffectSource">
            <b-upload v-model="multimediaSource.effectSource.sourceFile" accept="image/*" @input="uploadMediaEffectImage">
              <a class="button is-primary">
                <b-icon icon="upload" />
              </a>
            </b-upload>
            <span v-if="multimediaSource.effectSource.source?.name" class="file-name mr-5">
              {{ multimediaSource.effectSource.source.name }}
            </span>
            <span v-else class="file-name mr-2">
              Selecciona una imagen de fondo
            </span>
            <b-button 
              type="is-danger"
              @click="deleteMediaEffectImage"
              :disabled="!multimediaSource.effectSource.source?.key"
            >
              <f-icon icon="fa-regular fa-trash-can" />
            </b-button>
          </div>
          <div class="loading-wrapper" v-else>
            <b-loading :active="isLoadingEffectSource" :is-full-page="false" />
          </div>
        </div>
      </div>
    </div>

    <div class="is-flex is-justify-content-flex-end mt-5">
      <b-button type="is-danger" icon-left="trash" @click="removeResource(index)">
        Eliminar recurso
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      multimediaSource:{
        type: "image",
        source: {
          url: ''
        },
        sourceFile: null,
        coordinates: {
          x: '',
          y: ''
        },
        sizes: {
          width: '',
          height: ''
        },
        onClick: '',
        effectSource: {
          type: "image",
          source: {
            url: ''
          },
          sourceFile: null,
          effects: []
        },
      },
      isLoading: false,
      isLoadingEffectSource: false,
      multimediaOptions: [
        { type: 'image', label: 'Imagen' },
        { type: 'video', label: 'Video' },
        { type: 'embeded', label: 'Inserción' }
      ],
      multimediaEffects: ["hover", "visited"],
    };
  },
  props: {
    index: {
      type: Number,
      required: true // El índice de la fuente multimedia
    },
    defaultMultimediaSource: {
      type: Object,
      required: true
    },
    uploadImage: {
      type: Function
    },
    deleteImage: {
      type: Function
    },
  },
  mounted() {
    this.multimediaSource = {...this.multimediaSource, ...this.defaultMultimediaSource}
  },
  watch: {
    defaultMultimediaSource: {
      handler(val) {
        if (val) {
          this.multimediaSource = {...this.multimediaSource, ...val}
        }
      },
    },
  },
  methods: {
    updateMultimediaSource(){
      this.$emit('updateMultimediaSource', this.index, this.multimediaSource);
    },
    async uploadMediaImage(){
      this.isLoading = true
      const file = this.multimediaSource.sourceFile;
      const fileIdentifier = `section-source-${this.multimediaSource._id}`;
      const fileMetadata = this.multimediaSource.source;

      try {
        this.multimediaSource.source = await this.uploadImage(file, fileIdentifier, fileMetadata);
        await this.updateMultimediaSource();
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    async deleteMediaImage(){
      this.isLoading = true
      try {
        this.multimediaSource.source = await this.deleteImage(this.multimediaSource.source.key);
        await this.updateMultimediaSource();
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    async uploadMediaEffectImage(){
      this.isLoadingEffectSource = true
      const file = this.multimediaSource.effectSource.sourceFile;
      const fileIdentifier = `section-source-${this.multimediaSource._id}`;
      const fileMetadata = this.multimediaSource.effectSource.source;

      try {
        this.multimediaSource.effectSource.source = await this.uploadImage(file, fileIdentifier, fileMetadata);
        await this.updateMultimediaSource();
      } catch (error) {
        console.log(error)
      }
      this.isLoadingEffectSource = false
    },
    async deleteMediaEffectImage(){
      this.isLoadingEffectSource = true
      try {
        this.multimediaSource.effectSource.source = await this.deleteImage(this.multimediaSource.effectSource.source.key);
        await this.updateMultimediaSource();
      } catch (error) {
        console.log(error)
      }
      this.isLoadingEffectSource = false
    },
    async verifyMediaType(){
      if (this.multimediaSource.type !== 'image') {
          await this.deleteMediaImage()
        } else {
          this.updateMultimediaSource()
        }
    },
    async verifyMediaEffectType(){
      if (this.multimediaSource.effectSource.type !== 'image') {
          await this.deleteMediaEffectImage()
        } else {
          this.updateMultimediaSource()
        }
    },
    async removeResource() {
      this.$emit('removeResource')
      await this.deleteMediaImage()
      await this.deleteMediaEffectImage()
    },
  }
};
</script>

<style scoped>
.loading-wrapper {
  position: relative;
  min-height: 100px;
}
.separation-line{
  margin: 1rem 0;
  margin-bottom: 0.6rem;
  height: 2px;
}
</style>
