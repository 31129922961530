import PDFViewer from '@/screens/PDFViewer'

const pdf = [
  {
    path: '/ver-pdf',
    name: 'PDFViewer',
    component: PDFViewer
  }
]

export default pdf