<template>
  <b-collapse 
    class="simple-collapse mb-3" 
    animation="slide" 
    :aria-id="`dashboard-collapse-${sectionKey}`"
    :open.sync="isOpened"
    @open="openModal"
  >
    <!-- Botón para abrir la sección -->
    <template #trigger="props">
        <div
          class="card-header"
          role="button"
          :aria-id="`dashboard-collapse-${sectionKey}`"
          :aria-expanded="props.open">
          <p class="card-header-title is-size-5 has-text-weight-semibold has-text-primary">
            {{ title }}
            <f-icon :icon="icon" class="ml-2"/>
          </p>
          <a class="card-header-icon">
              <f-icon
                  class="has-text-primary"
                  :icon="props.open ? 'fa-regular fa-folder-open' : 'fa-regular fa-folder-closed'">
              </f-icon>
          </a>
        </div>
    </template>

    <!-- Sección de contenido -->
    <div class="card-content">
      <div
        v-if="!isLoading"
      > 
        <slot name="content"
          v-bind="$props"
        ></slot>
      </div>
  
      <div
        class="loading-wrapper"
        v-else
      >
        <b-loading 
          :is-full-page="false"
          :active="isLoading" 
        />
      </div>
    </div>

    <!-- Información de la sección -->
    <footer class="card-footer px-3 pb-3"
      v-if="description"
    >
      <div class="collapse-info">
        <f-icon icon="fa-regular fa-info-circle" class="has-text-primary"/>
        <span>{{ description }}</span>
      </div>
    </footer>
  </b-collapse>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    sectionKey: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fa-regular fa-chart-pie'
    },
    defaultOpened: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    data: {
    }
  },
  data() {
    return {
      isOpened: this.defaultOpened
    }
  },
  methods: {
    getIsOpened(){
      return this.isOpened
    },
    openModal(){
      this.isOpened = true
      this.$emit('open')
    }
  }
};
</script>

<style lang="scss" scoped>
.simple-collapse{
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 0 5px 0 #dcdcdc;
  background-color: #fff;
}
.description{
  margin-top: 2rem;
  border-top: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  gap: 0.5rem;
}

.collapse-info{
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
  width: 100%;
}

.loading-wrapper{
  position: relative;
  min-height: 100px;
}
</style>
