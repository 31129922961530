<template>
  <div>
    <div class="title">
      403, no tienes permisos para entrar aquí
    </div>
  </div>
</template>

<script>
export default {
  name: '403Error',
  mounted(){
    this.$router.push('/login')
  }
}
</script>

<style>

</style>