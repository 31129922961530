<template>
  <div class="container">
    <div
      class="columns is-multiline mb-0"
      v-if="data._id"
    >
      <!-- Info del candidato, vacante y regresar -->
      <div class="column is-12">
        <CandidateHeader
          :data="data"
          :query="query"
        />
      </div>

      <!-- Informacion de candidato y proceso  -->
      <div class="column is-full">
        <div class="columns">
          <div class="column is-8">
            <!-- Perfil de candidato -->
            <CandidateProfile
              :application="data"
              :email="user.email"
              class="profile-box"
            />

            <!-- KillerQuestions -->
            <CandidateKillerQuestionsAnswers
              v-if="data.killerQuestionsAnswers?.length"
              :killerQuestionsAnswers="data.killerQuestionsAnswers"
              class="profile-box"
            />

            <!-- Fat 6 -->   
            <div
              v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles) && data.promeritumInterviewSent"
              class="profile-box"
            >
              <!-- Info -->
              <CandidateProMeritumInterview 
                :form="data.form" 
                v-if="data.promeritumInterviewDone"
              />
              <!-- Form -->
              <ProMeritumInterviewForm 
                @promeritumInterviewUpdated="getCandidate" 
                v-else
              />
            </div>

            <!-- Custom evaluation  -->
            <div
              v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
              class="profile-box"
            >
              <!-- Info -->
              <CandidateCustomEvaluation 
                :profile="data.profile"  
                @editCustomEvaluation="editingCustomEvaluation = true"
                v-if="!editingCustomEvaluation"
              />
              <!-- Form -->
              <CustomEvaluationForm
                :profile="data.profile"
                ref="CustomEvaluationForm"
                @customEvaluationUpdated="getCandidate"
                v-else
              />
            </div>

            <!-- Feedback de empresa -->
            <div
              class="profile-box"
            >
              <!-- Info -->
              <CandidateFeedback
                v-if="data.companyInterviewDone || $hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
                :companyInterviewFeedback="data.companyInterviewFeedback" 
              />
              <!-- Form -->
              <CandidateFeedbackForm
                v-else
                @companyFeedbackUpdated="getCandidate"
              />
            </div>

            <!-- Hiring de candidato -->
            <CandidateHiring 
              class="profile-box"
              v-if="data.hiring" 
              :hiring:="data.hiring"
            />
          </div>

          <!-- Timeline  de aplicaciones -->
          <CandidateTimeLine
            v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
            class="column is-3 pr-2"
            :timeline="data.timeline"
          />
        </div>
      </div>

      <!-- Talentbar -->
      <TalentBar
        v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)"
        :candidate="data"
        @candidateUpdated="getCandidate"
      />
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import CandidateHeader from "@/components/Candidate/CandidateHeader.vue";
  import CandidateProfile from "@/components/Candidate/profile/CandidateProfile.vue";
  
  import CandidateKillerQuestionsAnswers from "@/components/Candidate/killer-questions/CandidateKillerQuestionsAnswers";

  import ProMeritumInterviewForm from "@/components/Candidate/promeritum-interview/ProMeritumInterviewForm.vue";
  import CandidateProMeritumInterview from "@/components/Candidate/promeritum-interview/CandidateProMeritumInterview.vue";

  import CandidateCustomEvaluation from "@/components/Candidate/custom-evaluation/CandidateCustomEvaluation.vue";
  import CustomEvaluationForm from "@/components/Candidate/custom-evaluation/CustomEvaluationForm.vue";

  import CandidateFeedback from "@/components/Candidate/feedback/CandidateFeedback.vue";
  import CandidateFeedbackForm from "@/components/Candidate/feedback/FeedbackForm.vue";

  import CandidateHiring from "@/components/Candidate/CandidateHiring.vue";
  import TalentBar from "@/components/Candidate/TalentBar";
  import CandidateTimeLine from "@/components/Candidate/CandidateTimeLine.vue";


  import { manageAllCandidatesAllowedRoles} from "@/lib/permissions";
  import { getCandidate } from "@/api/candidate";

  export default {
    components: {
      CandidateHeader,
      CandidateProfile,
      CandidateKillerQuestionsAnswers,
      ProMeritumInterviewForm,
      CandidateProMeritumInterview,
      CandidateCustomEvaluation,
      CustomEvaluationForm,
      CandidateFeedback,
      CandidateFeedbackForm,
      CandidateHiring,
      TalentBar,
      CandidateTimeLine,
    },
    data() {
      return {
        manageAllCandidatesAllowedRoles,
        isLoading: false,
        data: {},
        editingCustomEvaluation: false,
      };
    },
    computed: {
      user() {
        return this.$store.state.session.user;
      },
      id() {
        return this.$route.params.id;
      },
      query() {
        return this.$route.query;
      },
    },
    watch: {
      id() {
        this.getCandidate();
      },
    },
    mounted() {
      this.getCandidate();
      this.setSeenProfile();
    },
    methods: {
      async getCandidate() {
        this.isLoading = true;

        // Getting candidate data
        const candidateData = await getCandidate(this.id);
        this.data = candidateData;
        this.data.profile.email = candidateData.user.email

        // Setting editing modes to false
        this.editingCustomEvaluation = false;
        this.isLoading = false;
      },
      async setSeenProfile() {
        try {
          await this.$api.post(`application/${this.id}/set-seen-true`);
        } catch (error) {
          this.$buefy.toast.open({
            message: "Hubo un error al marcar como evaluado al candidato",
            type: "is-danger",
          });
        }
      },
    },
  };
</script>


<style scoped lang="scss">
  .profile-box {
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    background-color: white;
  }
</style>