<template>
  <div class="column is-12 m-0 is-sticky">
    <div class="card">
      <div class="card-content">
        <h4 class="title is-size-6 mb-1">Recursos de sección</h4>
        <div v-for="(multimediaSource, index) in mediaSection.multimediaSources" :key="index" class="mt-5">
          <b-collapse class="card" animation="slide" :aria-id="'contentIdForA11y' + index" :open="defaultMediaSource?._id == multimediaSource._id" :id="`media-source-collapse-${multimediaSource._id}`">
            <template #trigger="props">
              <div class="card-header is-flex is-justify-content-space-between is-align-items-center" role="button"
                :aria-controls="'contentIdForA11y' + index" :aria-expanded="props.open">
                <p class="card-header-title">Recurso {{ index + 1 }}</p>
                <f-icon :icon="props.open ? 'fa-regular fa-caret-down' : 'fa-regular fa-caret-up'" class="mr-2"/>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <AccordionSectionResource
                  :index="index"
                  :defaultMultimediaSource="multimediaSource"
                  :uploadImage="uploadImage" :deleteImage="deleteImage"
                  @updateMultimediaSource="updateMultimediaSource"
                  @removeResource="removeResource(index)"
                />
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="is-flex is-align-items-flex-end is-justify-content-flex-end mt-5">
          <b-button type="is-primary" @click="addResource">
            <f-icon icon="fa-regular fa-plus" class="mr-1" />
            Agregar recurso
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionSectionResource from '@/components/landings/multimedia/AccordionSectionResource.vue';

export default {
  components: {
    AccordionSectionResource,
  },
  props: {
    defaultMediaSection: {
      type: Object,
      default: () => ({})
    },
    defaultMediaSource: {
      type: Object,
      default: () => ({})
    },
    uploadImage: {
      type: Function
    },
    deleteImage: {
      type: Function
    },
  },
  data() {
    return {
      mediaSection: {
        multimediaSources: []
      },
    };
  },
  mounted() {
    this.mediaSection = {...this.mediaSection, ...this.defaultMediaSection}
  },
  watch: {
    defaultMediaSection: {
      handler(val) {
        if (val) {
          this.mediaSection = {...this.mediaSection, ...val}
        }
      },
      deep: true,
    },
    defaultMediaSource: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            document.getElementById(`media-source-collapse-${val._id}`).scrollIntoView({
              behavior: "smooth",
            });
          }, 1000)
        }
      },
      deep: true,
    },
  },
  methods: {
    updateMediaSections() {
      this.$emit('updateMediaSection', this.mediaSection);
    },
    addResource() {
      this.mediaSection.multimediaSources.push({
        type: "image",
        source: {
          url: ''
        },
        coordinates: {
          x: '0%',
          y: '0%'
        },
        sizes: {
          width: '30%',
          height: '20%'
        },
        onClick: '',
        effectSource: {
          type: 'image',
          effects: []
        }
      });
      this.updateMediaSections();
    },
    removeResource(index) {
      this.mediaSection.multimediaSources.splice(index, 1);
      this.updateMediaSections();
    },
    updateMultimediaSource(index, multimediaSource){
      this.mediaSection.multimediaSources[index] = multimediaSource;
      this.updateMediaSections();
    },
  }
};
</script>

<style scoped>
.loading-wrapper {
  position: relative;
  min-height: 100px;
}
</style>
