<template>
  <div class="bar-wrapper">
    <div class="columns">
      <p class="is-size-5 column is-7 mt-0 mb-0" v-if="title">
        {{ title }}
      </p>
      <p v-else class="is-size-5 column is-7 mt-0 mb-0"></p>
      <span class="column is-5 has-text-primary has-text-weight-medium has-text-right" v-if="total">Mostrando {{ total
        }} elemento(s)</span>
    </div>
    <div class="is-align-self-center bar">
      <ChartColumn v-if="total" :chartData="transformedData" :style="`min-height: ${chartHeight};`" :goals="goals"
        :rangeColors="rangeColors" />
      <div v-else class="has-text-centered is-flex is-flex-direction-column is-justify-content-center mt-6 mb-6">
        <f-icon icon="fa-regular fa-mug-hot" class="has-text-primary is-size-3 mb-3" />
        <p class="has-text-weight-normal">No se encontraron datos</p>
      </div>
    </div>
  </div>
</template>

<script>
import ChartColumn from '@/components/charts/ChartColumn';
import { dashboardTerms } from '@/lib/dashboardDictionaries';
const _ = require('lodash');

export default {
  name: "SimpleBarColumn",
  props: {
    barData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
    },
    termsKey: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      default: () => ['Stat', 'Cantidad']
    },
    chartHeight: {
      type: String,
      default: '200px'
    },
    goals: {
      type: Object,
      default: () => ({})
    },
    rangeColors: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ChartColumn,
  },
  data() {
    return {
      transformedData: null,
      total: 0
    }
  },
  watch: {
    barData: {
      handler: function (val) {
        this.transformedData = this.transformDataTable(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    transformDataTable(val) {
      const transformedData = []

      if (!val)
        return null

      // Creating chart format
      for (const key in val) {
        const stat = _.get(dashboardTerms, this.termsKey) ? _.get(dashboardTerms, this.termsKey)[key] : key
        const value = val[key]
        this.total += value

        transformedData.push([stat, value])
      }

      return [this.headers, ...transformedData]
    },
  }
};
</script>

<style lang="scss" scoped>
.bar-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.bar {
  width: 100%;
}
</style>
