<template>
  <div class="card mt-5 mb-5">
    <div class="card-content columns is-multiline">
      <div class="column is-12">
        <b-field label="Url de la marca">
          <b-input v-model="url" placeholder="Url de la marca" type="text" @blur="updateUrl" />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultUrl: {
      type: String,
    },
  },
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.url = this.defaultUrl;
  },
  watch: {
    defaultUrl: {
      handler: function (val) {
        this.url = val
      }
    },
  },
  methods: {
    updateUrl() {
      this.$emit('updateLandingProperty', this.url, 'url')
    },
  }
}
</script>

<style>
</style>
