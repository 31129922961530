<template>
  <div>
    <div class="columns is-multiline mb-1">
      <div class="column is-full">
        <div class="title is-4 has-text-blue">
          {{ vacancySourceTranslation.vacancyFoundSource }}
        </div>
        <div v-if="application.vacancyFoundSource">
          {{ application.vacancyFoundSource }}
        </div>
        <div v-else>
          {{ vacancySourceTranslation.noRecordedVacancyFound }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    application: {
      default: () => ({}),
      required: true
    },
  },
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    vacancySourceTranslation() {
      return this.$t("screens.candidate.detail");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>