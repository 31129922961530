const mxSemesters = [
  { text: 'Primer semestre', value: 1 },
  { text: 'Segundo semestre', value: 2 },
  { text: 'Tercer semestre', value: 3 },
  { text: 'Cuarto semestre', value: 4 },
  { text: 'Quinto semestre', value: 5 },
  { text: 'Sexto semestre', value: 6 },
  { text: 'Séptimo semestre', value: 7 },
  { text: 'Octavo semestre', value: 8 },
  { text: 'Noveno semestre', value: 9 },
  { text: 'Décimo semestre', value: 10 },
  { text: 'Décimo primer semestre', value: 11 },
  { text: 'Décimo segundo semestre', value: 12 },
];

const esSemesters = [
  { text: 'Recién graduado', value: 0 },
  { text: 'Primer curso', value: 1 },
  { text: 'Segundo curso', value: 2 },
  { text: 'Tercer curso', value: 3 },
  { text: 'Cuarto curso', value: 4 },
  { text: 'Quinto curso', value: 5 },
  { text: 'Sexto curso', value: 6 },
]

export default mxSemesters;

export function getSemestersList (location) {
  if (location) {
    return location === 'mx' ? mxSemesters : esSemesters;
  }
  return [];
}
